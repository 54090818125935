import React, { useState, useEffect, useRef, Fragment } from 'react'
import ReactApexChart from 'react-apexcharts'
import uuid from 'react-uuid'
import EndPoints from '../../../services/api'
import axios from '../../../services/axios'
import ClickWrapper from '../click-wrapper/ClickWrapper'
import Loader from '../loader/Loader'

const btnArray = [
  {
    title: '7 D',
    value: '7'
  },
  {
    title: '1 M',
    value: '30'
  },
  {
    title: '3 M',
    value: '90'
  },
  {
    title: '6 M',
    value: '180'
  }
]

const ResponsiveBucketChart = ({ id, staticData }) => {
  const { long_desc } = staticData
  const series = [
    {
      name: 'price',
      data: []
    }
  ]

  const options = {
    chart: {
      type: 'area',
      foreColor: '#ffffff',
      toolbar: {
        show: false
      }
    },

    dataLabels: {
      enabled: false
    },
    stroke: {
      curve: 'smooth'
    },
    grid: {
      show: false
    },

    xaxis: {
      type: 'datetime',

      categories: []
    },
    tooltip: {
      x: {
        format: 'dd/MM/yy HH:mm'
      },
      theme: 'dark'
    },
    yaxis: {
      labels: {
        show: true,
        formatter: (value) => {
          return value.toFixed(2) + '%'
        }
      }
    }
  }

  const [duration, setDuration] = useState(btnArray[0].value)
  const [loading, setLoading] = useState(true)
  const [chartSeries, setChartSeries] = useState(series)
  const [chartOptions, setChartOptions] = useState(options)
  const [showInfo, setShowInfo] = useState(false)
  const [perChange, setPerChange] = useState(0)

  const { getSingleBucketGraphData } = EndPoints.singleBucket

  const fetchSingleBucketGraphData = async () => {
    setLoading(true)
    try {
      const { data } = await axios.get(
        `${getSingleBucketGraphData}?duration=${duration}&crfno=${id}`
      )
      const parsedData = JSON.parse(data.body)

      setChartSeries([
        {
          name: 'percent',
          data: parsedData.percent
        }
      ])
      setPerChange(parsedData.percent[0] - parsedData.percent[parsedData.percent.length - 1])
      setChartOptions({
        ...chartOptions,
        xaxis: {
          ...chartOptions.xaxis,

          categories: parsedData.date
        }
      })

      setLoading(false)
    } catch (err) {
      console.log(err.message)
    }
  }

  useEffect(() => {
    fetchSingleBucketGraphData()
  }, [duration])

  return (
    <div className="flex lg:hidden ">
      <div className=" bg-purple-400 bg-opacity-10 flex-1  rounded-xl p-4 px-2 sm:px-4 relative ">
        <div className="flex justify-between items-start">
          <div>
            <div className="flex gap-3 items-center">
              <p className="font-600">Portfolio Chart</p>
              <p className={`font-600 text-${perChange >= 0 ? 'green' : 'red'}-500`}>
                {perChange.toFixed(2)} %{' '}
              </p>
            </div>
            <button onClick={() => setShowInfo(true)} className="font-600 text-xs text-blue-400">
              Bucket Information
            </button>
          </div>

          <div className="grid grid-cols-2 gap-2 md:grid-cols-4">
            {btnArray.map((btn) => (
              <button
                onClick={() => setDuration(btn.value)}
                className={`p-1 px-2 m-1 text-xs rounded-full ${
                  duration === btn.value ? 'bg-purple-dark' : ''
                } `}
                key={uuid()}>
                {btn.title}
              </button>
            ))}
          </div>
        </div>
        {showInfo && (
          <div className="bg-purple-dark p-4 mx-2 rounded-xl text-xs flex flex-col justify-evenly absolute top-15 left-5 shadow-lg z-10 max-w-xs">
            <ClickWrapper func={() => setShowInfo(false)}>
              <p className="text-center mb-3 ">Sector - Currency Bucket</p>
              <p className="my-3">{long_desc}</p>
            </ClickWrapper>
          </div>
        )}
        {loading ? (
          <Loader />
        ) : (
          <Fragment>
            <ReactApexChart
              options={chartOptions}
              series={chartSeries}
              type="area"
              height={250}
              className="hidden sm:block"
            />
            <ReactApexChart
              options={chartOptions}
              series={chartSeries}
              type="area"
              height={150}
              className="block sm:hidden"
            />
          </Fragment>
        )}
      </div>
    </div>
  )
}

export default ResponsiveBucketChart
