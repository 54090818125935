import React from 'react'
import ReactApexChart from 'react-apexcharts'
import { includeComma } from '../../../services/globalFunctions'

const SectorDonut = ({ header, data }) => {
  const { labels, series } = data
  const seriesData = series
  const options = {
    chart: {
      type: 'donut',
      foreColor: '#ffffff'
    },
    stroke: {
      width: 0
    },
    plotOptions: {
      pie: {
        startAngle: 0,
        endAngle: 360
      }
    },
    labels: labels,
    dataLabels: {
      enabled: true
    },
    fill: {
      type: 'gradient'
    },

    legend: {
      formatter: function (val, opts) {
        if (typeof val === 'string') {
          return val + ' - ' + opts.w.globals.series[opts.seriesIndex].toFixed(2) + '%'
        } else if (typeof val === 'number') {
          return (
            includeComma((parseFloat(val) / 1000000000).toFixed(2)) +
            ' B - ' +
            opts.w.globals.series[opts.seriesIndex] +
            '%'
          )
        }
      },
      position: 'bottom'
    },
    title: {
      text: `Investment by ${header}`,
      align: 'center',
      style: {
        fontSize: '10px'
      }
    }
  }

  return (
    <div className="bg-purple-400 bg-opacity-10 h-full w-full p-6 lg:px-1 xl:p-10  rounded-xl ">
      <div id="chart" className="grid place-items-center text-white text-xs text-center">
        <ReactApexChart
          options={options}
          series={seriesData}
          type="donut"
          height={400}
          className="w-full h-full grid place-items-center"
        />
      </div>
    </div>
  )
}

export default SectorDonut
