import React from 'react'
import { makeStyles } from '@material-ui/core'
import clsx from 'clsx'
import SingleResponsiveTable from './SingleResponsiveTable'
import uuid from 'react-uuid'

const useStyles = makeStyles((theme) => ({
  hiddenScrollBar: {
    '&::-webkit-scrollbar': {
      display: 'none'
    }
  }
}))

const ResponsiveTable = ({ data }) => {
  const classes = useStyles()
  return (
    <div className={clsx(' overflow-scroll flex flex-col  px-3 md:px-10 md:pt-4  lg:hidden')}>
      {data.map((singleData) => (
        <SingleResponsiveTable key={uuid()} {...singleData} />
      ))}
      {data.length === 0 && (
        <p className="text-center w-full font-600 py-4 my-4 text-gray-300">
          No Recommendation for now .
        </p>
      )}
    </div>
  )
}

export default ResponsiveTable
