import React, { useState, useEffect } from 'react'
import { FcDeleteDatabase } from 'react-icons/fc'
import { useSelector } from 'react-redux'
import EndPoints from '../../../services/api'
import axios from '../../../services/axios'
import { includeComma } from '../../../services/globalFunctions'
import DataLoader from '../loader/DataLoader'

const SingleResponsiveTable = ({ data, id, refresh }) => {
  const { assetname, change, changepercent, currentprice, timestamp } = data
  const assetId = id.replace(/[^0-9.]+/g, '')
  const { user } = useSelector((state) => state.auth)
  const [staticData, setStaticData] = useState({})
  const [loading, setLoading] = useState(true)
  const { getStaticData, getSingleCoin24hChange, addToWatchlistEndpoint } = EndPoints.watchList
  const fetchSingleWatchList = async () => {
    try {
      const res = await Promise.all([
        axios.get(`${getStaticData}?coinid=${assetId}`),
        axios.get(`${getSingleCoin24hChange}?coinid=${assetId}`)
      ])

      const singleWatchListData = res.map((res) => JSON.parse(res.data.body))
      const { symbol, logo } = singleWatchListData[0][`${assetId}`]
      const { change24h } = singleWatchListData[1][`${assetId}`]
      setStaticData({
        symbol,
        logo,
        change24h
      })
      setLoading(false)
    } catch (err) {
      console.log(err.message)
    }
  }

  const removeFromWatchlist = async () => {
    const config = {
      headers: {
        Authorization: `Bearer ${user.token}`
      }
    }
    try {
      const { data } = await axios.post(
        `${addToWatchlistEndpoint}?user_id=${user.user_id}`,
        {
          assetid: assetId,
          userid: user.user_id,
          command: 'REMOVE'
        },
        config
      )
      //console.log(data)
      refresh()
    } catch (err) {
      console.log(err.message)
    }
  }

  useEffect(() => {
    fetchSingleWatchList()
  }, [])

  return (
    <div className="bg-purple-400 bg-opacity-10 rounded-2xl  p-3 sm:px-4 sm:py-6 my-3 cursor-pointer">
      <div className="flex justify-between mb-4 ">
        <div className="flex items-center ">
          <div className=" h-8 w-8 self-start mt-2">
            {loading ? (
              <DataLoader />
            ) : (
              <img
                className="w-full h-full object-contain"
                src={staticData.logo}
                alt={assetname}
              />
            )}
          </div>

          <div className="flex flex-col ml-2">
            <h4 className="text-lg font-600 mb-1">{assetname} </h4>
            {!loading && (
              <p className="text-xs flex gap-2">
                {staticData.symbol}
                <button
                  className="grid place-items-center  text-sm font-bold bg-purple-darker rounded-full "
                  onClick={removeFromWatchlist}>
                  <FcDeleteDatabase />
                </button>
              </p>
            )}
          </div>
        </div>

        <div className="flex flex-col items-center py-1">
          <p className="text-xs ">Current Price</p>
          <p className="text-xl font-600">$ {includeComma(currentprice)} </p>
        </div>
      </div>

      <div className="flex justify-between items-center text-xs">
        <div className="mr-4 text-center flex flex-col">
          <p className="my-2">Watchlist Date :</p>
          <p className="text-sm sm:text-lg font-600">{timestamp}</p>
        </div>
        <div className="text-center flex flex-col">
          <p className="my-2">24hr Change :</p>
          {loading ? (
            <DataLoader />
          ) : (
            <p
              className={`text-sm sm:text-lg font-600 text-${
                staticData.change24h >= 0 ? 'green-500' : 'red-500'
              }`}>
              {' '}
              {staticData.change24h} %
            </p>
          )}
        </div>

        <div className="ml-4 text-center flex flex-col">
          <p className="my-2">%Change Since :</p>
          <p
            className={`text-sm sm:text-lg font-600 text-${
              change >= 0 ? 'green-500' : 'red-500'
            }`}>
            {includeComma(change.toFixed(2))} ( {changepercent} % )
          </p>
        </div>
      </div>
    </div>
  )
}

export default SingleResponsiveTable
