import React, { useState, useEffect, Fragment } from 'react'
import uuid from 'react-uuid'
import axios from '../../../services/axios'
import ReactApexChart from 'react-apexcharts'
import Loader from '../loader/Loader'
import { dark } from '@material-ui/core/styles/createPalette'
import EndPoints from '../../../services/api'
import useDidMountEffect from '../../hooks/useDidMountEffect'
import { includeComma } from '../../../services/globalFunctions'
import { useSelector } from 'react-redux'

const btnArray = [
  // {
  //   title: '7 D',
  //   value: '7',
  //   price_value: 'week'
  // },
  {
    title: '1 M',
    value: '30',
    price_value: 'month'
  },
  {
    title: '1 Y',
    value: '365',
    price_value: 'year'
  }
]

const ComparisionChart = ({ coinid, setShowAuthModal }) => {
  const { loggedIn } = useSelector((state) => state.auth)
  const series = []

  const getMin = (array) => {
    return 0.995 * Math.min.apply(null, array)
  }
  const options = {
    chart: {
      foreColor: '#ffffff',
      toolbar: {
        show: false
      },
      type: 'line'
    },
    dataLabels: {
      enabled: false
    },
    stroke: {
      curve: 'smooth'
    },
    grid: {
      show: false
    },
    xaxis: {
      type: 'datetime',
      categories: []
    },

    yaxis: [],
    tooltip: {
      x: {
        format: 'dd/MM/yy'
      },
      theme: 'dark'
    },
    responsive: [
      {
        breakpoint: 786,
        options: {
          height: 150,
          yaxis: [
            {
              labels: {
                show: false
              }
            },
            {
              labels: {
                show: false
              }
            }
          ]
        }
      }
    ]
  }

  const ratingArray = [
    {
      title: 'Social Score',
      id: 1
    },
    {
      title: 'Google Trends',
      id: 2
    },
    {
      title: 'CRF Score',
      id: 3
    }
  ]

  const [duration, setDuration] = useState(btnArray[0])
  const [rating, setRating] = useState(ratingArray[0])
  const [loading, setLoading] = useState(false)
  const [chartSeries, setChartSeries] = useState(series)
  const [chartOptions, setChartOptions] = useState(options)
  const [chartData, setChartData] = useState([])

  const { getSingleCoinTrends, getSingleCoinRatings, getChartData } = EndPoints.singleCoin

  const fetchChartData = async () => {
    setLoading(true)
    try {
      const res = await Promise.all([
        axios.get(`${getSingleCoinTrends}?coin_id=${coinid}&time_period=${duration.value}`),
        axios.get(`${getSingleCoinRatings}?coin_id=${coinid}&time_period=${duration.value}`),
        axios.get(`${getChartData}?coinid=${coinid}&duration=${duration.value}&type=DAY`)
      ])

      const newData = res.map((res) => JSON.parse(res.data.body))
      console.log(newData)
      setChartData(newData)
      setChartSeries([
        {
          name: rating.title,
          data: newData[1].message.map((rating) => rating.SocialScore)
        },
        {
          name: 'Price',
          data: newData[2].prices
        }
      ])
      setChartOptions({
        ...chartOptions,
        xaxis: {
          ...chartOptions.xaxis,

          categories: newData[2].timestamps
        },
        yaxis: [
          {
            opposite: true,
            tickAmount: 6,
            min: getMin(newData[1].message.map((rating) => rating.SocialScore)),
            axisTicks: {
              show: false
            },
            axisBorder: {
              show: false
            },
            labels: {
              formatter: function (val) {
                return !val ? 0 : includeComma(val.toFixed(2))
              }
            }
          },

          {
            tickAmount: 6,
            min: getMin(newData[2].prices),
            axisTicks: {
              show: false
            },
            axisBorder: {
              show: false
            },
            labels: {
              formatter: function (val) {
                return !val ? 0 : includeComma(val.toFixed(2))
              }
            }
          }
        ]
      })
      setLoading(false)
    } catch (err) {
      console.log(err.message)
    }
  }

  useEffect(() => {
    fetchChartData()
  }, [duration])

  useDidMountEffect(() => {
    //console.log(chartData)
    setChartSeries([
      {
        name: rating.title,
        data:
          rating.id === 1
            ? chartData[2].prices.map((_, idx) =>
                chartData[1].message[idx] ? chartData[1].message[idx]?.SocialScore : null
              )
            : rating.id === 2
            ? chartData[2].prices.map((_, idx) =>
                chartData[0].message[idx] ? chartData[0].message[idx]?.score : null
              )
            : chartData[2].prices.map((_, idx) =>
                chartData[1].message[idx] ? chartData[1].message[idx]?.CRFrating : null
              )
      },
      {
        name: 'Price',
        data: chartData[2].prices
      }
    ])
    setChartOptions({
      ...chartOptions,
      yaxis: [
        {
          tickAmount: 6,
          opposite: true,
          min: getMin(
            rating.id === 1
              ? chartData[1].message.map((rating) => rating.SocialScore)
              : rating.id === 2
              ? chartData[0].message.map((trend) => trend.score)
              : chartData[1].message.map((rating) => rating.CRFrating)
          ),
          axisTicks: {
            show: true
          },
          axisBorder: {
            show: true
          }
        },

        {
          tickAmount: 6,
          min: getMin(chartData[2].prices),
          axisTicks: {
            show: true
          },
          axisBorder: {
            show: true
          }
        }
      ]
    })
  }, [rating])

  return (
    <div className=" ">
      <div className="bg-purple-400 bg-opacity-10  flex-1 mx-2 rounded-xl p-2 sm:p-4  py-4 text-white ">
        <div className="flex flex-col md:flex-row gap-2 md:gap-4 md:justify-between md:items-center">
          <div className="flex gap-2">
            {ratingArray.map((btn) => (
              <button
                onClick={() => setRating(btn)}
                className={`p-2 md:px-3  text-xs rounded-full bg-opacity-10 ${
                  rating.id === btn.id ? 'bg-purple-dark' : '  '
                } `}
                key={uuid()}>
                {btn.title}
              </button>
            ))}
          </div>

          <div className="flex items-center ">
            {btnArray.map((btn) => (
              <button
                onClick={() => {
                  if (btn.value === '365') {
                    if (!loggedIn) {
                      setShowAuthModal(true)
                      return
                    }
                  }
                  setDuration(btn)
                }}
                className={`p-1 px-2 m-1 text-xs rounded-full bg-opacity-10 ${
                  duration.value === btn.value ? 'bg-purple-dark' : '  '
                } `}
                key={uuid()}>
                {btn.title}
              </button>
            ))}
          </div>
        </div>
        {loading ? (
          <Loader />
        ) : (
          <Fragment>
            <div className="hidden sm:block">
              {' '}
              <ReactApexChart
                options={chartOptions}
                series={chartSeries}
                type="line"
                height={350}
              />
            </div>
            <div className="sm:hidden">
              {' '}
              <ReactApexChart options={chartOptions} series={chartSeries} type="line" />
            </div>
          </Fragment>
        )}
      </div>
    </div>
  )
}

export default ComparisionChart
