import { consoleSandbox } from '@sentry/utils'
import React, { useState } from 'react'
import { MdRadioButtonChecked, MdRadioButtonUnchecked } from 'react-icons/md'
import uuid from 'react-uuid'

const ContactForm = () => {
  const [contactFormDetail, setContactFormDetail] = useState({
    name: '',
    email: '',
    message: '',
    type: ''
  })

  const types = [
    {
      name: 'New Feature',
      value: 'new-feature',
      checked: false
    },
    {
      name: 'Feedback',
      value: 'feedback',
      checked: false
    },
    {
      name: 'Partnership',
      value: 'partnership',
      checked: false
    },
    {
      name: 'Investment',
      value: 'investment',
      checked: false
    }
  ]

  const [messageType, setMessagetype] = useState(types)

  const handleTypeChange = (e, val) => {
    const index = types.findIndex((type) => type.value === val)

    const newMessageType = types.map((type, idx) => {
      if (idx === index) return { ...types[index], checked: true }
      return type
    })
    setMessagetype(newMessageType)
    setContactFormDetail({
      ...contactFormDetail,
      type: val
    })
  }

  const handleContactFormSubmit = () => {
    if (contactFormDetail.email.length === 0) return
    console.log(contactFormDetail)
  }

  return (
    <div className="flex flex-col  items-end gap-4 w-full  text-gray-500 max-w-2xl">
      <h1 className="text-blue-900 font-semibold text-3xl md:text-5xl text-center w-full mb-6 md:mb-10 ">
        Contact Us
      </h1>
      <div className="flex flex-col gap-8 w-full ">
        <div className="flex gap-4 items-center">
          <label className="flex flex-col text-xs w-full  text-left font-600 gap-2 z-0 ">
            <p className="ml-2 ">Full Name</p>
            <input
              className="p-4 rounded-md border-2 font-600 border-gray-300 placeholder-gray-400"
              type="text"
              value={contactFormDetail.name}
              onChange={(e) =>
                setContactFormDetail({
                  ...contactFormDetail,
                  name: e.target.value
                })
              }
              placeholder="Enter your full name"
              name="text"
              style={{ width: '100%' }}
            />
          </label>
          <label className="flex flex-col text-xs w-full   text-left font-600 gap-2 z-0 ">
            <p className="ml-2 ">Email</p>
            <input
              className="p-4 rounded-md border-2 font-600 border-gray-300 placeholder-gray-400"
              type="email"
              value={contactFormDetail.email}
              onChange={(e) =>
                setContactFormDetail({
                  ...contactFormDetail,
                  email: e.target.value
                })
              }
              placeholder="Enter your Email"
              name="email"
              style={{ width: '100%' }}
            />
          </label>
        </div>

        <div className="col-span-2 flex flex-col gap-3 text-xs text-left ">
          <p>What's with the website you need ?</p>
          <div className="flex flex-wrap justify-between items-center gap-3">
            {messageType.map((type) => (
              <label key={uuid()} className="flex cursor-pointer gap-2 items-center ">
                {type.checked ? (
                  <MdRadioButtonChecked className="text-blue-500 text-xl" />
                ) : (
                  <MdRadioButtonUnchecked className="text-blue-500 text-xl" />
                )}

                <input
                  className="hidden"
                  type="checkbox"
                  value={type.checked}
                  onChange={(e) => handleTypeChange(e, type.value)}
                />
                <p>{type.name}</p>
              </label>
            ))}
          </div>
        </div>
        <label className="flex flex-col col-span-2 text-xs flex-1 text-left font-600 gap-2 z-0 ">
          <p className="ml-2 ">Message</p>
          <textarea
            className="p-4 resize-none h-40 rounded-md border-2 font-600 border-gray-300 placeholder-gray-400"
            type="text"
            value={contactFormDetail.message}
            onChange={(e) =>
              setContactFormDetail({
                ...contactFormDetail,
                message: e.target.value
              })
            }
            placeholder="Type your message"
            name="text"
          />
        </label>
      </div>
      <button
        onClick={handleContactFormSubmit}
        disabled={contactFormDetail.email.length === 0 || contactFormDetail.name.length === 0}
        className="bg-blue-500 rounded-sm text-white  hover:bg-blue-400 grid place-items-center text-sm p-3 py-2 disabled:cursor-not-allowed ">
        Send Message
      </button>
    </div>
  )
}

export default ContactForm
