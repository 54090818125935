import React, { useState } from 'react'
import { RiArrowDropDownLine, RiArrowDropUpLine } from 'react-icons/ri'
import { Link, useLocation } from 'react-router-dom'
import uuid from 'react-uuid'

const SingleSidebarLink = ({ menu, setShowSidebar }) => {
  const location = useLocation()
  const [showsubMenu, setShowsubMenu] = useState(false)
  return (
    <div className={`${showsubMenu && 'bg-purple-400 bg-opacity-10 rounded-lg'}`} key={uuid()}>
      <div className="hidden xl:flex flex-col ">
        <div
          className={`${
            location.pathname === menu.target
              ? 'text-blue-400  bg-purple-400 bg-opacity-10'
              : 'text-gray-100'
          }  w-full text-left text-sm font-500  items-center flex justify-between  hover:bg-purple-400 hover:bg-opacity-10 p-3 rounded-lg my-1   `}>
          {' '}
          <Link className="flex-1" onClick={() => setShowSidebar(false)} to={menu.target}>
            {menu.title}
          </Link>
          {menu.child.length > 0 && (
            <button
              className="text-3xl font-bold"
              onClick={() => {
                setShowsubMenu(!showsubMenu)
              }}>
              {showsubMenu ? <RiArrowDropUpLine /> : <RiArrowDropDownLine />}
            </button>
          )}
        </div>
        {menu.child.length > 0 && showsubMenu && (
          <div className={`  flex flex-col rounded-lg  gap-1   `}>
            {menu.child.map((submenu) => (
              <Link
                key={uuid()}
                to={submenu.target}
                onClick={() => setShowSidebar(false)}
                className={`${
                  location.pathname === submenu.target
                    ? 'text-blue-400  bg-purple-400 bg-opacity-10'
                    : 'text-gray-100'
                }  w-full text-left text-xs font-500  p-3 pl-6 hover:bg-purple-400 hover:bg-opacity-10 rounded-lg   `}>
                {submenu.title}
              </Link>
            ))}
          </div>
        )}
      </div>

      <div className="flex xl:hidden flex-col ">
        <div
          className={`${
            location.pathname === menu.target
              ? 'text-blue-400  bg-purple-400 bg-opacity-10'
              : 'text-gray-100'
          }  w-full text-left text-sm font-500  items-center flex justify-between  hover:bg-purple-400 hover:bg-opacity-10 p-3 rounded-lg my-1   `}>
          {' '}
          <Link className="flex-1" onClick={() => setShowSidebar(false)} to={menu.target}>
            {menu.title}
          </Link>
          {menu.child.length > 0 && (
            <button
              className="text-3xl font-bold"
              onClick={() => {
                setShowsubMenu(!showsubMenu)
              }}>
              {showsubMenu ? <RiArrowDropUpLine /> : <RiArrowDropDownLine />}
            </button>
          )}
        </div>
        {menu.child.length > 0 && showsubMenu && (
          <div className={`  flex flex-col rounded-lg  gap-1  `}>
            {menu.child.map((submenu) => (
              <Link
                key={uuid()}
                to={submenu.target}
                onClick={() => setShowSidebar(false)}
                className={`${
                  location.pathname === submenu.target
                    ? 'text-blue-400  bg-purple-400 bg-opacity-10'
                    : 'text-gray-100'
                }  w-full text-left text-xs font-500  p-3 pl-6 hover:bg-purple-400 hover:bg-opacity-10 rounded-lg   `}>
                {submenu.title}
              </Link>
            ))}
          </div>
        )}
      </div>
    </div>
  )
}

export default SingleSidebarLink
