export const TableHeader = [
  {
    title: 'Asset',
    toolText: ''
  },
  {
    title: 'Price',
    toolText: ''
  },
  {
    title: '24 H',
    toolText: ''
  },
  {
    title: '7 D',
    toolText: ''
  },
  {
    title: 'MKTCAP',
    toolText: ''
  },
  {
    title: 'Category',
    toolText:
      'Crypto Coins ( Curriencies and tokens) fall into one of the few segements : Currencies , Smart Contract platforms , Stablecoins , Centralized Exchanges, Defi, Web3, Decentralized Exchanges, Privacy.'
  },
  {
    title: 'Coin Limit',
    toolText:
      'Total numbers of Token or Coin which can be generated. Only 21million bitcoins can be generated.'
  },
  {
    title: 'Code Activity',
    toolText:
      'We track coding activity of blockchain project. High activity signifiies regular code changes and a +ve sign of blockchain project.'
  },
  {
    title: 'Rating',
    toolText: 'We track the fundamental value of the coin. Higher the number more stable the coin.'
  },
  {
    title: 'Trade',
    toolText: ''
  }
]
