import React, { Fragment } from 'react'
import { ErrorBoundary } from 'react-error-boundary'
import uuid from 'react-uuid'
import ErrorComponent from '../../../error/ErrorComponent'
import SingleNewsCard from '../SingleNewsCard'

const NewsTab = ({ newsData }) => {
  return (
    <div className="h-full overflow-scroll overflow-x-hidden w-full">
      <div className="h-full">
        <div className="p-2">
          <p className="border-2 border-purple-900 p-2 rounded-full max-w-max text-xs font-600 mb-5">
            Breaking News
          </p>
        </div>

        <div className=" grid gap-2 grid-cols-2 lg:grid-cols-3  ">
          <ErrorBoundary FallbackComponent={ErrorComponent}>
            {Object.keys(newsData).length > 0 ? (
              Object.keys(newsData).map((news) => (
                <SingleNewsCard key={uuid()} news={newsData[news]} />
              ))
            ) : (
              <Fragment>
                <p className="text-center w-full font-600 py-4 my-4 text-gray-300">
                  Unfortunately , No News.
                </p>
              </Fragment>
            )}
          </ErrorBoundary>
        </div>

        <div className="pb-52"></div>
      </div>
    </div>
  )
}

export default NewsTab
