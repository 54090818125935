import React, { useState, useEffect, useRef } from 'react'
import ReactApexChart from 'react-apexcharts'
import EndPoints from '../../../services/api'
import axios from '../../../services/axios'
import ClickWrapper from '../click-wrapper/ClickWrapper'
import Loader from '../loader/Loader'

const BucketPerformanceChart = ({ id }) => {
  const series = [
    {
      name: 'Net Profit',
      data: []
    }
  ]

  const options = {
    chart: {
      type: 'bar',
      foreColor: '#ffffff',
      toolbar: {
        show: false
      }
    },
    plotOptions: {
      bar: {
        borderRadius: 10,
        dataLabels: {
          position: 'top'
        }
      }
    },
    grid: {
      show: false
    },
    dataLabels: {
      enabled: false,

      offsetY: -20,
      style: {
        fontSize: '12px'
      }
    },
    tooltip: {
      theme: 'dark'
    },

    xaxis: {
      categories: [],
      position: 'bottom',
      axisBorder: {
        show: true
      },
      axisTicks: {
        show: false
      },
      crosshairs: {
        fill: {
          type: 'gradient',
          gradient: {
            colorFrom: '#D8E3F0',
            colorTo: '#BED1E6',
            stops: [0, 100],
            opacityFrom: 0.4,
            opacityTo: 0.5
          }
        }
      }
    },
    yaxis: {
      axisBorder: {
        show: false
      },
      axisTicks: {
        show: false
      },
      labels: {
        show: true,
        formatter: (value) => {
          return value.toFixed(0) + '%'
        }
      }
    }
  }

  const [loading, setLoading] = useState(true)
  const [chartSeries, setChartSeries] = useState(series)
  const [chartOptions, setChartOptions] = useState(options)
  const [showInfo, setShowInfo] = useState(false)

  const { getSingleBucketComparisionData } = EndPoints.singleBucket

  const fetchSingleBucketGraphData = async () => {
    setLoading(true)
    try {
      const { data } = await axios.get(`${getSingleBucketComparisionData}?bucket_id=${id}`)
      const parsedData = JSON.parse(data.body).message
      //console.log(parsedData)
      const categories = Object.keys(parsedData)
      const currSeries = categories.map((category) => parsedData[category].current)
      setChartSeries([
        {
          name: 'Current',
          data: currSeries
        }
      ])
      setChartOptions({
        ...chartOptions,
        xaxis: {
          ...chartOptions.xaxis,

          categories
        }
      })

      setLoading(false)
    } catch (err) {
      console.log(err.message)
    }
  }

  useEffect(() => {
    fetchSingleBucketGraphData()
  }, [])

  return (
    <div className="my-10 flex  gap-4 ">
      <div className="bg-purple-400 bg-opacity-10 md:px-4 py-6 rounded-2xl flex-1 relative ">
        {loading ? (
          <Loader />
        ) : (
          <div className="">
            <div className="px-3">
              <div className="flex gap-3 items-center">
                <p className="font-600">Portfolio Chart</p>
              </div>
              <button
                onClick={() => setShowInfo(true)}
                className="md:hidden font-600 text-xs text-blue-400">
                Bucket Information
              </button>
            </div>
            {showInfo && (
              <div className="bg-purple-dark p-4 mx-2 rounded-xl text-xs flex flex-col justify-evenly absolute top-15 left-5 shadow-lg z-10  max-w-xs">
                <ClickWrapper func={() => setShowInfo(false)}>
                  <p className="text-center mb-3 ">Sector - Currency Bucket</p>
                  <p className="my-3">
                    Et debitis quod qui consequuntur voluptas est voluptatem corrupti et optio
                    sunt! Sed repellat incidunt est sunt totam eum quia quia. Qui distinctio
                    corrupti rem odit mollitia eum voluptatibus Quis 33 consectetur ipsam quo
                    distinctio officia vel unde consequatur vel blanditiis quia.
                  </p>
                </ClickWrapper>
              </div>
            )}
            <div className="text-white " id="chart">
              <ReactApexChart
                options={chartOptions}
                series={chartSeries}
                type="bar"
                height={280}
              />
            </div>
          </div>
        )}
      </div>
      <div className="rounded-2xl p-6 bg-purple-darker  hidden md:flex flex-col  text-xs leading-relaxed flex-1 max-w-xs">
        <p className="text-center mb-3 ">Sector - Currency Bucket</p>
        <p className="my-3">
          Et debitis quod qui consequuntur voluptas est voluptatem corrupti et optio sunt! Sed
          repellat incidunt est sunt totam eum quia quia. Qui distinctio corrupti rem odit mollitia
          eum voluptatibus Quis 33 consectetur ipsam quo distinctio officia vel unde consequatur
          vel blanditiis quia.
        </p>
      </div>
    </div>
  )
}

export default BucketPerformanceChart
