import React, { useEffect, useState } from 'react'
import uuid from 'react-uuid'
import EndPoints from '../../../services/api'
import axios from '../../../services/axios'
import DataLoader from '../loader/DataLoader'
import Loader from '../loader/Loader'

const SingleIndex = ({ data }) => {
  const {
    CRFBlock_no,
    allocation,
    assetid,
    coin_id,
    coin_name,
    createPrice,
    created_at,
    details,
    market_cap,
    sectors
  } = data
  const [staticData, setStaticData] = useState({})
  const [loading, setLoading] = useState(false)
  const { getStaticData } = EndPoints.watchList

  const fetchStaticData = async (assetId) => {
    try {
      const { data } = await axios.get(`${getStaticData}?coinid=${assetId}`)
      const { symbol, logo } = JSON.parse(data.body)[`${assetId}`]

      setStaticData({
        symbol,
        logo
      })
      setLoading(false)
    } catch (err) {
      console.log(err.message)
    }
  }
  useEffect(() => {
    fetchStaticData(coin_id)
  }, [])
  return (
    <tr className="text-xs  font-600  ">
      <td className="grid place-items-center h-full w-full">
        {loading ? (
          <DataLoader />
        ) : (
          <img className="w-6 h-6 object-contain" src={staticData.logo} alt={coin_name} />
        )}
      </td>

      <td className="text-center">
        <p>{allocation} %</p>
      </td>
    </tr>
  )
}

export default SingleIndex
