import React, { useState, useEffect } from 'react'
import { useLocation } from 'react-router-dom'
import axios from '../../../services/axios'
import { includeComma } from '../../../services/globalFunctions'
import AuthenticatedHeader from './AuthenticatedHeader'
import { SUBSCRIPTION_PAGE } from '../../../navigation/Routes'
import { useSelector } from 'react-redux'
import UnauthenticatedHeader from './UnauthenticatedHeader'

const Header = (props) => {
  const { loggedIn } = useSelector((state) => state.auth)
  return (
    <div
      className={`bg-purple-darkest  
       ${loggedIn && ' fixed w-full bg-purple-darkest z-20 top-0 '} `}>
      {loggedIn ? <AuthenticatedHeader {...props} /> : <UnauthenticatedHeader />}
    </div>
  )
}

export default Header
