import React, { useState, useEffect, useRef } from 'react'
import SingleTweet from '../../apps/news/tweets/SingleTweet'
import NewsAccordion from '../../apps/news/news/NewsAccordion'
import ListingTable from '../../apps/listing/ListingTable'
import ResponsiveTable from '../../apps/listing/ResponsiveTable'
import uuid from 'react-uuid'
import Loader from '../../apps/loader/Loader'
import axios from '../../../services/axios'
import { Link, useHistory } from 'react-router-dom'
import { NEWS_PAGE, GAME_PAGE } from '../../../navigation/Routes'
import ListingColumnChart from '../../apps/listing/ListingColumnChart'
import EndPoints from '../../../services/api'
import { ErrorBoundary } from 'react-error-boundary'
import ErrorComponent from '../../apps/error/ErrorComponent'
import useDidMountEffect from '../../hooks/useDidMountEffect'
import { Helmet } from 'react-helmet'
import { RiMessage3Line } from 'react-icons/ri'
import { useSelector } from 'react-redux'
import { FiSearch } from 'react-icons/fi'
import { BiLoaderAlt } from 'react-icons/bi'
import CoinAdditionModal from '../../apps/listing/CoinAdditionModal'
import ClickWrapper from '../../apps/click-wrapper/ClickWrapper'

const ListingPage = () => {
  const [loading, setLoading] = useState(true)
  const [loadingTable, setLoadingTable] = useState(true)
  const [newsData, setNewsData] = useState({})
  const [tweetData, setTweetdata] = useState({})
  const [listingData, setListingData] = useState({})
  const [loadingSearch, setLoadingSearch] = useState(false)
  const [showCoinAdditionModal, setShowCoinAdditionModal] = useState(false)
  const [searchData, setSearchData] = useState([])
  const inputRef = useRef()
  const history = useHistory()
  const [filteredListingData, setFilteredListingData] = useState({})
  const [pageNumber, setPageNumber] = useState(1)
  const { loggedIn } = useSelector((state) => state.auth)
  const [totalPage, setTotalPage] = useState(0)
  const [coinNameInput, setCoinNameInput] = useState('')
  const [sort, setSort] = useState('marketcap')
  const [sortOrder, setSortOrder] = useState('desc')

  const sortArr = [
    {
      title: 'None',
      property: ''
    },
    {
      title: 'Price',
      property: 'price'
    },
    {
      title: 'Marketcap',
      property: 'marketcap'
    },
    {
      title: '24 h change',
      property: 'PercentChange24h'
    },
    {
      title: '7 d change',
      property: 'PercentChange7d'
    },

    {
      title: 'Rating',
      property: 'score'
    }
  ]

  const quickSearchArr = [
    {
      title: 'Top assets',
      property: ''
    },
    {
      title: 'Top assets',
      property: ''
    },
    {
      title: 'Top assets',
      property: ''
    },
    {
      title: 'Top assets',
      property: ''
    },
    {
      title: 'Top assets',
      property: ''
    },
    {
      title: 'Top assets',
      property: ''
    },
    {
      title: 'Top assets',
      property: ''
    },
    {
      title: 'Top assets',
      property: ''
    }
  ]

  const { getAllNews, getAllTweets, getSearchCoin, getAllInOne, getSortCoin } = EndPoints.listing

  const sliceData = (array, start, end) => {
    return array.slice(start - 1, end)
  }

  const fetchTableData = async () => {
    try {
      const { data } = await axios.get(
        `${getAllInOne}?page=${pageNumber}&limit=${20}&sort_by=${sort}&order=${sortOrder}`
      )

      setTotalPage(JSON.parse(data.body).total_pages)
      console.log(JSON.parse(data.body).message)
      setListingData(JSON.parse(data.body).message)
      setFilteredListingData(JSON.parse(data.body).message)
      setLoadingTable(false)
    } catch (err) {
      console.log(err.message)
    }
  }

  useEffect(() => {
    fetchTableData()
  }, [])

  const fetchNewsAndTweet = async () => {
    try {
      const res = await Promise.all([axios.get(getAllNews), axios.get(getAllTweets)])
      const newData = res.map((res) => JSON.parse(res.data.body))
      setNewsData(newData[0])
      setTweetdata(newData[1])
      setLoading(false)
    } catch (err) {
      console.log(err.message)
    }
  }

  useEffect(() => {
    fetchNewsAndTweet()
    fetchTableData()
  }, [])

  const filterStaticData = async (searchText) => {
    //e.preventDefault()
    //setLoadingTable(true)

    if (searchText.length < 1) {
      setSearchData([])
      return
    }

    setLoadingSearch(true)

    try {
      const { data } = await axios.get(
        `${getSearchCoin}?search=${searchText}&page=${pageNumber}&limit=${20}`
      )
      //console.log(JSON.parse(data.body).message)
      // setTotalPage(JSON.parse(data.body).total_pages)
      // setFilteredListingData(
      //   sliceData(
      //     JSON.parse(data.body).message,
      //     (pageNumber - 1) * 20 + 1,
      //     (pageNumber - 1) * 20 + 20
      //   )
      // )
      setSearchData(JSON.parse(data.body).message)
      setLoadingSearch(false)
      // setCoinNameInput('')
      //setLoadingTable(false)
    } catch (err) {
      console.log(err.message)
    }
  }

  const fetchSortedData = async (sortValue) => {
    try {
      const { data } = await axios.get(
        `${getSortCoin}?&page=${pageNumber}&limit=${20}&sort_by=${sortValue}&order=${sortOrder}`
      )
      //console.log(JSON.parse(data.body).message)
      setTotalPage(JSON.parse(data.body).total_pages)
      setFilteredListingData(JSON.parse(data.body).message)
    } catch (err) {
      console.log(err.message)
    }
  }

  useEffect(() => {
    fetchSortedData(sort)
  }, [sortOrder])

  const handleSorting = async (e) => {
    e.preventDefault()
    setSort(e.target.value)
    setPageNumber(1)
    fetchSortedData(e.target.value)
  }

  useDidMountEffect(() => {
    window.scrollTo({ behavior: 'smooth', top: '0px' })

    fetchTableData()
  }, [pageNumber])

  useEffect(() => {
    if (coinNameInput.length > 0) {
      setLoadingSearch(true)
    } else setLoadingSearch(false)

    const coinSearchTimeout = setTimeout(() => {
      filterStaticData(coinNameInput)
    }, 2000)
    return () => {
      clearTimeout(coinSearchTimeout)
    }
  }, [coinNameInput])

  return (
    <div className="flex flex-col w-full bg-purple-darkest text-white">
      <Helmet>
        <meta charSet="utf-8" />

        <title>Crypto Prices and Rating | CryptoResearchfund-A CryptoEd Platform.</title>
        <meta
          name="title"
          content="Crypto Prices and Rating | CryptoResearchfund-A CryptoEd Platform."
        />
        <meta
          name="description"
          content="Bitcoin and cryptocurrency prices fluctuate more than the stock market does, so it’s important to stay up to date with the latest trends using our tools and data. We collect activity across social media for Bitcoin, thousands of altcoins, as well as what influencers are posting, and distill it into bite-size chunks easy for you to digest."
        />

        <meta property="og:type" content="website" />
        <meta property="og:url" content="http://www.cryptoresearchfund.com/listing" />
        <meta
          property="og:title"
          content="Crypto Prices and Rating | CryptoResearchfund-A CryptoEd Platform."
        />
        <meta
          property="og:description"
          content="Bitcoin and cryptocurrency prices fluctuate more than the stock market does, so it’s important to stay up to date with the latest trends using our tools and data. We collect activity across social media for Bitcoin, thousands of altcoins, as well as what influencers are posting, and distill it into bite-size chunks easy for you to digest."
        />
        <meta
          property="og:image"
          content="http://www.cryptoresearchfund.com/dataScienceAndAIApproch.png"
        />

        <meta property="twitter:card" content="summary_large_image" />
        <meta property="twitter:url" content="http://www.cryptoresearchfund.com/listing" />
        <meta
          property="twitter:title"
          content="Crypto Prices and Rating | CryptoResearchfund-A CryptoEd Platform."
        />
        <meta
          property="twitter:description"
          content="Bitcoin and cryptocurrency prices fluctuate more than the stock market does, so it’s important to stay up to date with the latest trends using our tools and data. We collect activity across social media for Bitcoin, thousands of altcoins, as well as what influencers are posting, and distill it into bite-size chunks easy for you to digest."
        />
        <meta
          property="twitter:image"
          content="http://www.cryptoresearchfund.com/dataScienceAndAIApproch.png"
        />
      </Helmet>
      <div>
        {loggedIn && (
          <div className="bg-blue-500 p-3 flex justify-center items-center gap-3 lg:mb-10 text-white">
            <RiMessage3Line /> <h2>Continue where you left off in the contest.</h2>
            <Link
              className="bg-yellow-500 rounded-md p-3 py-2 text-sm border-2 border-yellow-500 hover:bg-transparent transition-all duration-300 ease-in-out"
              to={GAME_PAGE}>
              Live Contest
            </Link>
          </div>
        )}
        <div className="p-4 sm:p-6 lg:p-10">
          <div className="flex justify-between items-center">
            <h1 className=" text-xl sm:text-3xl md:text-4xl my-2 mb-2 sm:mb-5 border-b border-dotted border-white w-max pb-2">
              Crypto Market
            </h1>
            <div className="relative">
              <form
                className="flex justify-between items-center bg-transparent md:w-80 p-2 border rounded-md overflow-hidden  border-indigo-300 border-opacity-40"
                //</div>onSubmit={filterStaticData}
              >
                <input
                  ref={inputRef}
                  className="bg-transparent text-xs w-full "
                  value={coinNameInput}
                  type="text"
                  placeholder="Search Coin "
                  onChange={(e) => setCoinNameInput(e.target.value.trim())}
                />
                {loadingSearch ? (
                  <div className="grid place-items-center text-sm animate-spin ">
                    <BiLoaderAlt />
                  </div>
                ) : (
                  <button className="grid place-items-center text-xs" type="submit">
                    <FiSearch />
                  </button>
                )}
              </form>
              {searchData.length > 0 && coinNameInput.length > 0 && (
                <div className="absolute top-full left-0 w-full bg-purple-darkest border border-purple-400 border-opacity-10 rounded-sm text-sm ">
                  {searchData.map((data) => (
                    <div
                      onClick={() => history.push(`/coin/${data.coinname}/${data.id}`)}
                      className="p-2 flex justify-between items-center gap-2 cursor-pointer hover:bg-purple-400 hover:bg-opacity-10 rounded-sm"
                      key={uuid()}>
                      <div className="flex items-center gap-2">
                        <img
                          className="w-4 h-4 object-contain "
                          src={data.urlOfLogo}
                          alt={data.coinname}
                        />{' '}
                        <p>{data.coinname}</p>
                      </div>

                      <p className="font-600">({data.symbol.toUpperCase()})</p>
                    </div>
                  ))}
                </div>
              )}
              {searchData.length === 0 && coinNameInput !== '' && !loadingSearch && (
                <div className="absolute top-full left-0 w-full bg-purple-darkest border border-purple-400 border-opacity-10 rounded-sm text-sm ">
                  <button
                    onClick={() => {
                      setCoinNameInput('')
                      setShowCoinAdditionModal(true)
                    }}
                    className="w-full h-full p-3 py-2 grid place-items-center bg-blue-500 hover:bg-blue-600 ">
                    + Request Coin Addition
                  </button>
                </div>
              )}
            </div>
          </div>
          {showCoinAdditionModal && (
            <div className="fixed left-0 right-0 top-0 h-screen w-screen z-40 backdrop-filter backdrop-blur-sm grid place-items-center">
              <div className="z-50 w-full max-w-2xl">
                <ClickWrapper func={() => setShowCoinAdditionModal(false)}>
                  <CoinAdditionModal close={() => setShowCoinAdditionModal(false)} />
                </ClickWrapper>
              </div>
            </div>
          )}
          {loadingTable ? (
            <Loader />
          ) : (
            <div>
              <div className="mb-4 flex justify-between items-center gap-3">
                {' '}
                <div className="flex gap-2 items-center ">
                  {/* {quickSearchArr.map((quickSearch) => (
                    <button
                      className="p-2 px-3 rounded-md border-1 border-purple-500 border-opacity-50 text-xs hover:bg-purple-400 hover:bg-opacity-10 "
                      key={uuid()}>
                      {quickSearch.title}
                    </button>
                  ))} */}
                </div>
                <div className="flex items-center gap-2 ">
                  <div className="border-1 border-purple-300 border-opacity-40 rounded-md overflow-hidden p-1">
                    <select
                      value={sortOrder}
                      onChange={(e) => setSortOrder(e.target.value)}
                      className="bg-transparent text-xs text-gray-300  cursor-pointer">
                      <option value={'asc'} className="bg-purple-darker p-1 text-xs">
                        Ascending
                      </option>
                      <option value={'desc'} className="bg-purple-darker p-1 text-xs">
                        Descending
                      </option>
                    </select>
                  </div>
                  <div className="border-1 border-purple-300 border-opacity-40 rounded-md overflow-hidden p-1">
                    <select
                      value={sort}
                      onChange={handleSorting}
                      className="bg-transparent text-xs text-gray-300  cursor-pointer">
                      {sortArr.map((sort) => (
                        <option
                          key={uuid()}
                          value={sort.property}
                          className="bg-purple-darker p-1 text-xs">
                          {sort.title}
                        </option>
                      ))}
                    </select>
                  </div>
                </div>
              </div>
              <ErrorBoundary FallbackComponent={ErrorComponent}>
                <ListingColumnChart cryptoData={filteredListingData} />
              </ErrorBoundary>
              <ErrorBoundary FallbackComponent={ErrorComponent}>
                <ListingTable
                  cryptoData={filteredListingData}
                  page={pageNumber}
                  onPageChange={(page) => setPageNumber(page)}
                  count={totalPage}
                />
              </ErrorBoundary>
              <ErrorBoundary FallbackComponent={ErrorComponent}>
                <ResponsiveTable
                  cryptoData={filteredListingData}
                  page={pageNumber}
                  onPageChange={(page) => setPageNumber(page)}
                  count={totalPage}
                />{' '}
              </ErrorBoundary>
            </div>
          )}
        </div>

        <div className="grid place-items-center p-4 sm:p-10 w-full max-w-7xl m-auto">
          <h1
            to={NEWS_PAGE}
            className="text-center bg-red-dark p-2 rounded-full font-700 uppercase w-full my-10 sm:mb-10 sm:mt-2">
            Influencers
          </h1>
          {loading ? (
            <Loader />
          ) : (
            <>
              <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-5">
                <ErrorBoundary FallbackComponent={ErrorComponent}>
                  {Object.keys(tweetData)
                    .slice(0, 6)
                    .map((tweet) => (
                      <SingleTweet
                        bg_color={'bg-purple-400 '}
                        marginY={'0'}
                        key={uuid()}
                        tweet={tweetData[tweet]}
                      />
                    ))}
                </ErrorBoundary>
              </div>
              <Link to={NEWS_PAGE} className="mt-6 text-sm ">
                Click here to <span className="text-blue-400">see more</span>
              </Link>
            </>
          )}
        </div>

        <div className="grid place-items-center p-4 sm:p-10 w-full max-w-7xl m-auto">
          <h1
            to={NEWS_PAGE}
            className="text-center bg-red-dark p-2 rounded-full font-700 uppercase w-full mb-10">
            News
          </h1>
          {loading ? (
            <Loader />
          ) : (
            <>
              <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-5 w-full py-4">
                <ErrorBoundary FallbackComponent={ErrorComponent}>
                  {Object.keys(newsData)
                    .slice(0, 6)
                    .map((news) => (
                      <NewsAccordion key={uuid()} news={newsData[news]} />
                    ))}
                </ErrorBoundary>
              </div>
              <Link to={NEWS_PAGE} className="mt-2 text-sm ">
                Click here to <span className="text-blue-400">see more</span>
              </Link>
            </>
          )}
        </div>
      </div>
    </div>
  )
}

export default ListingPage
