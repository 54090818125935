import React from 'react'
import PortfolioChartStats from './PortfolioChartStats'

const PortfolioChart = ({ data, sectorData }) => {
  return (
    <div className=" w-full bg-purple-darkest px-4 sm:px-6 md:px-10">
      <PortfolioChartStats data={data} sectorData={sectorData} />
    </div>
  )
}

export default PortfolioChart
