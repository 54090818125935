import React, { useEffect, useState } from 'react'
import uuid from 'react-uuid'
import EndPoints from '../../../services/api'
import axios from '../../../services/axios'
import SingleBlogCard from '../../apps/blogs/SingleBlogCard'
import BrandComponent from '../../apps/home/components/header/BrandComponent'
import Loader from '../../apps/loader/Loader'

const BlogPage = () => {
  const [blogData, setBlogData] = useState([])
  const [loading, setLoading] = useState(true)
  const { getUntoldStories } = EndPoints.news

  const fetchBlogData = async () => {
    try {
      const { data } = await axios.get(`${getUntoldStories}?type=static`)
      //console.log(JSON.parse(data.body).message)
      setBlogData(JSON.parse(data.body).message)
      setLoading(false)
    } catch (err) {
      console.log(err.message)
    }
  }

  useEffect(() => {
    fetchBlogData()
  }, [])

  return (
    <div className="min-h-screen text-white p-4 sm:p-6 md:p-10 ">
      {loading ? (
        <Loader />
      ) : (
        <div className="w-full max-w-5xl text-black m-auto md:p-4">
          <div className="flex flex-col ">
            <a
              href="http://www.cryptoresearchfund.com"
              target="blank"
              className="flex flex-col sm:flex-row  rounded-xl w-full overflow-hidden sm:h-80 bg-white ">
              <img
                src="https://st2.depositphotos.com/4107269/7635/i/450/depositphotos_76358181-stock-photo-messy-workspace-of-freelancer-concept.jpg"
                alt="blog img"
                className="w-full sm:w-80 h-full object-cover"
              />
              <div className="flex flex-col gap-4 justify-center p-2 sm:p-4 sm:px-8 ">
                <p className="text-blue-400 font-600 text-sm">In the news</p>
                <h2 className="text-lg md:text-2xl lg:text-3xl font-600 ">
                  Alchemy, which aims to be the ‘de facto platform’ for developers to build on
                  Web3, is now valued at $10.2B
                </h2>
                <p className="text-gray-400 font-600 text-sm">
                  The startup has seen its value surge by a staggering 19.8x since last April.
                </p>
              </div>
            </a>
            <div className="py-6 md:py-10">
              <BrandComponent />
            </div>
          </div>
          <div className="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-3 gap-4">
            {blogData.map((singleBlog) => (
              <SingleBlogCard key={uuid()} blogData={singleBlog} />
            ))}
          </div>
        </div>
      )}
    </div>
  )
}

export default BlogPage
