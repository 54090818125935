import React, { useState } from 'react'
import { useAlert } from 'react-alert'
import { useDispatch, useSelector } from 'react-redux'
import { useLocation } from 'react-router'
import authAction from '../../../redux/actions/authAction'
import Loader from '../loader/Loader'
import EmailField from './EmailField'
import PasswordField from './PasswordField'
import SubmitButton from './SubmitButton'

const Signin = ({ toggleTab, closeTab }) => {
  const dispatch = useDispatch()
  const location = useLocation()

  const [loading, setLoading] = useState(false)
  const alert = useAlert()
  const [signInDetails, setSignInDetails] = useState({
    email: '',
    password: ''
  })
  const [loginErrors, setLoginErrors] = useState({
    email: '',
    password: ''
  })

  const handleSignIn = (e) => {
    e.preventDefault()
    console.log(signInDetails)

    if (signInDetails.email.length === 0 || signInDetails.password.length === 0) {
      if (signInDetails.email.length === 0) {
        setLoginErrors({
          ...loginErrors,
          email: 'Enter valid Email'
        })
      } else if (signInDetails.password.length === 0) {
        setLoginErrors({
          ...loginErrors,
          password: 'Enter valid password'
        })
      }
      return
    }

    dispatch(authAction.login(signInDetails, setLoading, closeTab, notifyLogin))
  }

  const notifyLogin = (text) => {
    alert.show(text)
  }

  return (
    <div className="flex flex-col items-center w-full pb-3 relative  overflow-y-scroll h-full ">
      {loading && (
        <div className="absolute grid place-items-center w-full h-full top-0 left-0 z-10 bg-white bg-opacity-20 backdrop-filter blur-sm">
          <Loader />
        </div>
      )}

      <div className="flex flex-col w-full px-2">
        <h1 className="text-lg mb-2 font-700 text-blue-500">Login</h1>

        <p className="text-xs font-500 text-gray-500">
          See your growth and get consulting support!
        </p>
      </div>

      <form
        onSubmit={handleSignIn}
        className="w-full flex flex-col my-4 p-2  ">
        <EmailField
          value={signInDetails.email}
          checkEmail={false}
          errorMessage={signInDetails.email.length === 0 && loginErrors.email}
          onChange={(e) =>
            setSignInDetails({
              ...signInDetails,
              email: e.target.value
            })
          }
        />

        <PasswordField
          labelText={'Password'}
          value={signInDetails.password}
          errorMessage={signInDetails.password.length === 0 && loginErrors.password}
          onChange={(e) =>
            setSignInDetails({
              ...signInDetails,
              password: e.target.value
            })
          }
        />

        <div className="flex  items-center mb-3">
          <p
            onClick={() => toggleTab(2)}
            className="text-blue-500 text-xs font-600 hover:text-blue-700 cursor-pointer">
            Forgot Password ?
          </p>
        </div>

        <SubmitButton text={'Login'} />
      </form>

      <div className="flex text-xs text-left w-full p-2 font-600 ">
        <p>Not registered yet ? </p>

        <button
          onClick={() => toggleTab(1)}
          className="text-blue-500 text-xs ml-2 font-600 hover:text-blue-700">
          Create an Account
        </button>
      </div>
    </div>
  )
}

export default Signin
