import React from 'react'
import { Link, useLocation } from 'react-router-dom'
import uuid from 'react-uuid'

const SingleHeaderLink = ({ menu }) => {
  const { pathname } = useLocation()

  return (
    <div id="single__header__link" className="my-1 relative">
      <Link
        key={uuid()}
        to={menu.target}
        className={` ${
          pathname === menu.target ? 'text-blue-400 border-b-2 border-blue-400 ' : 'text-white'
        } text-xs pb-1 lg:text-sm font-600 mx-2 my-1  hover:text-blue-400 transition-all duration-300 ease-in-out `}>
        {menu.title}{' '}
      </Link>
      {menu.child.length > 0 && (
        <div
          id="single__link__child"
          className={`absolute w-40 top-full transform -translate-x-1/2 left-1/2  flex flex-col rounded-lg overflow-hidden p-2 z-50  bg-purple-darkest `}>
          {menu.child.map((submenu) => (
            <Link
              key={uuid()}
              to={submenu.target}
              className={` ${
                pathname === submenu.target ? 'text-blue-400  ' : 'text-white'
              }  text-xs pb-1 font-600 p-2 px-4  hover:text-blue-400 transition-all duration-300 ease-in-out`}>
              {submenu.title}
            </Link>
          ))}
        </div>
      )}
    </div>
  )
}

export default SingleHeaderLink
