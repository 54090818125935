export const splashData = [
  'Dogecoin started as a joke.',

  'The total amount of bitcoins is limited. Max is 21 million.',

  'Crypto economy is only 2-3% of the total economy',

  'Investing in cryto tokens ( yes coins and tokens are different) is more like investing in startup.',

  'There are more than 6000 cryptocurriencies.'
]
