import React from 'react'
import { IoClose } from 'react-icons/io5'
import ReactPlayer from 'react-player/lazy'

const VideoModal = ({ close }) => {
  const videoUrl = 'https://www.youtube.com/watch?v=gVhKIIsiYz0'
  return (
    <div className="w-full  m-auto  relative">
      <button
        className="absolute -top-2 -right-2 text-white z-10 text-xl  rounded-full h-10 w-10 grid place-items-center bg-purple-dark"
        onClick={close}>
        <IoClose />
      </button>
      <div className="grid place-items-center w-full bg-white  ">
        <ReactPlayer className="hidden sm:block " url={videoUrl} playing={false} />
        <ReactPlayer
          className="react-player sm:hidden "
          url={videoUrl}
          width="100vw"
          style={{ objectFit: 'contain' }}
          playing={false}
        />
      </div>
    </div>
  )
}

export default VideoModal
