import React, { useLayoutEffect } from 'react'
import { Helmet } from 'react-helmet'
import { Link } from 'react-router-dom'
import { HOME_PAGE } from '../../../navigation/Routes'
import ErrorLogo from './ErrorLogo'

const ErrorPage = () => {
  useLayoutEffect(() => {
    window.scrollTo(0, 0)
  })
  return (
    <div className="grid place-items-center w-full h-1/2 bg-purple-darkest text-white">
      <Helmet>
        <meta charSet="utf-8" />
        <title>CRF | 404 </title>
      </Helmet>
      <h1 className="text-3xl font-500 my-5">OOPS.. seems you're lost !</h1>
      <ErrorLogo />
      <Link className="p-2 bg-purple-dark rounded-2 my-5" to={HOME_PAGE}>
        Back to home
      </Link>
    </div>
  )
}

export default ErrorPage
