import React, { useState, useEffect } from 'react'
import { ErrorBoundary } from 'react-error-boundary'
import { Helmet } from 'react-helmet'
import { useSelector } from 'react-redux'
import EndPoints from '../../../services/api'
import axios from '../../../services/axios'
import { dateTimeConversion } from '../../../services/globalFunctions'
import ErrorComponent from '../../apps/error/ErrorComponent'
import Loader from '../../apps/loader/Loader'
import ResponsiveTable from '../../apps/watchlist/ResponsiveTable'
import WatchListTable from '../../apps/watchlist/WatchListTable'

const WatchlistPage = () => {
  const { user } = useSelector((state) => state.auth)

  const [watchListData, setWatchListData] = useState([])
  const [loading, setLoading] = useState(true)
  const [updated, setUpdated] = useState(new Date().toISOString())
  const { getAllWatchListData } = EndPoints.watchList
  const fetchWatchListData = async () => {
    const config = {
      headers: {
        Authorization: `Bearer ${user.token}`
      }
    }
    try {
      const { data } = await axios.get(`${getAllWatchListData}?userid=${user.user_id}`, config)

      setWatchListData(JSON.parse(data.body))
      setUpdated(new Date().toISOString())
      setLoading(false)
    } catch (err) {
      console.log(err.message)
    }
  }

  useEffect(() => {
    fetchWatchListData()
  }, [])

  return (
    <div className="bg-purple-darkest min-h-screen text-white ">
      <Helmet>
        <meta charSet="utf-8" />
        <title>CRF | Watchlist </title>
      </Helmet>
      {/* <div className="flex flex-col px-4 md:px-10 pt-6 md:pt-10">
        <h2 className="text-lg font-600">Your Watchlist</h2>
        <p className="text-xs">
          Accurately tracking the investment performance of your crypto assets.
        </p>
      </div> */}

      {/* <div className="px-4 md:px-10 py-8 md:py-10 text-xs border-b border-gray-500 border-opacity-20">
        <button className="bg-purple-darker rounded-full px-4 py-2">My Watchlist #1</button>
        {<button className="bg-purple-darker rounded-full px-4 py-2 ml-4 border-2 border-transparent hover:border-purple-500 hover:border-opacity-50">
					+ Create new Watchlist
	</button>}
      </div> */}
      {loading ? (
        <Loader />
      ) : (
        <div className="p-4 md:p-10 md:py-6">
          <div className="pb-4 md:pb-6">
            <h2 className="text-lg font-600 my-2 ">My Watchlist</h2>
            <p className="text-sm my-2">{Object.keys(watchListData).length} items</p>
            <p className="text-xs my-2">Updated at : {dateTimeConversion(updated)}</p>
          </div>

          <ErrorBoundary FallbackComponent={ErrorComponent}>
            <WatchListTable data={watchListData} func={fetchWatchListData} />
          </ErrorBoundary>
          <ErrorBoundary FallbackComponent={ErrorComponent}>
            {' '}
            <ResponsiveTable data={watchListData} func={fetchWatchListData} />
          </ErrorBoundary>

          <div className="p-6 md:px-12 md:py-20 text-white bg-purple-darkest text-xs ">
            <p className="text-center my-20 ">
              Never invest in cryptos more than you can afford to lose and always try to keep them
              in your own wallet!
            </p>
          </div>
        </div>
      )}
    </div>
  )
}

export default WatchlistPage
