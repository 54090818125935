import React, { Fragment } from 'react'
import uuid from 'react-uuid'
import { brandData } from './BrandData'

const BrandComponent = () => {
  return (
    <Fragment>
      <div
        className="hidden md:grid p-4 sm:p-6 gap-10 md:p-10 grid-cols-6
      max-w-5xl m-auto">
        {brandData.map((brand) => (
          <a
            href={brand.url}
            target="blank"
            className="max-w-md grid place-items-center "
            key={uuid()}>
            <img className="w-full  h-10  object-contain " src={brand.image} alt={brand.name} />
          </a>
        ))}
      </div>
      <div
        className="p-4 py-8 md:hidden  gap-10 md:p-10 grid grid-cols-3
      max-w-5xl m-auto">
        {brandData.slice(0, 3).map((brand) => (
          <a
            href={brand.url}
            target="blank"
            className="max-w-md grid place-items-center "
            key={uuid()}>
            <img className="w-full  h-6  object-contain " src={brand.image} alt={brand.name} />
          </a>
        ))}
      </div>
    </Fragment>
  )
}

export default BrandComponent
