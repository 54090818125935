import React, { useState, useEffect } from 'react'
import uuid from 'react-uuid'
import axios from '../../../services/axios'
import ReactApexChart from 'react-apexcharts'
import Loader from '../loader/Loader'
import { includeComma } from '../../../services/globalFunctions'
import { useSelector } from 'react-redux'

const btnArray = [
  {
    title: '7 D',
    value: '7'
  },
  {
    title: '1 M',
    value: '30'
  },
  {
    title: '1 Y',
    value: '365'
  }
]

const ChartComponent = ({ id, endpoint, data, setShowAuthModal }) => {
  const { category, Domain, launched, Platform, website, Algo_used } = data[6].message[`${id}`]
  const { price } = data[1][`${id}`]
  const { loggedIn } = useSelector((state) => state.auth)
  const series = [
    {
      name: 'price',
      data: []
    },
    {
      name: 'rating',
      data: []
    }
  ]

  const options = {
    chart: {
      type: 'area',
      foreColor: '#ffffff',
      toolbar: {
        show: false
      }
    },

    dataLabels: {
      enabled: false
    },
    stroke: {
      curve: 'smooth'
    },
    grid: {
      show: false
    },

    xaxis: {
      type: 'datetime',

      categories: []
    },
    yaxis: [
      {
        labels: {
          formatter: (value) => {
            return includeComma(value.toFixed(2))
          }
        }
      }
    ],
    tooltip: {
      x: {
        format: 'dd/MM/yy HH:mm'
      },
      theme: 'dark'
    },
    responsive: [
      {
        breakpoint: 786,
        options: {
          yaxis: [
            {
              labels: {
                formatter: function (val) {
                  return val > 1000 ? includeComma((val / 1000).toFixed(2)) + 'k' : val
                }
              }
            }
          ]
        }
      }
    ]
  }

  const [duration, setDuration] = useState(btnArray[0].value)
  const [loading, setLoading] = useState(true)
  const [chartSeries, setChartSeries] = useState(series)
  const [chartOptions, setChartOptions] = useState(options)
  const [changePer, setChangePer] = useState(data[0].keymetrics.change24hr)

  const fetchChartData = async () => {
    setLoading(true)
    try {
      const { data } = await axios.get(`${endpoint}?coinid=${id}&duration=${duration}&type=ALL`)

      const parsedData = JSON.parse(data.body)

      setChartSeries([
        {
          name: 'price',
          data: parsedData.prices
        }
        // {
        //   name: 'rating',
        //   data: [11, 32, 45, 32, 34, 52, 41]
        // }
      ])
      setChangePer(
        ((parsedData.prices[0] - parsedData.prices[parsedData.prices.length - 1]) /
          parsedData.prices[parsedData.prices.length - 1]) *
          100
      )
      setChartOptions({
        ...chartOptions,
        xaxis: {
          ...chartOptions.xaxis,

          categories: parsedData.timestamps
        }
      })
      setLoading(false)
    } catch (err) {
      console.log(err.message)
    }
  }

  useEffect(() => {
    fetchChartData()
  }, [duration])

  return (
    <div className="w-full  p-5 hidden lg:flex">
      <div className="bg-purple-400 bg-opacity-10 capitalize p-4 mx-2 rounded-xl text-xs flex flex-col  w-60  ">
        <p className="text-center mb-3 ">
          <b>Key information</b>
        </p>
        <p className="my-3">
          <b>Domain</b> - {Domain}
        </p>
        <p className="my-3">
          <b>Category</b> - {category}
        </p>
        <p className="my-3">
          <b>Platform</b> - {Platform === null ? '' : Platform}
        </p>
        <p className="my-3 lowercase">
          <b>Website</b> -{' '}
          <a className="text-blue-600" href={website === null ? '' : website} target="blank">
            {website === null ? '' : website}
          </a>
        </p>
        <p className="my-3">
          <b>Consensus Algorithm</b> - {Algo_used === null ? '' : Algo_used}
        </p>
        <p className="my-3">
          <b>Launched Date</b> - {launched === null ? '' : launched}
        </p>
      </div>
      <div className="bg-purple-400 bg-opacity-10  flex-1 mx-2 rounded-xl p-6 py-4 text-white ">
        <div className="flex justify-between items-center">
          <div className="flex gap-6 ">
            <p className="">Price Chart</p>
            <h1 className=" font-600 text-xl md:text-2xl ">
              ${includeComma(price.toFixed(2))}
              <sup
                className={`mx-3 font-400 text-sm text-${changePer >= 0 ? 'green' : 'red'}-500`}>
                {changePer.toFixed(2)} %
              </sup>
            </h1>
          </div>

          <div className="flex mx-2">
            {btnArray.map((btn) => (
              <button
                onClick={() => {
                  if (btn.value === '365') {
                    if (!loggedIn) {
                      setShowAuthModal(true)
                      return
                    }
                  }
                  setDuration(btn.value)
                }}
                className={`p-1 px-2 m-1 text-xs rounded-full bg-opacity-10 ${
                  duration === btn.value ? 'bg-purple-dark' : 'bg-purple-400  '
                } `}
                key={uuid()}>
                {btn.title}
              </button>
            ))}
          </div>
        </div>
        {loading ? (
          <Loader />
        ) : (
          <ReactApexChart options={chartOptions} series={chartSeries} type="area" height={250} />
        )}
      </div>
    </div>
  )
}

export default ChartComponent
