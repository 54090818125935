import React, { Fragment } from 'react'
import { includeComma } from '../../../services/globalFunctions'
import PortfolioDonut from './PortfolioDonut'

const PortfolioChartStats = ({ data, sectorData }) => {
  const {
    best_crypto,
    max_profitable,
    maximum,
    min_profitable,
    minimum,
    portfolio_change_percentage,
    portfolio_change_value,
    worst_crypto
  } = data

  return (
    <div className="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-4 gap-6 md:gap-8 w-full h-full items-center text-center  ">
      <div className="flex flex min-h-[250px]  flex-col justify-center gap-10 items-center h-full bg-purple-400 bg-opacity-10  rounded-xl   py-3 sm:py-6 ">
        <p>Portfolio Change</p>

        <div className="flex flex-col gap-10  items-center text-xs ">
          <h1
            className={`text-3xl sm:text-4xl md:text-5xl font-600 text-${
              portfolio_change_value >= 0 ? 'green' : 'red'
            }-500`}>
            $ {includeComma(portfolio_change_value.toFixed(2))}
          </h1>

          <div
            className={`flex  items-center text-base ml-3 text-${
              portfolio_change_percentage >= 0 ? 'green' : 'red'
            }-500`}>
            <p>{includeComma(portfolio_change_percentage.toFixed(2))}%</p>
            <p className="mx-1"> {portfolio_change_percentage < 0 ? '↓' : '↑'} </p>
          </div>
        </div>
      </div>

      <div className="flex min-h-[250px]  flex-col w-full px-8  bg-purple-400 bg-opacity-10  h-full   rounded-xl  ">
        <div className="flex flex-1 h-1/2 flex-col justify-center  border-b-2 border-gray-400 border-opacity-20 items-center ">
          <p>Portfolio MIN</p>
          <p className={` text-2xl font-semibold mt-3 text-${minimum < 0 ? 'red' : 'green'}-500`}>
            $ {includeComma(minimum.toFixed(2))}
          </p>
        </div>

        <div className="flex flex-1 h-1/2 flex-col justify-center items-center">
          <p>Portfolio MAX</p>
          <p className={` text-2xl font-semibold mt-3 text-${maximum < 0 ? 'red' : 'green'}-500`}>
            $ {includeComma(maximum.toFixed(2))}
          </p>
        </div>
      </div>

      <div className="flex flex-col flex min-h-[250px]  justify-evenly w-full p-6  bg-purple-400 bg-opacity-10 rounded-xl  h-full text-xs lg:text-sm ">
        <div className="flex flex-col  justify-between items-center flex-1  ">
          <div className="flex justify-evenly flex-1 h-1/2 w-full  border-b-2 border-gray-400 border-opacity-20  ">
            <div className="flex flex-col justify-center  pr-3 pb-3  w-1/2 border-r-2 border-gray-400 border-opacity-20 items-center text-center ">
              {' '}
              <p>Least Profitable</p>
              <p
                className={`text-xs mt-3 text-${
                  min_profitable.total_profit_loss >= 0 ? 'green' : 'red'
                }-500 `}>
                <span className="text-white font-600 mr-1">{min_profitable.symbol}</span> $
                {includeComma(min_profitable.total_profit_loss.toFixed(2))}
              </p>
            </div>
            <div className="flex flex-col justify-center pl-3 pb-3 w-1/2 items-center text-center ">
              {' '}
              <p>Most Profitable</p>
              <p
                className={`text-xs mt-3 text-${
                  max_profitable.total_profit_loss >= 0 ? 'green' : 'red'
                }-500 `}>
                <span className="text-white font-600 mr-1">{max_profitable.symbol}</span> $
                {includeComma(max_profitable.total_profit_loss.toFixed(2))}
              </p>
            </div>
          </div>

          <div className="flex  justify-evenly flex-1 h-1/2 w-full   ">
            <div className="flex flex-col  justify-center pt-3 pr-3 w-1/2 items-center  border-r-2 border-gray-400 border-opacity-20 text-center ">
              {' '}
              <p>Worst Crypto</p>
              <p
                className={`text-xs mt-3 text-${
                  worst_crypto.total_profit_loss >= 0 ? 'green' : 'red'
                }-500 `}>
                <span className="text-white font-600 mr-1">{worst_crypto.symbol}</span>(
                {includeComma(worst_crypto.total_profit_loss.toFixed(2))}%)
              </p>
            </div>
            <div className="flex flex-col justify-center pl-3 pt-3 w-1/2 items-center text-center">
              {' '}
              <p>Best Crypto</p>
              <p
                className={`text-xs mt-3 text-${
                  best_crypto.total_profit_loss >= 0 ? 'green' : 'red'
                }-500 `}>
                <span className="text-white font-600 mr-1">{best_crypto.symbol}</span>(
                {includeComma(best_crypto.total_profit_loss.toFixed(2))}%)
              </p>
            </div>
          </div>
        </div>
      </div>
      {sectorData.length === 0 ? (
        <Fragment></Fragment>
      ) : (
        <div className=" ">
          <PortfolioDonut sectorData={sectorData} />
        </div>
      )}
    </div>
  )
}

export default PortfolioChartStats
