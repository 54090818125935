import React, { useState } from 'react'
import { BiSearch } from 'react-icons/bi'
import { RiMenuUnfoldLine, RiMenuFoldLine } from 'react-icons/ri'
import { RiCloseFill } from 'react-icons/ri'
import { IoMdNotificationsOutline } from 'react-icons/io'
import { IoIosLogOut } from 'react-icons/io'
import { Avatar, makeStyles, Tooltip } from '@material-ui/core'
import { useDispatch, useSelector } from 'react-redux'
import authAction from '../../../redux/actions/authAction'
import ClickWrapper from '../click-wrapper/ClickWrapper'
import { Link, useHistory } from 'react-router-dom'
import { HOME_PAGE } from '../../../navigation/Routes'
import Logo from '../logo/Logo'
import CrfLogo from '../logo/CrfLogo'

const useStyles = makeStyles((theme) => ({
  arrow: {
    color: theme.palette.common.black
  },
  tooltip: {
    backgroundColor: theme.palette.common.black,
    maxWidth: 100,
    padding: 8,
    fontSize: 10
  }
}))

const AuthenticatedHeader = ({ showSidebar, setShowSidebar }) => {
  const dispatch = useDispatch()
  const history = useHistory()
  const { user } = useSelector((state) => state.auth)
  const classes = useStyles()
  const [showUserMenu, setShowUserMenu] = useState(false)
  return (
    <div className="flex bg-purple-darker z-40 p-4 sm:px-6 md:px-10  justify-between items-center ">
      <div className="flex items-center">
        <Link className="mr-3 w-16 lg:w-20 " to={HOME_PAGE}>
          <CrfLogo />
        </Link>

        <button
          onClick={() => setShowSidebar(!showSidebar)}
          className={`text-xl p-2  rounded-full  text-white transform duration-300 transition-all ${
            showSidebar && ' translate-x-20'
          }`}>
          {showSidebar ? <RiMenuFoldLine /> : <RiMenuUnfoldLine />}
        </button>
      </div>

      <div className="flex items-center">
        <button className="mx-2 text-xl text-white">
          <BiSearch />
        </button>

        <button className="mx-2 text-xl text-white">
          <IoMdNotificationsOutline />
        </button>

        <div className="flex items-center mx-2 text-white relative">
          {showUserMenu && (
            <div className="absolute  right-0 top-full bg-purple-dark my-2 w-24  p-2 rounded-lg">
              <ClickWrapper func={() => setShowUserMenu(false)}>
                <button
                  onClick={() => {
                    dispatch(authAction.logout())
                    history.push(HOME_PAGE)
                  }}
                  className=" text-white flex items-center  text-xs font-400 w-full justify-center ">
                  Logout
                </button>
              </ClickWrapper>
            </div>
          )}

          <Avatar
            className="cursor-pointer"
            onClick={() => setShowUserMenu(!showUserMenu)}
            src={user?.profile_image}
            alt={user?.name}
            style={{ width: 32, height: 32 }}
          />
        </div>
      </div>
    </div>
  )
}

export default AuthenticatedHeader
