import React from 'react'
import uuid from 'react-uuid'
import SingleTable from './SingleTable'
import { recommendationtableHeader } from './TableData'

const RecommendationTable = ({ data }) => {
  return (
    <div className="px-10 text-xs lg:text-sm hidden lg:block">
      <div className="bg-purple-700 bg-opacity-10 rounded-t-2xl  text-center relative p-4">
        <h1 className="absolute bottom-1/2 left-1/2 transform -translate-x-1/2 bg-teal-dark p-2 px-4">
          Coin Recommendation
        </h1>
      </div>
      <table className="p-4 w-full rounded-b-2xl overflow-hidden ">
        <tbody className="text-xs xl:text-sm">
          <tr className="bg-purple-400 bg-opacity-20 p-2">
            {recommendationtableHeader.map((head) => (
              <th key={uuid()} className="text-center p-2 cursor-pointer ">
                {head.title}{' '}
              </th>
            ))}
          </tr>
          {data.map((singleData) => (
            <SingleTable key={uuid()} {...singleData} />
          ))}
          {data.length === 0 && (
            <p className="text-center w-full font-600 py-4 my-4 text-gray-300">
              No Recommendation for now .
            </p>
          )}
        </tbody>
      </table>
    </div>
  )
}

export default RecommendationTable
