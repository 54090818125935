import axios from 'axios'
import React, { useEffect, useState } from 'react'
import EndPoints from '../../../../services/api'
import { includeComma } from '../../../../services/globalFunctions'
import DataLoader from '../../loader/DataLoader'

const SingleBucketSellCoin = ({ coinData }) => {
  const {
    alloted_price,
    amount_received,
    asset_id: assetId,
    charges,
    current_price,
    profit_loss,
    quantity,
    settlement_amount,
    trade_id,
    unit_price
  } = coinData
  const [loading, setLoading] = useState(true)
  const [staticData, setStaticData] = useState({})
  const { getStaticData } = EndPoints.watchList

  const fetchSingleWatchList = async () => {
    try {
      const res = await Promise.all([axios.get(`${getStaticData}?coinid=${assetId}`)])

      const singleWatchListData = res.map((res) => JSON.parse(res.data.body))
      const { symbol, logo, coinname } = singleWatchListData[0][`${assetId}`]

      setStaticData({
        coinname,
        symbol,
        logo
      })
      setLoading(false)
    } catch (err) {
      console.log(err.message)
    }
  }
  useEffect(() => {
    fetchSingleWatchList()
  }, [])
  return (
    <div className="p-2 text-xs items-center bg-transparent text-white font-600 placeholder-gray-500 border-2 border-purple-400 border-opacity-20 rounded-lg  ">
      {loading ? (
        <DataLoader />
      ) : (
        <div className="flex flex-col gap-2 w-full">
          <div className="flex justify-between items-start gap-2 ">
            <div className="flex gap-2 items-center ">
              <img
                className="w-4 h-4 object-contain"
                src={staticData.logo}
                alt={staticData.coinname}
              />
              <p>
                {staticData.coinname} ({staticData.symbol})
              </p>
            </div>
            <div className="flex gap-2 items-center">
              <p className="flex gap-1 items-center">
                {includeComma(alloted_price.toFixed(2))} ${' '}
                <span className={`text-${profit_loss >= 0 ? 'green' : 'red'}-500`}>
                  ({profit_loss.toFixed(2)} %)
                </span>{' '}
              </p>
            </div>
          </div>
          <div className="flex justify-between items-center gap-2 text-xs font-400">
            <div className=" ">
              <p>Qty : {includeComma(quantity.toFixed(3))} units</p>
            </div>
            <div className="">Price : {includeComma(current_price.toFixed(2))} $ / unit</div>
          </div>
        </div>
      )}
    </div>
  )
}

export default SingleBucketSellCoin
