import React, { Fragment } from 'react'
import uuid from 'react-uuid'
import { holdingTableData } from './HoldingTableData'
import SingleHoldingTable from './SingleHoldingTable'

const HoldingTable = ({ data, wallet, contestId, refreshData, isOpen }) => {
  return (
    <div className="hidden p-4 lg:block  ">
      <table className={`h-full w-full ${data.length !== 0 && `rounded-b-2xl`} overflow-hidden`}>
        <tbody className="text-xs xl:text-sm  ">
          <tr className="bg-purple-400 bg-opacity-20 p-2">
            {holdingTableData.map((head) => (
              <th key={uuid()} className="text-center p-2 py-3 ">
                {head.title}{' '}
              </th>
            ))}
          </tr>
          {data.length !== 0 &&
            data.map((data) => (
              <SingleHoldingTable
                key={uuid()}
                rowData={data}
                wallet={wallet}
                contestId={contestId}
                refreshData={refreshData}
                isOpen={isOpen}
              />
            ))}
        </tbody>
      </table>
      {data.length === 0 && (
        <Fragment>
          <p className="text-center w-full font-600 py-4 my-4 text-gray-300">
            Your Bucket is Empty
          </p>
        </Fragment>
      )}
    </div>
  )
}

export default HoldingTable
