import React, { useState, useEffect, Fragment } from 'react'
import RecommendationTable from '../../apps/recommendation/RecommendationTable'
import ResponsiveTable from '../../apps/recommendation/ResponsiveTable'
import TopRecommendation from '../../apps/recommendation/TopRecommendation'
import EndPoints from '../../../services/api'
import axios from '../../../services/axios'
import Loader from '../../apps/loader/Loader'
import { Helmet } from 'react-helmet'
import { ErrorBoundary } from 'react-error-boundary'
import ErrorComponent from '../../apps/error/ErrorComponent'

const Recommendation = () => {
  const [recommendationData, setRecommendationData] = useState([])
  const [loading, setLoading] = useState(true)

  const { getCoinRecommendation } = EndPoints.recommendation

  const fetchRecommendationData = async () => {
    try {
      const res = await Promise.all([
        axios.get(getCoinRecommendation),
        axios.get(`${getCoinRecommendation}?flag=true`)
      ])
      const newData = res.map((res) => JSON.parse(res.data.body).message)
      setRecommendationData(newData)
      // console.log(newData)
      setLoading(false)
    } catch (err) {
      console.log(err.message)
    }
  }

  useEffect(() => {
    fetchRecommendationData()
  }, [])
  return (
    <div className="bg-purple-darkest text-white">
      <Helmet>
        <meta charSet="utf-8" />
        <title>Data Driven Crypto Recommendation. Best Coin to Buy.</title>
      </Helmet>
      <div className="px-6 md:px-20 py-10">
        <div className="flex flex-col ">
          <p className="text-blue-400 text-xs mb-4 font-500">CRF Recommends</p>
          <h2 className="text-lg font-600">Coin Recommendation</h2>
          <p className="text-xs">
            Expert recommendation to buy, sell and hold cryptos offered after analyzing your
            portfolio and risk-taking capabilities
          </p>
        </div>
      </div>

      <div className="py-6">
        {loading ? (
          <Loader />
        ) : (
          <div>
            <div className=" max-w-7xl m-auto p-4 sm:p-6 mb-10">
              <ErrorBoundary FallbackComponent={ErrorComponent}>
                <TopRecommendation bg={'purple-darker'} data={recommendationData[1]} />
              </ErrorBoundary>
            </div>

            <ErrorBoundary FallbackComponent={ErrorComponent}>
              <RecommendationTable data={recommendationData[0]} />
            </ErrorBoundary>
            <ErrorBoundary FallbackComponent={ErrorComponent}>
              <ResponsiveTable data={recommendationData[0]} />
            </ErrorBoundary>
          </div>
        )}
      </div>

      <div className="p-10 pt-32 md:pt-56 pb-20 text-xs text-center">
        <p>
          Never invest in cryptos more than you can afford to lose and always try to keep them in
          your own wallet!
        </p>
      </div>
    </div>
  )
}

export default Recommendation
