import React from 'react'
import { Helmet } from 'react-helmet'
import { MdLocalPhone } from 'react-icons/md'
import { GrMail } from 'react-icons/gr'
import { HiLocationMarker } from 'react-icons/hi'
import ContactForm from '../../apps/contact/ContactForm'
import { footerData } from '../../apps/footer/Footerdata'
import uuid from 'react-uuid'

const ContactPage = () => {
  return (
    <div className="flex w-full min-h-screen">
      <Helmet>
        <meta charSet="utf-8" />
        <title>CRF | Contact Us</title>
      </Helmet>
      <div className="text-white hidden xl:flex flex-col justify-start gap-48 p-10 bg-purple-darker relative overflow-hidden  ">
        <div className="flex flex-col gap-6">
          {' '}
          <h1 className="text-white text-3xl font-semibold">Contact Information</h1>
          <p className="text-gray-300">
            Fill up the form and out team wil get back to you within 24 hrs{' '}
          </p>
        </div>

        <div className="flex flex-col gap-6">
          <div className="flex items-center gap-3">
            <MdLocalPhone />
            <p className="text-sm">+919999999999</p>
          </div>
          <div className="flex items-center gap-3">
            <GrMail />
            <p className="text-sm">crffund@email.com</p>
          </div>
          <div className="flex items-center gap-3">
            <HiLocationMarker />
            <p className="text-sm">London , United Kingdom</p>
          </div>
        </div>

        <ul className="flex gap-3 items-center z-30 ">
          {footerData.map((footer) => (
            <li key={uuid()}>
              <a href={footer.link} target="blank">
                {footer.component}
              </a>
            </li>
          ))}
        </ul>

        <div className="absolute w-[800px] h-[800px] rounded-full bg-pink-500 right-0 bottom-0 transform translate-x-1/2 translate-y-1/2 bg-opacity-75">
          <div className="w-full h-full relative">
            <div className="absolute w-[200px] h-[200px] rounded-full bg-blue-500 top-10 left-10 transform   bg-opacity-90"></div>
          </div>
        </div>
      </div>

      <div className=" bg-white flex flex-col  w-full items-center flex-1 p-4 sm:p-6 md:p-10 text-center text-white font-600">
        <ContactForm />
      </div>
    </div>
  )
}

export default ContactPage
