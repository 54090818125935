import React, { useEffect, useState } from 'react'
import { Link } from 'react-router-dom'
import uuid from 'react-uuid'
import { NEWS_PAGE } from '../../../../../navigation/Routes'
import EndPoints from '../../../../../services/api'
import axiosInstance from '../../../../../services/axios'
import SingleTweet from '../../../news/tweets/SingleTweet'
import NewsTab from '../../../news/news/tabs/NewsTab'
import Loader from '../../../loader/Loader'

const SocialComponent = () => {
  const [isLoading, setIsLoading] = useState(true)
  const [socialData, setSocialData] = useState({ tweetData: {}, newsData: {} })
  const { getAllNews, getAllTweets } = EndPoints.news

  const fetchSocialData = async () => {
    try {
      const res = await Promise.all([
        axiosInstance.get(getAllNews),
        axiosInstance.get(getAllTweets)
      ])
      const parsedData = res.map((res) => JSON.parse(res.data.body))
      console.log(parsedData)
      if (parsedData) {
        setSocialData({ newsData: parsedData[0], tweetData: parsedData[1] })
        setIsLoading(false)
      }
    } catch (err) {
      console.log(err.message)
    }
  }

  useEffect(() => {
    fetchSocialData()
  }, [])

  return (
    <div className=" mx-auto md:h-[600px] w-full  max-w-8xl ">
      {isLoading ? (
        <Loader />
      ) : (
        <div className="flex flex-col gap-6">
          <h1 className="text-4xl font-bold">Trending</h1>

          <div className=" flex flex-col md:flex-row gap-10  items-center">
            <div className="w-full h-full  bg-purple-darker rounded-3xl p-4 flex flex-col gap-4">
              {Object.keys(socialData.tweetData).length > 0 && (
                <div className=" h-full overflow-y-scroll rounded-3xl scrollbar-hidden grid grid-cols-1 sm:grid-cols-2 md:grid-cols-1 lg:grid-cols-2 gap-4 w-full ">
                  {Object.keys(socialData.tweetData)
                    .slice(0, 6)
                    .map((tweet) => (
                      <SingleTweet
                        bg_color={'bg-purple-darkest'}
                        marginY={'4'}
                        key={uuid()}
                        tweet={socialData.tweetData[tweet]}
                      />
                    ))}
                </div>
              )}
              <Link to={NEWS_PAGE} className="text-center text-sm ">
                View More
              </Link>
            </div>
            <div className="w-full h-full bg-purple-darker text-black rounded-3xl flex flex-col gap-4 p-4 ">
              <div className=" md:h-[530px] rounded-3xl scrollbar-hidden p-3 bg-white">
                <NewsTab
                  newsData={Object.keys(socialData.newsData)
                    .slice(0, 6)
                    .reduce((news, key) => {
                      news[key] = socialData.newsData[key]

                      return news
                    }, {})}
                />
              </div>
              <Link to={NEWS_PAGE} className="text-center text-sm w-full text-white">
                View More
              </Link>
            </div>
          </div>
        </div>
      )}
    </div>
  )
}

export default SocialComponent
