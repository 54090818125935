import React, { useState } from 'react'
import ReactApexChart from 'react-apexcharts'

const StaticPortfolioDonut = ({ seriesData, optionData, text }) => {
  const series = seriesData
  const options = {
    chart: {
      type: 'donut',
      foreColor: '#ffffff'
    },
    stroke: {
      width: 0
    },
    plotOptions: {
      pie: {
        startAngle: 0,
        endAngle: 360
      }
    },
    labels: optionData,
    dataLabels: {
      enabled: true
    },
    fill: {
      type: 'gradient'
    },
    legend: {
      formatter: function (val, opts) {
        return val + ' - ' + opts.w.globals.series[opts.seriesIndex] + '%'
      },
      position: 'bottom'
    },
    title: {
      text,
      align: 'center',
      style: {
        fontSize: '10px'
      }
    }
    // responsive: [
    // 	{
    // 		breakpoint: 1024,
    // 		options: {
    // 			chart: {
    // 				width: 200,
    // 			},
    // 			legend: {
    // 				position: 'bottom',
    // 			},
    // 		},
    // 	},
    // ],
  }

  const [donutSeries, setDonutSeries] = useState(series)
  const [donutOptions, setDonutOptions] = useState(options)

  return (
    <div className="bg-purple-400 bg-opacity-10 h-full w-full p-6 lg:px-1 xl:p-10  rounded-xl flex flex-col justify-between ">
      <div id="chart" className="grid place-items-center text-white text-xs ext-center">
        <ReactApexChart
          options={donutOptions}
          series={donutSeries}
          type="donut"
          height={400}
          className="w-full h-full grid place-items-center"
        />
      </div>
    </div>
  )
}

export default StaticPortfolioDonut
