import { useEffect, useRef, useState } from 'react'

const useElementOnScreen = (options) => {
  const containerRef = useRef(null)
  const [isVisible, setIsVisible] = useState(false)
  const [isRendered, setIsRendered] = useState(false)

  const callBackFunction = (enteries, observer) => {
    const [entry] = enteries
    if (entry.intersectionRatio > 0) {
      observer.unobserve(containerRef.current)
    }
    setIsVisible(entry.isIntersecting)
    if (entry.isIntersecting === true) {
      setIsRendered(entry.isIntersecting)
    }
  }

  useEffect(() => {
    const observer = new IntersectionObserver(callBackFunction, options)
    if (containerRef.current) observer.observe(containerRef.current)
    const current = containerRef.current
    return () => {
      if (current) observer.unobserve(current)
    }
  }, [containerRef, options])

  return [containerRef, isRendered, isVisible]
}

export default useElementOnScreen
