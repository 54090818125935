import React, { useEffect, useState } from 'react'
import EndPoints from '../../../../services/api'
import axios from '../../../../services/axios'
import { includeComma } from '../../../../services/globalFunctions'
import ClickWrapper from '../../click-wrapper/ClickWrapper'
import DataLoader from '../../loader/DataLoader'
import TradingTransactionModal from '../modal/TradingTransactionModal'

const SingleHoldingTable = ({ rowData, wallet, contestId, refreshData, isOpen }) => {
  const [showTransactionModal, setShowTransactionModal] = useState(false)

  const {
    asset_id,
    current_Value,
    daily_pl,
    quantity,
    current_price: price,
    previous_coinprice,
    total_profit_loss,
    unit_price,
    trade_id
  } = rowData

  const [staticData, setStaticData] = useState({})
  const [loading, setLoading] = useState(true)
  const { getStaticData } = EndPoints.singlePortfolio

  const fetchSinglePortfolioRow = async () => {
    try {
      const { data } = await axios.get(`${getStaticData}?coinid=${asset_id}`)

      const singleWatchListData = JSON.parse(data.body)
      const { symbol, logo, coinname, category } = singleWatchListData[`${asset_id}`]

      setStaticData({
        symbol,
        logo,
        coinname,
        category
      })
      setLoading(false)
    } catch (err) {
      console.log(err.message)
    }
  }

  useEffect(() => {
    fetchSinglePortfolioRow()
  }, [])

  return (
    <tr className="text-xs xl:text-sm font-600  bg-purple-700 bg-opacity-10 ">
      <td className="grid place-items-center h-full">
        {loading ? (
          <DataLoader />
        ) : (
          <img
            className="w-8 h-8 object-contain"
            src={staticData.logo}
            alt={staticData.coinname}
          />
        )}
      </td>
      <td className="text-center">
        {loading ? (
          <DataLoader />
        ) : (
          <p>
            {staticData.coinname} ({staticData.symbol})
          </p>
        )}
      </td>

      <td className="text-center">
        <p>{quantity.toFixed(3)}</p>
      </td>
      <td className="text-center text-xs">
        <p className={` text-base lg:text-lg mb-1`}>
          $ {includeComma((price > 1000 ? price / 1000 : price).toFixed(2))}
          {price > 1000 && `k`}
        </p>
        <p>
          ( ${' '}
          {includeComma(
            (previous_coinprice > 1000 ? previous_coinprice / 1000 : previous_coinprice).toFixed(2)
          )}{' '}
          {previous_coinprice > 1000 && `k`} )
        </p>
      </td>
      <td className="  text-center">
        <p className={` mb-1 text-base lg:text-lg`}>
          $
          {includeComma(
            (current_Value > 1000 || current_Value < -1000
              ? current_Value / 1000
              : current_Value
            ).toFixed(2)
          )}{' '}
          {(current_Value > 1000 || current_Value < -1000) && `k`}
        </p>
        <p className="text-xs">
          ${' '}
          {includeComma(
            (quantity * unit_price > 1000 || quantity * unit_price < -1000
              ? (quantity * unit_price) / 1000
              : quantity * unit_price
            ).toFixed(2)
          )}
          {(quantity * unit_price > 1000 || quantity * unit_price < -1000) && `k`} I.V
        </p>
      </td>

      <td className="text-center">
        <p className={`text-${total_profit_loss >= 0 ? `green` : `red`}-500 mb-1`}>
          ${' '}
          {includeComma(
            (total_profit_loss > 1000 || total_profit_loss < -1000
              ? total_profit_loss / 1000
              : total_profit_loss
            ).toFixed(2)
          )}{' '}
          {(total_profit_loss > 1000 || total_profit_loss < -1000) && `k`}
        </p>
        <p className="text-xs">
          {(((current_Value - unit_price * quantity) / (unit_price * quantity)) * 100).toFixed(2)}%
        </p>
      </td>
      <td className="text-center">
        <p className={`text-${daily_pl >= 0 ? `green` : `red`}-500 mb-1`}>
          ${' '}
          {includeComma(
            (daily_pl > 1000 || daily_pl < -1000 ? daily_pl / 1000 : daily_pl).toFixed(2)
          )}{' '}
          {(daily_pl > 1000 || daily_pl < -1000) && `k`}{' '}
        </p>
        {previous_coinprice === 0 ? (
          <p className="text-xs">0.00 %</p>
        ) : (
          <p className="text-xs">
            {(
              ((current_Value - previous_coinprice * quantity) / (previous_coinprice * quantity)) *
              100
            ).toFixed(2)}
            %
          </p>
        )}
      </td>
      {isOpen ? (
        <td className="text-center p-2">
          <button
            className="text-xs px-3 py-2 border-2  transition-all duration-200 border-purple-400 border-opacity-30 cursor-pointer rounded-full "
            onClick={() => setShowTransactionModal(true)}>
            Sell
          </button>
          {showTransactionModal && (
            <div className="fixed left-0 right-0 top-0 h-screen w-full z-10 backdrop-filter backdrop-blur-sm grid place-items-center">
              <div className="z-50">
                <ClickWrapper func={() => setShowTransactionModal(false)}>
                  <TradingTransactionModal
                    close={() => setShowTransactionModal(false)}
                    refreshData={refreshData}
                    tradingData={{
                      coinname: staticData.coinname,
                      price,
                      symbol: staticData.symbol,
                      id: asset_id,
                      quantity,
                      contestId,
                      wallet,
                      associate_trade_id: trade_id
                    }}
                  />
                </ClickWrapper>
              </div>
            </div>
          )}
        </td>
      ) : (
        <td className="text-center text-red-500">
          <p>Closed.</p>
        </td>
      )}
    </tr>
  )
}

export default SingleHoldingTable
