import React, { useEffect, useState } from 'react'

import { IoClose } from 'react-icons/io5'
import { MdDoneAll } from 'react-icons/md'
import { VscCopy } from 'react-icons/vsc'
import EndPoints from '../../../services/api'
import axios from '../../../services/axios'
import Loader from '../loader/Loader'
import { CopyToClipboard } from 'react-copy-to-clipboard'

const ReferralModal = ({ contestid, userid, close, userToken }) => {
  const [loading, setLoading] = useState(true)
  const [referralCode, setReferralCode] = useState('')
  const [isCopied, setIsCopied] = useState(false)

  const { getReferralCode } = EndPoints.contest
  const blockArray = [0, 1, 2, 3, 4, 5]

  const generateReferral = async () => {
    const config = {
      headers: {
        Authorization: `Bearer ${userToken}`
      }
    }
    try {
      const { data } = await axios.get(
        `${getReferralCode}?user_id=${userid}&contest_id=${contestid}`,
        config
      )
      setReferralCode(JSON.parse(data.body).message)
      setLoading(false)
    } catch (err) {
      console.log(err.message)
    }
  }

  const copyToClipboard = () => {
    //navigator.clipboard.writeText(referralCode)
    setIsCopied(true)
    setTimeout(() => {
      close()
    }, 1000)
  }

  useEffect(() => {
    generateReferral()
  }, [])

  return (
    <div className="w-full  p-6 bg-purple-darkest border-2 border-indigo-500 border-opacity-40 text-white grid place-items-center rounded-md relative">
      <button className="absolute top-4 right-4  font-600 z-10 text-xl text-white" onClick={close}>
        <IoClose />
      </button>
      {loading ? (
        <Loader />
      ) : (
        <div className="flex flex-col gap-4 justify-center items-center">
          <div className=" flex flex-col gap-4 text-sm mt-2">
            <p>
              Share your code with your friends for additional points and suprise prizes for both
              you and your referal.
            </p>

            <p>Steps for geting the reward:</p>

            <ul className="flex flex-col gap-1">
              <li>Step 1 : Referal to sign up and create the account.</li>
              <li>Step 2 : Visit http://www.cryptoresearchfund.com/games</li>
              <li>Step 3 : Click on 'ENTER CODE'</li>
              <li>Step 4 : CLick on ' USE REFERAL CODE'</li>
            </ul>
          </div>
          <div className="flex justify-center items-center my-3 text-white">
            {blockArray.map((block) => (
              <p
                key={block}
                className="bg-purple-dark p-2 border-1 border-blue-500 text-lg font-600 mx-1 w-10 h-10 grid place-items-center">
                {referralCode[block]}
              </p>
            ))}
          </div>
          <CopyToClipboard text={referralCode} onCopy={copyToClipboard}>
            <button
              // onClick={() => copyToClipboard()}
              className={`flex gap-2 items-center  justify-center bg-${
                isCopied ? `green` : `blue`
              }-500 px-3 py-2 text-white fomt-600 text-sm w-28 hover:bg-opacity-80 `}>
              {isCopied ? 'Copied' : 'Copy'} <span>{!isCopied ? <VscCopy /> : <MdDoneAll />}</span>
            </button>
          </CopyToClipboard>
        </div>
      )}
    </div>
  )
}

export default ReferralModal
