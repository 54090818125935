import axios from 'axios'
import React, { useEffect, useRef, useState } from 'react'
import { BiLoaderAlt } from 'react-icons/bi'
import { FiSearch } from 'react-icons/fi'
import { IoClose } from 'react-icons/io5'
import { toast, ToastContainer } from 'react-toastify'
import 'react-toastify/dist/ReactToastify.css'
import uuid from 'react-uuid'
import EndPoints from '../../../services/api'
import axiosInstance from '../../../services/axios'
import Loader from '../loader/Loader'

const CoinAdditionModal = ({ close }) => {
  const [loadingSearch, setLoadingSearch] = useState(false)
  const [searchData, setSearchData] = useState([])
  const inputRef = useRef()
  const [loadingRequest, setLoadingRequest] = useState(false)
  const [email, setEmail] = useState('')
  const [coinNameInput, setCoinNameInput] = useState('')

  const [requestedCoin, setRequestedCoin] = useState('')
  const [showEmailModal, setShowEmailModal] = useState(false)
  const { sendCoinRequest, searchInactiveCoins } = EndPoints.listing

  const filterStaticData = async (searchText) => {
    if (searchText.length < 1) {
      setSearchData([])
      return
    }

    setLoadingSearch(true)

    try {
      const { data } = await axios.get(`${searchInactiveCoins}?coin_name=${searchText}`)
      console.log(JSON.parse(data.body))
      setSearchData(JSON.parse(data.body).message)
      setLoadingSearch(false)
    } catch (err) {
      console.log(err.message)
    }
  }

  const handleSendCoinRequest = async (e) => {
    e.preventDefault()
    if (email === '') return
    setLoadingRequest(true)
    const postData = {
      email,
      coin_name: requestedCoin,
      status: ''
    }

    console.log(postData)
    try {
      const { data } = await axiosInstance.post(`${sendCoinRequest}/postcoinrequest`, postData)
      //   console.log(JSON.parse(data.body))
      toast.success(JSON.parse(data.body))
      setLoadingRequest(false)
      close()
    } catch (err) {
      console.log(err.message)
    }
  }

  useEffect(() => {
    if (coinNameInput.length > 0) {
      setLoadingSearch(true)
    } else setLoadingSearch(false)

    const coinSearchTimeout = setTimeout(() => {
      filterStaticData(coinNameInput)
    }, 2000)
    return () => {
      clearTimeout(coinSearchTimeout)
    }
  }, [coinNameInput])

  return (
    <div className="bg-purple-darker border border-purple-500 border-opacity-40 w-full h-96 rounded-md flex justify-center items-center relative">
      <ToastContainer />
      {loadingRequest && (
        <div className="absolute inset-0 bg-white bg-opacity-10 grid place-items-center">
          <Loader />
        </div>
      )}
      <button
        onClick={close}
        className="absolute top-6 right-6 grid place-items-center w-10 h-10 text-2xl rounded-full bg-purple-dark">
        <IoClose />
      </button>
      {showEmailModal ? (
        <div className="flex flex-col gap-6">
          <h2 className="text-sm">
            Please Enter your email to send addition request for coin{' '}
            <span className="text-blue-400 font-bold text-base">{requestedCoin}</span>{' '}
          </h2>
          <form onSubmit={handleSendCoinRequest} className="flex justify-between ">
            <input
              value={email}
              onChange={(e) => setEmail(e.target.value)}
              className="flex-1 w-full border-none outline-none p-3 text-gray-500 placeholder:text-gray-400 "
              type="email"
              placeholder="Enter your email"
            />

            <button className="bg-blue-500 px-3 ">Send Request</button>
          </form>
        </div>
      ) : (
        <div className=" flex flex-col gap-3 relative">
          <p className="">Search for coins</p>
          <form
            className="flex justify-between items-center bg-transparent w-96 p-3 border rounded-md overflow-hidden  border-indigo-100 border-opacity-40"
            //</div>onSubmit={filterStaticData}
          >
            <input
              ref={inputRef}
              className="bg-transparent text-xs w-full "
              value={coinNameInput}
              type="text"
              placeholder="Search for Coin Addition "
              onChange={(e) => setCoinNameInput(e.target.value.trim())}
            />
            {loadingSearch ? (
              <div className="grid place-items-center text-sm animate-spin ">
                <BiLoaderAlt />
              </div>
            ) : (
              <button className="grid place-items-center text-xs" type="submit">
                <FiSearch />
              </button>
            )}
          </form>
          {searchData.length > 0 && coinNameInput.length > 0 && (
            <div className="absolute top-full left-0 w-full bg-purple-darkest border border-purple-400 border-opacity-10 rounded-sm text-sm max-h-96 overflow-y-scroll  ">
              {searchData.map((data) => (
                <div
                  className="p-2 flex justify-between items-center gap-2 cursor-pointer hover:bg-purple-400 hover:bg-opacity-5 rounded-sm"
                  key={uuid()}>
                  <div className="flex items-center gap-2">
                    {/* <img
                      className="w-4 h-4 object-contain "
                      src={data.urlOfLogo}
                      alt={data.coin_name}
                    />{' '} */}
                    <p>{data.coin_name}</p>
                  </div>

                  <div className="flex gap-3 items-center">
                    <p className="font-600">({data.symbol.toUpperCase()})</p>
                    <button
                      onClick={() => {
                        setRequestedCoin(data.coin_name.trim())
                        setShowEmailModal(true)
                      }}
                      className="bg-blue-500 hover:bg-blue-600  px-3 p-2 rounded-full grid place-items-center text-xs ">
                      Request
                    </button>
                  </div>
                </div>
              ))}
            </div>
          )}
        </div>
      )}
    </div>
  )
}

export default CoinAdditionModal
