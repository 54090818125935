import React from 'react'

const DataLoader = () => {
  return (
    <div className="grid place-items-center w-full h-full">
      <div className="loadingio-spinner-ellipsis-8kmdv1oq89l object-contain">
        <div className="ldio-v2obrx097wr">
          <div></div>
          <div></div>
          <div></div>
          <div></div>
          <div></div>
        </div>
      </div>
    </div>
  )
}

export default DataLoader
