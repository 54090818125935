import React, { useState } from 'react'
import { useSelector } from 'react-redux'
import { BrowserRouter as Router } from 'react-router-dom'
import Footer from '../main/apps/footer/Footer'
import Header from '../main/apps/header/Header'
import PromotionBanner from '../main/apps/promotion/PromotionBanner'
import ScrollTop from '../main/apps/scrolltop/ScrollTop'
import SideBar from '../main/apps/sidebar/SideBar'
import SwitchConfig from './SwitchConfig'

const RouterConfig = () => {
  const { loggedIn } = useSelector((state) => state.auth)
  const [showSidebar, setShowSidebar] = useState(false)

  return (
    <Router>
      <ScrollTop />
      <Header showSidebar={showSidebar} setShowSidebar={setShowSidebar} />
      <div className={`${showSidebar && `flex  h-screen overflow-y-scroll`}`}>
        {loggedIn && showSidebar && <SideBar setShowSidebar={setShowSidebar} />}

        <div
          className={` min-h-screen ${
            showSidebar && 'h-full w-full overflow-x-hidden overflow-y-scroll  flex-1'
          }`}>
          {/* <PromotionBanner /> */}
          <SwitchConfig />
          <Footer />
        </div>
      </div>
    </Router>
  )
}

export default RouterConfig
