import React, { Fragment, useEffect, useState } from 'react'
import { ErrorBoundary } from 'react-error-boundary'
import uuid from 'react-uuid'
import EndPoints from '../../../services/api'
import axios from '../../../services/axios'
import ErrorComponent from '../../apps/error/ErrorComponent'
import Loader from '../../apps/loader/Loader'
import NewsAccordion from '../../apps/news/news/NewsAccordion'
import SingleNewsCard from '../../apps/news/news/SingleNewsCard'
import SingleTweet from '../../apps/news/tweets/SingleTweet'
import PromotionBanner from '../../apps/promotion/PromotionBanner'
import { BiChevronRight, BiChevronLeft } from 'react-icons/bi'
import TopRecommendation from '../../apps/recommendation/TopRecommendation'
import { Slider } from 'infinite-react-carousel/lib'
import { useSelector } from 'react-redux'
import ContestCard from '../../apps/games/ContestCard'
import SingleBucket from '../../apps/bucket/SingleBucket'
import WatchListTable from '../../apps/watchlist/WatchListTable'
import ResponsiveTable from '../../apps/watchlist/ResponsiveTable'
import IndexChart from '../../apps/dashboard/IndexChart'
import IndexTable from '../../apps/dashboard/IndexTable'
import { Link } from 'react-router-dom'
import SingleResponsiveBucket from '../../apps/bucket/SingleResponsiveBucket'
import { NEWS_PAGE } from '../../../navigation/Routes'

const ArrowButton = ({ type, onClick }) => {
  return (
    <button
      className={`absolute z-50 top-1/2 ${
        type === 'next' ? '-right-4 ' : '-left-4  '
      } transform -translate-y-1/2 p-1 rounded-full  hover:shadow-md `}
      onClick={onClick}>
      {type === 'next' ? (
        <BiChevronRight className="text-4xl" />
      ) : (
        <BiChevronLeft className="text-4xl" />
      )}
    </button>
  )
}

const DashboardPage = () => {
  const [dashboardData, setDashboardData] = useState({})
  const [loading, setLoading] = useState(true)
  const { loggedIn, user } = useSelector((state) => state.auth)
  const { getAllNews, getAllTweets } = EndPoints.news
  const { getCoinRecommendation } = EndPoints.recommendation
  const { getUserContests, getDummyUserContests } = EndPoints.gamePage
  const { getAllBuckets } = EndPoints.bucket
  const { getAllWatchListData } = EndPoints.watchList

  const fetchDashboardData = async () => {
    const config = {
      headers: {
        Authorization: `Bearer ${user.token}`
      }
    }
    setLoading(true)

    try {
      const res = await Promise.all([
        axios.get(getAllNews),
        axios.get(getAllTweets),
        axios.get(`${getCoinRecommendation}?flag=true`),
        axios.get(
          !loggedIn ? `${getDummyUserContests}` : `${getUserContests}?user_id=${user.user_id}`,
          config
        ),
        axios.get(getAllBuckets),
        axios.get(`${getAllWatchListData}?userid=${user.user_id}`, config)
      ])
      const parseData = res.map((res) => JSON.parse(res.data.body))
      setDashboardData(parseData)
      setLoading(false)
    } catch (err) {
      console.log(err.message)
    }
  }

  useEffect(() => {
    fetchDashboardData()
  }, [loggedIn])

  return (
    <div className="text-white min-h-screen  flex flex-col ">
      {loading ? (
        <Loader />
      ) : (
        <Fragment>
          <div className="flex-1 flex flex-col gap-6 md:gap-10 py-4 sm:py-6 md:py-10 ">
            {' '}
            {/* <PromotionBanner /> */}
            <ErrorBoundary FallbackComponent={ErrorComponent}>
              <div className=" px-4 sm:px-6  md:px-10 ">
                <TopRecommendation bg={'purple-darker'} data={dashboardData[2].message} />
              </div>
            </ErrorBoundary>
            <ErrorBoundary FallbackComponent={ErrorComponent}>
              <div className=" px-4 sm:px-6  md:px-10">
                <IndexChart />
              </div>
            </ErrorBoundary>
            <div>
              {Object.keys(dashboardData[4].message).length > 0 && (
                <Fragment>
                  <ErrorBoundary FallbackComponent={ErrorComponent}>
                    <Slider
                      className="hidden px-6 md:block "
                      swipe
                      adaptiveHeight
                      autoplay={false}
                      slidesToShow={1}
                      wheelScroll={1}
                      arrows={true}
                      nextArrow={<ArrowButton type="next" />}
                      prevArrow={<ArrowButton type="prev" />}>
                      {Object.keys(dashboardData[4].message).map((id) => (
                        <div key={uuid()} className="px-4 ">
                          <SingleBucket data={dashboardData[4].message[id]} id={id} />
                        </div>
                      ))}
                    </Slider>
                  </ErrorBoundary>
                  <ErrorBoundary FallbackComponent={ErrorComponent}>
                    <Slider
                      className="px-6 md:hidden"
                      dots
                      dotsClass={'carousel-dots'}
                      swipe
                      autoplay={false}
                      autoplaySpeed={5000}
                      arrows={false}>
                      {Object.keys(dashboardData[4].message).map((id) => (
                        <SingleResponsiveBucket
                          key={uuid()}
                          data={dashboardData[4].message[id]}
                          id={id}
                        />
                      ))}
                    </Slider>
                  </ErrorBoundary>
                </Fragment>
              )}

              <Fragment>
                {dashboardData[3].message.length > 0 && loggedIn && (
                  <Fragment>
                    <ErrorBoundary FallbackComponent={ErrorComponent}>
                      <Slider
                        className=" px-6 hidden xl:block mt-10"
                        swipe
                        autoplay={false}
                        slidesToShow={3}
                        wheelScroll={1}
                        arrows={true}
                        nextArrow={<ArrowButton type="next" />}
                        prevArrow={<ArrowButton type="prev" />}>
                        {dashboardData[3].message.map((contest) => (
                          <div key={uuid()} className="px-4">
                            {' '}
                            <ContestCard contestData={contest} />
                          </div>
                        ))}
                      </Slider>
                    </ErrorBoundary>
                    <ErrorBoundary FallbackComponent={ErrorComponent}>
                      <Slider
                        className=" px-6  hidden md:block xl:hidden mt-10"
                        swipe
                        autoplay={false}
                        slidesToShow={2}
                        wheelScroll={1}
                        arrows={true}
                        nextArrow={<ArrowButton type="next" />}
                        prevArrow={<ArrowButton type="prev" />}>
                        {dashboardData[3].message.map((contest) => (
                          <div key={uuid()} className="px-4">
                            {' '}
                            <ContestCard contestData={contest} />
                          </div>
                        ))}
                      </Slider>
                    </ErrorBoundary>

                    <ErrorBoundary FallbackComponent={ErrorComponent}>
                      <Slider
                        className="px-6 md:hidden mt-6"
                        dots
                        dotsClass={'carousel-dots'}
                        swipe
                        autoplay={false}
                        autoplaySpeed={5000}
                        arrows={false}>
                        {dashboardData[3].message.map((contest) => (
                          <ContestCard key={uuid()} contestData={contest} />
                        ))}
                      </Slider>
                    </ErrorBoundary>
                  </Fragment>
                )}
              </Fragment>
            </div>
            <ErrorBoundary FallbackComponent={ErrorComponent}>
              <div className=" flex flex-col md:flex-row  gap-10 px-4 sm:px-6  md:px-10 ">
                <div className="flex flex-1 flex-col gap-6 items-center p-6 md:p-10 rounded-xl bg-purple-darker">
                  {Object.keys(dashboardData[0]).length > 0 && (
                    <div className="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-1 xl:grid-cols-2 gap-6 w-full ">
                      {Object.keys(dashboardData[0])
                        .slice(0, 6)
                        .map((news) => (
                          <NewsAccordion
                            key={uuid()}
                            bg_color={'bg-purple-darkest'}
                            news={dashboardData[0][news]}
                          />
                        ))}
                    </div>
                  )}
                  <Link to={NEWS_PAGE} className=" text-xs text-center w-full ">
                    Click here to <span className="text-blue-400">see more</span>
                  </Link>
                </div>
                <div className="flex flex-1 flex-col gap-6 items-center p-6 md:p-10 rounded-xl bg-purple-darker">
                  {Object.keys(dashboardData[1]).length > 0 && (
                    <div className="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-1 xl:grid-cols-2 gap-6 w-full">
                      {Object.keys(dashboardData[1])
                        .slice(0, 4)
                        .map((tweet) => (
                          <SingleTweet
                            bg_color={'bg-purple-darkest'}
                            marginY={'4'}
                            key={uuid()}
                            tweet={dashboardData[1][tweet]}
                          />
                        ))}
                    </div>
                  )}
                  <Link to={NEWS_PAGE} className=" text-xs  text-center ">
                    Click here to <span className="text-blue-400">see more</span>
                  </Link>
                </div>
              </div>
            </ErrorBoundary>
            <ErrorBoundary FallbackComponent={ErrorComponent}>
              <div className=" px-4 sm:px-6  md:px-10 ">
                {' '}
                <WatchListTable data={dashboardData[5]} func={fetchDashboardData} />
              </div>
            </ErrorBoundary>
          </div>
        </Fragment>
      )}
    </div>
  )
}

export default DashboardPage
