export const LeaderBoardHeader = [
  {
    title: 'Rank'
  },
  {
    title: 'User'
  },
  {
    title: 'Profit / Loss'
  },
  {
    title: 'Contest'
  },
  {
    title: 'Status'
  }
]
