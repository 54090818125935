//Dev api endpoints

//platform api
const DEV_GET_HEADER_DATA = 'https://5wny1p8ifk.execute-api.ap-south-1.amazonaws.com/dev'

//News page api
const DEV_NEWSAPI_ENDPOINT = 'https://h3iiccq04i.execute-api.ap-south-1.amazonaws.com/dev'
const DEV_UNTOLD_STORIES_ENDPOINT = 'https://b5lpvp99sc.execute-api.ap-south-1.amazonaws.com/dev'
const DEV_TWEETAPI_ENDPOINT = 'https://e1kq79mt48.execute-api.ap-south-1.amazonaws.com/dev'

//Listing page api
const DEV_COIN_STATIC_DATA_ENDPOINT = 'https://xo64bkek03.execute-api.ap-south-1.amazonaws.com/dev'
const DEV_COIN_PRICE_DATA_ENDPOINT = 'https://ur331c2b6d.execute-api.ap-south-1.amazonaws.com/dev'
const DEV_COIN_SENTIMENT_ENDPOINT = 'https://2skv353nqg.execute-api.ap-south-1.amazonaws.com/dev'
const DEV_COIN_GITHUB_ENDPOINT = 'https://krae9c2tl4.execute-api.ap-south-1.amazonaws.com/dev'
const DEV_COIN_24H_ENDPOINT = 'https://pwc9ank1gd.execute-api.ap-south-1.amazonaws.com/dev'
const DEV_COIN_RATING_ENDPOINT = 'https://l458k958o5.execute-api.ap-south-1.amazonaws.com/dev'
const DEV_COIN_ALL_INFO_ENDPOINT = 'https://qmyp8bjs63.execute-api.ap-south-1.amazonaws.com/dev'
const DEV_COIN_ALL_IN_ONE = 'https://bb0ee2uxp1.execute-api.ap-south-1.amazonaws.com/dev'
const DEV_SEARCH_COIN = 'https://bb0ee2uxp1.execute-api.ap-south-1.amazonaws.com/dev/search/'
const DEV_SORT_COIN = 'https://bb0ee2uxp1.execute-api.ap-south-1.amazonaws.com/dev'
const DEV_SEND_COIN_REQUEST = 'https://47cgev28y7.execute-api.ap-south-1.amazonaws.com/dev'
const DEV_INACTIVE_COIN_SEARCH =
  'https://eg4elp1o77.execute-api.ap-south-1.amazonaws.com/dev/searchcoin'

//Single coin page api
const DEV_SINGLE_COIN_CHART_ENDPOINT =
  'https://hxksgknn59.execute-api.ap-south-1.amazonaws.com/dev'
const DEV_SINGLE_COIN_METRICS_ENDPOINT =
  'https://ro5cy36jc8.execute-api.ap-south-1.amazonaws.com/dev'
const DEV_SINGLE_COIN_ABOUT_ENDPOINT =
  'https://b5lpvp99sc.execute-api.ap-south-1.amazonaws.com/dev'
const DEV_SINGLE_COIN_TRENDS =
  'https://85oumz98uc.execute-api.ap-south-1.amazonaws.com/dev/gettrend'
const DEV_SINGLE_COIN_RATING = 'https://85oumz98uc.execute-api.ap-south-1.amazonaws.com/dev'
const DEV_CHECK_WATCHLIST_ENDPOINT =
  'https://b3elxpqdx9.execute-api.ap-south-1.amazonaws.com/dev/check'
const DEV_SINGLE_COIN_INDICATORS =
  'https://qe3hgocag6.execute-api.ap-south-1.amazonaws.com/dev/indicators'

const DEV_SINGLE_COIN_BOT_RECOMMENDATION =
  'https://4uwogvp086.execute-api.ap-south-1.amazonaws.com/dev'

//watchlist page api
const DEV_WATCHLIST_TABLE_ENDPOINT = 'https://b3elxpqdx9.execute-api.ap-south-1.amazonaws.com/dev'
const DEV_ADD_TO_WATCHLIST =
  'https://b3elxpqdx9.execute-api.ap-south-1.amazonaws.com/dev/postwatchlist'

//Authentication api
const DEV_AUTH_SIGNUP = 'https://ho7d3pjsib.execute-api.ap-south-1.amazonaws.com/dev/signup'
const DEV_AUTH_SIGNIN = 'https://ho7d3pjsib.execute-api.ap-south-1.amazonaws.com/dev/signin'
const DEV_AUTH_CHECK_EMAIL_AVAILABILITY =
  'https://ho7d3pjsib.execute-api.ap-south-1.amazonaws.com/dev/checkemail'
const DEV_AUTH_SEND_OTP = 'https://3cmti2ew5g.execute-api.ap-south-1.amazonaws.com/dev/sendotp'
const DEV_AUTH_VALIDATE_OTP =
  'https://3cmti2ew5g.execute-api.ap-south-1.amazonaws.com/dev/validateotp'
const DEV_AUTH_CHANGE_PASSWORD =
  'https://3cmti2ew5g.execute-api.ap-south-1.amazonaws.com/dev/changepassword'

//Portfolio api

const DEV_PORTFOLIO_SUMMARY = 'https://211pciinod.execute-api.ap-south-1.amazonaws.com/dev/summary'
const DEV_PORTFOLIO_TRADE = 'https://211pciinod.execute-api.ap-south-1.amazonaws.com/dev/trades'
const DEV_PORTFOLIO_BUCKETS = 'https://211pciinod.execute-api.ap-south-1.amazonaws.com/dev/buckets'
const DEV_PORTFOLIO_SECTOR = 'https://211pciinod.execute-api.ap-south-1.amazonaws.com/dev/trades'

//Bucket api

const DEV_BUCKET_API = 'https://g9enuecmqd.execute-api.ap-south-1.amazonaws.com/dev/'

//Single Bucket api

const DEV_SINGLE_BUCKET_CHART_API = 'https://g9enuecmqd.execute-api.ap-south-1.amazonaws.com/dev'
const DEV_SINGLE_BUCKET_GRAPH_API =
  'https://g9enuecmqd.execute-api.ap-south-1.amazonaws.com/dev/details/blockgraph'
const DEV_SINGLE_BUCKET_COMPARE_API =
  'https://g9enuecmqd.execute-api.ap-south-1.amazonaws.com/dev/details/coingraph'
const DEV_SINGLE_BUCKET_STATIC_DATA =
  'https://g9enuecmqd.execute-api.ap-south-1.amazonaws.com/dev/details/description'
const DEV_SINGLE_BUCKET_INDEX =
  'https://vqfv4dtra6.execute-api.ap-south-1.amazonaws.com/dev/dashboard'
const DEV_SINGLE_PORTFOLIO_GRAPH_ENDPOINT =
  'https://211pciinod.execute-api.ap-south-1.amazonaws.com/dev/graph'
const DEV_SINGLE_BUCKET_TRANSACTION =
  'https://vqfv4dtra6.execute-api.ap-south-1.amazonaws.com/dev/'

//game contest api

const DEV_ENTER_CONTEST = 'https://qv199j4wi9.execute-api.ap-south-1.amazonaws.com/dev/users'
const DEV_GET_CONTEST = 'https://qv199j4wi9.execute-api.ap-south-1.amazonaws.com/dev/contests'
const DEV_GET_CONTEST_USERS = 'https://qv199j4wi9.execute-api.ap-south-1.amazonaws.com/dev/users'
const DEV_GET_HOLDING_DATA = 'https://gdvgm7w286.execute-api.ap-south-1.amazonaws.com/dev'
const DEV_GET_REFERRAL = 'https://qv199j4wi9.execute-api.ap-south-1.amazonaws.com/dev/referralcode'
const DEV_GET_CONTEST_SUMMMARY =
  'https://soo9gbe71m.execute-api.ap-south-1.amazonaws.com/dev/getchange'
const DEV_GET_USER_CONTEST =
  'https://lo9xq1zokl.execute-api.ap-south-1.amazonaws.com/dev/getcontestids'
const DEV_GET_DUMMY_USER_CONTEST =
  'https://lo9xq1zokl.execute-api.ap-south-1.amazonaws.com/dev/getcontestidsdummy'
const DEV_GET_USER_RANKINGS =
  'https://yystodgdui.execute-api.ap-south-1.amazonaws.com/dev/getranks'

const DEV_CHECK_CONTEST_VALIDITY =
  'https://qv199j4wi9.execute-api.ap-south-1.amazonaws.com/dev/contests'
const DEV_GET_ALL_CONTEST =
  'https://qv199j4wi9.execute-api.ap-south-1.amazonaws.com/dev/getallcontest'

// const DEV_GET_TOKEN = 'https://soo9gbe71m.execute-api.ap-south-1.amazonaws.com/dev/gettrades'

// const DEV_GET_TRADE = 'https://soo9gbe71m.execute-api.ap-south-1.amazonaws.com/dev/gettrade'

// const DEV_GET_PROFIT_LOSS = 'https://soo9gbe71m.execute-api.ap-south-1.amazonaws.com/dev/getchange'

//Coin Recommendation Api

const DEV_GET_COIN_RECOMMENDATION = 'https://xfeyf07937.execute-api.ap-south-1.amazonaws.com/dev'

//bot recommendation api

const DEV_GET_BOT_RECOMMENDATION = 'https://4uwogvp086.execute-api.ap-south-1.amazonaws.com/dev/'
const DEV_GET_BOT_NAMES = 'https://7f5dzcxweg.execute-api.ap-south-1.amazonaws.com/dev/getbotnames'
const DEV_GET_BOT_STATS = 'https://z9klltsk56.execute-api.ap-south-1.amazonaws.com/dev'

//analyst recommendation api

const DEV_GET_ANALYST_RECOMMENDATION =
  'https://g9p54gzow5.execute-api.ap-south-1.amazonaws.com/dev'

//trading page api

const DEV_TRADE_ENDPOINT = 'https://kgjmlbhpvj.execute-api.ap-south-1.amazonaws.com/dev'

//ranking page api

const DEV_GET_CONTEST_RANKING =
  'https://qv199j4wi9.execute-api.ap-south-1.amazonaws.com/dev/summary'

//Prod api endpoints

//platform api
const PROD_GET_HEADER_DATA = ''

//News page api
const PROD_NEWSAPI_ENDPOINT = ''
const PROD_UNTOLD_STORIES_ENDPOINT = ''
const PROD_TWEETAPI_ENDPOINT = ''

//Listing page api
const PROD_COIN_STATIC_DATA_ENDPOINT = ''
const PROD_COIN_PRICE_DATA_ENDPOINT = ''
const PROD_COIN_SENTIMENT_ENDPOINT = ''
const PROD_COIN_GITHUB_ENDPOINT = ''
const PROD_COIN_24H_ENDPOINT = ''
const PROD_COIN_RATING_ENDPOINT = ''
const PROD_COIN_ALL_INFO_ENDPOINT = ''
const PROD_COIN_ALL_IN_ONE = ''
const PROD_SEARCH_COIN = ''
const PROD_SORT_COIN = ''
const PROD_SEND_COIN_REQUEST = ''
const PROD_INACTIVE_COIN_SEARCH = ''

//Single coin page api
const PROD_SINGLE_COIN_METRICS_ENDPOINT = ''
const PROD_SINGLE_COIN_CHART_ENDPOINT = ''
const PROD_SINGLE_COIN_ABOUT_ENDPOINT = ''
const PROD_SINGLE_COIN_TRENDS = ''
const PROD_SINGLE_COIN_RATING = ''
const PROD_CHECK_WATCHLIST_ENDPOINT = ''
const PROD_SINGLE_COIN_INDICATORS = ''
const PROD_SINGLE_COIN_BOT_RECOMMENDATION = ''

//wachlist page api
const PROD_WATCHLIST_TABLE_ENDPOINT = ''
const PROD_ADD_TO_WATCHLIST = ''

//Authentication api
const PROD_AUTH_SIGNUP = ''
const PROD_AUTH_SIGNIN = ''
const PROD_AUTH_CHECK_EMAIL_AVAILABILITY = ''
const PROD_AUTH_SEND_OTP = ''
const PROD_AUTH_VALIDATE_OTP = ''
const PROD_AUTH_CHANGE_PASSWORD = ''

//portfolio api

const PROD_PORTFOLIO_SUMMARY = ''
const PROD_PORTFOLIO_TRADE = ''
const PROD_PORTFOLIO_BUCKETS = ''
const PROD_PORTFOLIO_SECTOR = ''
const PROD_SINGLE_PORTFOLIO_GRAPH_ENDPOINT = ''

//Bucket api

const PROD_BUCKET_API = ''

//single Bucket api

const PROD_SINGLE_BUCKET_CHART_API = ''
const PROD_SINGLE_BUCKET_GRAPH_API = ''
const PROD_SINGLE_BUCKET_COMPARE_API = ''
const PROD_SINGLE_BUCKET_STATIC_DATA = ''
const PROD_SINGLE_BUCKET_INDEX = ''
const PROD_SINGLE_BUCKET_TRANSACTION = ''

//game contest api

const PROD_ENTER_CONTEST = ''
const PROD_GET_CONTEST = ''
const PROD_GET_CONTEST_USERS = ''
const PROD_GET_REFERRAL = ''
const PROD_GET_HOLDING_DATA = ''
const PROD_GET_CONTEST_SUMMARY = ''
const PROD_GET_USER_CONTEST = ''
const PROD_GET_DUMMY_USER_CONTEST = ''
const PROD_GET_USER_RANKINGS = ''
const PROD_CHECK_CONTEST_VALIDITY = ''
const PROD_GET_ALL_CONTEST = ''

// const PROD_GET_TOKEN = ''

// const PROD_GET_TRADE = ''

// const PROD_GET_PROFIT_LOSS = ''

//coin recommendation api

const PROD_GET_COIN_RECOMMENDATION = ''

//bot recommendation api

const PROD_GET_BOT_RECOMMENDATION = ''
const PROD_GET_BOT_NAMES = ''
const PROD_GET_BOT_STATS = ''

//analyst recommendation api

const PROD_GET_ANALYST_RECOMMENDATION = ''

//trading page api

const PROD_TRADE_ENDPOINT = ''

//ranking page api

const PROD_GET_CONTEST_RANKING = ''

let UAT = true

const EndPoints = {
  platform: {
    getHeaderData: UAT ? DEV_GET_HEADER_DATA : PROD_GET_HEADER_DATA
  },

  news: {
    getAllNews: UAT ? DEV_NEWSAPI_ENDPOINT : PROD_NEWSAPI_ENDPOINT,
    getAllTweets: UAT ? DEV_TWEETAPI_ENDPOINT : PROD_TWEETAPI_ENDPOINT,
    getUntoldStories: UAT ? DEV_UNTOLD_STORIES_ENDPOINT : PROD_UNTOLD_STORIES_ENDPOINT
  },

  listing: {
    getAllNews: UAT ? DEV_NEWSAPI_ENDPOINT : PROD_NEWSAPI_ENDPOINT,
    getAllTweets: UAT ? DEV_TWEETAPI_ENDPOINT : PROD_TWEETAPI_ENDPOINT,
    getStaticData: UAT ? DEV_COIN_STATIC_DATA_ENDPOINT : PROD_COIN_STATIC_DATA_ENDPOINT,
    getCurrentPrice: UAT ? DEV_COIN_PRICE_DATA_ENDPOINT : PROD_COIN_PRICE_DATA_ENDPOINT,
    getSentimentData: UAT ? DEV_COIN_SENTIMENT_ENDPOINT : PROD_COIN_SENTIMENT_ENDPOINT,
    getGithubData: UAT ? DEV_COIN_GITHUB_ENDPOINT : PROD_COIN_GITHUB_ENDPOINT,
    get24hChange: UAT ? DEV_COIN_24H_ENDPOINT : PROD_COIN_24H_ENDPOINT,
    getRiskRating: UAT ? DEV_COIN_RATING_ENDPOINT : PROD_COIN_RATING_ENDPOINT,
    getAllInOne: UAT ? DEV_COIN_ALL_IN_ONE : PROD_COIN_ALL_IN_ONE,
    getSearchCoin: UAT ? DEV_SEARCH_COIN : PROD_SEARCH_COIN,
    getSortCoin: UAT ? DEV_SORT_COIN : PROD_SORT_COIN,
    sendCoinRequest: UAT ? DEV_SEND_COIN_REQUEST : PROD_SEND_COIN_REQUEST,
    searchInactiveCoins: UAT ? DEV_INACTIVE_COIN_SEARCH : PROD_INACTIVE_COIN_SEARCH
  },
  singleCoin: {
    getSingleNews: UAT ? DEV_NEWSAPI_ENDPOINT : PROD_NEWSAPI_ENDPOINT,
    getSingleTweet: UAT ? DEV_TWEETAPI_ENDPOINT : PROD_TWEETAPI_ENDPOINT,
    getSingleCoinPrice: UAT ? DEV_COIN_PRICE_DATA_ENDPOINT : PROD_COIN_PRICE_DATA_ENDPOINT,
    getSingleCoinSentiment: UAT ? DEV_COIN_SENTIMENT_ENDPOINT : PROD_COIN_SENTIMENT_ENDPOINT,
    getSingleCoinGithub: UAT ? DEV_COIN_GITHUB_ENDPOINT : PROD_COIN_GITHUB_ENDPOINT,
    getSingleCoin24hChange: UAT ? DEV_COIN_24H_ENDPOINT : PROD_COIN_24H_ENDPOINT,
    getSingleCoinMetrics: UAT
      ? DEV_SINGLE_COIN_METRICS_ENDPOINT
      : PROD_SINGLE_COIN_METRICS_ENDPOINT,
    getChartData: UAT ? DEV_SINGLE_COIN_CHART_ENDPOINT : PROD_SINGLE_COIN_CHART_ENDPOINT,
    getSingleCoinRating: UAT ? DEV_COIN_RATING_ENDPOINT : PROD_COIN_RATING_ENDPOINT,
    getAboutCoin: UAT ? DEV_SINGLE_COIN_ABOUT_ENDPOINT : PROD_SINGLE_COIN_ABOUT_ENDPOINT,
    getAllSingleCoinData: UAT ? DEV_COIN_ALL_INFO_ENDPOINT : PROD_COIN_ALL_INFO_ENDPOINT,
    getSingleCoinTrends: UAT ? DEV_SINGLE_COIN_TRENDS : PROD_SINGLE_COIN_TRENDS,
    getSingleCoinRatings: UAT ? DEV_SINGLE_COIN_RATING : PROD_SINGLE_COIN_RATING,
    checkWatchlist: UAT ? DEV_CHECK_WATCHLIST_ENDPOINT : PROD_CHECK_WATCHLIST_ENDPOINT,
    getSingleCoinIndicators: UAT ? DEV_SINGLE_COIN_INDICATORS : PROD_SINGLE_COIN_INDICATORS,
    getSingleCoinBotRecommendation: UAT
      ? DEV_SINGLE_COIN_BOT_RECOMMENDATION
      : PROD_SINGLE_COIN_BOT_RECOMMENDATION
  },
  watchList: {
    getAllWatchListData: UAT ? DEV_WATCHLIST_TABLE_ENDPOINT : PROD_WATCHLIST_TABLE_ENDPOINT,
    getStaticData: UAT ? DEV_COIN_STATIC_DATA_ENDPOINT : PROD_COIN_STATIC_DATA_ENDPOINT,
    getSingleCoin24hChange: UAT ? DEV_COIN_24H_ENDPOINT : PROD_COIN_24H_ENDPOINT,
    addToWatchlistEndpoint: UAT ? DEV_ADD_TO_WATCHLIST : PROD_ADD_TO_WATCHLIST
  },
  singlePortfolio: {
    getPortfolioGraphData: UAT
      ? DEV_SINGLE_PORTFOLIO_GRAPH_ENDPOINT
      : PROD_SINGLE_PORTFOLIO_GRAPH_ENDPOINT,
    getStaticData: UAT ? DEV_COIN_STATIC_DATA_ENDPOINT : PROD_COIN_STATIC_DATA_ENDPOINT,
    getPortfolioSummary: UAT ? DEV_PORTFOLIO_SUMMARY : PROD_PORTFOLIO_SUMMARY,
    getPortfolioBuckets: UAT ? DEV_PORTFOLIO_BUCKETS : PROD_PORTFOLIO_BUCKETS,
    getPortfolioTrades: UAT ? DEV_PORTFOLIO_TRADE : PROD_PORTFOLIO_TRADE,
    getPortfolioSector: UAT ? DEV_PORTFOLIO_SECTOR : PROD_PORTFOLIO_SECTOR
  },
  auth: {
    authSignUp: UAT ? DEV_AUTH_SIGNUP : PROD_AUTH_SIGNUP,
    authSignIn: UAT ? DEV_AUTH_SIGNIN : PROD_AUTH_SIGNIN,
    authCheckEmailAvailability: UAT
      ? DEV_AUTH_CHECK_EMAIL_AVAILABILITY
      : PROD_AUTH_CHECK_EMAIL_AVAILABILITY,
    authSendOtp: UAT ? DEV_AUTH_SEND_OTP : PROD_AUTH_SEND_OTP,
    authValidateOtp: UAT ? DEV_AUTH_VALIDATE_OTP : PROD_AUTH_VALIDATE_OTP,
    authChangePassword: UAT ? DEV_AUTH_CHANGE_PASSWORD : PROD_AUTH_CHANGE_PASSWORD
  },
  bucket: {
    getAllBuckets: UAT ? DEV_BUCKET_API : PROD_BUCKET_API
  },
  singleBucket: {
    getSingleBucketChartData: UAT ? DEV_SINGLE_BUCKET_CHART_API : PROD_SINGLE_BUCKET_CHART_API,
    getSingleBucketGraphData: UAT ? DEV_SINGLE_BUCKET_GRAPH_API : PROD_SINGLE_BUCKET_GRAPH_API,
    getSingleBucketComparisionData: UAT
      ? DEV_SINGLE_BUCKET_COMPARE_API
      : PROD_SINGLE_BUCKET_COMPARE_API,
    getSingleBucketStaticData: UAT
      ? DEV_SINGLE_BUCKET_STATIC_DATA
      : PROD_SINGLE_BUCKET_STATIC_DATA,
    getSingleBucketIndex: UAT ? DEV_SINGLE_BUCKET_INDEX : PROD_SINGLE_BUCKET_INDEX,
    bucketTransaction: UAT ? DEV_SINGLE_BUCKET_TRANSACTION : PROD_SINGLE_BUCKET_TRANSACTION
  },
  gamePage: {
    getContestData: UAT ? DEV_GET_CONTEST : PROD_GET_CONTEST,
    addContestUser: UAT ? DEV_ENTER_CONTEST : PROD_ENTER_CONTEST,
    getContestUsers: UAT ? DEV_GET_CONTEST_USERS : PROD_GET_CONTEST_USERS,
    getUserContests: UAT ? DEV_GET_USER_CONTEST : PROD_GET_USER_CONTEST,
    getDummyUserContests: UAT ? DEV_GET_DUMMY_USER_CONTEST : PROD_GET_DUMMY_USER_CONTEST,
    getUserRankings: UAT ? DEV_GET_USER_RANKINGS : PROD_GET_USER_RANKINGS
  },
  contest: {
    getContestData: UAT ? DEV_GET_CONTEST : PROD_GET_CONTEST,
    getContestSummary: UAT ? DEV_GET_CONTEST_SUMMMARY : PROD_GET_CONTEST_SUMMARY,
    getContestHolding: UAT ? DEV_GET_HOLDING_DATA : PROD_GET_HOLDING_DATA,
    checkContestValidity: UAT ? DEV_CHECK_CONTEST_VALIDITY : PROD_CHECK_CONTEST_VALIDITY,
    getReferralCode: UAT ? DEV_GET_REFERRAL : PROD_GET_REFERRAL,
    getAllContest: UAT ? DEV_GET_ALL_CONTEST : PROD_GET_ALL_CONTEST
  },
  trade: {
    buyCoinTrade: UAT ? DEV_TRADE_ENDPOINT : PROD_TRADE_ENDPOINT
  },
  ranking: {
    getRanking: UAT ? DEV_GET_CONTEST_RANKING : PROD_GET_CONTEST_RANKING
  },
  recommendation: {
    getCoinRecommendation: UAT ? DEV_GET_COIN_RECOMMENDATION : PROD_GET_COIN_RECOMMENDATION,
    getStaticData: UAT ? DEV_COIN_STATIC_DATA_ENDPOINT : PROD_COIN_STATIC_DATA_ENDPOINT
  },
  botRecommendation: {
    getBotRecommendation: UAT ? DEV_GET_BOT_RECOMMENDATION : PROD_GET_BOT_RECOMMENDATION,
    getBotNames: UAT ? DEV_GET_BOT_NAMES : PROD_GET_BOT_NAMES,
    getBotStats: UAT ? DEV_GET_BOT_STATS : PROD_GET_BOT_STATS
  },
  analyst: {
    getAnalystRecommendation: UAT
      ? DEV_GET_ANALYST_RECOMMENDATION
      : PROD_GET_ANALYST_RECOMMENDATION
  }
}

export default EndPoints
