import ProgressBar from './ProgressBar'
import React, { Fragment, useState } from 'react'
import ResponsiveContestHeader from './ResponsiveContestHeader'
import { includeComma } from '../../../services/globalFunctions'
import ClickWrapper from '../click-wrapper/ClickWrapper'
import ReferralModal from './ReferralModal'
import { BsInfoSquare } from 'react-icons/bs'
import { useSelector } from 'react-redux'
import { FaUsers } from 'react-icons/fa'
import { makeStyles, Tooltip } from '@material-ui/core'

const useStyles = makeStyles((theme) => ({
  arrow: {
    color: theme.palette.common.black
  },
  tooltip: {
    backgroundColor: theme.palette.common.black,
    maxWidth: 300,
    padding: 10,
    fontSize: 12
  }
}))

const ContestHeader = ({ contestData, isOpen, id, tradeCount }) => {
  const {
    contest_owner,
    minimum_participant,
    initial_coins,
    status,
    start_date,
    end_date,
    participant_count
  } = contestData[0].message[0]

  const { change, invested_amount, percentage, token, value, networth } = contestData[1].message
  const [showReferralModal, setShowReferralModal] = useState(false)
  const [showResponsiveModal, setShowResponsiveModal] = useState()
  const { user } = useSelector((state) => state.auth)
  const classes = useStyles()
  return (
    <div className="flex flex-col-reverse  lg:flex-row  relative">
      <div className="flex flex-col w-full text-center ">
        {showResponsiveModal && (
          <div className="bg-purple-darker rounded-xl capitalize text-xs flex flex-col justify-evenly absolute -top-6 left-0 w-full border-2 border-purple-400 border-opacity-10 z-20 bg-purple-darkest p-4">
            <ClickWrapper func={() => setShowResponsiveModal(false)}>
              <Fragment>
                <ResponsiveContestHeader contestData={contestData[0].message[0]} isOpen={isOpen} />
              </Fragment>
            </ClickWrapper>
          </div>
        )}

        <Tooltip arrow title={'Contest Info'} classes={classes}>
          <button
            className=" text-white font-500 md:hidden mx-auto my-3 "
            onClick={() => setShowResponsiveModal(true)}>
            <BsInfoSquare />
          </button>
        </Tooltip>

        <div className="hidden md:flex justify-between items-start mb-6">
          <div className="flex flex-col justify-between h-full mx-2 text-sm">
            <p className="mb-2">Contest Owner</p>
            <p className="text-xs font-600">{contest_owner}</p>
          </div>
          <div className="flex flex-col justify-between h-full mx-2 text-sm">
            <p className="mb-2">Status</p>
            <p className="text-xs font-600">{status}</p>
          </div>
          <div className="flex flex-col justify-between h-full mx-2 text-sm">
            <p className="mb-2">Initial CRF Token</p>
            <p className="text-xs font-600">{initial_coins}</p>
          </div>
          <div className="flex flex-col justify-between h-full mx-2 text-sm">
            <p className="mb-2">Start Date</p>
            <p className="text-xs font-600">{start_date.split('-').reverse().join('-')}</p>
          </div>
          <div className="flex flex-col justify-between h-full mx-2 text-sm">
            <p className="mb-2">End Date</p>
            <p className="text-xs font-600">
              {end_date.split(' ')[0].split('-').reverse().join('-')}
            </p>
          </div>
          <div className="flex flex-col justify-between h-full mx-2 text-sm">
            <p className="mb-2">Participants</p>
            <p className="text-xs font-600">{participant_count}</p>
          </div>
          <div className="flex flex-col justify-between h-full mx-2 text-sm">
            <p className="mb-2">Min Participants</p>
            <p className="text-xs font-600">{minimum_participant}</p>
          </div>
        </div>
        <div className="flex flex-col md:flex-row w-full gap-6 relative">
          <div className="bg-purple-dark flex flex-col md:w-2/3 p-6 rounded-lg">
            {/* <div className="mb-2">
              <h5 className="font-700 text-center text-sm">Rank Meter</h5>
              <div className="py-3 w-full  max-w-6xl m-auto">
                <div className="flex-1">
                  {' '}
                  <ProgressBar value={14} />
                </div>
              </div>
            </div> */}
            <div className="flex flex-col gap-6 justify-around h-full  relative">
              <div className="flex flex-col mx-2 text-sm">
                <p className="mb-2">Account Value</p>
                <p className="text-base md:text-2xl lg:text-3xl font-700">
                  {includeComma(networth.toFixed(2))}
                </p>
              </div>
              {/* <div className="absolute top-4 left-4 flex flex-col col-span-2 sm:col-span-4  mx-2 text-sm">
                <p className="mb-2">Number of Trades</p>
                <p className=" font-700">{includeComma(networth)}</p>
              </div> */}
              <div className="grid grid-cols-2  gap-4 sm:grid-cols-4">
                <div className="flex flex-col justify-between  mx-2 text-sm">
                  <p className="mb-2">Invested Amount</p>
                  <p className="text-base md:text-2xl lg:text-3xl font-700">
                    {includeComma(invested_amount.toFixed(2))}
                  </p>
                </div>
                <div className="flex flex-col  justify-between   mx-2 text-sm">
                  <p className="mb-2">CRF Game Token</p>
                  <p className="text-base md:text-2xl lg:text-3xl font-700">
                    {includeComma(token)}
                  </p>
                </div>
                <div className="flex flex-col  justify-between   mx-2 text-sm">
                  <p className="mb-2">No of Trades</p>
                  <p className="text-base md:text-2xl lg:text-3xl font-700">{tradeCount}</p>
                </div>
                <div className="flex flex-col justify-between   mx-2 text-sm">
                  <p className="mb-2">Change (%)</p>
                  <p
                    className={`text-base md:text-2xl lg:text-3xl font-700 ${
                      percentage >= 0 ? `text-green-500` : `text-red-500`
                    }`}>
                    {includeComma(change.toFixed(2))}{' '}
                    <span
                      className={`text-sm ${percentage >= 0 ? `text-green-500` : `text-red-500`}`}>
                      ({percentage.toFixed(2)}%)
                    </span>
                  </p>
                </div>
              </div>

              {/* <div className="flex flex-col justify-between mx-2 text-sm">
            <p className="mb-2">P/L Realized</p>
            <p className="text-base md:text-2xl font-700 text-green-500">3000 (34%)</p>
          </div> */}
            </div>
          </div>
          <div className="md:w-1/3 bg-purple-dark rounded-md lg:rounded-lg relative">
            {
              //isOpen &&
              <div
                id="referal_design"
                className="flex flex-col justify-between gap-6 text-left  absolute inset-0 p-4  bg-black bg-opacity-40  ">
                <button
                  className="flex gap-2 justify-center  w-40 items-center border-2 border-yellow-600 bg-yellow-600 hover:bg-transparent transition-all duration-300 ease-in-out  p-2 text-xs rounded-md"
                  onClick={() => setShowReferralModal(true)}>
                  Refer and Earn{' '}
                  <span className="text-lg">
                    <FaUsers />
                  </span>
                </button>

                <h3 className="text-lg md:text-xl lg:text-2xl font-600 text-center ">
                  Refer to your friends and get rewards of 100 tokens
                </h3>
                <li className="text-xs">
                  <b className="text-red-500">Note :</b> Please note, invited friends must verify
                  their email and make at least one trade.
                </li>
              </div>
            }
            <div className=" w-4/5 h-4/5  m-auto ">
              <lottie-player
                src="https://assets2.lottiefiles.com/packages/lf20_jzmdkj5n.json"
                background="transparent"
                speed="1"
                style={{ width: '75%', height: '75%', objectFit: 'contain', margin: 'auto' }}
                loop
                autoplay></lottie-player>
            </div>
          </div>

          {showReferralModal && (
            <div className=" grid place-items-center fixed inset-0 z-40 md:z-10 backdrop-filter backdrop-blur-sm  ">
              <div className="w-full  z-10 p-4 py-8">
                <div className="w-full m-auto max-w-lg  z-50">
                  <ClickWrapper func={() => setShowReferralModal(false)}>
                    <ReferralModal
                      userid={user.user_id}
                      userToken={user.token}
                      contestid={id}
                      close={() => setShowReferralModal(false)}
                    />
                  </ClickWrapper>
                </div>
              </div>
            </div>
          )}
        </div>
        <p className="p-4 text-gray-300 text-xs text-center">
          CRF, CryptoResearchfund encourages users to redeem their profits in order to advance on
          the leaderboard post-closure of contest.
        </p>
      </div>
    </div>
  )
}

export default ContestHeader
