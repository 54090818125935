import React, { useState, useEffect } from 'react'
import MailchimpSubscribe from 'react-mailchimp-subscribe'
import EmailForm from '../../apps/landing/EmailForm'
import Header from '../../apps/landing/Header'
import ResponsiveHeader from '../../apps/landing/ResponsiveHeader'
import CrfFacts from '../../apps/splash-screen/CrfFacts'

import { Helmet } from 'react-helmet'

const LandingPage = () => {
  const postUrl = `https://cryptoresearchfund.us5.list-manage.com/subscribe/post?u=${process.env.REACT_APP_MAILCHIMP_U}&id=${process.env.REACT_APP_MAILCHIMP_ID}`

  const [showSplash, setShowSplash] = useState(true)

  useEffect(() => {
    const splashTime = setTimeout(() => {
      setShowSplash(false)
    }, 3000)

    return () => {
      clearTimeout(splashTime)
    }
  }, [])

  return (
    <>
      {showSplash ? (
        <CrfFacts />
      ) : (
        <div id="header_one" className="bg-purple-darkest w-screen text-white">
          <Helmet>
            <meta charSet="utf-8" />
            <title>Crypto Research and Views | CryptoResearchfund.</title>
          </Helmet>
          {/* <Header />
        <ResponsiveHeader /> */}

          <div className="max-w-7xl h-screen m-auto">
            <div className="flex flex-col h-full  w-full px-6 md:px-10 py-4 pb-10 items-center md:items-start">
              <div className="max-w-xl text-center md:text-left flex flex-col gap-6 justify-around">
                <p className="text-sm">Newsletter Subscription</p>
                <h1 className="text-3xl md:5xl text-green-400 my-2 font-500 text__shadow">
                  Data-driven and expert-curated cryptocurrency market research reports.
                </h1>
                <h1 className="text-3xl md:5xl text-green-400 my-2 font-500 text__shadow">
                  In your inbox — every Month.
                </h1>

                <div className="max-w-sm">
                  <p className="text-lg my-8">Sign up below to join The Monthly Update</p>
                  <p className="text-sm my-8 text-green-400">
                    Exciting prizes and offers to early subscribers
                  </p>
                  <MailchimpSubscribe
                    url={postUrl}
                    render={({ subscribe, status, message }) => (
                      <EmailForm
                        status={status}
                        message={message}
                        onValidated={(formData) => subscribe(formData)}
                      />
                    )}
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
      )}
    </>
  )
}

export default LandingPage
