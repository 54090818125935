import React, { useState } from 'react'

const EmailForm = ({ onValidated, status, message }) => {
  const [email, setEmail] = useState('')
  const [msg, setMsg] = useState('')

  const checkValidation = (email) => {
    if (email.length > 1 && email.indexOf('@') > -1) return true
    else return false
  }

  const emailSubscribe = async (e) => {
    e.preventDefault()

    checkValidation(email) &&
      (await onValidated({
        EMAIL: email.trim()
      }))
    if (message !== null) {
      if (message.indexOf('already') > -1) {
        setMsg('Email already Subscribed')
      } else if (message.indexOf('too') > -1) {
        setMsg('This Email has too much request recently . Please wait.')
      } else {
        setMsg(message)
      }
    }
    setEmail('')
  }
  return (
    <div className="flex flex-col gap-2">
      <form className="flex flex-col w-full rounded-md overflow-hidden " onSubmit={emailSubscribe}>
        <div className="flex">
          <input
            type="email"
            value={email}
            onChange={(e) => setEmail(e.target.value)}
            placeholder="your email address"
            className="flex-1 p-2 px-4 text-black"
          />
          <button
            className="grid place-items-center text-xs sm:text-sm md:text-md bg-blue-500 text-white p-2 px-4 font-500 uppercase w-1/3"
            type="submit">
            Subscribe
          </button>
        </div>

        {/* {msg?.length > 0 && <p className="text-xs my-1">{msg}</p>} */}
      </form>
      {status === 'sending' && <div className="text-blue-400">sending email ...</div>}
      {status === 'error' && <div className="text-red-400">{message}</div>}
      {status === 'success' && <div className="text-green-400">Email Subscribed .</div>}
    </div>
  )
}

export default EmailForm
