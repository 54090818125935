import React, { Fragment, useEffect, useState } from 'react'
import { useSelector } from 'react-redux'
import EndPoints from '../../../services/api'
import axios from '../../../services/axios'
import ClickWrapper from '../../apps/click-wrapper/ClickWrapper'
import Loader from '../../apps/loader/Loader'
import PortfolioChart from '../../apps/portfolio/PortfolioChart'
import PortfolioDonut from '../../apps/portfolio/PortfolioDonut'
import PortfolioTable from '../../apps/portfolio/PortfolioTable'
import ResponsivePortfolioTable from '../../apps/portfolio/ResponsivePortfolioTable'
import SinglePortfolioChart from '../../apps/portfolio/SinglePortfolioChart'
import SinglePortfolioCard from '../../apps/portfolio/SinglePortfolioCard'
import SinglePortfolioSector from '../../apps/portfolio/SinglePortfolioSector'
import { Helmet } from 'react-helmet'
import uuid from 'react-uuid'
import ErrorComponent from '../../apps/error/ErrorComponent'
import { ErrorBoundary } from 'react-error-boundary'

const PortfolioPage = () => {
  const [showTransactionModal, setShowTransactionModal] = useState(false)
  const [portfolioTab, setPortfolioTab] = useState(0)
  const [portfolioTableData, setPortfolioTableData] = useState([])
  const [loading, setLoading] = useState(true)
  const { user, loggedIn } = useSelector((state) => state.auth)
  const { getPortfolioBuckets, getPortfolioSummary, getPortfolioTrades, getPortfolioSector } =
    EndPoints.singlePortfolio

  const formatDataSectorwise = (obj) => {
    let resArr = []
    Object.keys(obj.sector_wise).forEach((sector) => {
      resArr.push({
        ...obj.sector_wise[sector],
        sector,
        assets: [...obj.data[sector]]
      })
    })

    return resArr
  }

  const fetchSinglePortfolioData = async () => {
    const config = {
      headers: {
        Authorization: `Bearer ${user.token}`
      }
    }
    setLoading(true)
    try {
      //const { data } = await axios.get(`${getSingleBucketTableData}?userid=${user.user_id}`)
      const res = await Promise.all([
        axios.get(`${getPortfolioTrades}?user_id=${user.user_id}`, config),
        axios.get(`${getPortfolioBuckets}?user_id=${user.user_id}`, config),
        axios.get(`${getPortfolioSummary}?user_id=${user.user_id}`, config),
        axios.get(`${getPortfolioSector}?user_id=${user.user_id}&sector=True`, config)
      ])
      //console.log(res)
      const parsedData = res.map((res) =>
        JSON.parse(res.data.body)?.message ? JSON.parse(res.data.body) : { message: {} }
      )
      console.log(parsedData)
      // console.log(formatDataSectorwise(parsedData[3].message))
      // console.log(JSON.parse(data.body))
      // setPortfolioTableData(JSON.parse(data.body))
      setPortfolioTableData(parsedData)
      setLoading(false)
    } catch (err) {
      console.log(err.message)
    } finally {
      setLoading(false)
    }
  }

  useEffect(() => {
    fetchSinglePortfolioData()
  }, [loggedIn])

  return (
    <div className="bg-purple-darkest text-white min-h-screen">
      <Helmet>
        <meta charSet="utf-8" />
        <title>CRF | Portfolio </title>
      </Helmet>
      {loading ? (
        <div className="grid place-items-center w-full h-screen">
          {' '}
          <Loader />
        </div>
      ) : (
        <Fragment>
          <div className="flex flex-col p-4 md:p-10 ">
            <div className="flex flex-col my-4 md:my-0 px-4 md:px-0 ">
              <h2 className="text-lg font-600 mb-2">Your Portfolio</h2>
              <p className="text-xs">
                Accurately tracking the investment performance of your crypto assets.
              </p>
            </div>
            <div className="flex justify-between gap-3 py-4 md:py-10 relative">
              <div className="flex justify-center text-xs font-500 bg-purple-darker bg-opacity-10 p-1 rounded-lg">
                <p className="p-1">Portfolio Currency</p>

                <select className="bg-purple-darker text-xs font-700 cursor-pointer  w-20 ml-2 ">
                  <option className="font-600 ">USD $</option>
                  <option className="font-600 ">INR ₹</option>
                  <option className="font-600 ">GBP £</option>
                </select>
              </div>

              <button
                //onClick={() => setShowTransactionModal(true)}
                className="p-2 px-4 bg-purple-darker bg-opacity-10 text-xs font-500 rounded-lg ">
                + Add Transaction
              </button>
            </div>
          </div>

          <div className="p-1 sm:p-6 md:p-10 py-6">
            <ErrorBoundary FallbackComponent={ErrorComponent}>
              {' '}
              <SinglePortfolioChart />
            </ErrorBoundary>
          </div>

          <div>
            <ErrorBoundary FallbackComponent={ErrorComponent}>
              {' '}
              {loggedIn && (
                <PortfolioChart
                  data={portfolioTableData[2].message}
                  sectorData={portfolioTableData[3].message}
                />
              )}
            </ErrorBoundary>
          </div>

          {loading ? (
            <Loader />
          ) : (
            <div className="py-20">
              <div className="w-full h-10 p-2 sm:p-6 flex justify-around items-center text-xs sm:text-sm">
                <button
                  onClick={() => setPortfolioTab(0)}
                  className={` ${
                    portfolioTab === 0
                      ? `bg-purple-400 bg-opacity-10 `
                      : 'border-2 border-gray-400 border-opacity-20'
                  } rounded-full p-3 mx-2 flex-1 max-w-xs   `}>
                  Assets
                </button>
                <button
                  onClick={() => setPortfolioTab(1)}
                  className={` ${
                    portfolioTab === 1
                      ? `bg-purple-400 bg-opacity-10 `
                      : 'border-2 border-gray-400 border-opacity-20'
                  } rounded-full p-3  mx-2 flex-1 max-w-xs`}>
                  CRF Buckets
                </button>
                <button
                  onClick={() => setPortfolioTab(2)}
                  className={` ${
                    portfolioTab === 2
                      ? `bg-purple-400 bg-opacity-10 `
                      : 'border-2 border-gray-400 border-opacity-20'
                  } rounded-full p-3 mx-2 flex-1 max-w-xs`}>
                  Sector
                </button>
              </div>

              <div>
                {portfolioTab === 0 && (
                  <div className="p-4 sm:p-6 md:p-10">
                    <ErrorBoundary FallbackComponent={ErrorComponent}>
                      {' '}
                      <PortfolioTable data={portfolioTableData[0].message} trade={true} />
                    </ErrorBoundary>
                    <ErrorBoundary FallbackComponent={ErrorComponent}>
                      {' '}
                      <ResponsivePortfolioTable
                        data={portfolioTableData[0].message}
                        trade={true}
                      />
                    </ErrorBoundary>
                  </div>
                )}

                {portfolioTab === 1 && (
                  <ErrorBoundary FallbackComponent={ErrorComponent}>
                    <div className="p-4 sm:p-6 md:p-10">
                      {portfolioTableData[1].message !== {} &&
                      Object.keys(portfolioTableData[1].message).length > 0 ? (
                        Object.keys(portfolioTableData[1].message).map((bucketId) => (
                          <SinglePortfolioCard
                            data={portfolioTableData[1].message[bucketId]}
                            key={uuid()}
                          />
                        ))
                      ) : (
                        <Fragment>
                          <p className="text-center w-full font-600 py-4 my-4 text-gray-300">
                            No Current Portfolio Bucket .
                          </p>
                        </Fragment>
                      )}
                    </div>
                  </ErrorBoundary>
                )}
                {portfolioTab === 2 && (
                  <ErrorBoundary FallbackComponent={ErrorComponent}>
                    <div className="p-4 sm:p-6 md:p-10">
                      {portfolioTableData[3].message !== {} &&
                      !Array.isArray(portfolioTableData[3].message) ? (
                        formatDataSectorwise(portfolioTableData[3].message).map((singleSector) => (
                          <SinglePortfolioSector key={uuid()} sector={singleSector} />
                        ))
                      ) : (
                        <p className="text-center w-full font-600 py-4 my-4 text-gray-300">
                          No Current Portfolio Sector .
                        </p>
                      )}
                    </div>
                  </ErrorBoundary>
                )}
              </div>
            </div>
          )}

          {/* {loggedIn && portfolioTableData[3].message.length === 0 ? (
            <Fragment></Fragment>
          ) : (
            loggedIn && (
              <div className="block  md:hidden px-4 sm:px-6 md:px-10">
                <ErrorBoundary FallbackComponent={ErrorComponent}>
                  <PortfolioDonut sectorData={portfolioTableData[3].message} />
                </ErrorBoundary>
              </div>
            )
          )} */}
        </Fragment>
      )}

      <div className="p-6 md:px-12  text-white bg-purple-darkest text-xs ">
        <p className="text-center ">
          Never invest in cryptos more than you can afford to lose and always try to keep them in
          your own wallet!
        </p>
      </div>
    </div>
  )
}

export default PortfolioPage
