import React, { useState } from 'react'
import { useDispatch } from 'react-redux'
import { FcGoogle } from 'react-icons/fc'
import { useHistory } from 'react-router'
import { AUTHENTICATION_PAGE, TERMS_PAGE } from '../../../navigation/Routes'
import authAction from '../../../redux/actions/authAction'
import Loader from '../loader/Loader'
import EmailField from './EmailField'
import PasswordField from './PasswordField'
import SubmitButton from './SubmitButton'
import TextInput from './TextInput'
import { auth, provider } from '../../../services/firebase'
import { Avatar } from '@material-ui/core'
import { useAlert } from 'react-alert'
import { toast, ToastContainer } from 'react-toastify'
import 'react-toastify/dist/ReactToastify.css'
import { Link } from 'react-router-dom'

const SignUp = ({ toggleTab, closeTab }) => {
  const dispatch = useDispatch()
  const [tnc, setTnc] = useState(false)
  const [showSignup, setShowSignup] = useState(false)
  const [isEmailAvailable, setIsEmailAvailable] = useState(true)
  const alert = useAlert()
  const [signUpDetails, setSignUpDetails] = useState({
    email: '',
    password: '',
    confirmPassword: '',
    firstname: '',
    lastname: '',
    profile_image: ''
  })
  const [signUpErrors, setSignupErrors] = useState({
    email: '',
    password: '',
    confirmPassword: '',
    firstname: ''
  })

  const [loading, setLoading] = useState(false)

  const handleSignUp = (e) => {
    e.preventDefault()
    const { email, password, firstname, confirmPassword } = signUpDetails
    if (email.length === 0) {
      setSignupErrors({
        ...signUpErrors,
        email: 'Enter a valid Email'
      })
      return
    } else if (password.length === 0) {
      setSignupErrors({
        ...signUpErrors,
        password: 'Enter a valid password'
      })
      return
    } else if (password !== confirmPassword) {
      setSignupErrors({
        ...signUpErrors,
        confirmPassword: "Password doesn't match"
      })
      return
    } else if (firstname.length === 0) {
      setSignupErrors({
        ...signUpErrors,
        firstname: 'Enter a valid Name'
      })
      return
    }

    // console.log(signUpDetails)
    // console.log(isEmailAvailable)
    // console.log(signUpErrors)
    dispatch(authAction.register(signUpDetails, setLoading, closeTab, notifySignup))
  }

  const notifySignup = (text) => {
    alert.show(text)
  }

  const signUpWithGoogle = () => {
    auth
      .signInWithPopup(provider)
      .then((res) => {
        //console.log(res)
        const { email, given_name, family_name, picture } = res.additionalUserInfo.profile
        setSignUpDetails({
          ...signUpDetails,
          email,
          firstname: given_name,
          lastname: family_name,
          profile_image: picture
        })
        setShowSignup(true)
      })
      .catch((error) => {
        console.log(error.message)
      })
  }

  return (
    <div className="flex flex-col items-center w-full h-full pb-3 relative  overflow-y-scroll">
      {loading && (
        <div className="absolute grid place-items-center w-full h-full top-0 left-0 z-10 bg-white bg-opacity-20 backdrop-filter blur-sm">
          <Loader />
        </div>
      )}
      <ToastContainer />
      <div className="flex flex-col gap-2 w-full px-2">
        <h1 className="text-base mb-2 font-700 text-blue-500">
          {showSignup ? 'Set Password' : 'Sign Up'}
        </h1>
        {showSignup && (
          <div className="flex flex-col gap-2">
            <p className="text-xs font-500 text-gray-500">
              Passwords are very important to the security of user accounts.
            </p>
            <p className="text-xs font-500 text-gray-500">
              {' '}
              We request you to setup a new password for Crypto Reseach Fund which is different
              from your Google Account password.
            </p>
          </div>
        )}
      </div>

      <form onSubmit={handleSignUp} className="w-full flex flex-col gap-4 my-4 p-2 ">
        {!showSignup ? (
          <div className="w-full grid place-items-center  border border-transparent hover:border-blue-500 transition-all duration-300 ease-in-out rounded-md overflow-hidden">
            <button
              onClick={() => signUpWithGoogle()}
              className="flex items-center uppercase w-full outline-none text-sm bg-blue-500 text-white font-500 hover:bg-white hover:text-black transition-all duration-300 ease-in-out">
              {' '}
              <span className="text-xl bg-white text-center p-3 ">
                <FcGoogle />
              </span>{' '}
              <p className="flex-1 py-3 px-2 text-center ">sign up with google</p>
            </button>
          </div>
        ) : (
          <div className="w-full flex flex-col">
            {' '}
            <div className="flex flex-col justify-center gap-2 items-center p-2">
              <Avatar
                src={signUpDetails.profile_image}
                alt={signUpDetails.firstname}
                style={{ width: 50, height: 50 }}
              />

              <div className="flex flex-col gap-2 text-sm text-center">
                <h5 className="font-500 mb-2">
                  {signUpDetails.firstname} {signUpDetails.lastname}
                </h5>
              </div>
            </div>
            <EmailField
              value={signUpDetails.email}
              checkEmail={true}
              errorMessage={signUpDetails.email.length === 0 && signUpErrors.email}
              onChange={(e) => {}}
              setEmailValidity={setIsEmailAvailable}
            />
            <PasswordField
              labelText={'Set Password'}
              value={signUpDetails.password}
              errorMessage={signUpDetails.password.length === 0 && signUpErrors.password}
              onChange={(e) =>
                setSignUpDetails({
                  ...signUpDetails,
                  password: e.target.value
                })
              }
            />
            <PasswordField
              labelText={'Confirm Password'}
              value={signUpDetails.confirmPassword}
              errorMessage={
                (signUpDetails.confirmPassword === 0 ||
                  signUpDetails.password !== signUpDetails.confirmPassword) &&
                signUpErrors.confirmPassword
              }
              onChange={(e) =>
                setSignUpDetails({
                  ...signUpDetails,
                  confirmPassword: e.target.value
                })
              }
            />
            <div className="flex justify-between items-center">
              <label className="flex items-center font-600 my-3 cursor-pointer text-xs z-0">
                <input
                  value={tnc}
                  onChange={(e) => setTnc(e.target.checked)}
                  className="mr-2 cursor-pointer"
                  type="checkbox"
                />

                <p>
                  I agree with{' '}
                  <Link to={TERMS_PAGE} className="text-blue-500">
                    Terms and Conditions
                  </Link>
                </p>
              </label>
            </div>
            {isEmailAvailable && <SubmitButton disabled={!tnc} text={'Sign Up'} />}
          </div>
        )}
      </form>

      <div className="flex text-xs text-left w-full p-2 font-600 ">
        <p>Already have an account ? </p>

        <button
          onClick={() => toggleTab(0)}
          className="text-blue-500 text-xs ml-2 font-600 hover:text-blue-700">
          Sign In
        </button>
      </div>
    </div>
  )
}

export default SignUp
