export const BotRecommendationData = [
  {
    title: 'Date'
  },
  {
    title: 'Bollinger Action'
  },
  {
    title: 'RSI Action'
  },
  {
    title: 'MACD Action'
  },
  {
    title: 'Closing Price'
  }
]
