import clsx from 'clsx'
import React, { useState } from 'react'
import { IoClose } from 'react-icons/io5'
import uuid from 'react-uuid'
import ClickWrapper from '../click-wrapper/ClickWrapper'
import ListingPagination from './ListingPagination'
import SingleResponsiveTable from './SingleResponsiveTable'
import { TableHeader } from './TableData'

const ResponsiveTable = ({
  cryptoData,
  onPageChange,
  count,
  page,
  showPagination = true,
  theme = 'light'
}) => {
  const [showKeyInfo, setShowInfo] = useState(false)
  return (
    <div className="lg:hidden">
      <div className="sm:p-6 flex flex-col gap-2 mt-3   ">
        <button
          className="bg-purple-darker rounded-full p-2 px-3 text-xs w-40"
          onClick={() => setShowInfo(true)}>
          Key Information
        </button>
        {showKeyInfo && (
          <ClickWrapper func={() => setShowInfo(false)}>
            <div className="bg-purple-darker  rounded-md flex flex-col gap-2 max-w-sm sm:p-6 ">
              <button onClick={() => setShowInfo(false)} className=" self-end text-lg ">
                <IoClose />
              </button>
              <div className="flex flex-col gap-3 w-full text-sm  ">
                {TableHeader.map(
                  (head) =>
                    head.toolText.length > 0 && (
                      <p className="leading-relaxed" key={uuid()}>
                        <b>{head.title} : </b>
                        {head.toolText}
                      </p>
                    )
                )}
              </div>
            </div>
          </ClickWrapper>
        )}{' '}
      </div>

      <div className={clsx('  flex flex-col  md:p-10 md:pt-4  ')}>
        {cryptoData.map((data) => (
          <SingleResponsiveTable
            key={uuid()}
            coinid={data.id}
            singleCoinData={data}
            theme={theme}
          />
        ))}
      </div>
      {showPagination && <ListingPagination onPageChange={onPageChange} count={count} />}
    </div>
  )
}

export default ResponsiveTable
