import axios from 'axios'
import React, { useState, useEffect, Fragment } from 'react'
import EndPoints from '../../../services/api'

const EmailField = ({ onChange, value, checkEmail, errorMessage, setEmailValidity }) => {
  const [text, setText] = useState(value)
  const [isAvailable, setIsAvailable] = useState(null)
  const { authCheckEmailAvailability } = EndPoints.auth

  const checkEmailAvailability = async (email) => {
    if (checkEmail && email && email.length > 0 && email.indexOf('@') > 0) {
      try {
        const { data } = await axios.post(authCheckEmailAvailability, {
          email: value
        })
        const res = JSON.parse(data.response)
        if (res.message === true) {
          setIsAvailable(true)
          setEmailValidity(true)
        } else if (res.message === false) {
          setIsAvailable(false)
          setEmailValidity(false)
        }
      } catch (err) {
        console.log(err.message)
      }
    }
  }

  useEffect(() => {
    const checkEmailTimeout = setTimeout(() => {
      checkEmailAvailability(value)
    }, 1000)
    return () => {
      clearTimeout(checkEmailTimeout)
    }
  }, [text])

  return (
    <Fragment>
      {checkEmail ? (
        <label className="flex flex-col text-xs gap-1 justify-center items-center w-full font-600  z-0">
          <div className=" ">{text}</div>
          {isAvailable !== null && (
            <p className={`text-xs text-center  text-${isAvailable ? `green-500` : `red-500`}`}>
              {isAvailable
                ? '✔ Email available for use'
                : '！Email already in use . Use different email or Sign In instead'}
            </p>
          )}
          {errorMessage && <p className="text-red-500 text-xs ml-4">{errorMessage}</p>}
        </label>
      ) : (
        <label className="flex flex-col text-xs w-full font-600 my-2 z-0">
          <p>Email* </p>

          <input
            className="p-4 rounded-xl border-2  my-2 font-600 border-gray-300 placeholder-gray-400"
            type="email"
            name="email"
            value={value}
            onChange={onChange}
            onKeyUp={(e) => {
              if (!checkEmail) {
                return
              }

              setText(e.target.value)
            }}
            placeholder="mail@website.com"
          />
          {isAvailable !== null && (
            <p className={`text-xs  ml-4 text-${isAvailable ? `green-500` : `red-500`}`}>
              {isAvailable ? 'Email available for use' : 'Email already in use'}
            </p>
          )}
          {errorMessage && <p className="text-red-500 text-xs ml-4">{errorMessage}</p>}
        </label>
      )}
    </Fragment>
  )
}

export default EmailField
