import React, { useEffect } from 'react'
import { useState } from 'react'
import { useParams } from 'react-router-dom'
import uuid from 'react-uuid'
import EndPoints from '../../../../services/api'
import axios from '../../../../services/axios'
import { includeComma } from '../../../../services/globalFunctions'

const TransactionDetails = ({ closeTab, refreshData, data }) => {
  const { blocks, fee, message, payment_method, status, subtotal, time, total, transaction_id } =
    data

  const { name } = useParams()

  const handleCompleteTransaction = () => {
    closeTab()
    //refreshData()
  }
  return (
    <div className=" pt-4">
      <div className="flex flex-col flex-1 relative font-500 px-3">
        <div className="flex justify-between items-start p-2 text-xs text-white">
          <p>Bucket Name</p>
          <p>{name} </p>
        </div>
        <div className="flex justify-between items-start p-2 text-xs text-white">
          <p>Transaction ID</p>
          <p>{transaction_id} </p>
        </div>
        <div className="flex flex-col max-h-48 overflow-y-scroll scrollbar-hidden">
          {blocks.map((coin) => (
            <div key={uuid()} className="flex justify-between items-start p-2 text-xs text-white">
              <p>{coin.asset_name}</p>
              <p className="flex gap-2 items-center">
                {' '}
                $ {includeComma(coin.alloted_price.toFixed(2))}{' '}
                <span className="text-yellow-400">
                  ( {includeComma(coin.quantity.toFixed(3))} units )
                </span>
              </p>
            </div>
          ))}
        </div>

        <div className="flex justify-between items-start p-2 text-xs text-white">
          <p>Payment Method</p>
          <p>{payment_method}</p>
        </div>
        <div className="flex justify-between items-start p-2 text-xs text-white">
          <p>Subtotal</p>
          <p>${subtotal.toFixed(2)}</p>
        </div>
        <div className="flex justify-between items-start p-2 text-xs text-white">
          <p>Total</p>
          <p>${total.toFixed(2)}</p>
        </div>
        <div className="flex justify-between items-start p-2 text-xs text-white">
          <p>Fee</p>
          <p>${fee.toFixed(2)}</p>
        </div>
        <div className="flex justify-between items-start p-2 text-xs text-white">
          <p>Date</p>
          <p>{time.split(' ')[0].split('-').reverse().join('-')}</p>
        </div>
        <div className="flex justify-between items-start p-2 text-xs text-white">
          <p>Status</p>
          <p>{status}</p>
        </div>
        <button
          onClick={handleCompleteTransaction}
          className="bg-red-dark text-sm text-white p-3 hover:bg-red-600 transition-all duration-300 ease-in-out">
          OK
        </button>
      </div>
    </div>
  )
}

export default TransactionDetails
