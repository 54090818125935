import React, { useEffect, useState } from 'react'
import EndPoints from '../../../services/api'
import { useHistory } from 'react-router-dom'
import axios from '../../../services/axios'
import { includeComma } from '../../../services/globalFunctions'
import DataLoader from '../loader/DataLoader'

const SingleResponsiveTable = (props) => {
  const {
    coin_id,
    current_price,
    holding_period,
    potential,
    reco_data_price,
    reco_date,
    reco_id,
    status,
    risk,
    type
  } = props

  const [staticData, setStaticData] = useState({})
  const [loading, setLoading] = useState(true)
  const history = useHistory()
  const { getStaticData } = EndPoints.recommendation
  const fetchSinglePortfolioRow = async () => {
    try {
      const { data } = await axios.get(`${getStaticData}?coinid=${coin_id}`)

      const singleWatchListData = JSON.parse(data.body)
      const { symbol, logo, coinname } = singleWatchListData[`${coin_id}`]

      setStaticData({
        symbol,
        logo,
        coinname
      })
      setLoading(false)
    } catch (err) {
      console.log(err.message)
    }
  }

  useEffect(() => {
    fetchSinglePortfolioRow()
  }, [])

  return (
    <div
      onClick={() =>
        staticData.coinname && history.push(`/coin/${staticData.coinname}/${coin_id}`)
      }
      className="bg-purple-400 bg-opacity-10  rounded-2xl  p-3 sm:px-4 sm:py-6 my-3 cursor-pointer">
      <div className="flex justify-between mb-2 ">
        <div className="flex items-center ">
          <div className=" h-8 w-8 self-start mt-2">
            {loading ? (
              <DataLoader />
            ) : (
              <img
                className="w-full h-full object-contain"
                src={staticData.logo}
                alt={staticData.coinname}
              />
            )}
          </div>

          {loading ? (
            <DataLoader />
          ) : (
            <div className="flex flex-col ml-2">
              <h4 className="text-lg font-600 mb-1">{staticData.coinname}</h4>
              <p className="text-xs">{staticData.symbol}</p>
            </div>
          )}
        </div>

        <div className="flex flex-col items-center py-1">
          <p className="text-xs ">Reco Price</p>
          <p className="text-xl font-600">$ {includeComma(reco_data_price)} </p>
        </div>
      </div>

      <div className="text-center p-2">
        <h1
          className={`text-3xl sm:text-4xl  font-700 mb-2 ${
            type.split(' ')[0].toLowerCase() === 'hold'
              ? `text-yellow-500`
              : type.split(' ')[0].toLowerCase() === 'high'
              ? `text-green-500`
              : `text-red-500`
          }`}>
          {type}
        </h1>
      </div>

      <div className="flex justify-between items-center text-xs">
        <div className="mr-4 text-center flex flex-col">
          <p className="my-1">Reco Date :</p>
          <p className="text-sm sm:text-lg font-600">{reco_date.split(' ')[0]}</p>
        </div>
        <div className="text-center flex flex-col">
          <p className="my-1">Price Change :</p>
          <p className="text-sm sm:text-lg font-600">
            ${includeComma((current_price - reco_data_price).toFixed(2))}{' '}
            <span
              className={`${
                current_price - reco_data_price >= 0 ? `text-green-500` : `text-red-500`
              }`}>
              ({(((current_price - reco_data_price) / reco_data_price) * 100).toFixed(2)}%)
            </span>{' '}
          </p>{' '}
        </div>

        <div className="ml-4 text-center flex flex-col">
          <p className="my-1">Risk :</p>
          <p className="text-sm sm:text-lg font-600">{risk}%</p>
        </div>
      </div>
    </div>
  )
}

export default SingleResponsiveTable
