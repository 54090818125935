import React, { Fragment, useEffect, useState } from 'react'
import { memo } from 'react'
import { useHistory } from 'react-router-dom'
import EndPoints from '../../../services/api'
import axios from '../../../services/axios'
import { includeComma } from '../../../services/globalFunctions'
import DataLoader from '../loader/DataLoader'

const SingleBotTable = (props) => {
  const {
    action: type,
    current_price,
    bot_id,
    coin,
    coinid: coin_id,
    date: reco_date,
    id,
    price: reco_data_price,
    quantity,
    risk_rating: risk,
    strategy,
    strategy_based_on
  } = props

  const [staticData, setStaticData] = useState({})
  const [loading, setLoading] = useState(true)
  const { getStaticData } = EndPoints.recommendation
  const history = useHistory()
  const fetchSinglePortfolioRow = async () => {
    try {
      const { data } = await axios.get(`${getStaticData}?coinid=${coin_id}`)

      const singleWatchListData = JSON.parse(data.body)
      const { symbol, logo, coinname } = singleWatchListData[`${coin_id}`]

      setStaticData({
        symbol,
        logo,
        coinname
      })
      setLoading(false)
    } catch (err) {
      console.log(err.message)
    }
  }

  useEffect(() => {
    fetchSinglePortfolioRow()
  }, [])

  return (
    <tr className="text-xs xl:text-sm font-600  bg-purple-700 bg-opacity-10  ">
      <td>
        <div className="flex justify-center  items-center h-full w-full px-2">
          {loading ? (
            <DataLoader />
          ) : (
            <img
              className="w-8 h-8 object-contain"
              src={staticData.logo}
              alt={staticData.coinname}
            />
          )}
        </div>
      </td>

      <td
        className="text-left px-4 cursor-pointer"
        onClick={() =>
          staticData.coinname && history.push(`/coin/${staticData.coinname}/${coin_id}`)
        }>
        {loading ? (
          <DataLoader />
        ) : (
          <div className="flex justify-between items-center">
            <p>
              {staticData.coinname} ({staticData.symbol}){' '}
            </p>
            {/* <p
              className={`w-2 h-2 rounded-full ${
                status.toLowerCase() === 'active' ? `bg-green-500` : `bg-red-500`
              }  ml-4`}></p> */}
          </div>
        )}
      </td>

      <td className="text-center">
        <p>{reco_date.split(' ')[0]}</p>
      </td>
      <td className="text-center">
        <p>$ {includeComma(reco_data_price.toFixed(2))}</p>
      </td>
      <td className="text-center">
        <p>$ {includeComma(current_price.toFixed(2))}</p>
      </td>

      <td className="text-center">
        <p>
          ${includeComma((current_price - reco_data_price).toFixed(2))}{' '}
          <span
            className={`${
              current_price - reco_data_price >= 0 ? `text-green-500` : `text-red-500`
            }`}>
            ({(((current_price - reco_data_price) / reco_data_price) * 100).toFixed(2)}%)
          </span>{' '}
        </p>{' '}
      </td>
      <td
        className={`text-center ${
          type.split(' ')[0].toLowerCase() === 'hold'
            ? `text-yellow-500`
            : type.split(' ')[0].toLowerCase() === 'buy'
            ? `text-green-500`
            : `text-red-500`
        }  font-700`}>
        <p>{type}</p>{' '}
      </td>
      <td className="text-center">
        <p>{risk ? risk : '-'}%</p>{' '}
      </td>
    </tr>
  )
}

export default SingleBotTable
