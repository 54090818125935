import React, { useState } from 'react'
import BuyTab from './BuyTab'

import { IoClose } from 'react-icons/io5'
import TransactionDetails from '../../contest/modal/TransactionDetails'
import { useHistory } from 'react-router-dom'

const TradingTransactionModal = ({ close, tradingData }) => {
  const [tab, setTab] = useState(0)
  const [transactionData, setTransactionData] = useState({})
  const [loading, setLoading] = useState(false)

  const sendTransactionData = async (data) => {
    setLoading(true)
    await setTransactionData(data)
    setLoading(false)
  }

  const { contestName, contestId } = tradingData
  const history = useHistory()
  return (
    <div className="bg-purple-darkest max-w-md w-screen m-auto text-white shadow-2xl rounded-xl overflow-hidden ">
      <div className="bg-purple-900 p-4  md:p-8 bg-opacity-10 w-full h-full">
        <div className="flex justify-between items-center">
          <h1 className="text-lg font-600">Buy Transaction</h1>
          <button className="text-xl" onClick={close}>
            <IoClose />
          </button>
        </div>

        <div>
          {tab === 0 && (
            <BuyTab
              tradingData={tradingData}
              setTab={(val) => setTab(val)}
              sendTransactionData={sendTransactionData}
            />
          )}
          {tab === 1 && (
            <TransactionDetails
              closeTab={close}
              data={transactionData}
              refreshData={() => history.push(`/contest/${contestName}/${contestId}`)}
            />
          )}
        </div>
      </div>
    </div>
  )
}

export default TradingTransactionModal
