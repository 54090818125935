import React, { Fragment, useEffect, useState } from 'react'
import uuid from 'react-uuid'
import ReactApexChart from 'react-apexcharts'
import axios from '../../../services/axios'
import EndPoints from '../../../services/api'
import Loader from '../loader/Loader'
import { includeComma } from '../../../services/globalFunctions'
import { useSelector } from 'react-redux'

const btnArray = [
  {
    title: '7 D',
    value: '7'
  },
  {
    title: '1 M',
    value: '30'
  },
  {
    title: '3 M',
    value: '90'
  },
  {
    title: '6 M',
    value: '180'
  }
]

const SinglePortfolioChart = () => {
  const series = [
    {
      name: 'price',
      data: []
    }
  ]

  const options = {
    chart: {
      type: 'area',
      foreColor: '#ffffff',
      toolbar: {
        show: false
      }
    },

    dataLabels: {
      enabled: false
    },
    stroke: {
      curve: 'smooth'
    },
    grid: {
      show: false
    },

    xaxis: {
      type: 'datetime',

      categories: []
    },
    tooltip: {
      x: {
        format: 'dd/MM/yy HH:mm'
      },
      theme: 'dark'
    },
    yaxis: {
      labels: {
        show: true,
        formatter: (value) => {
          return includeComma(value.toFixed(2))
        }
      }
    }
  }
  const { getPortfolioGraphData } = EndPoints.singlePortfolio
  const [duration, setDuration] = useState(btnArray[0].value)
  const [loading, setLoading] = useState(true)
  const [price, setPrice] = useState(0)
  const [changePer, setChangePer] = useState(0)
  const [chartSeries, setChartSeries] = useState(series)
  const [chartOptions, setChartOptions] = useState(options)
  const { user } = useSelector((state) => state.auth)

  const fetchChartData = async () => {
    setLoading(true)
    const config = {
      headers: {
        Authorization: `Bearer ${user.token}`
      }
    }
    try {
      const { data } = await axios.get(
        `${getPortfolioGraphData}?user_id=16&duration=${duration}`,
        config
      )

      const parsedData = JSON.parse(data.body).message
      //   console.log(JSON.parse(data.body).message)

      setChartSeries([
        {
          name: 'price',
          data: parsedData.values
        }
      ])
      setChartOptions({
        ...chartOptions,
        xaxis: {
          ...chartOptions.xaxis,

          categories: parsedData.dates
        }
      })
      setPrice(parsedData.values[parsedData.values.length - 1])
      setChangePer(
        -(
          (parsedData.values[0] - parsedData.values[parsedData.values.length - 1]) /
          parsedData.values[parsedData.values.length - 1]
        ) * 100
      )
      setLoading(false)
    } catch (err) {
      console.log(err.message)
    }
  }

  useEffect(() => {
    fetchChartData()
  }, [duration])

  return (
    <div className="bg-purple-400 bg-opacity-10 flex-1  rounded-xl p-2 sm:p-4 md:p-6 py-4 text-white min-h-60">
      {loading ? (
        <Loader />
      ) : (
        <Fragment>
          <div className="flex justify-between items-center">
            <div className="flex gap-3 items-center">
              <p className="font-600 text-sm">Portfolio Chart</p>
              <h1 className=" font-600 text-xl md:text-2xl ">
                ${includeComma(price.toFixed(2))}
                <sup
                  className={`mx-1 sm:mx-3 font-400 text-sm text-${
                    changePer >= 0 ? 'green' : 'red'
                  }-500`}>
                  {changePer.toFixed(2)} %
                </sup>
              </h1>
            </div>

            <div className="grid grid-cols-2 md:grid-cols-4 gap-1 md:gap-2 ">
              {btnArray.map((btn) => (
                <button
                  onClick={() => setDuration(btn.value)}
                  className={`p-1 px-2  text-xs rounded-full  ${
                    duration === btn.value ? 'bg-purple-dark' : ''
                  } `}
                  key={uuid()}>
                  {btn.title}
                </button>
              ))}
            </div>
          </div>

          <ReactApexChart options={chartOptions} series={chartSeries} type="area" height={250} />
        </Fragment>
      )}
    </div>
  )
}

export default SinglePortfolioChart
