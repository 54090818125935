import React, { useState } from 'react'
import { Link } from 'react-router-dom'
import uuid from 'react-uuid'
import { HOME_PAGE } from '../../../navigation/Routes'
import { headerMenu } from './headerData'
import { BiMenu } from 'react-icons/bi'
import { RiCloseFill } from 'react-icons/ri'
import CrfLogo from '../logo/CrfLogo'
import SingleResponsiveHeader from './SingleResponsiveHeader'

const ResponsiveHeader = ({ showAuthModal }) => {
  const [showMenu, setShowMenu] = useState(false)

  return (
    <div className="flex justify-between items-center px-4 sm:px-6 py-2 w-full relative lg:hidden">
      <Link className="w-20" to={HOME_PAGE}>
        <CrfLogo />
      </Link>

      <div className="flex items-center gap-2">
        <button
          onClick={showAuthModal}
          className="text-xs font-600  border border-transparent bg-blue-500 hover:bg-opacity-0 hover:border-blue-500 p-2  grid place-items-center transition-all duration-300 ease-in-out">
          Sign In / Sign Up
        </button>
        <button
          onClick={() => setShowMenu(!showMenu)}
          className="border-none outline-none p-1 text-white text-3xl cursor-pointer  ">
          {!showMenu ? <BiMenu /> : <RiCloseFill />}
        </button>
      </div>

      <div
        className={`flex flex-col w-full min-h-screen p-3 sm:p-4  items-center absolute top-full left-0 bg-purple-darkest transform ${
          showMenu ? ` translate-x-0 ` : ` -translate-x-full `
        } ${
          showMenu ? `opacity-100` : `opacity-40`
        } transition-all duration-500 ease-in-out z-50`}>
        {headerMenu.map((menu) => (
          <SingleResponsiveHeader key={uuid()} menu={menu} setShowMenu={setShowMenu} />
        ))}
      </div>
    </div>
  )
}

export default ResponsiveHeader
