import React from 'react'

const ResponsiveContestHeader = ({ contestData }) => {
  const {
    contest_owner,
    minimum_participant,
    initial_coins,
    status,
    start_date,
    end_date,
    participant_count
  } = contestData

  return (
    <div className="flex flex-col items-start mb-6 md:hidden text-xs  w-full h-full">
      <div className=" mb-2 flex justify-between items-center gap-2 w-full px-2">
        <p>{start_date}</p>
        <p>{end_date}</p>
      </div>
      <div className="flex justify-between w-full mt-2 ">
        <div className="flex flex-col justify-between">
          <div className="flex flex-col justify-between h-full mx-2 text-sm mb-2">
            <p className="mb-2">Status</p>
            <p className="text-xs font-600">{status}</p>
          </div>
          <div className="flex flex-col justify-between h-full mx-2 text-sm">
            <p className="mb-2">Initial Token</p>
            <p className="text-xs font-600">{initial_coins}</p>
          </div>
        </div>

        <div className="flex flex-col ">
          <div className="flex flex-col  h-full mx-2 text-sm">
            <p className="mb-2">Contest Owner</p>
            <p className="text-2xl font-600 text-blue-500">{contest_owner}</p>
          </div>
        </div>

        <div className="flex flex-col justify-between">
          <div className="flex flex-col justify-between h-full mx-2 text-sm mb-2">
            <p className="mb-2">Participants</p>
            <p className="text-xs font-600">{participant_count}</p>
          </div>
          <div className="flex flex-col justify-between h-full mx-2 text-sm">
            <p className="mb-2">Min Participants</p>
            <p className="text-xs font-600">{minimum_participant}</p>
          </div>
        </div>
      </div>
    </div>
  )
}

export default ResponsiveContestHeader
