import { Avatar, makeStyles } from '@material-ui/core'
import React from 'react'
import { useSelector } from 'react-redux'
import uuid from 'react-uuid'
import { headerMenu } from '../header/headerData'
import clsx from 'clsx'
import SingleSidebarLink from './SingleSidebarLink'

const useStyles = makeStyles((theme) => ({
  scrollBar: {
    '&::-webkit-scrollbar-track': {
      backgroundColor: 'rgb(32,21,70)'
    }
  }
}))

const SideBar = ({ setShowSidebar }) => {
  const classes = useStyles()
  const { user } = useSelector((state) => state.auth)
  return (
    <div className="h-screen bg-purple-darkest -mt-2 lg:mt-1 w-64  text-white fixed z-40 xl:z-0 xl:relative">
      <div className=" bg-purple-darker p-4 flex flex-col h-full w-full">
        <div className="flex flex-col justify-center items-center mb-4 p-4">
          <Avatar src={user.profile_image} alt={user.name} style={{ width: 50, height: 50 }} />

          <div className="flex flex-col my-2 text-base text-center">
            <h5 className="font-500 mb-2">{user.name}</h5>
            <p className="text-sm">{user.email}</p>
          </div>
        </div>

        <div
          className={clsx(
            classes.scrollBar,
            'flex flex-col w-full flex-1 pb-20 overflow-y-scroll scrollbar-hidden'
          )}>
          {headerMenu.map(
            (menu) =>
              menu.loggedIn && (
                <SingleSidebarLink menu={menu} key={uuid()} setShowSidebar={setShowSidebar} />
              )
          )}
        </div>
      </div>
    </div>
  )
}

export default SideBar
