import { useMediaQuery, useTheme } from '@material-ui/core'
import React, { useState, useEffect } from 'react'
import ReactApexChart from 'react-apexcharts'
import uuid from 'react-uuid'

const ListingColumnChart = ({ cryptoData, theme = 'light', mobile = false }) => {
  const btnArray = [
    {
      title: 'Price Change (24h)',
      abbr: 'Price',
      value: '24h change(%)',
      data: cryptoData.map((data) =>
        data?.PercentChange24h !== null ? data.PercentChange24h.toFixed(2) : 0
      )
    },

    {
      title: 'Social score',
      abbr: 'Social',
      value: 'social(/100)',
      data: cryptoData.map((data) => ((data?.tweetsRating + data?.newsRating) / 2).toFixed(2))
    },
    {
      title: 'Risk Rating',
      abbr: 'Risk',
      value: 'risk(/100)',
      data: cryptoData.map((data) => (data?.risk).toFixed(2))
    },
    {
      title: 'CRF Score',
      abbr: 'CRF',
      value: 'crf-score(/100)',
      data: cryptoData.map((data) => data.score.toFixed(2))
    },
    {
      title: 'Market CAP',
      abbr: 'Market',
      value: 'marketcap (B $)',
      data: cryptoData.map((data) => Math.ceil(data?.marketcap / 1000000000))
    }
  ]

  const [parameter, setParameter] = useState(btnArray[0].value)
  const mediaTheme = useTheme()
  const largeView = useMediaQuery(mediaTheme.breakpoints.up('md'))

  const options = {
    chart: {
      type: 'bar',
      foreColor: '#ffffff',
      toolbar: {
        show: false
      }
    },
    plotOptions: {
      bar: {
        borderRadius: 10,
        dataLabels: {
          position: 'top'
        }
      }
    },
    grid: {
      show: false
    },
    dataLabels: {
      enabled: true,

      offsetY: -20,
      style: {
        fontSize: '10px'
      }
    },
    tooltip: {
      theme: 'dark'
    },

    xaxis: {
      categories: [],
      position: 'bottom',
      axisBorder: {
        show: false
      },
      axisTicks: {
        show: false
      },
      crosshairs: {
        fill: {
          type: 'gradient',
          gradient: {
            colorFrom: '#D8E3F0',
            colorTo: '#BED1E6',
            stops: [0, 100],
            opacityFrom: 0.4,
            opacityTo: 0.5
          }
        }
      }
    },
    yaxis: {
      axisBorder: {
        show: false
      },
      axisTicks: {
        show: false
      },
      labels: {
        show: true
      }
    }
  }

  const [chartSeries, setChartSeries] = useState([
    {
      name: btnArray[0].value,
      data: btnArray[0].data
    }
  ])
  const [chartOptions, setChartOptions] = useState(options)

  useEffect(() => {
    setChartOptions({
      ...chartOptions,
      xaxis: {
        ...chartOptions.xaxis,
        categories: cryptoData.map((data) => data.symbol)
      }
    })
    setParameter(btnArray[0].value)

    setChartSeries([
      {
        name: btnArray[0].value,
        data: btnArray[0].data
      }
    ])
  }, [cryptoData, largeView])

  return (
    <div className={`${mobile ? 'block' : 'hidden sm:block'}  `}>
      <div
        className={`${
          theme === 'dark' ? 'bg-purple-darkest' : 'bg-purple-400 bg-opacity-10'
        } md:px-4 py-6 rounded-2xl`}>
        <div className="flex flex-wrap justify-end">
          {btnArray.map((btn) => (
            <button
              onClick={() => {
                setParameter(btn.value)

                setChartSeries([
                  {
                    name: btn.value,
                    data: btn.data
                  }
                ])
              }}
              className={`p-1 px-2 m-1 text-xs rounded-full ${
                parameter === btn.value ? 'bg-purple-dark' : ''
              } `}
              key={uuid()}>
              {largeView ? btn.title : btn.abbr}
            </button>
          ))}
        </div>

        <div className="text-white " id="chart">
          <ReactApexChart options={chartOptions} series={chartSeries} type="bar" height={280} />
        </div>
      </div>
    </div>
  )
}

export default ListingColumnChart
