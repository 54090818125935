import { makeStyles } from '@material-ui/core'
import React, { Fragment } from 'react'
import clsx from 'clsx'
import SingleResponsiveHolding from './SingleResponsiveHolding'
import uuid from 'react-uuid'

const useStyles = makeStyles((theme) => ({
  hiddenScrollBar: {
    '&::-webkit-scrollbar': {
      display: 'none'
    }
  }
}))

const ResponsiveHoldingTable = ({ data, wallet, contestId, refreshData, isOpen }) => {
  const classes = useStyles()
  return (
    <div className={clsx(' flex flex-col sm:p-2 md:p-10  lg:hidden my-6')}>
      {data.length === 0 ? (
        <Fragment>
          <p className="text-center text-gray-300  font-600 py-4">Your Bucket is Empty</p>
        </Fragment>
      ) : (
        data.map((data) => (
          <SingleResponsiveHolding
            key={uuid()}
            rowData={data}
            wallet={wallet}
            contestId={contestId}
            refreshData={refreshData}
            isOpen={isOpen}
          />
        ))
      )}
    </div>
  )
}

export default ResponsiveHoldingTable
