import React, { useState, useRef, useEffect } from 'react'
import { IoClose } from 'react-icons/io5'
import { AiOutlineBarcode } from 'react-icons/ai'
import { MdSupervisedUserCircle } from 'react-icons/md'
import { useSelector } from 'react-redux'
import { useHistory } from 'react-router'
import EndPoints from '../../../services/api'
import axios from '../../../services/axios'
import Loader from '../loader/Loader'
import uuid from 'react-uuid'
import { useAlert } from 'react-alert'
import { TERMS_PAGE } from '../../../navigation/Routes'
import { Link } from 'react-router-dom'

const EnterContestModal = ({ close }) => {
  const [contestCode, setContestCode] = useState('')
  const [referralCode, setReferralCode] = useState('')
  const [hasChecked, setHasChecked] = useState(false)
  const [codeSubmitted, setCodeSubmitted] = useState(false)
  const [selectOption, setSelectOption] = useState(false)
  const [isReferral, setIsReferral] = useState(false)
  const [loading, setLoading] = useState(false)
  const [contestData, setContestData] = useState({})
  const { user } = useSelector((state) => state.auth)
  const codeRef = useRef()
  const history = useHistory()
  const blockArray = [0, 1, 2, 3, 4, 5]

  const { addContestUser, getContestData } = EndPoints.gamePage
  const alert = useAlert()
  const fetchContestData = async (code) => {
    setLoading(true)
    try {
      const { data } = await axios.get(
        `${getContestData}?${isReferral ? 'referral' : 'contest_code'}=${code}`
      )

      if (JSON.parse(data.body).message.length === 0) {
        alert.error('Invalid Contest Code')
        close()
        return
      }
      setContestData(JSON.parse(data.body).message[0])
      setLoading(false)
      setCodeSubmitted(true)
    } catch (err) {
      console.log(err.message)
    }
  }

  const addUserToContest = async (contest_code, referral_code) => {
    setLoading(true)
    const config = {
      headers: {
        Authorization: `Bearer ${user.token}`
      }
    }
    try {
      const enterContestData = isReferral
        ? {
            user_id: user.user_id,
            name: user.name,
            status: 'Active',
            contest_code,
            referral: referral_code
          }
        : {
            user_id: user.user_id,
            name: user.name,
            status: 'Active',
            contest_code
          }
      //console.log(enterContestData)
      const { data } = await axios.post(
        `${addContestUser}?user_id=${user.user_id}`,
        enterContestData,
        config
      )
      //console.log(data)
      if (data.statusCode === 401) {
        alert.show(JSON.parse(data.body))
        close()
        return
      }
      setLoading(false)
      history.push(`contest/${contestData.contest_name}/${contestData.contest_id}`)
    } catch (err) {
      console.log(err.message)
    }
  }

  const handleSelectOption = ({ code, referral }) => {
    if (referral) setIsReferral(true)
    if (code) setIsReferral(false)
    setSelectOption(true)
  }

  useEffect(() => {
    if (codeSubmitted || !selectOption) {
      return
    }
    if (codeRef) codeRef.current.focus()
  }, [selectOption, codeSubmitted, codeRef])

  return (
    <div className="w-full bg-purple-darkest border-2 border-indigo-500 border-opacity-40 text-white p-4 sm:p-8 rounded-lg overflow-hidden relative">
      <button
        className="absolute top-4 right-4 sm:top-8 sm:right-8 font-600 z-10 text-xl"
        onClick={close}>
        <IoClose />
      </button>

      {!selectOption ? (
        <div className="flex justify-center  items-center">
          <div className="p-2 sm:p-4 flex-1 flex flex-col justify-center gap-3 text-xl md:text-4xl items-center">
            <AiOutlineBarcode />
            <button
              onClick={() => handleSelectOption({ code: true, referral: false })}
              className="bg-blue-500 text-white border-2 border-blue-500 hover:bg-blue-400 transition-all duration-300 px-3 py-2 text-sm font-500">
              Join Using Code
            </button>
          </div>

          <div className="p-2 sm:p-4 flex-1 flex flex-col justify-center gap-3 text-xl md:text-4xl items-center">
            <MdSupervisedUserCircle />
            <button
              onClick={() => handleSelectOption({ code: false, referral: true })}
              className="bg-blue-500 text-white border-2 border-blue-500 hover:bg-blue-400 transition-all duration-300 px-3 py-2 text-sm font-500">
              Join Using Referral
            </button>
          </div>
        </div>
      ) : !codeSubmitted ? (
        <div className="flex flex-col justify-center items-center">
          <label>
            <div className="flex justify-center items-center my-3 text-white">
              {blockArray.map((block) => {
                if (block + 1 > contestCode.split('').length) {
                  return (
                    <p
                      key={block}
                      className={`bg-purple-dark p-2 border-1 relative border-blue-500 text-lg font-600 mx-1 w-10 h-10 grid place-items-center  text-gray-400 `}>
                      <span
                        className={`absolute top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2 ${
                          contestCode.length === 0 && block === 0 ? ` animate-pulse` : `opacity-0`
                        }`}>
                        |
                      </span>
                    </p>
                  )
                } else
                  return (
                    <p
                      key={block}
                      className="bg-purple-dark p-2 border-1 border-blue-500 text-lg font-600 mx-1 w-10 h-10 grid place-items-center">
                      {contestCode[block]}
                    </p>
                  )
              })}
            </div>
            <input
              ref={codeRef}
              className="text-black transform scale-0 "
              maxLength={6}
              value={contestCode}
              onChange={(e) => setContestCode(e.target.value)}
              type="text"
            />
          </label>

          <button
            onClick={() => fetchContestData(contestCode)}
            disabled={contestCode.length < 6}
            className={`text-white ${
              contestCode.length === 6
                ? `bg-blue-500 hover:bg-blue-400 border-blue-500`
                : `bg-blue-200 cursor-not-allowed`
            }  px-3 py-2  border-2 font-600 uppercase   transition-all duration-300  text-xs `}>
            Enter Code
          </button>
          {loading && <Loader />}
        </div>
      ) : (
        <div className="flex flex-col text-sm relative">
          {loading && (
            <div className="absolute inset-0 bg-white bg-opacity-5 grid place-items-center">
              <Loader />
            </div>
          )}
          <div className="font-500">
            <p className="my-1">Show Details</p>

            <div className="bg-purple-dark p-4 flex justify-between items-start text-white font-500 text-xs text-center rounded-sm">
              <div className="flex flex-col justify-between h-full mx-2 ">
                <p className="mb-2">Name</p>
                <p className="text-xs font-600 w-20 truncate">{contestData.contest_name}</p>
              </div>
              <div className="flex flex-col justify-between h-full mx-2 ">
                <p className="mb-2">Start Date</p>
                <p className="text-xs font-600">{contestData.start_date}</p>
              </div>
              <div className="flex flex-col justify-between h-full mx-2 ">
                <p className="mb-2">End Date</p>
                <p className="text-xs font-600">{contestData.end_date}</p>
              </div>
              <div className="flex flex-col justify-between h-full mx-2 ">
                <p className="mb-2">Contest</p>
                <p className="text-xs font-600">{contestData.contest_code}</p>
              </div>
              <div className="flex flex-col justify-between h-full mx-2 ">
                <p className="mb-2">Coins</p>
                <p className="text-xs font-600">{contestData.initial_coins}</p>
              </div>
            </div>
          </div>

          <div className=" my-4">
            <p className="text-sm my-2 font-500">Rules</p>

            <ul className="text-sm list-disc pl-4 text-gray-200 max-h-28 overflow-y-scroll">
              {contestData.rules.split('%_$_%').map((rule) => (
                <li className="my-2" key={uuid()}>
                  {rule}
                </li>
              ))}
            </ul>
          </div>

          <div className="flex justify-between items-center">
            <label className="flex items-center text-xs">
              <input
                type="checkbox"
                checked={hasChecked}
                onChange={(e) => setHasChecked(e.target.checked)}
              />

              <p className="font-600 ml-2">
                I agree{' '}
                <Link to={TERMS_PAGE} className="text-blue-500">
                  Terms and Conditions
                </Link>
              </p>
            </label>

            <button
              onClick={() => addUserToContest(contestData.contest_code, contestCode)}
              disabled={!hasChecked}
              className={`text-white ${
                hasChecked
                  ? `bg-red-500 border-red-500 hover:text-red-500 hover:bg-transparent`
                  : `bg-red-300 cursor-not-allowed`
              }  w-24 p-3 font-600 rounded-sm border-1 transition-all duration-300`}>
              Join Now
            </button>
          </div>
        </div>
      )}
    </div>
  )
}

export default EnterContestModal
