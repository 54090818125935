import React, { useState } from 'react'
import Typewriter from 'typewriter-effect'
import AuthModal from '../../../auth/AuthModal'
import ClickWrapper from '../../../click-wrapper/ClickWrapper'
import VideoModal from '../VideoModal'

const HeaderComponent = () => {
  const [showAuthModal, setShowAuthModal] = useState(false)
  const [showVideoModal, setShowVideoModal] = useState(false)
  return (
    <div className="bg-gradient-to-t from-[#180f3c] to-[#271b4f]">
      <div className="relative flex  justify-between p-4 sm:p-6  md:px-10 md:py-6  w-full min-h-[80vh]  max-w-7xl m-auto">
        <div
          id="responsive__home__header"
          className="absolute top-0 left-0 right-0 bottom-0 z-30 lg:bg-opacity-0 lg:relative flex flex-col items-center text-center lg:items-start lg:text-left  lg:px-10 ">
          <div className="text-lg font-700 px-4 my-10">
            <h1 className="text-2xl sm:text-3xl md:text-4xl mb-3">
              Data Science and AI Approach to Crypto Market
            </h1>
            <p className="font-500 my-4">No more gambling with your crypto Investment</p>
          </div>

          <div className="flex flex-col px-4 my-10">
            <h3 className="text-lg mb-4 font-500">One platform to understand</h3>
            <div className="typewriter text-2xl sm:text-3xl md:text-4xl text-green-400 md:text-red-500 font-700">
              <Typewriter
                options={{
                  autoStart: true,
                  loop: true
                }}
                onInit={(typewriter) => {
                  typewriter
                    .typeString('Crypto Ecosystem .')
                    .pauseFor(2000)
                    .deleteAll()
                    .typeString('Crypto Coin .')
                    .pauseFor(2000)
                    .deleteAll()
                    .typeString('Fundamental Value . ')
                    .pauseFor(2000)
                    .deleteAll()
                    .typeString('Social Sentiment .')
                    .pauseFor(2000)
                    .deleteAll()
                    .typeString('Price Movements .')
                    .pauseFor(2000)
                    .deleteAll()
                    .start()
                }}
              />
            </div>
          </div>
          <div className="flex  items-center">
            <button
              onClick={() => setShowAuthModal(true)}
              className=" grid place-items-center bg-blue-500 border-2 border-blue-500 hover:bg-transparent transition-all duration-300 ease-in-out text-white rounded-md w-32 p-2 text-sm md:text-base m-3  font-600">
              Get Started
            </button>
            <button
              onClick={() => setShowVideoModal(true)}
              className=" grid place-items-center bg-blue-500 border-2 border-blue-500 hover:bg-transparent transition-all duration-300 ease-in-out text-white rounded-md w-32 p-2 text-sm md:text-base m-3  font-600">
              Watch Now
            </button>
          </div>
        </div>
        <div className="w-full lg:w-1/2 grid place-items-center relative">
          <div className="hidden lg:block">
            <lottie-player
              src="https://assets7.lottiefiles.com/packages/lf20_cgjrfdzx.json"
              background="transparent"
              speed="1"
              style={{ width: '100%', height: '500px', objectFit: 'contain' }}
              loop
              autoplay></lottie-player>
          </div>
          <div className="lg:hidden grid place-items-center">
            <lottie-player
              src="https://assets9.lottiefiles.com/packages/lf20_u8jppxsl.json"
              background="transparent"
              speed="1"
              style={{ width: '75%', height: '500px', objectFit: 'contain' }}
              loop
              autoplay></lottie-player>
          </div>

          <p className="hidden lg:block absolute text-blue-400 text-base font-600 top-10 left-1/3 transform ">
            20+ Analysts{' '}
          </p>
          <p className="hidden lg:block  absolute text-blue-400 text-base font-600 top-1/3 -left-20">
            Daily process 1 million data points.{' '}
          </p>
          <p className="hidden lg:block absolute text-blue-400 text-base font-600 bottom-24 -left-4">
            500+ Social Accounts{' '}
          </p>
          <p className="hidden lg:block absolute text-blue-400 text-base font-600 top-1/4 right-0">
            500+ Github Repos{' '}
          </p>
          <p className="hidden lg:block absolute text-blue-400 text-base font-600 bottom-1/4 -right-6">
            100+ News Sources{' '}
          </p>
        </div>
        {showAuthModal && (
          <div className="fixed left-0 right-0 top-0 h-screen w-screen z-40 backdrop-filter backdrop-blur-sm grid place-items-center">
            <div className="z-50">
              <ClickWrapper func={() => setShowAuthModal(false)}>
                <AuthModal close={() => setShowAuthModal(false)} />
              </ClickWrapper>
            </div>
          </div>
        )}
        {showVideoModal && (
          <div className="fixed left-0 right-0 top-0 h-screen w-screen z-40 backdrop-filter backdrop-blur-sm grid place-items-center">
            <div className="z-50   ">
              <ClickWrapper func={() => setShowVideoModal(false)}>
                <VideoModal close={() => setShowVideoModal(false)} />
              </ClickWrapper>
            </div>
          </div>
        )}
      </div>
    </div>
  )
}

export default HeaderComponent
