import React, { useEffect } from 'react'
import { useState } from 'react'
import uuid from 'react-uuid'
import ListingPagination from '../listing/ListingPagination'
import SingleBotTable from './SingleBotTable'
import { recommendationtableHeader } from './TableData'

const BotRecommendationTable = ({ data }) => {
  const [pageNumber, setPageNumber] = useState(1)
  const [filteredData, setFilteredData] = useState(data.asset)

  useEffect(() => {
    setPageNumber(1)
  }, [data])

  useEffect(() => {
    setFilteredData(data.asset.slice((pageNumber - 1) * 20, pageNumber * 20))
    window.scrollTo({ behavior: 'smooth', top: '0px' })
  }, [pageNumber, data])

  return (
    <div className="text-xs lg:text-sm hidden lg:block">
      <div className="bg-purple-700 bg-opacity-10 rounded-t-2xl  text-center relative p-4">
        <h1 className="absolute bottom-1/2 left-1/2 transform -translate-x-1/2 bg-teal-dark p-2 px-4">
          Bot Recommendation
        </h1>
      </div>
      <table className="p-4 w-full rounded-b-2xl overflow-hidden ">
        <tbody className="text-xs xl:text-sm">
          <tr className="bg-purple-400 bg-opacity-20 p-2">
            {recommendationtableHeader.map((head) => (
              <th key={uuid()} className="text-center p-2 cursor-pointer ">
                {head.title}{' '}
              </th>
            ))}
          </tr>
          {filteredData.map((singleData) => (
            <SingleBotTable key={uuid()} {...singleData} />
          ))}
        </tbody>
      </table>
      {data.asset.length === 0 && (
        <p className="text-center w-full font-600 py-4 my-4 text-gray-300">
          No Recommendation for now .
        </p>
      )}
      {data.asset.length > 20 && (
        <ListingPagination
          onPageChange={(page) => setPageNumber(page)}
          count={Math.ceil(data.asset.length / 20)}
          page={pageNumber}
        />
      )}
    </div>
  )
}

export default BotRecommendationTable
