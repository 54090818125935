import React, { useEffect, useRef, useState } from 'react'
import { ErrorBoundary } from 'react-error-boundary'
import { Helmet } from 'react-helmet'
import uuid from 'react-uuid'
import EndPoints from '../../../services/api'
import axios from '../../../services/axios'
import AnalystCard from '../../apps/analyst/AnalystCard'
import ErrorComponent from '../../apps/error/ErrorComponent'
import Loader from '../../apps/loader/Loader'

const AnalystPage = () => {
  const [analystData, setAnalystData] = useState([])
  const [loading, setLoading] = useState(true)
  const { getAnalystRecommendation } = EndPoints.analyst
  const componentMounted = useRef(true)

  const fetchAnalystData = async () => {
    try {
      const { data } = await axios.get(getAnalystRecommendation)
      // console.log(JSON.parse(data.body).message)
      setAnalystData(JSON.parse(data.body)?.message)
      setLoading(false)
    } catch (err) {
      console.log(err.message)
    }
  }

  useEffect(() => {
    if (componentMounted.current) {
      fetchAnalystData()
    }
    return () => {
      componentMounted.current = false
    }
  }, [])

  return (
    <div className="text-white p-4 sm:p-6  md:p-10 min-h-screen">
      <Helmet>
        <meta charSet="utf-8" />
        <title className="capitalize">High Potential Crypto Coin | Detailed Research.</title>
      </Helmet>
      <div className="flex flex-col my-4 md:my-0 px-2 sm:px-4 md:px-10  ">
        <h2 className="text-lg font-600 mb-1 sm:mb-2">Crypto to Watchout</h2>
        <p className="text-xs">Cryptos which are highly trusted by CRF</p>
      </div>
      {/* 
      <div className="flex justify-end items-center">
        <div className="flex text-2xl bg-purple-darker p-3 py-1 rounded-md">
          <select className="bg-transparent text-xs font-700 cursor-pointer p-1 w-20 ">
            <option className="font-600 bg-purple-dark ">sector</option>
          </select>
        </div>
      </div> */}

      {loading ? (
        <Loader />
      ) : (
        <ErrorBoundary FallbackComponent={ErrorComponent}>
          <div className="py-4 sm:py-10">
            {analystData.map((data) => (
              <AnalystCard key={uuid()} data={data} />
            ))}
          </div>
        </ErrorBoundary>
      )}
    </div>
  )
}

export default AnalystPage
