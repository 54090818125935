import React from 'react'
import uuid from 'react-uuid'
import SingleListing from './SingleListing'
import { TableHeader } from './TableData'
import { makeStyles } from '@material-ui/core/styles'
import Tooltip from '@material-ui/core/Tooltip'
import ListingPagination from '../listing/ListingPagination'

const useStyles = makeStyles((theme) => ({
  arrow: {
    color: theme.palette.common.black
  },
  tooltip: {
    backgroundColor: theme.palette.common.black,
    maxWidth: 300,
    padding: 10,
    fontSize: 12
  }
}))

const ListingTable = ({
  cryptoData,
  onPageChange,
  count,
  page,
  contestId,
  wallet,
  contestName
}) => {
  const classes = useStyles()

  return (
    <div className=" text-xs lg:text-sm hidden lg:block ">
      <table className="p-4 w-full rounded-2xl overflow-hidden  ">
        <tbody className="text-xs xl:text-sm  ">
          <tr className="bg-purple-400 bg-opacity-20 p-3 ">
            {TableHeader.map((head) =>
              head?.toolText.length > 0 ? (
                <Tooltip arrow key={uuid()} title={head?.toolText} classes={classes}>
                  <th className="text-center p-3 cursor-pointer ">{head.title} </th>
                </Tooltip>
              ) : (
                <th key={uuid()} className="text-center p-3 cursor-pointer ">
                  {head.title}{' '}
                </th>
              )
            )}
          </tr>

          {cryptoData.map((data) => (
            <SingleListing
              key={uuid()}
              coinid={data.id}
              singleCoinData={data}
              contestId={contestId}
              wallet={wallet}
              contestName={contestName}
              // coinid={data}
              // staticData={staticData}
              // priceData={priceData}
              // priceChange={priceChange}
              // gitData={gitData}
              // sentiment={sentiment}
              // rating={rating}
            />
          ))}
        </tbody>
      </table>
      <ListingPagination page={page} onPageChange={onPageChange} count={count} />
    </div>
  )
}

export default ListingTable
