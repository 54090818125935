import React, { Fragment, useEffect, useState } from 'react'
import ReactApexChart from 'react-apexcharts'
import { useSelector } from 'react-redux'
import uuid from 'react-uuid'
import { Legend, Line, LineChart, ResponsiveContainer, Tooltip, XAxis, YAxis } from 'recharts'
import EndPoints from '../../../services/api'
import axios from '../../../services/axios'
import { includeComma } from '../../../services/globalFunctions'
import Loader from '../loader/Loader'

const btnArray = [
  {
    title: '7 D',
    value: '7',
    price_value: 'week'
  },
  {
    title: '1 M',
    value: '30',
    price_value: 'month'
  },
  {
    title: '1 Y',
    value: '365',
    price_value: 'year'
  }
]

const AlgorithmChart = ({ coinid, setShowAuthModal }) => {
  const algoArray = [
    {
      title: 'MACD',
      id: 1,
      tag: 'MACD_ALL'
    },
    {
      title: 'Bollinger Bands',
      id: 2,
      tag: 'BANDS'
    },
    {
      title: 'RSI',
      id: 3,
      tag: 'RSI'
    }
  ]

  const [duration, setDuration] = useState(btnArray[0])
  const [rating, setRating] = useState(algoArray[0])
  const [loading, setLoading] = useState(false)
  const [chartData, setChartData] = useState([])

  const { getSingleCoinIndicators } = EndPoints.singleCoin
  const { loggedIn } = useSelector((state) => state.auth)

  const fetchChartData = async () => {
    setLoading(true)
    try {
      const { data } = await axios.get(
        `${getSingleCoinIndicators}?limit=${duration.value}&coin_id=${coinid}&indiactor=${rating.tag}`
      )
      //console.log(JSON.parse(data.data))
      const parsedData = JSON.parse(data.data)
      // console.log(parsedData)
      switch (rating.tag) {
        case 'BANDS':
          let resArr = []
          let m = parsedData.date.length
          for (var j = 0; j < m; j++) {
            resArr.push({
              Price: parsedData.price[m - j - 1],
              Date: parsedData.date[m - j - 1],
              'Bollinger Upper': parsedData.bollinger_upper_band[m - j - 1],
              'Bollinger Lower': parsedData.bollinger_lower_band[m - j - 1],
              'Bollinger Mid': parsedData.bollinger_mid_band[m - j - 1]
            })
          }
          //console.log(resArr)
          setChartData(resArr)
          break
        case 'MACD_ALL':
          let macArr = []
          let r = parsedData.date.length
          for (var j = 0; j < r; j++) {
            macArr.push({
              Price: parsedData.price[r - j - 1],
              Date: parsedData.date[r - j - 1],
              MACD: parsedData.macd[r - j - 1],
              'MACD Signal': parsedData.macd_signal[r - j - 1]
            })
          }
          //console.log(resArr)
          setChartData(macArr)
          break
        case 'RSI':
          let rsiArr = []
          let s = parsedData.date.length
          for (var j = 0; j < s; j++) {
            rsiArr.push({
              Price: parsedData.price[s - j - 1],
              Date: parsedData.date[s - j - 1],
              RSI: parsedData.rsi[s - j - 1]
            })
          }
          //console.log(resArr)
          setChartData(rsiArr)

          break
        default:
          break
      }

      setLoading(false)
    } catch (err) {
      console.log(err.message)
    }
  }

  useEffect(() => {
    fetchChartData()
  }, [duration, rating])

  return (
    <div className=" ">
      <div className="bg-purple-400 bg-opacity-10  flex-1 mx-2 rounded-xl p-2 sm:p-4  py-4 text-white ">
        <div className="flex flex-col md:flex-row gap-2 md:gap-4 md:justify-between md:items-center">
          <div className="flex gap-2">
            {algoArray.map((btn) => (
              <button
                onClick={() => setRating(btn)}
                className={`p-2 md:px-3 text-xs rounded-full bg-opacity-10 ${
                  rating.id === btn.id ? 'bg-purple-dark' : '  '
                } `}
                key={uuid()}>
                {btn.title}
              </button>
            ))}
          </div>

          <div className="flex items-center">
            {btnArray.map((btn) => (
              <button
                onClick={() => {
                  if (btn.value === '365') {
                    if (!loggedIn) {
                      setShowAuthModal(true)
                      return
                    }
                  }
                  setDuration(btn)
                }}
                className={`p-1 px-2 m-1 text-xs rounded-full bg-opacity-10 ${
                  duration.value === btn.value ? 'bg-purple-dark' : '  '
                } `}
                key={uuid()}>
                {btn.title}
              </button>
            ))}
          </div>
        </div>
        {loading ? (
          <Loader />
        ) : (
          <Fragment>
            {rating.tag === 'BANDS' ? (
              <Fragment>
                <ResponsiveContainer width="100%" height={350}>
                  <LineChart
                    width={500}
                    height={300}
                    data={chartData}
                    margin={{
                      top: 20,
                      right: 10,
                      left: 10,
                      bottom: 10
                    }}>
                    <XAxis
                      dataKey="Date"
                      // axisLine={false}
                      tick={{ fill: 'white' }}
                      tickLine={{ fill: 'white' }}
                      style={{
                        fontSize: '.8rem'
                      }}
                    />
                    <YAxis
                      yAxisId="left"
                      axisLine={false}
                      tick={{ fill: 'white' }}
                      tickLine={false}
                      domain={['auto', 'auto']}
                      style={{
                        fontSize: '.8rem'
                      }}
                    />

                    <Tooltip
                      wrapperStyle={{
                        background: 'transparent',
                        fontSize: '.8rem',
                        padding: '.5rem'
                      }}
                      labelStyle={{
                        color: 'white',
                        paddingBottom: '.4rem',
                        borderBottom: '1px solid gray'
                      }}
                      itemStyle={{ color: 'white', fontWeight: 'semi-bold' }}
                      formatter={function (value) {
                        return `${includeComma(value)}`
                      }}
                      labelFormatter={function (value) {
                        return `${value}`
                      }}
                    />
                    <Legend wrapperStyle={{ fontSize: '.8rem' }} />
                    <Line
                      yAxisId="left"
                      type="monotone"
                      dataKey="Bollinger Upper"
                      stroke="#00E396"
                      dot={false}
                      activeDot={{ r: 4 }}
                      strokeWidth={4}
                    />
                    <Line
                      yAxisId="left"
                      type="monotone"
                      dataKey="Bollinger Mid"
                      stroke="#FFC300"
                      dot={false}
                      activeDot={{ r: 4 }}
                      strokeWidth={4}
                    />
                    <Line
                      yAxisId="left"
                      type="monotone"
                      dataKey="Bollinger Lower"
                      stroke="#ED4141"
                      dot={false}
                      activeDot={{ r: 4 }}
                      strokeWidth={4}
                    />
                    <Line
                      yAxisId="left"
                      dot={false}
                      type="monotone"
                      dataKey="Price"
                      stroke="#41CBED"
                      strokeWidth={5}
                    />
                  </LineChart>
                </ResponsiveContainer>
              </Fragment>
            ) : rating.tag === 'MACD_ALL' ? (
              <Fragment>
                <ResponsiveContainer width="100%" height={350}>
                  <LineChart
                    width={500}
                    height={300}
                    data={chartData}
                    margin={{
                      top: 20,
                      right: 10,
                      left: 10,
                      bottom: 10
                    }}>
                    <XAxis
                      dataKey="Date"
                      tick={{ fill: 'white' }}
                      tickLine={{ fill: 'white' }}
                      style={{
                        fontSize: '.8rem'
                      }}
                    />
                    <YAxis
                      yAxisId="left"
                      axisLine={false}
                      tick={{ fill: 'white' }}
                      tickLine={false}
                      domain={['auto', 'auto']}
                      style={{
                        fontSize: '.8rem'
                      }}
                    />
                    <YAxis
                      yAxisId="right"
                      orientation={'right'}
                      axisLine={false}
                      tick={{ fill: 'white' }}
                      tickLine={false}
                      domain={['auto', 'auto']}
                      style={{
                        fontSize: '.8rem'
                      }}
                    />

                    <Tooltip
                      wrapperStyle={{
                        background: 'transparent',
                        fontSize: '.8rem',
                        padding: '.5rem'
                      }}
                      labelStyle={{
                        color: 'white',
                        paddingBottom: '.4rem',
                        borderBottom: '1px solid gray'
                      }}
                      itemStyle={{ color: 'white', fontWeight: 'semi-bold' }}
                      formatter={function (value) {
                        return `${includeComma(value)}`
                      }}
                      labelFormatter={function (value) {
                        return `${value}`
                      }}
                    />
                    <Legend wrapperStyle={{ fontSize: '.8rem' }} />
                    <Line
                      yAxisId="left"
                      type="monotone"
                      dataKey="MACD"
                      stroke="#00E396"
                      dot={false}
                      activeDot={{ r: 4 }}
                      strokeWidth={4}
                    />
                    <Line
                      yAxisId="left"
                      type="monotone"
                      dataKey="MACD Signal"
                      stroke="#FFC300"
                      dot={false}
                      activeDot={{ r: 4 }}
                      strokeWidth={4}
                    />

                    <Line
                      yAxisId="right"
                      dot={false}
                      type="monotone"
                      dataKey="Price"
                      stroke="#41CBED"
                      strokeWidth={5}
                    />
                  </LineChart>
                </ResponsiveContainer>
              </Fragment>
            ) : rating.tag === 'RSI' ? (
              <Fragment>
                <ResponsiveContainer width="100%" height={350}>
                  <LineChart
                    width={500}
                    height={300}
                    data={chartData}
                    margin={{
                      top: 20,
                      right: 10,
                      left: 10,
                      bottom: 10
                    }}>
                    <XAxis
                      dataKey="Date"
                      // axisLine={false}
                      tick={{ fill: 'white' }}
                      tickLine={{ fill: 'white' }}
                      style={{
                        fontSize: '.8rem'
                      }}
                    />
                    <YAxis
                      yAxisId="left"
                      axisLine={false}
                      tick={{ fill: 'white' }}
                      tickLine={false}
                      domain={['auto', 'auto']}
                      style={{
                        fontSize: '.8rem'
                      }}
                    />
                    <YAxis
                      yAxisId="right"
                      orientation={'right'}
                      axisLine={false}
                      tick={{ fill: 'white' }}
                      tickLine={false}
                      domain={['auto', 'auto']}
                      style={{
                        fontSize: '.8rem'
                      }}
                    />

                    <Tooltip
                      wrapperStyle={{
                        background: 'transparent',
                        fontSize: '.8rem',
                        padding: '.5rem'
                      }}
                      labelStyle={{
                        color: 'white',
                        paddingBottom: '.4rem',
                        borderBottom: '1px solid gray'
                      }}
                      itemStyle={{ color: 'white', fontWeight: 'semi-bold' }}
                      formatter={function (value) {
                        return `${includeComma(value)}`
                      }}
                      labelFormatter={function (value) {
                        return `${value}`
                      }}
                    />
                    <Legend wrapperStyle={{ fontSize: '.8rem' }} />
                    <Line
                      yAxisId="left"
                      type="monotone"
                      dataKey="RSI"
                      stroke="#00E396"
                      dot={false}
                      activeDot={{ r: 4 }}
                      strokeWidth={4}
                    />

                    <Line
                      yAxisId="right"
                      dot={false}
                      type="monotone"
                      dataKey="Price"
                      stroke="#41CBED"
                      strokeWidth={5}
                    />
                  </LineChart>
                </ResponsiveContainer>
              </Fragment>
            ) : null}
          </Fragment>
        )}
      </div>
    </div>
  )
}

export default AlgorithmChart
