import React, { useEffect, useState } from 'react'
import uuid from 'react-uuid'
import EndPoints from '../../../services/api'
import axios from '../../../services/axios'
import DataLoader from '../loader/DataLoader'
import Loader from '../loader/Loader'
import SingleIndex from './SingleIndexTable'

const IndexHeader = [
  {
    title: 'Coin'
  },
  {
    title: 'Percentage'
  }
]

const IndexTable = ({ id }) => {
  const [singleBucketData, setSingleBucketData] = useState([])
  const [loading, setLoading] = useState(true)

  const { getSingleBucketChartData } = EndPoints.singleBucket
  const fetchSingleBucketData = async () => {
    try {
      const { data } = await axios.get(`${getSingleBucketChartData}/details?crfno=${id}`)
      //console.log(JSON.parse(data.body).message)
      setSingleBucketData(JSON.parse(data.body).message)

      // setLoading(false)
    } catch (err) {
      console.log(err.message)
    } finally {
      setLoading(false)
    }
  }
  useEffect(() => {
    fetchSingleBucketData()
  }, [])

  return (
    <div
      id="index_table"
      className="w-56 border-2 border-purple-400 border-opacity-10 rounded-lg overflow-hidden">
      {loading ? (
        <Loader />
      ) : (
        <table
          className={`p-4 w-full border-none
     overflow-hidden rounded-lg `}>
          <tbody className="text-xs ">
            <tr className="bg-purple-400 bg-opacity-20">
              {IndexHeader.map((head) => (
                <th key={uuid()} className=" p-2 text-xs font-400 ">
                  {head.title}{' '}
                </th>
              ))}
            </tr>
            {singleBucketData.map((singleData) => (
              <SingleIndex key={uuid()} data={singleData} />
            ))}
          </tbody>
        </table>
      )}
    </div>
  )
}

export default IndexTable
