import React, { useState, useEffect } from 'react'
import uuid from 'react-uuid'
import axios from '../../../services/axios'
import ReactApexChart from 'react-apexcharts'
import Loader from '../loader/Loader'
import EndPoints from '../../../services/api'

const btnArray = [
  {
    title: '7 D',
    value: '7'
  },
  {
    title: '1 M',
    value: '30'
  },
  {
    title: '3 M',
    value: '90'
  },
  {
    title: '6 M',
    value: '180'
  }
]

const SingleBucketChart = ({ id, staticData }) => {
  const { long_desc } = staticData
  const series = [
    {
      name: 'price',
      data: []
    }
  ]

  const options = {
    chart: {
      type: 'area',
      foreColor: '#ffffff',
      toolbar: {
        show: false
      }
    },

    dataLabels: {
      enabled: false
    },
    stroke: {
      curve: 'smooth'
    },
    grid: {
      show: false
    },

    xaxis: {
      type: 'datetime',

      categories: []
    },
    tooltip: {
      x: {
        format: 'dd/MM/yy HH:mm'
      },
      theme: 'dark'
    },
    yaxis: {
      labels: {
        show: true,
        formatter: (value) => {
          return value.toFixed(2) + '%'
        }
      }
    }
  }

  const [duration, setDuration] = useState(btnArray[0].value)
  const [loading, setLoading] = useState(true)
  const [chartSeries, setChartSeries] = useState(series)
  const [chartOptions, setChartOptions] = useState(options)
  const [perChange, setPerChange] = useState(0)

  const { getSingleBucketGraphData } = EndPoints.singleBucket

  const fetchSingleBucketGraphData = async () => {
    setLoading(true)
    try {
      const { data } = await axios.get(
        `${getSingleBucketGraphData}?duration=${duration}&crfno=${id}`
      )
      const parsedData = JSON.parse(data.body)
      //console.log(parsedData)
      setChartSeries([
        {
          name: 'percent',
          data: parsedData.percent
        }
      ])
      setPerChange(parsedData.percent[0] - parsedData.percent[parsedData.percent.length - 1])
      setChartOptions({
        ...chartOptions,
        xaxis: {
          ...chartOptions.xaxis,

          categories: parsedData.date
        }
      })

      setLoading(false)
    } catch (err) {
      console.log(err.message)
    }
  }

  useEffect(() => {
    fetchSingleBucketGraphData()
  }, [duration])

  return (
    <div className="w-full   hidden lg:flex">
      <div className="bg-purple-400 bg-opacity-10  p-4 mr-2 rounded-xl text-xs flex  flex-col w-72 max-h-96 ">
        <p className="text-center mb-3 ">Sector - Currency Bucket</p>
        <p className="my-3 h-full overflow-y-scroll scrollbar-hidden leading-relaxed">
          {long_desc}
        </p>
      </div>
      <div className="bg-purple-400 bg-opacity-10 flex-1  ml-2 rounded-xl p-2 sm:p-4 md:p-6 py-4 text-white ">
        <div className="flex justify-between items-center">
          <div className="flex gap-3 items-center">
            <p className="font-600">Portfolio Chart</p>
            <p className={`font-600 text-${perChange >= 0 ? 'green' : 'red'}-500`}>
              {perChange.toFixed(2)} %{' '}
            </p>
          </div>

          <div className="flex mx-2">
            {btnArray.map((btn) => (
              <button
                onClick={() => setDuration(btn.value)}
                className={`p-1 px-2 m-1 text-xs rounded-full  ${
                  duration === btn.value ? 'bg-purple-dark' : ''
                } `}
                key={uuid()}>
                {btn.title}
              </button>
            ))}
          </div>
        </div>
        {loading ? (
          <Loader />
        ) : (
          <ReactApexChart options={chartOptions} series={chartSeries} type="area" height={250} />
        )}
      </div>
    </div>
  )
}

export default SingleBucketChart
