import React, { useState, Fragment, useEffect } from 'react'
import { useParams, useHistory } from 'react-router-dom'
import ResponsiveContestHeader from '../../apps/contest/ResponsiveContestHeader'
import { BsChevronLeft, BsChevronRight } from 'react-icons/bs'
import RanklistTable from '../../apps/rank-list/RanklistTable'
import ResponsiveRanklist from './ResponsiveRanklist'
import EndPoints from '../../../services/api'
import axios from '../../../services/axios'
import Loader from '../../apps/loader/Loader'
import { Helmet } from 'react-helmet'
import { ErrorBoundary } from 'react-error-boundary'
import ErrorComponent from '../../apps/error/ErrorComponent'

const RanklistPage = () => {
  const [contestData, setContestData] = useState({})
  const [rankData, setRankData] = useState({})
  const [loading, setLoading] = useState(true)
  const [loadingTable, setLoadingTable] = useState(true)
  const { id, name } = useParams()
  const history = useHistory()
  const { getContestData } = EndPoints.contest
  const { getRanking } = EndPoints.ranking

  const fetchContestData = async () => {
    try {
      const { data } = await axios.get(`${getContestData}?contest_id=${id}`)
      setContestData(JSON.parse(data.body).message[0])
      //console.log(JSON.parse(data.body).message[0])
      setLoading(false)
    } catch (err) {
      console.log(err.message)
    }
  }

  const fetchRankList = async () => {
    try {
      const { data } = await axios.get(`${getRanking}?contest_id=${id}`)

      // console.log(JSON.parse(data.body).message)
      setRankData(JSON.parse(data.body).message)
      setLoadingTable(false)
    } catch (err) {
      console.log(err.message)
    }
  }

  useEffect(() => {
    fetchContestData()
    fetchRankList()
  }, [])
  return (
    <Fragment>
      <Helmet>
        <meta charSet="utf-8" />
        <title>CRF | RankList - {name} </title>
      </Helmet>
      <div className="bg-purple-darkest text-white p-3 sm:p-4 md:p-6 lg:p-10 hidden md:block">
        <div className="text-center text-blue-500  pb-10 relative">
          <div className="text-center text-blue-500 hidden md:flex justify-between items-center ">
            <button
              onClick={() => history.push(`/trade-list/${name}/${id}`)}
              className="rounded-full border-2 border-blue-500 p-3 text-xs w-32 cursor-pointer font-700 text-white">
              {' '}
              Trade Now
            </button>
            <h1 className="mx-4 font-700 text-2xl md:text-2xl">{name}</h1>

            <button
              onClick={() => history.push(`/contest/${name}/${id}`)}
              className="rounded-full border-2 border-blue-500 p-3 text-xs w-32 cursor-pointer font-700 text-white">
              {' '}
              Contest
            </button>
          </div>
          <div className="text-center text-blue-500 flex flex-col md:hidden items-center pb-10">
            <h1 className="mx-4 font-700 text-2xl">{name}</h1>
            <div className="mt-4 flex justify-evenly">
              <button
                onClick={() => history.push(`/trade-list/${name}/${id}`)}
                className="rounded-full border-2 border-blue-500 p-3 text-xs w-32 mx-4">
                {' '}
                Trade Now
              </button>
              <button
                onClick={() => history.push(`/contest/${name}/${id}`)}
                className="rounded-full border-2 border-blue-500 p-3 text-xs w-32 mx-4">
                {' '}
                Contest
              </button>
            </div>
          </div>
        </div>

        {loading ? (
          <Loader />
        ) : (
          <Fragment>
            <ResponsiveContestHeader contestData={contestData} />

            <div className="hidden md:flex justify-between items-start mb-6 text-center">
              <div className="flex flex-col justify-between h-full mx-2 text-sm">
                <p className="mb-2">Contest Owner</p>
                <p className="text-xs font-600">{contestData.contest_owner}</p>
              </div>
              <div className="flex flex-col justify-between h-full mx-2 text-sm">
                <p className="mb-2">Status</p>
                <p className="text-xs font-600">{contestData.status}</p>
              </div>
              <div className="flex flex-col justify-between h-full mx-2 text-sm">
                <p className="mb-2">Intal CRF Token</p>
                <p className="text-xs font-600">{contestData.initial_coins}</p>
              </div>
              <div className="flex flex-col justify-between h-full mx-2 text-sm">
                <p className="mb-2">Start Date</p>
                <p className="text-xs font-600">{contestData.start_date}</p>
              </div>
              <div className="flex flex-col justify-between h-full mx-2 text-sm">
                <p className="mb-2">End Date</p>
                <p className="text-xs font-600">{contestData.end_date}</p>
              </div>
              <div className="flex flex-col justify-between h-full mx-2 text-sm">
                <p className="mb-2">Participants</p>
                <p className="text-xs font-600">{contestData.participant_count}</p>
              </div>
              <div className="flex flex-col justify-between h-full mx-2 text-sm">
                <p className="mb-2">Min Participants</p>
                <p className="text-xs font-600">{contestData.minimum_participant}</p>
              </div>
            </div>
          </Fragment>
        )}

        {loadingTable ? (
          <Loader />
        ) : (
          <Fragment>
            <div className="py-10">
              <ErrorBoundary FallbackComponent={ErrorComponent}>
                <RanklistTable data={rankData} />
              </ErrorBoundary>
            </div>
            <div className="p-10 pt-32 md:pt-56 pb-20 text-xs text-center">
              <p>
                Never invest in cryptos more than you can afford to lose and always try to keep
                them in your own wallet!
              </p>
            </div>
          </Fragment>
        )}
      </div>
      <div className="md:hidden">
        {loadingTable ? (
          <Loader />
        ) : (
          <ErrorBoundary FallbackComponent={ErrorComponent}>
            <ResponsiveRanklist rankData={rankData} contestName={name} />
          </ErrorBoundary>
        )}
      </div>
    </Fragment>
  )
}

export default RanklistPage
