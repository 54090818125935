import React from 'react'
import { makeStyles } from '@material-ui/core'
import clsx from 'clsx'
import SingleBotResponsiveTable from './SingleBotResponsiveTable'
import uuid from 'react-uuid'
import ListingPagination from '../listing/ListingPagination'
import { useEffect } from 'react'
import { useState } from 'react'

const useStyles = makeStyles((theme) => ({
  hiddenScrollBar: {
    '&::-webkit-scrollbar': {
      display: 'none'
    }
  }
}))

const BotResponsiveTable = ({ data }) => {
  const [pageNumber, setPageNumber] = useState(1)
  const [filteredData, setFilteredData] = useState(data.asset)

  useEffect(() => {
    setPageNumber(1)
  }, [data])

  useEffect(() => {
    window.scrollTo({ behavior: 'smooth', top: '0px' })
    setFilteredData(data.asset.slice((pageNumber - 1) * 20, pageNumber * 20))
  }, [pageNumber, data])
  const classes = useStyles()
  return (
    <div className={clsx(' flex flex-col  px-3 md:px-10 md:pt-4  lg:hidden')}>
      {filteredData.map((singleData) => (
        <SingleBotResponsiveTable key={uuid()} {...singleData} />
      ))}
      {data.asset.length === 0 && (
        <p className="text-center w-full font-600 py-4 my-4 text-gray-300">
          No Recommendation for now .
        </p>
      )}
      {data.asset.length > 20 && (
        <ListingPagination
          onPageChange={(page) => setPageNumber(page)}
          count={Math.ceil(data.asset.length / 20)}
          page={pageNumber}
        />
      )}
    </div>
  )
}

export default BotResponsiveTable
