import React, { useEffect } from 'react'
import { useState } from 'react'
import { useSelector } from 'react-redux'
import InputRange from 'react-input-range'
import 'react-input-range/lib/css/index.css'
import uuid from 'react-uuid'
import EndPoints from '../../../services/api'
import axiosInstance from '../../../services/axios'
import Loader from '../loader/Loader'
import BotChart from './BotChart'
import { includeComma } from '../../../services/globalFunctions'

const BotStats = () => {
  const [loading, setLoading] = useState(true)
  const [chartLoading, setChartLoading] = useState(false)
  const [allBotData, setAllBotData] = useState([])
  const [botStats, setBotStats] = useState({})
  const [selectedStrategy, setSelectedStrategy] = useState({})
  const [duration, setDuration] = useState(1)
  const [amount, setAmount] = useState(100)
  const [returnAmount, setReturnAmount] = useState(100)
  const { user } = useSelector((state) => state.auth)
  const { getBotNames, getBotStats } = EndPoints.botRecommendation

  const config = {
    headers: {
      Authorization: `Bearer ${user.token}`
    }
  }

  const fetchAllBots = async () => {
    try {
      const { data } = await axiosInstance.get(`${getBotNames}`, config)

      const botData = await JSON.parse(data.body.message)
      //console.log(res)
      const parsedData = await Object.keys(botData).map((key) => ({
        botId: key,
        botName: botData[key].bot_name
      }))
      //console.log(parsedData)
      setAllBotData(parsedData)
      setSelectedStrategy(parsedData[0].botName)
      const { data: botStats } = await axiosInstance.get(
        `${getBotStats}/return?days=${duration * 30}&bot_id=${parsedData[0].botId}`,
        config
      )

      // console.log(botStats)
      const botResult = JSON.parse(botStats.body).message
      setBotStats(botResult)
      setReturnAmount(
        botResult.price.length > 0 ? amount * botResult.price[botResult.price.length - 1] : 100
      )
      setLoading(false)
    } catch (err) {
      console.log(err.message)
    }
  }

  const fetchBotStats = async (length, id) => {
    setChartLoading(true)

    try {
      const { data: botStats } = await axiosInstance.get(
        `${getBotStats}/return?days=${length * 30}&bot_id=${id}`,
        config
      )

      // console.log(botStats)
      const botResult = JSON.parse(botStats.body).message
      setBotStats(botResult)

      setReturnAmount(
        botResult.price.length > 0 ? amount * botResult.price[botResult.price.length - 1] : 100
      )

      setChartLoading(false)
    } catch (err) {
      console.log(err.message)
    }
  }

  const handleStrategyChange = (e) => {
    setSelectedStrategy(e.target.value)
    const selectedBotId = allBotData.filter((bot) => bot.botName === e.target.value)[0].botId

    fetchBotStats(duration, selectedBotId)
  }

  const handleDurationChange = (value) => {
    setDuration(value)

    const selectedBotId = allBotData.filter((bot) => bot.botName === selectedStrategy)[0].botId
    // console.log(selectedBotId, value * 30)
    fetchBotStats(value, selectedBotId)
  }

  const handleAmountChange = (value) => {
    setAmount(value)

    setReturnAmount(
      botStats.price.length > 0 ? value * botStats.price[botStats.price.length - 1] : 100
    )
  }

  useEffect(() => {
    fetchAllBots()
  }, [])

  return (
    <div className="bg-purple-darker p-4 sm:p-6 md:p-10 rounded-xl md:rounded-2xl">
      {loading ? (
        <Loader />
      ) : (
        <div>
          <div className="flex flex-col gap-4">
            <div className="flex justify-between items-center gap-8">
              <h2 className="text-xl md:text-2xl">{includeComma(returnAmount.toFixed(3))} $ </h2>
              <div className="p-2 px-3 grid place-items-center border text-xs border-gray-300 border-opacity-40 rounded-md  ">
                <select
                  value={selectedStrategy}
                  onChange={handleStrategyChange}
                  className="bg-transparent cursor-pointer">
                  {allBotData.map((botData) => (
                    <option className="bg-purple-darker " key={uuid()} value={botData.botName}>
                      {botData.botName}
                    </option>
                  ))}
                </select>
              </div>
            </div>

            <div className="flex  gap-6  md:gap-16 items-center justify-between  md:justify-end">
              <div className="flex flex-col gap-4 w-32 lg:w-48">
                <h3 className="text-xs">Investment</h3>
                <div className="py-3">
                  <InputRange
                    value={amount}
                    minValue={0}
                    step={100}
                    maxValue={1000}
                    formatLabel={(value) => `${value} $`}
                    onChange={(value) => setAmount(value)}
                    onChangeComplete={(value) => handleAmountChange(value)}
                  />
                </div>
              </div>

              <div className="flex flex-col gap-4 w-32 lg:w-48">
                <h3 className="text-xs">Duration</h3>
                <div className="py-3 ">
                  <InputRange
                    minValue={1}
                    maxValue={12}
                    step={1}
                    formatLabel={(value) => `${value} M`}
                    value={duration}
                    onChange={(value) => setDuration(value)}
                    onChangeComplete={(value) => handleDurationChange(value)}
                  />
                </div>
              </div>
            </div>
          </div>

          {chartLoading ? (
            <Loader />
          ) : (
            <div className="py-8">
              <BotChart data={botStats} amount={amount} />
            </div>
          )}
        </div>
      )}
    </div>
  )
}

export default BotStats
