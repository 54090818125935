import React from 'react';
import { includeComma } from '../../../services/globalFunctions';

const PriceMetrics = ({ data }) => {
	const { dayhigh, daylow, high1h, high24hr, low1hr, low24hr } = data;

	return (
		<div className="flex-1 flex flex-col  bg-purple-400 bg-opacity-10  m-2 sm:m-4 rounded-2xl p-4 sm:py-6 sm:px-8 text-xs sm:text-sm md:text-xs lg:text-sm  ">
			<div className="flex justify-between items-center mb-10">
				<div className="bg-red-dark rounded-full p-2 text-xs">
					<p className="w-full px-2">Price Metrics</p>
				</div>

				<div className="flex-1 border-b-2 border-gray-500 border-opacity-50 mx-2"></div>

				<p>Share</p>
			</div>

			<div className="w-full">
				<div className="flex justify-between border-b-2 border-gray-500 border-opacity-50 pb-1 pt-4 ">
					<p>1Hr Low / 1Hr High </p>
					<p>
						${includeComma(low1hr.toFixed(2))} / $
						{includeComma(high1h.toFixed(2))}
					</p>
				</div>

				<div className="flex justify-between border-b-2 border-gray-500 border-opacity-50 pb-1 pt-4 ">
					<p>24Hr Low / 24Hr High </p>
					<p>
						${includeComma(low24hr.toFixed(2))} / $
						{includeComma(high24hr.toFixed(2))}
					</p>
				</div>
				<div className="flex justify-between border-b-2 border-gray-500 border-opacity-50 pb-1 pt-4 ">
					<p>Day High (USD)</p>
					<p>${includeComma(dayhigh.toFixed(2))}</p>
				</div>

				<div className="flex justify-between border-b-2 border-gray-500 border-opacity-50 pb-1 pt-4 ">
					<p>Day Low (USD) </p>
					<p>${includeComma(daylow.toFixed(2))}</p>
				</div>
			</div>
		</div>
	);
};

export default PriceMetrics;
