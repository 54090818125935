import React, { useEffect } from 'react'
import { useState } from 'react'
import EndPoints from '../../../../services/api'
import axios from '../../../../services/axios'

const TransactionDetails = ({ closeTab, refreshData, data }) => {
  const {
    coinid,
    coinname,
    contest_id,
    fee,
    payment_method,
    quantity,
    status,
    subtotal,
    time,
    total,
    transaction_id,
    unit_price
  } = data

  const [loading, setLoading] = useState(true)
  const [staticData, setStaticData] = useState({})

  const { getContestData } = EndPoints.contest
  const { getStaticData } = EndPoints.watchList

  const fetchRelevantData = async () => {
    try {
      const res = await Promise.all([
        axios.get(`${getStaticData}?coinid=${coinid}`),
        axios.get(`${getContestData}?contest_id=${contest_id}`)
      ])
      const parsed_data = res.map((res) => JSON.parse(res.data.body))
      const { symbol, logo } = parsed_data[0][`${coinid}`]
      const { contest_name, contest_owner } = parsed_data[1].message[0]
      setStaticData({
        logo,
        symbol,
        contest_name,
        contest_owner
      })
      setLoading(false)
    } catch (err) {
      console.log(err.message)
    }
  }

  useEffect(() => {
    fetchRelevantData()
  }, [])

  const handleCompleteTransaction = () => {
    closeTab()
    refreshData()
  }
  return (
    <div className=" pt-4">
      <div className="bg-purple-dark rounded-b-xl h-40 flex flex-col font-600 justify-center items-center ">
        {!loading && (
          <img className="h-8 w-8 object-contain mb-4" src={staticData.logo} alt={coinname} />
        )}

        <h2 className="text-xl">${total.toFixed(2)}</h2>
        <p className="text-blue-500 text-sm ">
          {quantity.toFixed(3)} {coinname} - {staticData.symbol}
        </p>
      </div>

      <div className="flex flex-col flex-1 relative font-500 px-3">
        <div className=" bg-purple-darker left-3 right-3  absolute -top-4 rounded-md   flex justify-between items-start text-xs p-2 text-white">
          <p>Contest</p>
          {loading ? <p>...</p> : <p>{staticData.contest_name}</p>}
        </div>
        <div className="flex justify-between items-start p-2 text-xs mt-4 text-white">
          <p>Owner</p>
          {loading ? <p>...</p> : <p>{staticData.contest_owner}</p>}
        </div>
        <div className="flex justify-between items-start p-2 text-xs text-white">
          <p>Transaction ID</p>
          <p>{transaction_id} </p>
        </div>
        <div className="flex justify-between items-start p-2 text-xs text-white">
          <p>Price Per Coin</p>
          <p>${unit_price.toFixed(2)}</p>
        </div>
        <div className="flex justify-between items-start p-2 text-xs text-white">
          <p>Payment Method</p>
          <p>{payment_method}</p>
        </div>
        <div className="flex justify-between items-start p-2 text-xs text-white">
          <p>Subtotal</p>
          <p>${subtotal.toFixed(2)}</p>
        </div>
        <div className="flex justify-between items-start p-2 text-xs text-white">
          <p>Total</p>
          <p>${total.toFixed(2)}</p>
        </div>
        <div className="flex justify-between items-start p-2 text-xs text-white">
          <p>Fee</p>
          <p>${fee.toFixed(2)}</p>
        </div>
        <div className="flex justify-between items-start p-2 text-xs text-white">
          <p>Date</p>
          <p>{time.split(' ')[0].split('-').reverse().join('-')}</p>
        </div>
        <div className="flex justify-between items-start p-2 text-xs text-white">
          <p>Status</p>
          <p>{status}</p>
        </div>
        <button
          onClick={handleCompleteTransaction}
          className="bg-red-dark text-sm text-white p-3 hover:bg-red-600 transition-all duration-300 ease-in-out">
          OK
        </button>
      </div>
    </div>
  )
}

export default TransactionDetails
