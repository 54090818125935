import React from 'react';
import { FaFacebookSquare, FaLinkedin, FaTwitter } from 'react-icons/fa';

const SingleInvest = () => {
	return (
		<div className="w-full flex rounded-lg overflow-hidden my-6">
			<div className="flex bg-white text-black">
				<div className="w-10 flex flex-col justify-start">
					<img
						className="w-10 h-10  rounded-full object-contain mt-3"
						src="https://www.nicepng.com/png/detail/31-318256_tesla-logo-tesla-motors-logo-png.png"
						alt="tesla"
					/>
				</div>
				<div className="flex flex-col flex-1 text-xs p-4 max-w-6xl">
					<h2 className="font-500 text-base mb-2">About Tesla</h2>
					<p>
						A USA base company, sectored in AI technology, consectetur
						adipiscing elit, sed do eiusmod tempor incididunt ut labore et
						dolore magna aliqua. Natoque penatibus et magnis dis parturient
						montes nascetur ridiculus mus. Eget mauris et ultrices neque ornare.
						Augue neque gravida in fermentum et sollicitudin. Tincidunt id
						aliquet risus feugiat in ante metus. Nulla aliquet porttitor lacus
						luctus accumsan tortor posuere. Pellentesque dignissim enim sit amet
						venenatis urna cursus nascetur ridiculusan vel. Consequat nisl vel
						pretium lectus quam. Aenean euismod elementum nisi quis. Eget velit
						aliquet sagittis id consectetur purus.isus feugiat in ante metus.
						Nulla aliquet porttitor lacus luctus accumsan torton euismod eled{' '}
					</p>
				</div>
			</div>

			<div className="flex justify-between px-6  bg-purple-400 bg-opacity-10 text-xs xl:text-sm">
				<div className="flex flex-col justify-evenly py-2 mr-2 w-40 xl:w-48">
					<p className="my-1">Company Name : Tesla </p>
					<p className="my-1">Type : Equity/Debt </p>
					<p className="my-1">Valuation : $100Million </p>
					<p className="my-1">Minimum Investment : $30,000 </p>
				</div>

				<div className="flex flex-col justify-evenly py-2 ml-2 w-40 px-2 xl:w-48">
					<p className="my-1">Segment : AI/Tech </p>
					<p className="my-1">Stage : Series A </p>
					<p className="my-1">Status : Upcoming </p>
					<p className="my-1">Risk : Medium</p>
				</div>
			</div>

			<div className="flex flex-col items-center justify-center w-64 bg-white p-2">
				<button className="bg-red-dark text-white text-xs xl:text-sm px-2 xl:px-4 py-2 rounded-full font-500 my-4 ">
					Know More
				</button>

				<ul className="flex items-center justify-evenly text-xl text-blue-500 w-full ">
					<li>
						<a href="https://tailwindcss.com/docs/font-size" target="blank">
							<FaTwitter />
						</a>
					</li>

					<li className="mx-1">
						<a href="https://tailwindcss.com/docs/font-size" target="blank">
							<FaFacebookSquare />
						</a>
					</li>

					<li className="mx-1">
						<a href="https://tailwindcss.com/docs/font-size" target="blank">
							<FaLinkedin />
						</a>
					</li>
				</ul>
			</div>
		</div>
	);
};

export default SingleInvest;
