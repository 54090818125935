import React from 'react'
import { Avatar } from '@material-ui/core'

const SingleTweet = ({ bg_color, marginY, tweet }) => {
  return (
    <a
      href={`http://twitter.com/${tweet.username}`}
      target="blank"
      className={`${bg_color} ${(bg_color = 'bg-purple-400'
        ? 'bg-opacity-10'
        : '')} p-5 rounded-6 flex flex-col`}>
      <div className="flex justify-start mb-4">
        <Avatar src={tweet.profilepic} />
        <div className=" ml-4 font-bold text-sm">
          <h4 className="">{tweet.coinname} News</h4>

          <h4 className="text-xs">
            @{tweet.username} <span className="ml-2 font-400">{tweet.tweetdate}</span>{' '}
          </h4>
        </div>
      </div>

      <p className="text-xs tracking-wide leading-relaxed font-350">
        {tweet.tweet.slice(0, 150) + '... more'}
      </p>
    </a>
  )
}

export default SingleTweet
