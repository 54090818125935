import React, { useState } from 'react'
import Helmet from 'react-helmet'
import ClickWrapper from '../../apps/click-wrapper/ClickWrapper'
import HomePopup from '../../apps/home/HomePopup'
import BrandComponent from '../../apps/home/components/header/BrandComponent'
import HeaderComponent from '../../apps/home/components/header/HeaderComponent'
import useElementOnScreen from '../../hooks/useElementOnScreen'
import MarketComponent from '../../apps/home/components/market/MarketComponent'
import BucketComponent from '../../apps/home/components/buckets/BucketComponent'
import SocialComponent from '../../apps/home/components/social/SocialComponent'

const HomePage = () => {
  const [showPopup, setShowPopup] = useState(true)

  const [marketRef, isMarketRendered] = useElementOnScreen({
    root: null,
    rootMargin: '0px',
    thresold: 1.0
  })
  const [bucketRef, isBucketRendered] = useElementOnScreen({
    root: null,
    rootMargin: '0px',
    thresold: 1.0
  })
  const [socialRef, isSocialRendered] = useElementOnScreen({
    root: null,
    rootMargin: '0px',
    thresold: 1.0
  })

  return (
    <div className="text-white min-h-screen bg-purple-darkest ">
      <Helmet>
        <meta charSet="utf-8" />
        <title>
          Crypto Research and Social Intelligence | CryptoResearchfund-A CryptoEd Platform.
        </title>
        <meta
          name="title"
          content="Crypto Research and Social Intelligence | CryptoResearchfund-A CryptoEd Platform."
        />
        <meta
          name="description"
          content="Make smarter decisions. The CRF Exchange gives you all the options, tools, and research you need to spot trends in the crypto market. Ranging from activity across social media for bitcoin and thousands of other crypto exchange events, to what influencers are posting on both Twitter and Reddit, this tool will help make your decisions that much easier by giving you a bird's eye view of everything happening in cryptocurrency!"
        />

        <meta property="og:type" content="website" />
        <meta property="og:url" content="http://www.cryptoresearchfund.com/" />
        <meta
          property="og:title"
          content="Crypto Research and Social Intelligence | CryptoResearchfund-A CryptoEd Platform."
        />
        <meta
          property="og:description"
          content="Make smarter decisions. The CRF Exchange gives you all the options, tools, and research you need to spot trends in the crypto market. Ranging from activity across social media for bitcoin and thousands of other crypto exchange events, to what influencers are posting on both Twitter and Reddit, this tool will help make your decisions that much easier by giving you a bird's eye view of everything happening in cryptocurrency!"
        />
        <meta
          property="og:image"
          content="http://www.cryptoresearchfund.com/dataScienceAndAIApproch.png"
        />

        <meta property="twitter:card" content="summary_large_image" />
        <meta property="twitter:url" content="http://www.cryptoresearchfund.com/" />
        <meta
          property="twitter:title"
          content="Crypto Research and Social Intelligence | CryptoResearchfund-A CryptoEd Platform."
        />
        <meta
          property="twitter:description"
          content="Make smarter decisions. The CRF Exchange gives you all the options, tools, and research you need to spot trends in the crypto market. Ranging from activity across social media for bitcoin and thousands of other crypto exchange events, to what influencers are posting on both Twitter and Reddit, this tool will help make your decisions that much easier by giving you a bird's eye view of everything happening in cryptocurrency!"
        />
        <meta
          property="twitter:image"
          content="http://www.cryptoresearchfund.com/dataScienceAndAIApproch.png"
        />
      </Helmet>
      <div className="md:hidden flex sm:justify-evenly items-center w-screen overflow-x-scroll h-10 bg-purple-darkest text-blue-400 text-xs font-700 ">
        <p className="  mx-4 min-w-max text-center ">20+ Analysts </p>
        <p className="  mx-4 min-w-max text-center  ">500+ Social Accounts </p>
        <p className="  mx-4 min-w-max text-center  ">500+ Github Repos </p>
        <p className="  mx-4 min-w-max text-center ">100+ News Sources </p>
        <p className="  mx-4 min-w-max text-center  ">Daily process 1 million data points. </p>
      </div>

      {showPopup && (
        <div className="fixed h-screen w-full left-0 top-0 grid place-items-center p-6 sm:p-10  z-50">
          <div className="z-50  ">
            <ClickWrapper func={() => setShowPopup(false)}>
              <HomePopup close={() => setShowPopup(false)} />
            </ClickWrapper>
          </div>
        </div>
      )}
      <div className="flex flex-col gap-16 md:gap-32 pb-32">
        <div className=" flex flex-col  ">
          <HeaderComponent />
          <BrandComponent />
        </div>

        <div ref={marketRef}>{isMarketRendered && <MarketComponent />}</div>
        <div ref={bucketRef}>{isBucketRendered && <BucketComponent />}</div>
        <div ref={socialRef}>{isSocialRendered && <SocialComponent />}</div>
      </div>
    </div>
  )
}

export default HomePage
