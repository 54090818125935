import React from 'react'
import '../../../styles/index.css'
import MailchimpSubscribe from 'react-mailchimp-subscribe'
import EmailForm from '../landing/EmailForm'
import { Link } from 'react-router-dom'
import { HOME_PAGE, TERMS_PAGE, POLICY_PAGE } from '../../../navigation/Routes'
import CrfLogo from '../logo/CrfLogo'
import { footerData } from './Footerdata'
import uuid from 'react-uuid'

const Footer = () => {
  const postUrl = `https://cryptoresearchfund.us5.list-manage.com/subscribe/post?u=${process.env.REACT_APP_MAILCHIMP_U}&id=${process.env.REACT_APP_MAILCHIMP_ID}`
  return (
    <div className="bg-purple-darkest text-white z-10">
      <div className="border-t-2 border-b-2 border-gray-500 border-opacity-40 flex flex-col md:flex-row justify-evenly px-10 py-4 md:py-10">
        <div className="my-2 md:my-0">
          <div className="hidden md:grid place-items-center w-24 lg:w-28 mr-10">
            <Link to={HOME_PAGE}>
              <CrfLogo />
            </Link>
          </div>

          <div className="w-full grid place-items-center">
            <div className="grid place-items-center md:hidden w-16 ">
              <Link to={HOME_PAGE}>
                <CrfLogo />
              </Link>
            </div>
          </div>
        </div>

        <div className="flex flex-col-reverse md:flex-row justify-between mb-4 flex-1 max-w-xl">
          <div className="flex flex-col mx-4 lg:mx-10">
            <p className="hidden md:block">Legal</p>
            <ul className="md:mt-6 pt-6 md:pt-0 flex justify-between md:flex-col md:justify-center">
              <li className="md:mb-1">
                <Link to={POLICY_PAGE}>Privacy policy</Link>
              </li>
              <li className="md:mt-1">
                <Link to={TERMS_PAGE}>Terms of use</Link>
              </li>
            </ul>
          </div>
          <div className="flex flex-col mx-4 lg:mx-10 py-6 md:py-0 border-b-2 md:border-b-0 border-gray-500 border-opacity-40">
            <p className="text-center md:text-left hidden md:block mb-6 ">Social</p>

            <ul className="grid place-items-center  grid-cols-6 md:grid-cols-3 gap-2 md:gap-3 text-xl ">
              {footerData.map((footer) => (
                <li key={uuid()}>
                  <a href={footer.link} target="blank">
                    {footer.component}
                  </a>
                </li>
              ))}
            </ul>
          </div>
        </div>

        <div className="flex flex-col items-center md:items-start my-2 md:my-0 ">
          <p className="mb-4 md:mb-10 text-center md:text-left text-gray-400 md:text-gray-300">
            Sign up for our newsletter
          </p>
          <div className="max-w-lg">
            <MailchimpSubscribe
              url={postUrl}
              render={({ subscribe, status, message }) => (
                <EmailForm
                  status={status}
                  message={message}
                  onValidated={(formData) => subscribe(formData)}
                />
              )}
            />
          </div>
        </div>
      </div>

      <p className="p-6 md:p-10 text-xs text-center text-gray-300">
        Privacy Policy | Copyright © 2016-{new Date().getFullYear()} CRF PVT LTD. All rights
        reserved.
      </p>
    </div>
  )
}

export default Footer
