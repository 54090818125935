import React from 'react'
import { makeStyles } from '@material-ui/styles'
import NewsComponent from '../../apps/news/news/NewsComponent'
import TweetComponent from '../../apps/news/tweets/TweetComponent'
import clsx from 'clsx'
import ResponsiveNews from '../../apps/news/ResponsiveNews'
import { Helmet } from 'react-helmet'

const useStyles = makeStyles((theme) => ({
  smoothScroll: {
    scrollBehavior: 'smooth'
  }
}))

const NewsPage = () => {
  const classes = useStyles()

  return (
    <div className={clsx(classes.smoothScroll, 'flex flex-col w-full bg-purple-darkest  ')}>
      <Helmet>
        <meta charSet="utf-8" />
        <title>Crypto News and Views | CryptoResearchfund. </title>
      </Helmet>
      <div className="hidden h-screen  md:flex pt-10 bg-purple-400 bg-opacity-10 ">
        <TweetComponent />

        <NewsComponent />
      </div>

      <ResponsiveNews />
    </div>
  )
}
export default NewsPage
