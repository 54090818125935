import { makeStyles } from '@material-ui/core'
import React, { Fragment } from 'react'
import clsx from 'clsx'
import uuid from 'react-uuid'
import SingleResponsivePortfolioTable from './SingleResponsivePortfolioTable'

const useStyles = makeStyles((theme) => ({
  hiddenScrollBar: {
    '&::-webkit-scrollbar': {
      display: 'none'
    }
  }
}))

const ResponsivePortfolioTable = ({ data, trade }) => {
  const classes = useStyles()

  return (
    <div className={clsx(' flex flex-col sm:p-2 md:p-10  lg:hidden my-6')}>
      {data.length === 0 ? (
        <Fragment>
          <p className="text-center text-gray-300  font-600 py-4">No Current Portfolio Trades .</p>
        </Fragment>
      ) : (
        data.map((singleTrade) => (
          <SingleResponsivePortfolioTable key={uuid()} rowData={singleTrade} trade={trade} />
        ))
      )}
    </div>
  )
}

export default ResponsivePortfolioTable
