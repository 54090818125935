import React, { Fragment, useState, useEffect } from 'react'
import { useParams, useHistory } from 'react-router-dom'
import HoldingTable from '../../apps/contest/holding-table/HoldingTable'
import ResponsiveHoldingTable from '../../apps/contest/holding-table/ResponsiveHoldingTable'
import { FaUsers } from 'react-icons/fa'
import ContestHeader from '../../apps/contest/ContestHeader'
import EndPoints from '../../../services/api'
import axios from '../../../services/axios'
import Loader from '../../apps/loader/Loader'
import { useSelector } from 'react-redux'
import { ErrorBoundary } from 'react-error-boundary'
import ErrorComponent from '../../apps/error/ErrorComponent'
import { Helmet } from 'react-helmet'
import { GoAlert } from 'react-icons/go'
import { RiMessage3Line } from 'react-icons/ri'
import ClickWrapper from '../../apps/click-wrapper/ClickWrapper'
import ReferralModal from '../../apps/contest/ReferralModal'

const ContestPage = () => {
  const [loading, setLoading] = useState(true)
  const [contestData, setContestData] = useState({})
  const [holdingData, setHoldingData] = useState({})
  const [isContestOpen, setIsContestOpen] = useState(true)
  const { id, name } = useParams()
  const history = useHistory()
  const { user } = useSelector((state) => state.auth)

  const { getContestData, getContestHolding, getContestSummary, checkContestValidity } =
    EndPoints.contest

  const fetchContestData = async () => {
    const config = {
      headers: {
        Authorization: `Bearer ${user.token}`
      }
    }
    setLoading(true)
    try {
      const { data } = await axios.get(`${checkContestValidity}/check?contest_id=${id}`)
      //console.log(data)
      setIsContestOpen(JSON.parse(data.body).message)
      const res = await Promise.all([
        axios.get(`${getContestData}?contest_id=${id}`),
        axios.get(`${getContestSummary}?user_id=${user.user_id}&contest_id=${id}`, config),
        axios.get(
          JSON.parse(data.body).message
            ? `${getContestHolding}?user_id=${user.user_id}&contest_id=${id}`
            : `${getContestHolding}?user_id=${user.user_id}&contest_id=${id}&status=inactive`,
          config
        )
      ])
      console.log(res)
      const rev_data = res.map((res) => JSON.parse(res.data.body))
      console.log(rev_data)
      setContestData(rev_data)
      setHoldingData(rev_data[2])
      setLoading(false)
    } catch (err) {
      console.log(err.message)
    }
  }

  useEffect(() => {
    fetchContestData()
  }, [])

  return (
    <div className="relative">
      {!loading &&
        new Date(contestData[0].message[0].end_date).getTime() - Date.now() <= 1296000000 &&
        new Date(contestData[0].message[0].end_date).getTime() - Date.now() > 0 && (
          <div className="bg-red-500 p-3 flex justify-center items-center gap-6 lg:mb-10 text-white">
            <GoAlert />{' '}
            <h2>
              Contest is about to end in{' '}
              {Math.floor(
                (new Date(contestData[0].message[0].end_date).getTime() - Date.now()) / 86400000
              )}{' '}
              {Math.floor(
                (new Date(contestData[0].message[0].end_date).getTime() - Date.now()) / 86400000
              ) > 1
                ? `  days`
                : `  day`}
            </h2>
          </div>
        )}
      {!loading && new Date(contestData[0].message[0].start_date).getTime() - Date.now() > 0 && (
        <div className="bg-blue-500 p-3 flex justify-center items-center gap-3 lg:mb-10 text-white">
          <RiMessage3Line />{' '}
          <h2>
            Contest will Start in{' '}
            {Math.floor(
              (new Date(contestData[0].message[0].start_date).getTime() - Date.now()) / 86400000
            )}{' '}
            {Math.floor(
              (new Date(contestData[0].message[0].start_date).getTime() - Date.now()) / 86400000
            ) > 1
              ? `  days`
              : `  day`}
          </h2>
        </div>
      )}
      <Helmet>
        <meta charSet="utf-8" />
        <title>CRF | Contest - {name} </title>
      </Helmet>
      <div className="bg-purple-darkest text-white p-3 sm:p-4 md:p-6 lg:p-10  ">
        {loading ? (
          <Loader />
        ) : (
          <Fragment>
            <div className="text-center text-blue-500 hidden md:flex justify-between items-center pb-10 relative">
              <button
                onClick={() => history.push(`/rank-list/${name}/${id}`)}
                className="rounded-full border-2 border-blue-500 p-3 text-xs w-32 cursor-pointer font-700 text-white">
                {' '}
                Rank List
              </button>
              <h1 className="mx-4 font-700 text-2xl md:text-2xl absolute left-1/2 transform -translate-x-1/2">
                {name}
              </h1>
              {isContestOpen && (
                <button
                  onClick={() => history.push(`/trade-list/${name}/${id}`)}
                  className="rounded-full border-2 border-blue-500 p-3 text-xs w-32 cursor-pointer font-700 text-white">
                  {' '}
                  Trade Now
                </button>
              )}
            </div>

            <div className="text-center text-blue-500 flex flex-col md:hidden items-center pb-10">
              <h1 className="mx-4 font-700 text-2xl">{name}</h1>
              <div className="mt-4 flex justify-evenly">
                <button
                  onClick={() => history.push(`/rank-list/${name}/${id}`)}
                  className="rounded-full border-2 border-blue-500 p-3 text-xs w-32 mx-4">
                  {' '}
                  Rank List
                </button>
                {isContestOpen && (
                  <button
                    onClick={() => history.push(`/trade-list/${name}/${id}`)}
                    className="rounded-full border-2 border-blue-500 p-3 text-xs w-32 mx-4">
                    {' '}
                    Trade Now
                  </button>
                )}
              </div>
            </div>
          </Fragment>
        )}

        {loading ? (
          <Loader />
        ) : (
          <div className="">
            <ErrorBoundary FallbackComponent={ErrorComponent}>
              {' '}
              <ContestHeader
                contestData={contestData}
                tradeCount={holdingData.trade_count}
                isOpen={isContestOpen}
                id={id}
              />
            </ErrorBoundary>
          </div>
        )}

        <div className="py-10">
          <Fragment>
            {loading ? (
              <Loader />
            ) : (
              <Fragment>
                {' '}
                <div className="flex items-center justify-around z-10 h-16 mb-6 w-full ">
                  <div className="h-full flex justify-evenly  flex-1  ">
                    <lottie-player
                      src="https://assets3.lottiefiles.com/packages/lf20_oNHjED.json"
                      background="transparent"
                      speed="1"
                      style={{ height: '100%', width: '5rem', objectFit: 'contain' }}
                      loop
                      autoplay
                    />
                    <lottie-player
                      src="https://assets2.lottiefiles.com/private_files/lf30_gavvxe6v.json"
                      background="transparent"
                      speed="1"
                      style={{ height: '100%', width: '5rem', objectFit: 'contain' }}
                      loop
                      autoplay
                    />
                    <lottie-player
                      src="https://assets3.lottiefiles.com/packages/lf20_oNHjED.json"
                      background="transparent"
                      speed="1"
                      style={{ height: '100%', width: '5rem', objectFit: 'contain' }}
                      loop
                      autoplay
                    />
                  </div>
                  <h1 className="p-6 text-xl font-700 text-center text-blue-500 cursor-pointer  ">
                    Holding List
                  </h1>
                  <div className="h-full flex justify-evenly  flex-1  ">
                    <lottie-player
                      src="https://assets3.lottiefiles.com/packages/lf20_oNHjED.json"
                      background="transparent"
                      speed="1"
                      style={{ height: '100%', width: '5rem', objectFit: 'contain' }}
                      loop
                      autoplay
                    />
                    <lottie-player
                      src="https://assets2.lottiefiles.com/private_files/lf30_gavvxe6v.json"
                      background="transparent"
                      speed="1"
                      style={{ height: '100%', width: '5rem', objectFit: 'contain' }}
                      loop
                      autoplay
                    />
                    <lottie-player
                      src="https://assets3.lottiefiles.com/packages/lf20_oNHjED.json"
                      background="transparent"
                      speed="1"
                      style={{ height: '100%', width: '5rem', objectFit: 'contain' }}
                      loop
                      autoplay
                    />
                  </div>
                </div>
                <ErrorBoundary FallbackComponent={ErrorComponent}>
                  <HoldingTable
                    data={holdingData.message}
                    wallet={contestData[1].message.token}
                    contestId={id}
                    refreshData={fetchContestData}
                    isOpen={isContestOpen}
                  />
                </ErrorBoundary>
                <ErrorBoundary FallbackComponent={ErrorComponent}>
                  <ResponsiveHoldingTable
                    data={holdingData.message}
                    wallet={contestData[1].message.token}
                    contestId={id}
                    refreshData={fetchContestData}
                    isOpen={isContestOpen}
                  />
                </ErrorBoundary>
              </Fragment>
            )}
          </Fragment>
        </div>

        <div className="p-10 pt-32 md:pt-56 pb-20 text-xs text-center">
          <p>
            Never invest in cryptos more than you can afford to lose and always try to keep them in
            your own wallet!
          </p>
        </div>
      </div>
    </div>
  )
}

export default ContestPage
