import React, { Fragment } from 'react'
import uuid from 'react-uuid'
import { PortfolioTableHeader } from './SinglePortfolioData'
import SinglePortfolioTable from './SinglePortfolioTable'

const PortfolioTable = ({ data, trade }) => {
  return (
    <div className="hidden py-4 lg:block  ">
      <table
        className={`h-full w-full ${
          Object.keys(data).length !== 0 && `rounded-b-2xl`
        } overflow-hidden`}>
        <tbody className="text-xs xl:text-sm  ">
          <tr className="bg-purple-400 bg-opacity-20 p-2">
            {PortfolioTableHeader.map((head) => {
              if (!trade) {
                if (head.title === 'Trade') return <div key={uuid()}></div>
                else
                  return (
                    <th key={uuid()} className="text-center p-2 py-3 ">
                      {head.title}{' '}
                    </th>
                  )
              } else {
                return (
                  <th key={uuid()} className="text-center p-2 py-3 ">
                    {head.title}{' '}
                  </th>
                )
              }
            })}
          </tr>
          {data.length !== 0 &&
            data.map((singleTrade) => (
              <SinglePortfolioTable key={uuid()} rowData={singleTrade} trade={trade} />
            ))}
        </tbody>
      </table>
      {data.length === 0 && (
        <Fragment>
          <p className="text-center w-full font-600 py-4 my-4 text-gray-300">
            No current Portfolio Trades .
          </p>
        </Fragment>
      )}
    </div>
  )
}

export default PortfolioTable
