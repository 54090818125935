import React from 'react'
import { useHistory } from 'react-router-dom'
import { includeComma } from '../../../services/globalFunctions'
import Crfscore from './Crfscore'

const SingleResponsiveBucket = ({ data, id }) => {
  const history = useHistory()
  const {
    company_id,
    created_at,
    crf_score,
    long_desc,
    name,
    sector,
    short_desc,
    status,
    type,
    updated_at,
    return_1year,
    return_24hrs,
    return_30days,
    user_id
  } = data
  return (
    <div
      id="single_bucket_card"
      onClick={() => history.push(`/bucket/${name}/${id}`)}
      className="flex flex-col gap-2 p-3 md:p-6 pb-2 bg-purple-400 bg-opacity-10 w-full rounded-xl my-3 sm:my-4 cursor-pointer">
      <div className="flex justify-between items-start">
        <div className="text-xs mr-2 ">
          <div className="flex items-center flex-col sm:flex-row ">
            <p className="md:my-1 sm:mr-1 flex items-center gap-2">
              Status:{' '}
              <span
                className={`w-2 h-2 rounded-full bg-${
                  status.toLowerCase() === 'active' ? 'green' : 'red'
                }-500`}></span>
            </p>
          </div>
        </div>
        <div className="flex flex-col justify-center items-center text-center mx-2 flex-grow ">
          <h1 className="font-600 text-xs sm:text-base md:text-xl md:my-1 capitalize">{name}</h1>
        </div>
        <div className="flex flex-col justify-center items-center text-right md:text-center text-xs ml-2">
          <h2 className="text-xs sm:text-sm md:text-lg font-500">Description</h2>
          <p className="my-1 w-16 sm:w-32 truncate">{short_desc}</p>
        </div>
      </div>

      <div className="text-xs flex flex-col gap-2 bg-purple-400 bg-opacity-10 p-2 px-3 rounded-md mt-2">
        <p className="my-1 flex justify-between items-center gap-2">
          30Days Return :{' '}
          <span className={`${return_30days >= 0 ? `text-green-500` : `text-red-500`} ml-1`}>
            {' '}
            {return_30days.toFixed(2)}%
          </span>
        </p>
        <p className="my-1 flex justify-between items-center gap-2">
          24Hr Return :{' '}
          <span className={`${return_24hrs >= 0 ? `text-green-500` : `text-red-500`}  ml-1`}>
            {' '}
            {return_24hrs.toFixed()}%{' '}
          </span>
        </p>
        <p className="my-1 flex justify-between items-center gap-2">
          1yr Return :{' '}
          <span className={`${return_1year >= 0 ? `text-green-500` : `text-red-500`}  ml-1`}>
            {' '}
            {return_1year.toFixed()}%{' '}
          </span>
        </p>
      </div>

      <div className="flex justify-between  items-start text-xs sm:text-sm md:text-base m-4">
        <div className="flex flex-col justify-between   font-350 text-center mr-2">
          <p>Type</p>

          <h1 className="md:text-xl xl:text-3xl font-600 mt-2 capitalize tracking-wide">
            {type}{' '}
          </h1>
        </div>

        <div className="flex flex-col  justify-between  font-350 text-center mx-2">
          <p>Sector</p>

          <h1 className="md:text-xl xl:text-3xl  font-600 mt-2 tracking-wide capitalize">
            {sector.toLowerCase()}
          </h1>
        </div>
        <div className="flex flex-col justify-between md:hidden  font-350 text-center ml-2 sm:mx-2">
          <p>CRF Score</p>

          <h1 className="md:md:text-xl xl:text-3xl font-600 mt-2 sm:mt-3 tracking-wide">
            {crf_score}
          </h1>
        </div>

        <div className="hidden flex-col justify-between  md:flex font-350 text-center ml-2 sm:mx-2">
          <p>CRF Score</p>

          <Crfscore score={crf_score} />
        </div>
        {/* <div className="hidden sm:flex justify-between  flex-col  font-350 text-center ml-2">
          <p>Social Score</p>

          <h1 className="md:md:text-xl xl:text-3xl font-600 mt-2 tracking-wide">8,782</h1>
</div> */}
      </div>
    </div>
  )
}

export default SingleResponsiveBucket
