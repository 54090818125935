import React from 'react'
import uuid from 'react-uuid'
import { rankTableData } from './rankTableData'
import SingleRankTable from './SingleRankTable'

const RanklistTable = ({ data }) => {
  return (
    <div id="ranklist" className=" text-xs lg:text-sm hidden md:block rounded-2xl ">
      <table className="p-4 w-full rounded-lg overflow-hidden ">
        <tbody className="text-xs xl:text-sm">
          <tr className="bg-purple-dark p-2 bg-opacity-10 ">
            {rankTableData.map((head) => (
              <th key={uuid()} className="text-center p-3 cursor-pointer ">
                {head.title}{' '}
              </th>
            ))}
          </tr>

          {data.length > 0 &&
            data.map((singlerank, idx) => (
              <SingleRankTable key={uuid()} data={singlerank} rank={idx + 1} />
            ))}
        </tbody>
      </table>
    </div>
  )
}

export default RanklistTable
