import React, { useState, useEffect } from 'react'
import { useSelector } from 'react-redux'
import EndPoints from '../../../services/api'
import axios from '../../../services/axios'
import { includeComma } from '../../../services/globalFunctions'
import DataLoader from '../loader/DataLoader'
import { FcDeleteDatabase } from 'react-icons/fc'
import { makeStyles, Tooltip } from '@material-ui/core'

const useStyles = makeStyles((theme) => ({
  arrow: {
    color: theme.palette.common.black
  },
  tooltip: {
    backgroundColor: theme.palette.common.black,
    maxWidth: 300,
    padding: 10,
    fontSize: 12
  }
}))

const SingleTable = ({ data, id, refresh }) => {
  const { assetname, change, changepercent, currentprice, timestamp } = data
  const assetId = id.replace(/[^0-9.]+/g, '')
  const { user } = useSelector((state) => state.auth)
  const [staticData, setStaticData] = useState({})
  const [loading, setLoading] = useState(true)
  const classes = useStyles()
  const { getStaticData, getSingleCoin24hChange, addToWatchlistEndpoint } = EndPoints.watchList
  const fetchSingleWatchList = async () => {
    try {
      const res = await Promise.all([
        axios.get(`${getStaticData}?coinid=${assetId}`),
        axios.get(`${getSingleCoin24hChange}?coinid=${assetId}`)
      ])

      const singleWatchListData = res.map((res) => JSON.parse(res.data.body))
      const { symbol, logo } = singleWatchListData[0][`${assetId}`]
      const { change24h } = singleWatchListData[1][`${assetId}`]
      setStaticData({
        symbol,
        logo,
        change24h
      })
      setLoading(false)
    } catch (err) {
      console.log(err.message)
    }
  }

  const removeFromWatchlist = async () => {
    const config = {
      headers: {
        Authorization: `Bearer ${user.token}`
      }
    }
    try {
      const { data } = await axios.post(
        `${addToWatchlistEndpoint}?user_id=${user.user_id}`,
        {
          assetid: assetId,
          userid: user.user_id,
          command: 'REMOVE'
        },
        config
      )
      // console.log(data)
      refresh()
    } catch (err) {
      console.log(err.message)
    }
  }

  useEffect(() => {
    fetchSingleWatchList()
  }, [])

  return (
    <tr className="text-xs xl:text-sm font-600  bg-purple-700 bg-opacity-10 ">
      <td className="grid place-items-center h-full w-full">
        {loading ? (
          <DataLoader />
        ) : (
          <img className="w-8 h-8 object-contain" src={staticData.logo} alt={assetname} />
        )}
      </td>
      <td className="text-center">
        {loading ? (
          <DataLoader />
        ) : (
          <p className="relative">
            {assetname} ({staticData.symbol}){' '}
            <Tooltip arrow title={'Remove'} classes={classes}>
              <button
                className="grid place-items-center p-2 text-sm font-bold  rounded-full absolute right-0 -top-1"
                onClick={removeFromWatchlist}>
                <FcDeleteDatabase />
              </button>
            </Tooltip>
          </p>
        )}
      </td>
      <td className="text-center">
        <p>{timestamp}</p>
      </td>
      <td className="text-center">
        <p>$ {includeComma(currentprice.toFixed(2))}</p>
      </td>
      <td className="text-center">
        {loading ? (
          <p>....</p>
        ) : (
          <p className={`text-${staticData.change24h >= 0 ? 'green-500' : 'red-500'}`}>
            {staticData.change24h.toFixed(2)} %
          </p>
        )}
      </td>
      <td className="text-center">
        <p className={`text-${change >= 0 ? 'green-500' : 'red-500'}`}>
          $ {includeComma(change.toFixed(2))}
        </p>{' '}
      </td>
      <td className="">
        <div className="h-full w-full flex flex-col text-center">
          <p className={`text-sm text-${changepercent >= 0 ? 'green-500' : 'red-500'}`}>
            {changepercent.toFixed(2)} %
          </p>
        </div>
      </td>
    </tr>
  )
}

export default SingleTable
