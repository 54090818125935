export const WatchListHeader = [
  {
    title: '#',
    toolText: "This is how it's done"
  },
  {
    title: 'Coin / Date',
    toolText: "This is how it's done"
  },
  {
    title: 'Watchlist Date',
    toolText: "This is how it's done"
  },
  {
    title: 'Current Price',
    toolText: "This is how it's done"
  },
  {
    title: '24 H Change',
    toolText: "This is how it's done"
  },
  {
    title: 'Profit / Loss',
    toolText: "This is how it's done"
  },
  {
    title: '% Change Since',
    toolText: "This is how it's done"
  }
]
