import React, { Fragment, useEffect } from 'react'
import { useState } from 'react'
import EndPoints from '../../../../services/api'
import axios from '../../../../services/axios'
import { includeComma } from '../../../../services/globalFunctions'
import ClickWrapper from '../../click-wrapper/ClickWrapper'
import DataLoader from '../../loader/DataLoader'
import TradingTransactionModal from '../modal/TradingTransactionModal'

const SingleResponsiveHolding = ({ rowData, wallet, contestId, refreshData, isOpen }) => {
  const [showTransactionModal, setShowTransactionModal] = useState(false)

  const {
    asset_id,
    current_Value,
    daily_pl,
    total_profit_loss,
    previous_coinprice,
    quantity,
    current_price: price,
    trade_id,
    unit_price
  } = rowData

  const [staticData, setStaticData] = useState({})
  const [loading, setLoading] = useState(true)
  const { getStaticData } = EndPoints.singlePortfolio
  const fetchSinglePortfolioRow = async () => {
    try {
      const { data } = await axios.get(`${getStaticData}?coinid=${asset_id}`)

      const singleWatchListData = JSON.parse(data.body)
      const { symbol, logo, coinname, category } = singleWatchListData[`${asset_id}`]

      setStaticData({
        symbol,
        logo,
        coinname,
        category
      })
      setLoading(false)
    } catch (err) {
      console.log(err.message)
    }
  }

  useEffect(() => {
    fetchSinglePortfolioRow()
  }, [])

  return (
    <div className="flex justify-between bg-purple-400 bg-opacity-10 rounded-2xl  p-2 sm:px-4 sm:py-6 my-3 cursor-pointer">
      <div className="flex flex-col justify-between max-w-1/3 ">
        <div className="flex items-start mb-2">
          {loading ? (
            <DataLoader />
          ) : (
            <img
              className=" object-contain  h-6 w-6 self-start "
              src={staticData.logo}
              alt={staticData.coinname}
            />
          )}

          <div className="flex flex-col ml-1">
            <h4 className="text-sm font-600  ">
              {loading ? <DataLoader /> : staticData.coinname}
            </h4>
            <p className={` text-base flex gap-1 items-center font-500 mb-1`}>
              $ {includeComma((price > 1000 ? price / 1000 : price).toFixed(2))}
              {price > 1000 && `k`}
              <span className="text-xs">
                ( ${' '}
                {includeComma(
                  (previous_coinprice > 1000
                    ? previous_coinprice / 1000
                    : previous_coinprice
                  ).toFixed(2)
                )}{' '}
                {previous_coinprice > 1000 && `k`})
              </span>
            </p>
          </div>
        </div>
        {isOpen && (
          <Fragment>
            <button
              className="text-xs w-16 mb-3 px-3 py-1 border-2  transition-all duration-200 border-purple-400 border-opacity-30 cursor-pointer rounded-full transform scale-90"
              onClick={() => setShowTransactionModal(true)}>
              Sell
            </button>
            {showTransactionModal && (
              <div className="fixed left-0 right-0 top-0 h-screen w-full z-10 backdrop-filter backdrop-blur-sm grid place-items-center">
                <div className="z-50">
                  <ClickWrapper func={() => setShowTransactionModal(false)}>
                    <TradingTransactionModal
                      close={() => setShowTransactionModal(false)}
                      refreshData={refreshData}
                      tradingData={{
                        coinname: staticData.coinname,
                        price,
                        symbol: staticData.symbol,
                        id: asset_id,
                        quantity,
                        contestId,
                        wallet,
                        associate_trade_id: trade_id
                      }}
                    />
                  </ClickWrapper>
                </div>
              </div>
            )}
          </Fragment>
        )}

        <div className="text-xs sm:text-sm font-600">
          <p>QTY : {quantity.toFixed(3)}</p>
        </div>
      </div>
      <div className="flex flex-col justify-between mx-2 text-center text-xs ">
        <p className="mb-1">MKT Value</p>
        <h1 className="text-xl font-700 sm:text-4xl md:my-1">
          {' '}
          $
          {includeComma(
            (current_Value > 1000 || current_Value < -1000
              ? current_Value / 1000
              : current_Value
            ).toFixed(2)
          )}{' '}
          {(current_Value > 1000 || current_Value < -1000) && `k`}
        </h1>
        <p className="text-sm sm:text-lg font-600">
          ${' '}
          {includeComma(
            (quantity * unit_price > 1000 || quantity * unit_price < -1000
              ? (quantity * unit_price) / 1000
              : quantity * unit_price
            ).toFixed(2)
          )}
          {(quantity * unit_price > 1000 || quantity * unit_price < -1000) && `k`} I.V
        </p>
      </div>

      <div className="flex flex-col justify-between items-end  text-xs">
        <div className="flex items-center py-1  text-center mb-2">
          <p className="text-xs flex flex-col items-center">
            24Hr P/L :{' '}
            <span className={`font-600 text-${daily_pl >= 0 ? `green` : `red`}-500`}>
              ${' '}
              {includeComma(
                (daily_pl > 1000 || daily_pl < -1000 ? daily_pl / 1000 : daily_pl).toFixed(2)
              )}{' '}
              {(daily_pl > 1000 || daily_pl < -1000) && `k`}{' '}
            </span>{' '}
          </p>
        </div>

        <div className="ml-4 text-center flex flex-col items-end ">
          <p className="mb-1 text-center w-full">Holding P/L </p>
          <p
            className={`text-sm flex flex-col sm:text-lg font-700 text-${
              total_profit_loss >= 0 ? `green` : `red`
            }-500
						`}>
            ${' '}
            {includeComma(
              (total_profit_loss > 1000 || total_profit_loss < -1000
                ? total_profit_loss / 1000
                : total_profit_loss
              ).toFixed(2)
            )}{' '}
            {(total_profit_loss > 1000 || total_profit_loss < -1000) && `k`}
            <span>
              ({' '}
              {(((current_Value - unit_price * quantity) / (unit_price * quantity)) * 100).toFixed(
                2
              )}
              %)
            </span>
          </p>
        </div>
      </div>
    </div>
  )
}

export default SingleResponsiveHolding
