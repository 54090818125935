import React, { useState, useEffect } from 'react'

import NewsTab from './tabs/NewsTab'
import Loader from '../../loader/Loader'
import axios from '../../../../services/axios'
import EndPoints from '../../../../services/api'

const NewsComponent = () => {
  const [loading, setLoading] = useState(true)
  const [newsData, setNewsData] = useState({})

  const fetchNews = async () => {
    try {
      const { data } = await axios.get(EndPoints.news.getAllNews)

      const news = JSON.parse(data.body)

      setNewsData(news)
      setLoading(false)
    } catch (err) {
      console.log(err.message)
    }
  }

  useEffect(() => {
    fetchNews()
  }, [])

  return (
    <div className={`h-full flex flex-col p-3 w-full md:w-1/2 bg-white`}>
      {loading ? <Loader /> : <NewsTab newsData={newsData} />}
    </div>
  )
}

export default NewsComponent
