import EndPoints from '../../services/api'
import axios from '../../services/axios'
import { LOGIN_FAIL, LOGIN_SUCCESS, LOGOUT, REGISTER_FAIL, REGISTER_SUCCESS } from './actionTypes'

const { authSignIn, authSignUp } = EndPoints.auth

const register =
  ({ email, password, firstname, lastname, profile_image }, setLoading, closeTab, notifySignup) =>
  async (dispatch) => {
    setLoading(true)
    try {
      const { data } = await axios.post(authSignUp, {
        email,
        password,
        firstname,
        lastname,
        profile_image
      })
      console.log(data)

      notifySignup(JSON.parse(data.body).message)
      const user = JSON.parse(data.body)

      if (user.error) {
        //alert(user.error)
        notifySignup(user.error)
        dispatch({
          type: REGISTER_FAIL,
          payload: user.error
        })
        setLoading(false)
        return
      }
      dispatch({
        type: REGISTER_SUCCESS
      })

      dispatch(login({ email, password }, setLoading, closeTab))
    } catch (err) {
      notifySignup(err.message)
    }
  }

const login =
  ({ email, password }, setLoading, closeTab, notifyLogin) =>
  async (dispatch) => {
    setLoading(true)
    try {
      const { data } = await axios.post(authSignIn, { email, password })
      console.log(JSON.parse(data.body))
      const user = JSON.parse(data.body)

      if (user.error) {
        notifyLogin(user.error)
        dispatch({
          type: LOGIN_FAIL,
          payload: user.error
        })
        setLoading(false)
        return
      }

      dispatch({
        type: LOGIN_SUCCESS,
        payload: user
      })
      localStorage.setItem('AUTH_USER', JSON.stringify(JSON.parse(data.body)))
      setLoading(false)
      closeTab()
    } catch (err) {
      notifyLogin(err.message)
    }
  }

const logout = () => (dispatch) => {
  localStorage.clear()
  sessionStorage.clear()
  dispatch({
    type: LOGOUT
  })
}

const authAction = {
  register,
  login,
  logout
}

export default authAction
