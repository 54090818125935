import React from 'react';
import { FaFacebookSquare, FaLinkedin, FaTwitter } from 'react-icons/fa';

const SingleResponsiveInvest = () => {
	return (
		<div className="flex flex-col rounded-lg bg-white text-black text-xs sm:text-sm md:text-xs">
			<div className="flex justify-between items-center px-3">
				<div>
					{' '}
					<img
						className="w-14 h-14  rounded-full object-contain mt-3"
						src="https://www.nicepng.com/png/detail/31-318256_tesla-logo-tesla-motors-logo-png.png"
						alt="tesla"
					/>
				</div>

				<ul className="flex items-center justify-end text-xl text-blue-500 w-full  ">
					<li className="mx-2">
						<a href="https://tailwindcss.com/docs/font-size" target="blank">
							<FaTwitter />
						</a>
					</li>

					<li className="mx-2">
						<a href="https://tailwindcss.com/docs/font-size" target="blank">
							<FaFacebookSquare />
						</a>
					</li>

					<li className="mx-2">
						<a href="https://tailwindcss.com/docs/font-size" target="blank">
							<FaLinkedin />
						</a>
					</li>
				</ul>
			</div>

			<div className="flex flex-col justify-evenly  p-2 sm:p-4 md:p-2">
				<p>Company Name : Tesla </p>
				<p>Type : Equity/Debt</p>
				<p>Valuation : $100 Million </p>
				<p>Minimum Investment : $30,000 </p>
				<p>Segment : AI/Tech</p>
				<p>Stage : Series A</p>
				<p>Status : Upcoming </p>
				<p>Risk : Medium </p>

				<button className="bg-red-dark text-white text-sm px-4 py-2 rounded-full font-500 mt-2 self-end w-32 ">
					Know More
				</button>
			</div>
		</div>
	);
};

export default SingleResponsiveInvest;
