import React, { Fragment } from 'react'
import uuid from 'react-uuid'
import { BucketTableHeader } from './BucketTableData'
import SingleBucketTable from './SingleBucketTable'

const BucketTable = ({ data }) => {
  return (
    <div className="my-6 text-xs sm:text-sm">
      <table
        className={`p-4 w-full 
     overflow-hidden rounded-lg `}>
        <tbody className="text-xs xl:text-sm ">
          <tr className="bg-purple-400 bg-opacity-20 p-2">
            {BucketTableHeader.map((head) => (
              <th key={uuid()} className="text-center p-2 py-3 ">
                {head.title}{' '}
              </th>
            ))}
          </tr>
          {data.map((singleData) => (
            <SingleBucketTable key={uuid()} data={singleData} />
          ))}
        </tbody>
      </table>
      {/* {Object.keys(data).length === 0 && (
    <Fragment>
      <p className="text-center w-full font-600 py-4 my-4 text-gray-300">
        Your Watchlist is Empty
      </p>
    </Fragment>
  )} */}
    </div>
  )
}

export default BucketTable
