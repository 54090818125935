import React from 'react'
import { makeStyles } from '@material-ui/core'
import clsx from 'clsx'
import SingleResponsiveLeaderboard from './SingleResponsiveLeaderboard'
import uuid from 'react-uuid'

const useStyles = makeStyles((theme) => ({
  hiddenScrollBar: {
    '&::-webkit-scrollbar': {
      display: 'none'
    }
  }
}))

const ResponsiveLeaderBoard = ({ data }) => {
  const classes = useStyles()
  return (
    <div className={clsx('  flex flex-col  px-3 md:px-10 md:pt-4  lg:hidden')}>
      {data.map((singleRank, idx) => (
        <SingleResponsiveLeaderboard key={uuid()} data={singleRank} idx={idx} />
      ))}
    </div>
  )
}

export default ResponsiveLeaderBoard
