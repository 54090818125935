import { Avatar } from '@material-ui/core'
import React from 'react'
import { useHistory, useParams } from 'react-router-dom'
import ResponsiveRankTable from '../../apps/rank-list/responsive-ranklist/ResponsiveRankTable'

const ResponsiveRanklist = ({ rankData }) => {
  const history = useHistory()
  const { name, id } = useParams()
  return (
    <div className="bg-purple-darkest h-screen overflow-hidden w-full flex flex-col md:hidden text-white ">
      <div className="mt-6">
        <div className="text-center text-blue-500 flex flex-col md:hidden items-center pb-10">
          <h1 className="mx-4 font-700 text-2xl">{name}</h1>
          <div className="mt-4 flex justify-evenly">
            <button
              onClick={() => history.push(`/trade-list/${name}/${id}`)}
              className="rounded-full border-2 border-blue-500 p-3 text-xs w-32 mx-4">
              {' '}
              Trade Now
            </button>
            <button
              onClick={() => history.push(`/contest/${name}/${id}`)}
              className="rounded-full border-2 border-blue-500 p-3 text-xs w-32 mx-4">
              {' '}
              Contest
            </button>
          </div>
        </div>
      </div>

      <div className="p-6">
        {/* <div className="flex items-center w-full rounded-full overflow-hidden bg-purple-400 bg-opacity-10">
          <button className="flex-1 p-3 font-500 rounded-full overflow-hidden bg-purple-dark">
            i8labs
          </button>
          <button className="flex-1 p-3 font-500 rounded-full overflow-hidden">Crypto</button>
          <button className="flex-1 p-3 font-500 rounded-full overflow-hidden">Marvel</button>
        </div> */}
      </div>
      <ResponsiveRankTable data={rankData} />
    </div>
  )
}

export default ResponsiveRanklist
