import { makeStyles } from '@material-ui/core'
import React, { useState } from 'react'
import ForgetPassword from './ForgetPassword'
import clsx from 'clsx'
import SignIn from './SignIn'
import SignUp from './SignUp'

const useStyles = makeStyles((theme) => ({
  hiddenScrollBar: {
    '&::-webkit-scrollbar': {
      display: 'none'
    }
  }
}))

const AuthComponent = ({ closeTab }) => {
  const [selectedTab, setSelectedTab] = useState(0)
  const classes = useStyles()
  function handleTabChange(value) {
    setSelectedTab(value)
  }
  return (
    <div
      className={clsx(
        classes.hiddenScrollBar,
        'w-full max-w-md h-full max-h-screen overflow-y-scroll '
      )}>
      <div className=" bg-white text-black ">
        <div className=" w-full">
          <div className="p-3 mb-3  ">
            <div className="flex justify-between items-center text-gray-500  ">
              <button
                onClick={() => handleTabChange(0)}
                className={`flex-1 mx-2 p-3 text-sm font-600 ${
                  selectedTab === 0 ? `text-black ` : ``
                }`}>
                Sign In
              </button>
              <button
                onClick={() => handleTabChange(1)}
                className={`flex-1 mx-2 p-3 text-sm font-600 ${
                  selectedTab === 1 ? `text-black` : ``
                }`}>
                Sign Up
              </button>
            </div>

            <div
              className={`h-1 w-1/2 bg-blue-400 transform transition-all duration-500 origin-left translate-x-${
                selectedTab === 0 ? `0` : `full`
              } `}></div>
          </div>

          {selectedTab === 0 && <SignIn closeTab={closeTab} toggleTab={handleTabChange} />}
          {selectedTab === 1 && <SignUp closeTab={closeTab} toggleTab={handleTabChange} />}
          {selectedTab === 2 && <ForgetPassword toggleTab={handleTabChange} />}
        </div>
      </div>
    </div>
  )
}

export default AuthComponent
