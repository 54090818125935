import React, { useEffect, useState } from 'react'
import { BsChevronDown, BsChevronUp } from 'react-icons/bs'
import { TiWaves } from 'react-icons/ti'
import { AiOutlineEye } from 'react-icons/ai'
import { RiProductHuntFill } from 'react-icons/ri'
import { CgDanger } from 'react-icons/cg'
import EndPoints from '../../../services/api'
import DataLoader from '../loader/DataLoader'
import axios from '../../../services/axios'
import { useSelector } from 'react-redux'
import ClickWrapper from '../click-wrapper/ClickWrapper'
import AuthModal from '../auth/AuthModal'

const AnalystCard = ({ data }) => {
  const [showInfo, setShowInfo] = useState(false)
  const [showAuthModal, setShowAuthModal] = useState(false)
  const { loggedIn } = useSelector((state) => state.auth)

  const {
    coinname,
    concept_rating,
    crr,
    crr_view,
    investment,
    liquidity,
    liquidity_desc,
    logo,
    platform,
    product,
    product_desc,
    risk,
    risk_desc,
    sector,
    short_desc,
    status,
    team_rating,
    vc_investment
  } = data
  const convertRatingToStats = (num) => {
    if (num === 0) return { stats: 'none', color: 'white' }
    else if (num > 0 && num <= 3) return { stats: 'low', color: 'red-500' }
    else if (num >= 4 && num <= 7) return { stats: 'med', color: 'yellow-500' }
    else return { stats: 'high', color: 'green-500' }
  }
  const convertRiskToStats = (num) => {
    if (num === 0) return { stats: 'none', color: 'white' }
    else if (num > 0 && num <= 3) return { stats: 'low', color: 'green-500' }
    else if (num >= 4 && num <= 7) return { stats: 'med', color: 'yellow-500' }
    else return { stats: 'high', color: 'red-500' }
  }

  return (
    <div className="flex flex-col my-4 ">
      <div className="flex flex-col lg:flex-row text-xs">
        <div className="bg-purple-darker my-1 lg:mr-2 lg:my-0 lg:ml-0 flex items-start p-4 rounded-lg flex-1">
          {logo !== null && logo.length !== 0 && (
            <img className="w-8 h-8 object-contain" src={logo} alt={coinname} />
          )}

          <div className="flex flex-col mx-2">
            <p className="mb-1 text-base font-600"> {coinname}</p>

            <p>{short_desc !== null && short_desc}</p>
          </div>
        </div>
        <div className="bg-purple-darker rounded-lg my-1 lg:mx-2 lg:my-0 flex-1  flex items-center justify-around">
          <div className="flex flex-col items-center text-center mx-1 p-4">
            <h4 className="text-sm mb-1">Team Rating</h4>
            <p className="text-2xl font-600">{team_rating === null ? '-' : team_rating}</p>
          </div>
          <div className="flex flex-col items-center text-center mx-1 p-4">
            <h4 className="text-sm mb-1">Concept Rating</h4>

            <p className="text-2xl font-600">{concept_rating === null ? '-' : concept_rating}</p>
          </div>
          <div className="flex flex-col items-center text-center mx-1 p-4">
            <h4 className="text-sm mb-1">Product Rating</h4>
            <p className="text-2xl font-600">{product === null ? '-' : product}</p>
          </div>
        </div>

        <div className=" flex flex-1 justify-evenly my-1 lg:my-0  ">
          <div
            className={`flex flex-col items-center flex-1  text-center  bg-${
              convertRiskToStats(risk).color
            } bg-opacity-70 bg-purple-darker rounded-lg ml-1 lg:mx-1 p-4 px-6  relative`}>
            <h4 className="text-base mb-1">Risk</h4>
            <p className="text-base md:text-xl font-600 uppercase">
              {convertRiskToStats(risk).stats}
            </p>
          </div>
          <div
            className={`flex flex-col items-center flex-1  text-center  bg-${
              convertRatingToStats(crr).color
            } bg-opacity-70 bg-purple-darker rounded-lg ml-1 lg:mx-1 p-4 px-6  relative`}>
            <h4 className="text-base mb-1">CRR</h4>
            <p className="text-base md:text-xl font-600 uppercase">
              {convertRatingToStats(crr).stats}
            </p>
          </div>
          <div
            className={`flex flex-col items-center flex-1  text-center  bg-${
              convertRatingToStats(investment).color
            } bg-opacity-70 bg-purple-darker rounded-lg ml-1 lg:mx-1 p-4 px-6  relative`}>
            <h4 className="text-base mb-1">Investment</h4>
            <p className="text-base md:text-xl font-600 uppercase">
              {convertRatingToStats(investment).stats}
            </p>
          </div>
        </div>
      </div>

      {/* accordion will come here */}

      {showInfo && (
        <div className="p-4 grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-4  gap-6 bg-purple-darker my-2">
          <div className="flex flex-col ">
            <div className="flex items-start  mb-1">
              <div className="mr-1 text-yellow-600 text-2xl">
                <RiProductHuntFill />
              </div>
              <h3 className="text-base font-600 ">Prd Des</h3>
            </div>

            <p className="text-xs">{product_desc === null ? '...' : product_desc}</p>
          </div>
          <div className="flex flex-col ">
            <div className="flex items-start  mb-1">
              <div className="mr-1 text-red-500 text-2xl">
                <CgDanger />
              </div>
              <h3 className="text-base font-600 ">Risk Des</h3>
            </div>

            <p className="text-xs">{risk_desc === null ? '...' : risk_desc}</p>
          </div>{' '}
          <div className="flex flex-col ">
            <div className="flex items-start mb-1">
              <div className="mr-1 text-white text-2xl">
                <AiOutlineEye />
              </div>
              <h3 className="text-base font-600 ">CRR Views</h3>
            </div>

            <p className="text-xs">{crr_view === null ? '...' : crr_view}</p>
          </div>{' '}
          <div className="flex flex-col ">
            <div className="flex items-start mb-1 ">
              <div className="mr-1 text-blue-500 text-2xl">
                <TiWaves />
              </div>
              <h3 className="text-base font-600 ">Liquidity Des</h3>
            </div>
            <p className="text-xs">{liquidity_desc === null ? '...' : liquidity_desc}</p>
          </div>
        </div>
      )}

      <button
        //onClick={() => (loggedIn ? setShowInfo(!showInfo) : setShowAuthModal(true))}
        onClick={() => setShowInfo(!showInfo)}
        className="bg-purple-darker p-2 w-full my-2 text-lg grid place-items-center font-700 rounded-lg">
        {showInfo ? <BsChevronUp /> : <BsChevronDown />}
      </button>
      {/* {showAuthModal && (
        <div className="fixed left-0 right-0 top-0 h-screen w-screen z-10 backdrop-filter backdrop-blur-sm grid place-items-center">
          <div className="z-50">
            <ClickWrapper func={() => setShowAuthModal(false)}>
              <AuthModal close={() => setShowAuthModal(false)} />
            </ClickWrapper>
          </div>
        </div>
      )} */}
    </div>
  )
}

export default AnalystCard
