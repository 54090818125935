import React, { useState, useEffect } from 'react'
import { Link, useLocation } from 'react-router-dom'
import { headerMenu } from './headerData'
import uuid from 'react-uuid'
import CrfLogo from '../logo/CrfLogo'
import axios from '../../../services/axios'
import { includeComma } from '../../../services/globalFunctions'
import { HOME_PAGE, SUBSCRIPTION_PAGE } from '../../../navigation/Routes'
import { FcCurrencyExchange } from 'react-icons/fc'
import ResponsiveHeader from './ResponsiveHeader'
import EndPoints from '../../../services/api'
import ClickWrapper from '../click-wrapper/ClickWrapper'
import AuthModal from '../auth/AuthModal'
import SingleHeaderLink from './SingleHeaderLink'

const UnauthenticatedHeader = () => {
  const [headerData, setHeaderData] = useState({})
  const [loading, setLoading] = useState(true)
  const [showsubMenu, setShowsubMenu] = useState(false)
  const [showAuthModal, setShowAuthModal] = useState(false)
  const { pathname } = useLocation()
  const { getHeaderData } = EndPoints.platform

  const fetchHeaderData = async () => {
    try {
      const { data } = await axios.get(getHeaderData)
      //console.log(JSON.parse(data.body).message[0])
      setHeaderData(JSON.parse(data.body).message[0])
      setLoading(false)
    } catch (err) {
      console.log(err.message)
    }
  }

  useEffect(() => {
    fetchHeaderData()
  }, [])
  return (
    <div
      className={` ${
        pathname === HOME_PAGE ? ' bg-purple-darkest' : ' bg-purple-darker'
      } md:px-4 lg:px-10 py-4 pb-6  flex-col text-white `}>
      {!loading && (
        <div className="flex gap-6 items-center px-3 md:px-6 lg:px-0">
          <Link className="hidden lg:block w-28 mr-4" to={HOME_PAGE}>
            <CrfLogo />
          </Link>
          <div className="flex items-center flex-wrap">
            <p className="text-xs font-700 m-2 hidden md:block">
              Cryptos :{' '}
              <span className="mx-1 text-blue-400">
                {includeComma(headerData.active_cryptocurrencies)}
              </span>
            </p>
            <p className="text-xs font-700 m-2 hidden md:block">
              Exchanges :{' '}
              <span className="mx-1 text-blue-400">{includeComma(headerData.markets)}</span>
            </p>
            <p className="text-xs font-700 m-2 hidden md:block">
              MarketCap :{' '}
              <span className="mx-1 text-blue-400">
                $ {includeComma(headerData.total_market_cap / 1000000000)} B
              </span>
            </p>
            <p className="text-xs font-700 m-2 hidden md:block">
              24h Vol :{' '}
              <span className="mx-1 text-blue-400">
                $ {includeComma(headerData.total_volume / 1000000000)} B
              </span>
            </p>
            <p className="text-xs font-700 m-2">
              Dominance :{' '}
              <span className="mx-1 uppercase text-blue-400">
                {' '}
                {headerData.market_cap_1st} : {headerData.market_cap_percentage_1st.toFixed(2)}%{' '}
                {headerData.market_cap_2nd} : {headerData.market_cap_percentage_2nd.toFixed(2)}%
              </span>
            </p>
          </div>
          {/* 
        <div className=" hidden lg:flex text-2xl">
          <FcCurrencyExchange />
          <select className="bg-transparent text-xs font-700 cursor-pointer p-1 w-20 ">
            <option className="font-600 bg-purple-dark ">USD $</option>
            <option className="font-600 bg-purple-dark  ">INR ₹</option>
            <option className="font-600 bg-purple-dark ">GBP £</option>
          </select>
        </div> */}
        </div>
      )}

      <div className="hidden lg:flex items-start pt-3 justify-between w-full gap-10 ">
        <div className="flex items-center  flex-wrap">
          {headerMenu.map((menu) => (
            <SingleHeaderLink key={uuid()} menu={menu} />
          ))}
        </div>
        <button
          onClick={() => setShowAuthModal(true)}
          className='className="mx-3 text-xs w-40 font-600 list-none border border-transparent bg-blue-500 hover:bg-opacity-0 hover:border-blue-500 p-2 px-4  grid place-items-center transition-all duration-300 ease-in-out"'>
          Sign In / Sign Up
        </button>
      </div>
      <ResponsiveHeader showAuthModal={() => setShowAuthModal(true)} />
      {showAuthModal && (
        <div className="fixed left-0 right-0 top-0 h-screen w-screen z-40 backdrop-filter backdrop-blur-sm grid place-items-center">
          <div className="z-50">
            <ClickWrapper func={() => setShowAuthModal(false)}>
              <AuthModal close={() => setShowAuthModal(false)} />
            </ClickWrapper>
          </div>
        </div>
      )}
    </div>
  )
}

export default UnauthenticatedHeader
