import React from 'react'
import { Avatar } from '@material-ui/core'
import { BsArrowRightShort } from 'react-icons/bs'
import { useHistory } from 'react-router-dom'
import { includeComma } from '../../../services/globalFunctions'

const ContestCard = ({ contestData }) => {
  const {
    contest_id,
    name,
    contest_name,
    end_date,
    profit,
    percentage,
    user_id,
    start_date,
    status
  } = contestData
  const history = useHistory()

  const total_duration = new Date(end_date).getTime() - new Date(start_date).getTime()
  const elapsed_time = Date.now() - new Date(start_date).getTime()
  const contest_duration = (elapsed_time / total_duration) * 100

  return (
    <div
      id="single_contest_card"
      className="bg-purple-darker flex flex-col gap-4 relative p-4 rounded-lg overflow-hidden ">
      <div
        style={{ width: `${contest_duration}%` }}
        className={`absolute top-0 left-0 right-0 bg-${
          contest_duration < 0 ? `red` : `green`
        }-400 h-1 rounded-full`}></div>
      {status.toLowerCase() !== 'inactive' && (
        <button
          onClick={() => history.push(`contest/${contest_name}/${contest_id}`)}
          className="bg-white rounded-full p-2 absolute bottom-4 right-4 text-black h-8 w-8 grid place-items-center text-lg font-bold ">
          <BsArrowRightShort />
        </button>
      )}

      <p className="text-xs flex items-center capitalize absolute top-2 right-2 text-right gap-2 ">
        <span
          className={`rounded-full  p-1  bg-${contest_duration < 0 ? `red` : `green`}-500`}></span>
        {status.toLowerCase()}
      </p>
      <div className="flex gap-2 justify-between items-end mt-2">
        <div className="">
          <p className="text-sm text-green-500 mb-1">{contest_name}</p>
          <h2 className="text-lg font-600">{name}</h2>
        </div>
      </div>
      <div className="flex gap-2 text-xl font-600 mb-2">
        <div className="flex-1">
          <p className="text-sm text-gray-400 mb-1 font-400">P/L Realized</p>
          <h3>
            ${includeComma(profit.toFixed(2))}{' '}
            <span
              className={`text-${percentage >= 0 ? `green` : `red`}-500 text-xs font-semibold`}>
              ({percentage.toFixed(2)}%)
            </span>
          </h3>
        </div>
        <div className="flex-1">
          <p className="text-sm text-gray-400 mb-1 font-400">Close Date</p>
          <h3>{end_date.split(' ')[0].split('-').reverse().join('-')}</h3>
        </div>
      </div>
    </div>
  )
}

export default ContestCard
