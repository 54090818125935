import React, { useState } from 'react'
import ReactApexChart from 'react-apexcharts'

const BucketPieChart = ({ header, data }) => {
  const { labels, series } = data

  const pieSeries = series
  const options = {
    chart: {
      type: 'donut',
      foreColor: '#ffffff'
    },
    stroke: {
      width: 0
    },
    plotOptions: {
      pie: {
        startAngle: 0,
        endAngle: 360
      }
    },
    labels: labels,
    dataLabels: {
      enabled: true,
      textAnchor: 'middle'
    },
    fill: {
      type: 'gradient'
    },
    legend: {
      formatter: function (val, opts) {
        return val + ' - ' + opts.w.globals.series[opts.seriesIndex] + '%'
      },
      position: 'bottom'
    },
    title: {
      text: `Investment by ${header}`,
      align: 'center',
      style: {
        fontSize: '10px'
      }
    }
  }

  return (
    <div className="bg-purple-400 bg-opacity-10 h-full w-full p-6 lg:px-1 xl:p-10  rounded-xl ">
      <div id="chart" className="grid place-items-center text-white text-xs ext-center">
        <ReactApexChart
          options={options}
          series={pieSeries}
          type="pie"
          height={400}
          className="w-full h-full grid place-items-center"
        />
      </div>
    </div>
  )
}

export default BucketPieChart
