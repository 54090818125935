import axios from 'axios'
import React, { useState } from 'react'
import { useSelector } from 'react-redux'
import { useHistory } from 'react-router-dom'
import EndPoints from '../../../../services/api'
import { includeComma } from '../../../../services/globalFunctions'

const BuyTab = ({ tradingData, setTab, sendTransactionData }) => {
  const { coinname, price, symbol, logo, id, wallet, contestId, domain, platform } = tradingData
  const { user } = useSelector((state) => state.auth)
  const [userAmount, setUserAmount] = useState(parseFloat(wallet > price ? price : 0.99 * wallet))
  const [loadingTransaction, setLoadingTransaction] = useState(false)

  const [transactionData, setTransactionData] = useState({
    user_id: user.user_id,
    asset_type: 'Crypto',
    asset_id: id,
    coin_name: coinname,
    logo,
    symbol,
    sector: domain,
    ecosystem: platform,
    trans_type: 'BUY',
    quantity: parseFloat(userAmount / price),
    unit_price: price,
    platform_charges: parseFloat(0.01 * price),
    fee: 0,
    flag: 'NEW',
    contest_id: contestId,
    notes: ''
  })

  const { buyCoinTrade } = EndPoints.trade

  const makeTransaction = async (postData) => {
    setLoadingTransaction(true)
    const config = {
      headers: {
        Authorization: `Bearer ${user.token}`
      }
    }
    try {
      const { data } = await axios.post(
        `${buyCoinTrade}?user_id=${user.user_id}`,
        postData,
        config
      )
      //console.log(JSON.parse(data.body))
      await sendTransactionData(JSON.parse(data.body))
      setLoadingTransaction(false)
      setTab(1)
    } catch (err) {
      console.log(err.message)
    }
  }

  const handleBuyAsset = (e) => {
    e.preventDefault()
    //console.log(transactionData)
    makeTransaction(transactionData)
  }

  return (
    <div className="flex flex-col w-full text-left">
      <p className="text-xs my-2 font-400">Wallet : $ {includeComma(wallet)}</p>
      <div className="flex justify-between   bg-purple-darkest cursor-pointer border-2 border-purple-400 border-opacity-20 rounded-lg p-4 py-3 text-sm my-2">
        <p>
          {coinname} ({symbol})
        </p>
        <p>$ {includeComma(price)}</p>
      </div>

      <div className="flex justify-between items-center my-2 text-xs w-full  ">
        <label className="flex flex-col flex-1  font-600">
          <div className="mb-3">
            <p className="">Amount</p>
            {userAmount > wallet && (
              <p className="text-red-500 font-400 text-xs">Not enough Balance</p>
            )}
          </div>
          <input
            className="p-2 bg-transparent text-white font-600 placeholder-gray-500 border-2 border-purple-400 border-opacity-20 rounded-lg  "
            type="number"
            value={userAmount}
            onChange={(e) => {
              setTransactionData({
                ...transactionData,
                quantity: e.target.value
                  ? parseFloat((0.99 * parseFloat(e.target.value)) / price)
                  : 0,
                platform_charges: e.target.value
                  ? parseFloat(0.01 * parseFloat(e.target.value))
                  : 0
              })
              setUserAmount(e.target.value ? parseFloat(e.target.value) : 0)
            }}
            placeholder={price}
            min={0}
            max={wallet}
            step="0.01"
            style={{ width: '100%' }}
          />
        </label>
      </div>

      <div className="flex justify-between gap-2 my-2 text-gray-600 text-xs  placeholder-gray-400">
        <div className="flex flex-col flex-1  font-600">
          <p className="mb-3 text-white">Quantity</p>

          <div className="p-2 bg-white text-gray-500 font-600 placeholder-gray-100  rounded-lg ">
            <p>{transactionData.quantity.toFixed(3)}</p>
          </div>
        </div>
        <div className="flex flex-col flex-1  font-600">
          <p className="mb-3 text-white">Asset Amount</p>

          <div className="p-2 bg-white text-gray-500 font-600 placeholder-gray-100  rounded-lg ">
            <p>{(0.99 * userAmount).toFixed(3)}</p>
          </div>
        </div>
        <div className="flex flex-col flex-1  font-600">
          <p className="mb-3 text-white">Fee</p>

          <div className="p-2 bg-white text-gray-500 font-600 placeholder-gray-100  rounded-lg ">
            <p>{(0.01 * userAmount).toFixed(3)}</p>
          </div>
        </div>
        <div className="flex flex-col flex-1  font-600">
          <p className="mb-3 text-white">Total Spent</p>

          <div className="p-2 bg-white text-gray-500 font-600 placeholder-gray-100  rounded-lg ">
            <p>{userAmount.toFixed(3)}</p>
          </div>
        </div>
      </div>

      <div className="w-full my-2 text-xs">
        <textarea
          onChange={(e) => {
            setTransactionData({ ...transactionData, notes: e.target.value })
          }}
          value={transactionData.notes}
          className="w-full p-2 rounded-lg font-600 h-20 text-gray-500"
          placeholder="Notes"
        />
      </div>

      <button
        onClick={handleBuyAsset}
        className="w-full p-2 my-2 rounded-xl bg-red-dark hover:bg-red-600 transition-all duration-300">
        {loadingTransaction ? 'Processing ...' : 'Buy Crypto'}
      </button>
    </div>
  )
}

export default BuyTab
