export const holdingTableData = [
  {
    title: '#',
    toolText: "This is how it's done"
  },
  {
    title: 'Coin',
    toolText: "This is how it's done"
  },

  {
    title: 'Quantity',
    toolText: "This is how it's done"
  },
  {
    title: 'Coin Price',
    toolText: "This is how it's done"
  },
  {
    title: 'MKT Value',
    toolText: "This is how it's done"
  },
  {
    title: 'Holding P/L',
    toolText: "This is how it's done"
  },
  {
    title: '24Hr P/L',
    toolText: "This is how it's done"
  },
  {
    title: 'Trade',
    toolText: ''
  }
]
