import React, { useEffect, useState } from 'react'
import EndPoints from '../../../services/api'
import axios from '../../../services/axios'
import DataLoader from '../loader/DataLoader'
import uuid from 'react-uuid'
import { useHistory } from 'react-router-dom'

const TopRecommendationCard = ({ bg, title, data }) => {
  const { coin_id, potential, current_price, social_score, flag } = data

  const [staticData, setStaticData] = useState({})
  const [loading, setLoading] = useState(true)
  const { getStaticData } = EndPoints.recommendation
  const history = useHistory()
  const fetchSinglePortfolioRow = async () => {
    try {
      const { data } = await axios.get(`${getStaticData}?coinid=${coin_id}`)

      const singleWatchListData = JSON.parse(data.body)
      const { symbol, logo, coinname } = singleWatchListData[`${coin_id}`]

      setStaticData({
        symbol,
        logo,
        coinname
      })
      setLoading(false)
    } catch (err) {
      console.log(err.message)
    }
  }

  const handleClick = () => {
    if (loading || !staticData.coinname) return

    history.push(`/coin/${staticData.coinname}/${coin_id}`)
  }

  useEffect(() => {
    fetchSinglePortfolioRow()
  }, [])

  return (
    <div
      onClick={handleClick}
      className={`flex flex-col bg-${bg}  p-4 sm:p-6 rounded-xl cursor-pointer`}>
      <div className="flex justify-between items-start mb-2">
        <div>
          <p className="text-xs text-red-500">{title}</p>
          <h3 className="text-sm my-1 font-600">
            {staticData.coinname} ({staticData.symbol})
          </h3>
        </div>
        {loading ? (
          <DataLoader />
        ) : (
          <img
            className="w-4 h-4 sm:w-8 sm:h-8 object-contain ml-1"
            src={staticData.logo}
            alt={staticData.coinname}
          />
        )}
      </div>
      <div className="flex justify-between items-start">
        <div>
          <p className="text-gray-500 text-xs">Upside Potential</p>
          {flag === 'ON_FIRE' ? (
            <h4 className="text-sm my-1 font-600">Social Score : {social_score}</h4>
          ) : (
            <h4 className="text-sm my-1 font-600">${current_price}</h4>
          )}
        </div>

        <p className="text-xs text-green-500 ml-1">{potential}%</p>
      </div>
    </div>
  )
}

const TopRecommendation = ({ bg, data }) => {
  const flagConvert = (flag) => {
    switch (flag) {
      case 'TOP_RECOMMENDED':
        return 'Top Recommended'

      case 'NEW_COIN':
        return 'New Coin'

      case 'HIGH_POTENTIAL':
        return 'High Potential'

      case 'ON_FIRE':
        return 'On Fire'

      default:
        return ''
    }
  }
  return (
    <div className="grid grid-cols-2 lg:grid-cols-4 gap-4 w-full  ">
      {data.length > 0 &&
        data.map((singleReco) => (
          <TopRecommendationCard
            key={uuid()}
            bg={bg}
            title={flagConvert(singleReco.flag)}
            data={singleReco}
          />
        ))}
    </div>
  )
}

export default TopRecommendation
