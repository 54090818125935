import React from 'react'
import { useHistory } from 'react-router-dom'
import Crfscore from './Crfscore'
import { includeComma } from '../../../services/globalFunctions'
import uuid from 'react-uuid'

const SingleBucket = ({ data, id }) => {
  const history = useHistory()
  const {
    company_id,
    created_at,
    crf_score,
    long_desc,
    name,
    sector,
    short_desc,
    status,
    type,
    coins_detail,
    updated_at,
    return_1year,
    return_24hrs,
    return_30days,
    user_id
  } = data
  return (
    <div
      id="single_bucket_card"
      onClick={() => history.push(`/bucket/${name}/${id}`)}
      className="flex flex-col p-2 py-4 md:p-6 pb-2 bg-purple-400 bg-opacity-10 w-full rounded-xl my-3 sm:my-4 cursor-pointer">
      <div className="hidden lg:flex justify-between  gap-3">
        <div className="flex-1  lg:w-1/5 flex flex-col gap-3 ">
          <h3 className="text-lg font-600 flex gap-3 items-center">
            {name}{' '}
            <span
              className={`p-1 rounded-full text-xs bg-${
                status.toLowerCase() === 'active' ? 'green' : 'red'
              }-500`}></span>
          </h3>

          <p className="text-xs max-w-xs">{short_desc}</p>
        </div>
        <div className="flex-1 flex flex-col gap-3 fle  ">
          <div className="flex justify-between items-center gap-3">
            <div className="flex flex-col items-center gap-2">
              <h5 className="text-xs">Type</h5>
              <p>{type}</p>
            </div>
            <div className="flex flex-col items-center gap-2">
              <h5 className="text-xs">Sector</h5>
              <p>{sector}</p>
            </div>
            <div className="flex flex-col items-center gap-2">
              <h5 className="text-xs">CRF Score</h5>
              <p>{crf_score}</p>
            </div>
          </div>
        </div>
        <div className="flex-1  lg:w-1/5 flex  justify-end items-end text-right gap-3 text-xs">
          <div className="flex flex-col gap-2">
            <p>1 day Return</p>
            <p
              className={`p-2 px-3 rounded-md bg-${
                return_24hrs >= 0 ? 'green' : 'red'
              }-500 text-sm font-600 grid place-items-center `}>
              {return_24hrs.toFixed(2)} %
            </p>
          </div>
          <div className="flex flex-col gap-2">
            <p>1 month Return</p>
            <p
              className={`p-2 px-3 rounded-md bg-${
                return_30days >= 0 ? 'green' : 'red'
              }-500 text-sm font-600 grid place-items-center `}>
              {return_30days.toFixed(2)} %
            </p>
          </div>
          <div className="flex flex-col gap-2">
            <p>1 year Return</p>
            <p
              className={`p-2 px-3 rounded-md bg-${
                return_1year >= 0 ? 'green' : 'red'
              }-500 text-sm font-600 grid place-items-center `}>
              {return_1year.toFixed(2)} %
            </p>
          </div>
        </div>
      </div>

      <div className="flex flex-col lg:hidden justify-between  gap-3">
        <div className="flex gap-4">
          <div className="flex-1  w-1/2 flex flex-col gap-3 ">
            <h3 className="text-lg font-600 flex gap-2 items-center">
              {name}{' '}
              <span
                className={`p-1 rounded-full text-xs bg-${
                  status.toLowerCase() === 'active' ? 'green' : 'red'
                }-500`}></span>
            </h3>

            <p className="text-xs w-32 sm:w-40 md:w-full truncate">{short_desc}</p>
          </div>
          <div className="flex-1  w-1/2 flex  justify-end items-end text-right gap-3 text-xs">
            <div className="flex flex-col gap-2">
              <p>1 day Return</p>
              <p
                className={`p-2  rounded-md bg-${
                  return_24hrs >= 0 ? 'green' : 'red'
                }-500 text-sm font-600 grid place-items-center `}>
                {return_24hrs.toFixed(2)}%
              </p>
            </div>
            <div className="flex flex-col gap-2">
              <p>1 month Return</p>
              <p
                className={`p-2  rounded-md bg-${
                  return_30days >= 0 ? 'green' : 'red'
                }-500 text-sm font-600 grid place-items-center `}>
                {return_30days.toFixed(2)}%
              </p>
            </div>
            <div className="flex flex-col gap-2">
              <p>1 year Return</p>
              <p
                className={`p-2  rounded-md bg-${
                  return_1year >= 0 ? 'green' : 'red'
                }-500 text-sm font-600 grid place-items-center `}>
                {return_1year.toFixed(2)}%
              </p>
            </div>
          </div>
        </div>

        <div className="flex-1 flex flex-col gap-3 pt-3">
          <div className="flex justify-between items-center gap-3">
            <div className="flex flex-col items-center gap-2">
              <h5 className="text-xs">Type</h5>
              <p>{type}</p>
            </div>
            <div className="flex flex-col items-center gap-2">
              <h5 className="text-xs">Sector</h5>
              <p>{sector}</p>
            </div>
            <div className="flex flex-col items-center gap-2">
              <h5 className="text-xs">CRF Score</h5>
              <p>{crf_score}</p>
            </div>
          </div>
        </div>
      </div>

      <div className="hidden md:flex gap-3 justify-center items-center p-3 py-4">
        {Object.values(coins_detail)
          .slice(0, 6)
          .map((coin) => (
            <div key={uuid()} className="flex gap-1 items-center">
              <img className="w-6 h-6 object-contain" src={coin?.logo} alt={coin?.symbol} />
              <p className="text-xs">{coin?.allocation.toFixed(2)} % </p>
            </div>
          ))}
        {Object.keys(coins_detail).length > 6 && (
          <p className="text-xs font-600">+{Object.keys(coins_detail).length - 6} more</p>
        )}
      </div>
      <div className="flex md:hidden gap-3 justify-center items-center p-3 py-6">
        {Object.values(coins_detail)
          .slice(0, 4)
          .map((coin) => (
            <div key={uuid()} className="flex gap-1 items-center">
              <img className="w-4 h-4 object-contain" src={coin?.logo} alt={coin?.symbol} />
              <p className="text-xs">{coin?.allocation.toFixed(2)} % </p>
            </div>
          ))}
        {Object.keys(coins_detail).length > 4 && (
          <p className="text-xs font-600">+{Object.keys(coins_detail).length - 4} more</p>
        )}
      </div>
    </div>
  )
}

export default SingleBucket
