import React from 'react'
import { includeComma } from '../../../services/globalFunctions'

const SingleBotRecommendationTable = (props) => {
  const { Close_price, executiondate, macd_action, rsiaction, bollingeraction } = props

  const checkRecommendationColor = (recommendation) => {
    switch (recommendation.toUpperCase()) {
      case 'BUY':
        return 'green'
      case 'SELL':
        return 'red'
      case 'HOLD':
        return 'yellow'
      default:
        return 'white'
    }
  }

  return (
    <tr className="text-xs xl:text-sm font-600   bg-purple-700 bg-opacity-10  ">
      <td className="text-center">
        <p>{executiondate.split(' ')[0].split('-').reverse().join('/')}</p>
      </td>
      <td
        className={`text-center text-${
          bollingeraction && checkRecommendationColor(bollingeraction)
        }-400 `}>
        <p> {bollingeraction ? bollingeraction : '-'}</p>
      </td>
      <td className={`text-center text-${rsiaction && checkRecommendationColor(rsiaction)}-400 `}>
        <p> {rsiaction ? rsiaction : '-'}</p>
      </td>
      <td
        className={`text-center text-${
          macd_action && checkRecommendationColor(macd_action)
        }-400 `}>
        <p> {macd_action ? macd_action : '-'}</p>
      </td>
      <td className="text-center">
        <p>$ {includeComma(Close_price)}</p>
      </td>
    </tr>
  )
}

export default SingleBotRecommendationTable
