import React, { useState, useEffect, useRef } from 'react'
import { useHistory } from 'react-router-dom'
import { AiFillStar, AiOutlineStar } from 'react-icons/ai'
import { includeComma } from '../../../services/globalFunctions'
import ClickWrapper from '../click-wrapper/ClickWrapper'
import TradingTransactionModal from './modal/TradingTransactionModal'

const SingleResponsiveTable = ({ singleCoinData, coinid, contestId, contestName, wallet }) => {
  const {
    Algo_used,
    Domain: domain,
    ExchangesCount,
    Funds_Raised,
    PercentChange7d: change7d,
    PercentChange24h: change24h,
    PercentChange30d,
    Product_Live,
    Sale_type,
    Whitepaper,
    activity,
    category,
    coinname,
    country,
    exchanges,
    id,
    language,
    lastmonthcommits,
    launched,
    marketcap,
    maxsupply,
    newsRating,
    owner,
    platform,
    price,
    repo,
    score,
    symbol,
    timestamp,
    timestamp7d,
    timestamp24h,
    timestamp30d,
    tosymbol,
    total_public_repos,
    tweetsRating,
    type,
    urlOfFlag,
    urlOfLogo: logo,
    website
  } = singleCoinData
  const [changedPrice, setChangedprice] = useState(price.toFixed(2))
  const [changed24h, setChanged24h] = useState(change24h)
  const [changed7d, setChanged7d] = useState(change7d)
  const [addToWatchlist, setAddToWatchlist] = useState(false)
  const [showTransactionModal, setShowTransactionModal] = useState(false)
  const labelRef = useRef()
  const fakePriceChange = (random) => {
    if (random >= price) {
      return
    }

    if (Math.floor(random * 3) % 2 === 0) {
      setChangedprice((price + random / 10).toFixed(2))
    }

    setChangedprice((price - random / 10).toFixed(2))
  }

  const fake24hChange = (random) => {
    if (change24h !== null) {
      if ((change24h > 0 && random >= change24h) || change24h - random / 10 < -100) {
        return
      }

      if (Math.floor(random * 3) % 2 === 0) {
        setChanged24h((change24h + random / 10).toFixed(2))
      }

      setChanged24h((change24h - random / 10).toFixed(2))
    }
  }

  const fake7dChange = (random) => {
    if (change7d !== null) {
      if ((change7d > 0 && random >= change7d) || change7d - random / 10 < -100) {
        return
      }

      if (Math.floor(random * 3) % 2 === 0) {
        setChanged7d((change7d + random / 10).toFixed(2))
      }

      setChanged7d((change7d - random / 10).toFixed(2))
    }
  }

  useEffect(() => {
    const changeInterval = setInterval(() => {
      const random = parseFloat(Math.random().toFixed(2))

      fakePriceChange(random)
      fake24hChange(random)
      fake7dChange(random)
    }, 3000)

    return () => {
      clearInterval(changeInterval)
    }
  }, [])

  return (
    <div className="bg-purple-400 bg-opacity-10  rounded-2xl  p-3 sm:px-4 sm:py-6 my-3 cursor-pointer relative">
      <div className="flex justify-between ">
        <div className="flex ">
          <div className=" h-8 w-8 self-start mt-2">
            <img className="w-full h-full object-cover" src={logo} alt={coinname} />
          </div>

          <div className="flex flex-col ml-2">
            <h4 className="text-lg font-600 mb-1">{coinname}</h4>
            <p className="text-xs">{symbol}</p>
          </div>

          {/* <label ref={labelRef} className="text-yellow-500 text-lg mx-3 mt-1 p-1">
            <input
              className="hidden"
              value={addToWatchlist}
              onChange={(e) => setAddToWatchlist(e.target.checked)}
              type="checkbox"
            />
            {addToWatchlist ? <AiFillStar /> : <AiOutlineStar />}
          </label> */}
        </div>

        <div className="flex flex-col items-center py-1 relative">
          <p className="text-xl font-600 mb-2">$ {includeComma(changedPrice)}</p>

          <button
            className="text-xs px-3 py-2 border-2  transition-all duration-200 border-purple-400 border-opacity-30 cursor-pointer rounded-full transform scale-90"
            onClick={() => setShowTransactionModal(true)}>
            Buy
          </button>
          {showTransactionModal && (
            <div className="fixed left-0 right-0 top-0 h-screen w-full z-10 backdrop-filter backdrop-blur-sm grid place-items-center">
              <div className="z-50">
                <ClickWrapper func={() => setShowTransactionModal(false)}>
                  <TradingTransactionModal
                    close={() => setShowTransactionModal(false)}
                    tradingData={{
                      coinname,
                      price,
                      symbol,
                      logo,
                      id,
                      domain,
                      platform,
                      category,
                      contestId,
                      wallet,
                      contestName
                    }}
                  />
                </ClickWrapper>
              </div>
            </div>
          )}
        </div>
      </div>

      <div className="text-sm flex justify-between items-center my-6">
        <div className=" flex flex-col items-center">
          <p className="font-500">MKTCAP</p>
          <p>$ {includeComma((marketcap / 1000000000).toFixed(2))} B</p>
        </div>

        <div className="flex flex-col items-center">
          <p className="font-500">7 D</p>
          <p
            className={`flex items-center text-sm font-600 ${
              changed7d >= 0 ? `text-green-400` : `text-red-400`
            }`}>
            {changed7d >= 0 ? `+ ${changed7d}` : ` ${changed7d}`} %{' '}
            <span className="ml-2 text-lg font-700">{changed7d >= 0 ? `↑` : `↓`}</span>
          </p>
        </div>
        <div className="flex flex-col items-center">
          <p className="font-500">24 H</p>
          <p
            className={`flex items-center text-sm font-600 ${
              changed24h >= 0 ? `text-green-400` : `text-red-400`
            } `}>
            {changed24h >= 0 ? `+ ${changed24h}` : ` ${changed24h}`} %{' '}
            <span className="ml-2 text-lg font-700">{changed24h >= 0 ? `↑` : `↓`}</span>
          </p>
        </div>
      </div>
      <div className="flex justify-between items-center text-xs">
        <div className="mr-4 text-center">
          <p>
            Domain : <br></br> {domain}
          </p>
        </div>
        <div className="text-center">
          <p>
            Rating : <br></br> {score.toFixed(2)}
          </p>
        </div>

        <div className="ml-4 text-center">
          <p>
            Social Score : <br></br> {(tweetsRating + newsRating).toFixed(2) / 2}
          </p>
        </div>
      </div>
    </div>
  )
}

export default SingleResponsiveTable
