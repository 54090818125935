import React, { useState } from 'react'
import { Fragment } from 'react'
import { useSelector } from 'react-redux'
import uuid from 'react-uuid'
import EndPoints from '../../../services/api'
import axiosInstance from '../../../services/axios'
import Loader from '../loader/Loader'
import { BotRecommendationData } from './data'
import SingleBotRecommendationTable from './SingleBotRecommendationTable'

const durationArr = [
  {
    title: '1 Week',
    value: '7'
  },
  {
    title: '1 Month',
    value: '30'
  },
  {
    title: '1 Year',
    value: '365'
  }
]

const FilterArr = [
  {
    title: 'All',
    value: 'ALL'
  },
  {
    title: 'Buy',
    value: 'BUY'
  },
  {
    title: 'Sell',
    value: 'SELL'
  },
  {
    title: 'Buy / Sell',
    value: 'BUYSELL'
  }
]

const SingleCoinBotRecommendation = ({ coinid, data, setShowAuthModal }) => {
  //console.log(coinid, data)
  const { getSingleCoinBotRecommendation } = EndPoints.singleCoin
  const [loading, setLoading] = useState(false)
  const [botRecommendation, setBotRecommendation] = useState(data)
  const [duration, setDuration] = useState(durationArr[0].value)
  const [filter, setFilter] = useState(FilterArr[0].value)
  const { loggedIn } = useSelector((state) => state.auth)

  const fetchBotRecommendation = async (filter, limit) => {
    setLoading(true)
    try {
      const { data } = await axiosInstance.get(
        `${getSingleCoinBotRecommendation}/coinwise?coin_id=${coinid}&limit=${limit}&filter=${filter}`
      )
      //console.log(JSON.parse(data.body).message)
      setBotRecommendation(JSON.parse(data.body).message)
      setLoading(false)
    } catch (err) {
      console.log(err.message)
    }
  }

  const handleDurationChange = (e) => {
    if (e.target.value === '365') {
      if (!loggedIn) {
        setShowAuthModal(true)
        return
      }
    }
    setDuration(e.target.value)
    fetchBotRecommendation(filter, e.target.value)
  }
  const handleFilterChange = (e) => {
    setFilter(e.target.value)
    fetchBotRecommendation(e.target.value, duration)
  }

  return (
    <div className="p-4 sm:p-6 md:p-10 py-6 md:py-10 text-xs lg:text-sm ">
      {loading ? (
        <Loader />
      ) : (
        <Fragment>
          <div className="flex justify-end items-center gap-4 mb-8 sm:mb-4 text-xs">
            <div className="p-2 px-3 grid place-items-center border border-gray-300 border-opacity-40 rounded-md">
              <select
                value={filter}
                onChange={handleFilterChange}
                className="bg-transparent cursor-pointer">
                {FilterArr.map((filter) => (
                  <option className="bg-purple-darker" key={uuid()} value={filter.value}>
                    {filter.title}
                  </option>
                ))}
              </select>
            </div>
            <div className="p-2 px-3 grid place-items-center border border-gray-300 border-opacity-40 rounded-md">
              <select
                value={duration}
                onChange={handleDurationChange}
                className="bg-transparent cursor-pointer">
                {durationArr.map((duration) => (
                  <option className="bg-purple-darker " key={uuid()} value={duration.value}>
                    {duration.title}
                  </option>
                ))}
              </select>
            </div>
          </div>
          <div className="bg-purple-700 bg-opacity-10 rounded-t-2xl  text-center relative p-4">
            <h1 className="absolute bottom-1/2 left-1/2 transform -translate-x-1/2 bg-teal-dark p-2 px-4">
              Bot Recommendation
            </h1>
          </div>
          <table className="p-4 w-full rounded-b-2xl overflow-hidden ">
            <tbody className="text-xs xl:text-sm">
              <tr className="bg-purple-400 bg-opacity-20 p-2">
                {BotRecommendationData.map((head) => (
                  <th key={uuid()} className="text-xs md:text-sm text-center p-2 cursor-pointer ">
                    {head.title}{' '}
                  </th>
                ))}
              </tr>
              {botRecommendation.map((singleData) => (
                <SingleBotRecommendationTable key={uuid()} {...singleData} />
              ))}
              {data.length === 0 && (
                <p className="text-center w-full font-600 py-4 my-4 text-gray-300">
                  No Recommendation for now .
                </p>
              )}
            </tbody>
          </table>
        </Fragment>
      )}
    </div>
  )
}

export default SingleCoinBotRecommendation
