import React from 'react'
import { includeComma } from '../../../services/globalFunctions'

const KeyMetrics = ({ data }) => {
  const { change24hr, marketcap, maxsupply, price, realVol24hr, supply } = data

  return (
    <div className="flex-1 flex flex-col justify-between bg-purple-400 bg-opacity-10 m-2 sm:m-4 rounded-2xl p-4 sm:py-6 sm:px-8 text-xs sm:text-sm md:text-xs lg:text-sm  ">
      <div className="flex justify-between items-center mb-10">
        <div className="bg-red-dark rounded-full p-2 text-xs">
          <p className="w-full px-2">Key Metrics</p>
        </div>

        <div className="flex-1 border-b-2 border-gray-500 border-opacity-50 mx-2"></div>

        <p>Share</p>
      </div>

      <div className="w-full">
        <div className="flex justify-between border-b-2 border-gray-500 border-opacity-50 pb-1 pt-4 ">
          <p>Price</p>
          <p>${includeComma(price.toFixed(2))}</p>
        </div>

        <div className="flex justify-between border-b-2 border-gray-500 border-opacity-50 pb-1 pt-4 ">
          <p>24hr Change</p>
          <p className={`font-500 text-${change24hr >= 0 ? 'green-500' : 'red-500'}`}>
            {change24hr > 0 ? `+${change24hr}` : change24hr}%
          </p>
        </div>
        <div className="flex justify-between border-b-2 border-gray-500 border-opacity-50 pb-1 pt-4 ">
          <p>24hr Real Vol</p>
          <p>${includeComma(Math.floor(realVol24hr))}</p>
        </div>
        <div className="flex justify-between border-b-2 border-gray-500 border-opacity-50 pb-1 pt-4 ">
          <p>Marketcap</p>
          <p>${includeComma(marketcap)} </p>
        </div>
        <div className="flex justify-between border-b-2 border-gray-500 border-opacity-50 pb-1 pt-4 ">
          <p>Liquid Supply</p>
          <p>{includeComma(supply)}</p>
        </div>
        <div className="flex justify-between border-b-2 border-gray-500 border-opacity-50 pb-1 pt-4 ">
          <p>Max Supply</p>
          <p>{maxsupply === '-1' ? 'unlimited' : includeComma(maxsupply)}</p>
        </div>
      </div>
    </div>
  )
}

export default KeyMetrics
