import React, { useEffect, useState } from 'react'
import EndPoints from '../../../services/api'
import axios from '../../../services/axios'
import { includeComma } from '../../../services/globalFunctions'
import DataLoader from '../loader/DataLoader'

const SingleBucketTable = ({ data }) => {
  const {
    CRFBlock_no,
    allocation,
    assetid,
    coin_id,
    coin_name,
    createPrice,
    created_at,
    details,
    market_cap,
    sectors
  } = data
  const [staticData, setStaticData] = useState({})
  const [loading, setLoading] = useState(false)
  const { getStaticData } = EndPoints.watchList

  const fetchStaticData = async (assetId) => {
    try {
      const { data } = await axios.get(`${getStaticData}?coinid=${assetId}`)
      const { symbol, logo } = JSON.parse(data.body)[`${assetId}`]

      setStaticData({
        symbol,
        logo
      })
      setLoading(false)
    } catch (err) {
      console.log(err.message)
    }
  }

  useEffect(() => {
    fetchStaticData(coin_id)
  }, [])

  return (
    <tr className="text-xs xl:text-sm font-600  bg-purple-700 bg-opacity-10 ">
      <td className="grid place-items-center h-full w-full">
        {loading ? (
          <DataLoader />
        ) : (
          <img className="w-8 h-8 object-contain" src={staticData.logo} alt={coin_name} />
        )}
      </td>
      <td className="text-center">
        {loading ? (
          <DataLoader />
        ) : (
          <p className="">
            {coin_name} - {staticData.symbol}
          </p>
        )}
      </td>
      <td className="text-center">
        <p>{includeComma((market_cap / 1000000000).toFixed(2))} B </p>
      </td>
      <td className="text-center">
        <p>{sectors}</p>
      </td>
      <td className="text-center">
        <p>{allocation}</p>
      </td>
    </tr>
  )
}

export default SingleBucketTable
