import {
  FaTwitter,
  FaTelegramPlane,
  FaFacebookSquare,
  FaYoutube,
  FaGoogle,
  FaInstagram,
  FaLinkedin
} from 'react-icons/fa'

export const footerData = [
  {
    title: 'Twitter',
    link: '',
    component: <FaTwitter />
  },
  {
    title: 'Telegram',
    link: '',
    component: <FaTelegramPlane />
  },
  {
    title: 'Facebook',
    link: '',
    component: <FaFacebookSquare />
  },
  {
    title: 'Youtube',
    link: 'https://www.youtube.com/channel/UCA1ssebuPrEV8fo55ylJSwA',
    component: <FaYoutube />
  },
  //   {
  //     title: 'Google',
  //     link: '',
  //     component: <FaGoogle />
  //   },
  {
    title: 'Instagram',
    link: 'https://www.instagram.com/crypto_research_fund/',
    component: <FaInstagram />
  },
  {
    title: 'Linkedin',
    link: 'https://www.linkedin.com/company/crypto-research-fund',
    component: <FaLinkedin />
  }
]
