import React from 'react'
import { splashData } from './data'
import SplashLogo from './SplashLogo'
import landingLogo from '../logo/assets/img/crypto1.png'

const CrfFacts = () => {
  const random = Math.floor(Math.random() * 5)

  return (
    <div className="w-screen h-screen flex justify-center items-center bg-purple-darkest p-4 ">
      <div className="max-w-5xl m-auto text-white flex flex-col justify-center items-center">
        <div className="animate-bounce text-center">
          <SplashLogo />
        </div>

        <img className="w-56 h-auto object-contain my-8 mt-4" src={landingLogo} alt="CRF" />

        <h3>Do You Know ?</h3>
        <h1 className="my-10 text-xl sm:text-2xl md:text-4xl text-center text-blue-400 leading-8">
          {splashData[random]}
        </h1>
      </div>
    </div>
  )
}

export default CrfFacts
