import React from 'react'
import phoneLogo from '../../apps/static-portfolio/assets/img/phone.jpeg'
import comingSoon from '../../apps/static-portfolio/assets/img/comingsoon.png'
import MiniGraph from '../../apps/static-portfolio/assets/img/mini-graph.jpeg'
import { featuresData } from '../../apps/static-portfolio/staticData'
import uuid from 'react-uuid'
import { Link } from 'react-router-dom'
import { NEWS_PAGE } from '../../../navigation/Routes'
import StaticPortfolioHeader from '../../apps/static-portfolio/StaticPortfolioHeader'
import StaticPortfolioDonut from '../../apps/static-portfolio/StaticPortfolioDonut'
import { Helmet } from 'react-helmet'

const StaticPortfolioPage = () => {
  return (
    <div className="bg-purple-darkest text-white px-4 sm:px-6 md:px-10 relative ">
      <Helmet>
        <meta charSet="utf-8" />
        <title>Invest in Crypto Portfolio and CRR Index | CryptoResearchfund</title>
      </Helmet>
      <div className="absolute top-0 left-0 z-30 w-32 h-32 ">
        <img className="w-full h-full object-contain" src={comingSoon} alt="coming soon" />
      </div>
      <div className="bg-purple-darkest w-full  max-w-7xl m-auto ">
        <StaticPortfolioHeader />
      </div>
      <div className="flex flex-col-reverse lg:flex-row justify-evenly p-4 lg:py-20  ">
        <div className="max-w-sm md:max-w-md lg:max-w-xl m-auto lg:m-0">
          <img className="w-full object-contain lg:mb-0" src={phoneLogo} alt="crypto" />
        </div>
        <div className="flex-1 flex flex-col lg:max-w-lg mt-4 md:mt-0 md:p-4 lg:pt-20 lg:pr-10 ">
          <h1 className="text-blue-500 text-xl sm:text-2xl lg:text-3xl font-500 mb-4 lg:mb-8 leading-snug lg:leading-relaxed">
            CryptoReseachFund will help you find institutional grade actionable ideas driven by
            data science and Advance AI that are customized especially Individuals.
          </h1>

          <h5 className=" mt-4 lg:mt-8 text-lg font-500 leading-relaxed ">
            It is one thing to have a good investment idea, but quite another to extract the
            maximum potential from that idea and combine it with other investment to create an
            optimal portfolio.
          </h5>
        </div>
      </div>

      <div className="flex flex-col p-4 md:p-6 lg:p-10  max-w-7xl m-auto">
        <div className="flex flex-col lg:flex-row items-center lg:items-start justify-around py-10">
          <div className="flex flex-col  lg:max-w-lg ">
            <h1 className="text-3xl mb-10 lg:leading-loose font-500">
              Cryptos defines the risk and potential rewards.
              <span className="text-blue-500">rewards</span>{' '}
            </h1>

            <h3 className="text-sm lg:text-lg lg:leading-loose ">
              We’ve built a set of investment portfolios designed to illustrate how you could
              distribute your money across several types of investments and themes depending on how
              much risk you are willing to take on and how much growth you hope to attain. We want
              to help you make more informed decisions about investing in crypto by performing
              Market Data Analysis and Market Risk Prediction.
            </h3>
          </div>

          <div className="grid grid-cols-3 gap-4 w-full px-2 lg:px-10 my-10 lg:my-0 max-w-2xl ">
            {featuresData.map((data) => (
              <button
                className="p-3 border rounded-xl border-blue-500 w-full text-xs sm:text-sm hover:bg-blue-500 transition-all duration-300"
                key={uuid()}>
                {data}
              </button>
            ))}
          </div>
        </div>

        <div>
          <h4 className="text-lg leading-loose font-600 md:mt-10">
            Each crypto portfolio carries a level of risk—the riskier the asset,{' '}
            <span className="text-blue-500">
              the higher the potential return - and the greater the potential loss.
            </span>{' '}
            Our deep technical knowledge and advanced machine learning models help us to build the
            right portfolio for you.
          </h4>
        </div>
      </div>
      <div className="grid gap-4 grid-cols-1 md:grid-cols-3 max-w-8xl m-auto py-10 px-4 sm:px-6 md:px-10 lg:px-20">
        <StaticPortfolioDonut
          optionData={['Low Risk', 'Medium Risk', 'High Risk']}
          seriesData={[25, 60, 15]}
          text={'Investment by Risk'}
        />
        <StaticPortfolioDonut
          optionData={['Cryptocurrency', 'Web 3.0', 'Defi']}
          seriesData={[30, 40, 30]}
          text={'Investment by Sector'}
        />
      </div>

      <div className=" px-2 lg:px-10 lg:py-14">
        <div
          className={`bg-purple-400 bg-opacity-10 rounded-3xl flex flex-col justify-between p-4 md:p-6 px-2 sm:px-4 md:px-6 md:h-56 w-full max-w-7xl my-3  md:my-6 m-auto
				
			 `}>
          <div className="flex justify-between ">
            <div>
              <p className="text-xs md:text-sm">
                Name <span className="ml-2">Storage CRF</span>{' '}
              </p>
              <p className="text-xs">Description</p>
            </div>

            <div className="flex flex-col mb-4">
              <h1 className=" text-sm sm:text-lg font-600 md:mb-2 text-center">CRF BUCKET</h1>

              <div className="flex flex-col md:hidden text-sm  font-350 text-center">
                <p>Date</p>

                <h1 className="text-lg md:text-2xl lg:text-3xl font-700  tracking-wide text-gray-400">
                  12-06-21
                </h1>
              </div>
            </div>

            <div>
              <button className="bg-purple-dark px-4 py-2 rounded-full text-xs md:text-sm">
                Invest Now
              </button>
            </div>
          </div>
          <div className="flex justify-between font-300 text-xs sm:text-sm md:text-lg py-2">
            <div className="flex flex-col justify-between  font-350 text-center">
              <p>Invested Amount</p>

              <h1 className="text-xl md:text-3xl font-700 mt-2 tracking-wide">103,500</h1>
            </div>
            <div className="flex flex-col justify-between   font-350 text-center">
              <p>Minimum Amount</p>

              <h1 className="text-xl md:text-3xl text-green-500 font-700 mt-2 tracking-wide">
                143,650
              </h1>
            </div>
            <div className="flex flex-col  justify-between  font-350 text-center">
              <p>Profit/Loss</p>

              <h1 className="text-xl md:text-3xl text-green-500 font-700 mt-2 tracking-wide">
                40,150
              </h1>
            </div>
            <div className="flex flex-col justify-between   font-350 text-center">
              <p>Return Since</p>

              <h1 className="text-xl md:text-3xl font-700 mt-2 tracking-wide">0.154</h1>
            </div>
            <div className="hidden md:flex justify-between  flex-col  font-350 text-center">
              <p>Date</p>

              <h1 className="text-xl md:text-3xl font-700 mt-2 tracking-wide text-gray-400">
                12-06-21
              </h1>
            </div>
          </div>
        </div>
      </div>

      {/* <div className="my-10 p-4 md:p-6 flex flex-col items-center lg:items-start lg:flex-row justify-between max-w-7xl m-auto">
        <div className="flex flex-col justify-between px-4 pb-10  lg:max-w-md">
          <div>
            {' '}
            <h1 className="text-4xl leading-normal font-600 mb-4">
              Our Proven Process for Your Profit
            </h1>
            <p className="text-sm mb-4 leading-relaxed">
              We have a pleasure of working in this field for almost 20 years and we provide a very
              detailed study for your benefit and for your financial freedome through investing
              with us.
            </p>
          </div>

          <ul className="text-sm list-disc pl-4">
            <li className="my-2">We have a research time 24X7 guiding you virtually</li>
            <li className="my-2">We have a research time 24X7 guiding you virtually</li>
            <li className="my-2">We have a research time 24X7 guiding you virtually</li>
            <li className="my-2">We have a research time 24X7 guiding you virtually</li>
          </ul>
        </div>

        <div className="flex flex-col justify-between max-w-lg  ">
          <img className="w-full object-contain" src={MiniGraph} alt="" />

          <div className="flex mt-4">
            <button className="bg-pink-600 p-4 md:p-6 rounded-full my-2 mr-2 flex-1 font-600">
              <Link to={NEWS_PAGE}>INFLUENCERS</Link>
            </button>
            <button className="bg-pink-600 p-4 md:p-6 rounded-full my-2 ml-2 flex-1 font-600">
              <Link to={NEWS_PAGE}>NEWS</Link>
            </button>
          </div>
        </div>
      </div> */}

      {/* <div className="p-4 pb-10">
        <div className="max-w-7xl m-auto bg-blue-500 rounded-xl flex flex-col justify-around items-center p-6 h-52 md:h-64">
          <h1 className=" text-2xl text-center md:text-4xl font-600">
            Build Your Portfolio With Us
          </h1>
          <p className="text-xs sm:text-sm font-500 text-center my-1">
            Receive a custom report and discover exactly how to increase your profits in market
          </p>
          <button className="bg-white p-2 sm:p-4 md:p-6 px-6 text-black rounded-xl text-sm font-500 my-2">
            Portfolio Health Checkup
          </button>
        </div>
      </div> */}
    </div>
  )
}

export default StaticPortfolioPage
