import React from 'react'
import { makeStyles } from '@material-ui/core'
import clsx from 'clsx'
import SingleResponsiveTable from './SingleResponsiveTable'
import uuid from 'react-uuid'
import ListingPagination from '../listing/ListingPagination'

const useStyles = makeStyles((theme) => ({
  hiddenScrollBar: {
    '&::-webkit-scrollbar': {
      display: 'none'
    }
  }
}))

const ResponsiveTable = ({
  cryptoData,
  onPageChange,
  count,
  page,
  contestId,
  wallet,
  contestName
}) => {
  const classes = useStyles()
  return (
    <div className="lg:hidden">
      <div className={clsx('flex flex-col lg:hidden ')}>
        {cryptoData.map((data) => (
          <SingleResponsiveTable
            key={uuid()}
            coinid={data.id}
            singleCoinData={data}
            contestId={contestId}
            wallet={wallet}
            contestName={contestName}
          />
        ))}
      </div>
      <ListingPagination page={page} onPageChange={onPageChange} count={count} />
    </div>
  )
}

export default ResponsiveTable
