import React, { useState } from 'react'
import { Tabs, Tab, makeStyles } from '@material-ui/core'
import TweetComponent from './tweets/TweetComponent'
import ArticleComponent from './articles/ArticleComponent'
import NewsComponent from './news/NewsComponent'
import clsx from 'clsx'

const useStyles = makeStyles((theme) => ({
  hiddenScrollBar: {
    '&::-webkit-scrollbar': {
      display: 'none'
    }
  }
}))

const ResponsiveNews = () => {
  const classes = useStyles()
  const [selectedTab, setSelectedTab] = useState(0)

  function handleTabChange(event, value) {
    setSelectedTab(value)
  }
  return (
    <div
      className={clsx(
        classes.hiddenScrollBar,
        'w-full h-screen overflow-scroll  md:hidden bg-white text-black'
      )}>
      <Tabs
        value={selectedTab}
        onChange={handleTabChange}
        indicatorColor="primary"
        className="mb-2  p-2 text-sm font-800  flex ">
        <Tab className="mx-2 flex-1" label="Breaking News" />
        <Tab className="mx-2 flex-1" label="Tweets" />
        {/* <Tab className="mx-2 flex-1" label="News Articles" /> */}
      </Tabs>
      <div className="bg-purple-darkest w-full h-full">
        {selectedTab === 0 && <NewsComponent />}
        {selectedTab === 1 && <TweetComponent />}
        {/* {selectedTab === 2 && <ArticleComponent />} */}
      </div>
    </div>
  )
}

export default ResponsiveNews
