import React, { useState } from 'react'
import { useHistory, useLocation } from 'react-router'
import { includeComma } from '../../../services/globalFunctions'
import ClickWrapper from '../click-wrapper/ClickWrapper'
import PortfolioTransactionModal from './modal/PortfolioTransactionModal'

const SinglePortfolioCard = ({ data }) => {
  const { pathname } = useLocation()
  const history = useHistory()
  const [showTransactionModal, setShowTransactionModal] = useState(false)

  // const cardClick = () => {
  //   if (pathname === '/static-portfolio') {
  //     return
  //   }
  //   history.push('/portfolio/123')
  // }

  const {
    bought_date,
    bucket_description,
    bucket_id,
    bucket_name: name,
    id,
    invested_amount,
    percent,
    proft_loss,
    value
  } = data

  return (
    <div
      //   onClick={cardClick}
      className={`bg-purple-400 bg-opacity-10 rounded-3xl relative flex flex-col justify-between p-4 md:p-6 px-2 sm:px-4 md:px-6  w-full my-6 ${
        pathname === '/static-portfolio' ? 'max-w-7xl m-auto ' : 'cursor-pointer'
      } `}>
      {showTransactionModal && (
        <div className="fixed h-screen w-full left-0 top-0 flex justify-center pt-20 backdrop-filter backdrop-blur-sm z-10">
          <div className="z-50  ">
            <ClickWrapper func={() => setShowTransactionModal(false)}>
              <PortfolioTransactionModal
                bucketData={{ bucketDetails: data }}
                close={() => setShowTransactionModal(false)}
              />
            </ClickWrapper>
          </div>
        </div>
      )}
      <div className="hidden lg:flex justify-between  gap-3">
        <div className="flex-1  lg:w-1/5 flex flex-col gap-3 ">
          <h3 className="text-lg font-600 flex gap-3 items-center">{name} </h3>
        </div>
        <div className="flex-1 flex flex-col gap-3 fle  ">
          <div className="flex justify-between items-center gap-3">
            <div className="flex flex-col items-center gap-2">
              <h5 className="text-xs">Invested Amount</h5>
              <p>{includeComma(invested_amount)} $</p>
            </div>
            <div className="flex flex-col items-center gap-2">
              <h5 className="text-xs">Value</h5>
              <p>{includeComma(value)} $</p>
            </div>
            <div className="flex flex-col items-center gap-2">
              <h5 className="text-xs">Buy Date</h5>
              <p>{bought_date.split('-').reverse().join('-')}</p>
            </div>
          </div>
        </div>
        <div className="flex-1  lg:w-1/5 flex  justify-end  text-right gap-3 text-xs">
          <div className="flex flex-col text-center gap-3 ">
            <p>1 day Return</p>
            <p
              className={`p-2 px-3 rounded-md bg-${
                proft_loss >= 0 ? 'green' : 'red'
              }-500 text-sm font-600 grid place-items-center `}>
              {includeComma(proft_loss.toFixed(2))} $
            </p>
          </div>
          <div className="flex flex-col text-center gap-3 ">
            <p>1 month Return</p>
            <p
              className={`p-2 px-3 rounded-md bg-${
                percent >= 0 ? 'green' : 'red'
              }-500 text-sm font-600 grid place-items-center `}>
              {percent.toFixed(2)} %
            </p>
          </div>
        </div>
      </div>

      <div className="flex flex-col lg:hidden justify-between px-3 py-2  gap-3">
        <div className="flex-1  w-1/2 flex flex-col gap-3 ">
          <h3 className="text-lg font-600 flex gap-2 items-center">{name} </h3>
        </div>
        <div className="flex-1   flex justify-end  text-right gap-3 text-xs">
          <div className="flex flex-col text-center gap-2">
            <p>1 day Return</p>
            <p
              className={`p-2  rounded-md bg-${
                proft_loss >= 0 ? 'green' : 'red'
              }-500 text-sm font-600 grid place-items-center `}>
              {includeComma(proft_loss.toFixed(2))} $
            </p>
          </div>
          <div className="flex flex-col gap-2">
            <p>1 month Return</p>
            <p
              className={`p-2  rounded-md bg-${
                percent >= 0 ? 'green' : 'red'
              }-500 text-sm font-600 grid place-items-center `}>
              {percent.toFixed(2)}%
            </p>
          </div>
        </div>

        <div className="flex-1 flex flex-col gap-3 pt-3">
          <div className="flex justify-between items-center gap-3">
            <div className="flex flex-col items-center gap-2">
              <h5 className="text-xs">Invested Amount</h5>
              <p>{includeComma(invested_amount)} $</p>
            </div>
            <div className="flex flex-col items-center gap-2">
              <h5 className="text-xs">Value</h5>
              <p>{includeComma(value)} $</p>
            </div>
            <div className="flex flex-col items-center gap-2">
              <h5 className="text-xs">Buy Date</h5>
              <p>{bought_date.split('-').reverse().join('-')}</p>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default SinglePortfolioCard
