import React, { useState, useEffect } from 'react'
import ReactApexChart from 'react-apexcharts'
import { useSelector } from 'react-redux'
import uuid from 'react-uuid'
import axios from '../../../services/axios'
import { includeComma } from '../../../services/globalFunctions'
import ClickWrapper from '../click-wrapper/ClickWrapper'
import Loader from '../loader/Loader'

const btnArray = [
  {
    title: '7 D',
    value: '7'
  },
  {
    title: '1 M',
    value: '30'
  },
  {
    title: '1 Y',
    value: '365'
  }
]

const ResponsiveChart = ({ id, endpoint, data, setShowAuthModal }) => {
  const { category, Domain, launched, Platform, website, Algo_used } = data[6].message[`${id}`]
  const { price } = data[1][`${id}`]
  const { loggedIn } = useSelector((state) => state.auth)
  const series = [
    {
      name: 'price',
      data: []
    }
  ]

  const options = {
    chart: {
      type: 'area',
      foreColor: '#ffffff',
      toolbar: {
        show: false
      }
    },

    dataLabels: {
      enabled: false
    },
    stroke: {
      curve: 'smooth'
    },
    grid: {
      show: false
    },

    xaxis: {
      type: 'datetime',

      categories: []
    },
    yaxis: [
      {
        labels: {
          formatter: function (val) {
            return val > 1000 ? includeComma((val / 1000).toFixed(2)) + 'k' : val
          }
        }
      }
    ],
    tooltip: {
      x: {
        format: 'dd/MM/yy HH:mm'
      },
      theme: 'dark'
    }
  }

  const [duration, setDuration] = useState('week')
  const [loading, setLoading] = useState(true)
  const [chartSeries, setChartSeries] = useState(series)
  const [chartOptions, setChartOptions] = useState(options)
  const [showInfo, setShowInfo] = useState(false)
  const [changePer, setChangePer] = useState(data[0].keymetrics.change24hr)

  const fetchChartData = async () => {
    setLoading(true)
    try {
      const { data } = await axios.get(`${endpoint}?coinid=${id}&duration=${duration}&type=ALL`)

      const parsedData = JSON.parse(data.body)

      setChartSeries([
        {
          name: 'price',
          data: parsedData.prices
        }
      ])
      setChangePer(
        ((parsedData.prices[0] - parsedData.prices[parsedData.prices.length - 1]) /
          parsedData.prices[parsedData.prices.length - 1]) *
          100
      )
      setChartOptions({
        ...chartOptions,
        xaxis: {
          ...chartOptions.xaxis,

          categories: parsedData.timestamps
        }
      })
      setLoading(false)
    } catch (err) {
      console.log(err.message)
    }
  }

  useEffect(() => {
    fetchChartData()
  }, [duration])

  return (
    <div className="flex lg:hidden p-2">
      <div className=" bg-purple-400 bg-opacity-10 flex-1 mx-2 rounded-xl p-4 px-2 sm:px-4 relative ">
        <div className="flex flex-col sm:flex-row justify-between items-start">
          <div>
            <div className="flex gap-3 ">
              <p className="">Price Chart</p>
              <h1 className=" font-600 text-lg ">
                ${includeComma(price.toFixed(2))}
                <sup
                  className={`mx-2 font-400 text-sm text-${changePer >= 0 ? 'green' : 'red'}-500`}>
                  {changePer.toFixed(2)}%
                </sup>
              </h1>
            </div>
            <button onClick={() => setShowInfo(true)} className="font-600 text-xs text-blue-400">
              Key Information
            </button>
          </div>

          <div className="flex mx-2 self-end ">
            {btnArray.map((btn) => (
              <button
                onClick={() => {
                  if (btn.value === '365') {
                    if (!loggedIn) {
                      setShowAuthModal(true)
                      return
                    }
                  }
                  setDuration(btn.value)
                }}
                className={`p-1 px-2 m-1 text-xs rounded-full ${
                  duration === btn.value ? 'bg-purple-dark' : 'bg-purple-400 bg-opacity-10'
                } `}
                key={uuid()}>
                {btn.title}
              </button>
            ))}
          </div>
        </div>
        {showInfo && (
          <div className="bg-purple-dark p-4 mx-2 rounded-xl capitalize text-xs flex flex-col justify-evenly absolute top-15 left-5 shadow-lg z-10">
            <ClickWrapper func={() => setShowInfo(false)}>
              <p className="text-center mb-3 ">
                <b>Key information</b>
              </p>
              <p className="my-3">
                <b>Domain</b> - {Domain}
              </p>
              <p className="my-3">
                <b>Category</b> - {category}
              </p>
              <p className="my-3">
                <b>Platform</b> - {Platform === null ? '' : Platform}
              </p>
              <p className="my-3 lowercase">
                <b>Website</b> -{' '}
                <a className="text-blue-600" href={website === null ? '' : website} target="blank">
                  {website === null ? '' : website}
                </a>
              </p>
              <p className="my-3">
                <b>Consensus Algorithm</b> - {Algo_used === null ? '' : Algo_used}
              </p>
              <p className="my-3">
                <b>Launched Date</b> - {launched === null ? '' : launched}
              </p>
            </ClickWrapper>
          </div>
        )}
        {loading ? (
          <Loader />
        ) : (
          <>
            <ReactApexChart options={chartOptions} series={chartSeries} type="area" height={250} />
          </>
        )}
      </div>
    </div>
  )
}

export default ResponsiveChart
