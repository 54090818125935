import React, { Fragment } from 'react'
import { useEffect } from 'react'
import { useState } from 'react'
import { ErrorBoundary } from 'react-error-boundary'
import { useSelector } from 'react-redux'
import uuid from 'react-uuid'
import EndPoints from '../../../services/api'
import axiosInstance from '../../../services/axios'
import BotRecommendationTable from '../../apps/bot-recommendation/BotRecommendationTable'
import BotResponsiveTable from '../../apps/bot-recommendation/BotResponsiveTable'
import ErrorComponent from '../../apps/error/ErrorComponent'
import Loader from '../../apps/loader/Loader'

const BotRecommendation = () => {
  const [loading, setLoading] = useState(true)
  const [loadingTable, setLoadingTable] = useState(false)
  const [recommendationData, setRecommendationData] = useState([])
  const [filteredRecommendationData, setFilteredRecommendationData] = useState({})
  const [allStrategy, setAllStrategy] = useState([])
  const [selectedStrategy, setSelectedStrategy] = useState({})
  const [duration, setDuration] = useState(7)
  const { getBotRecommendation } = EndPoints.botRecommendation
  const { user } = useSelector((state) => state.auth)
  const config = {
    headers: {
      Authorization: `Bearer ${user.token}`
    }
  }

  const fetchRecommendationData = async () => {
    try {
      const { data } = await axiosInstance.get(
        `${getBotRecommendation}/botrecommendation?limit=${duration}`,
        config
      )
      //console.log(JSON.parse(data.body.message))
      setAllStrategy(Object.values(JSON.parse(data.body.message)).map((value) => value.bot_name))
      const res = JSON.parse(data.body.message)
      setRecommendationData(res)
      //console.log(res[Object.keys(res)[0]])
      setFilteredRecommendationData(res[Object.keys(res)[0]])
      setSelectedStrategy(res[Object.keys(res)[0]].bot_name)

      // console.log(newData)
      setLoading(false)
    } catch (err) {
      console.log(err.message)
    }
  }

  const updateRecommendationData = async (limit) => {
    setLoadingTable(true)
    try {
      const { data } = await axiosInstance.get(
        `${getBotRecommendation}/botrecommendation?limit=${limit}`,
        config
      )
      //console.log(JSON.parse(data.body.message))
      // setAllStrategy(Object.values(JSON.parse(data.body.message)).map((value) => value.bot_name))
      const res = JSON.parse(data.body.message)
      setRecommendationData(res)
      setFilteredRecommendationData(
        Object.values(res).filter((value) => value.bot_name === selectedStrategy)[0]
      )
      setLoadingTable(false)
    } catch (err) {
      console.log(err.message)
    }
  }

  const handleStrategyChange = (e) => {
    setSelectedStrategy(e.target.value)
    const filteredData = Object.values(recommendationData).filter(
      (value) => value.bot_name === e.target.value
    )
    //console.log(filteredData[0])
    setFilteredRecommendationData(filteredData[0])
  }

  const handleDurationChange = (e) => {
    setDuration(e.target.value)
    updateRecommendationData(e.target.value)
  }

  useEffect(() => {
    fetchRecommendationData()
  }, [])

  return (
    <div className="p-4 min-h-screen sm:p-6 md:p-10 text-white ">
      <div className="py-6">
        {loading ? (
          <Loader />
        ) : (
          <div>
            <div className="flex justify-end items-center gap-4 mb-8 ">
              <div className="grid place-items-center p-3 py-2 border border-purple-400 border-opacity-20 rounded-md">
                <select
                  value={filteredRecommendationData.bot_name}
                  onChange={handleStrategyChange}
                  className="bg-transparent text-xs cursor-pointer">
                  {allStrategy.map((strategy) => (
                    <option key={uuid()} value={strategy} className="bg-purple-darker">
                      {strategy}
                    </option>
                  ))}
                </select>
              </div>
              <div className="grid place-items-center p-3 py-2 border border-purple-400 border-opacity-20 rounded-md">
                <select
                  onChange={handleDurationChange}
                  className="bg-transparent text-xs cursor-pointer">
                  <option value={7} className="bg-purple-darker">
                    7 Days
                  </option>
                  <option value={30} className="bg-purple-darker">
                    30 Days
                  </option>
                </select>
              </div>
            </div>
            {loadingTable ? (
              <Loader />
            ) : (
              <Fragment>
                {' '}
                <ErrorBoundary FallbackComponent={ErrorComponent}>
                  <BotRecommendationTable data={filteredRecommendationData} />
                </ErrorBoundary>
                <ErrorBoundary FallbackComponent={ErrorComponent}>
                  <BotResponsiveTable data={filteredRecommendationData} />
                </ErrorBoundary>
              </Fragment>
            )}
          </div>
        )}
      </div>
    </div>
  )
}

export default BotRecommendation
