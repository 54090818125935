import React, { Fragment, useState } from 'react'
import { ErrorBoundary } from 'react-error-boundary'
import { BsChevronDown, BsChevronUp } from 'react-icons/bs'
import { includeComma } from '../../../services/globalFunctions'
import ErrorComponent from '../error/ErrorComponent'
import PortfolioTable from './PortfolioTable'
import ResponsivePortfolioTable from './ResponsivePortfolioTable'

const SinglePortfolioSector = ({ sector: sectorData }) => {
  const { assets, invested_amount, percentage, profit_loss, sector, value } = sectorData
  const [openTable, setOpenTable] = useState(false)
  return (
    <div className=" w-full my-4 rounded-xl overflow-hidden text-sm">
      <div className="flex flex-col sm:flex-row gap-4 justify-between sm:items-center p-4   bg-purple-darker font-600">
        <div className="flex flex-col justify-between px-2 h-full gap-2 ">
          <h3 className="capitalize text-base md:text-xl ">{sector}</h3>
          <p className="text-xs">No of assets : {assets.length}</p>
        </div>

        <div className="flex gap-4 justify-between md:gap-10 items-center">
          <div className="flex flex-col items-center gap-2">
            <h3 className="text-xs md:text-sm">Value</h3>
            <h1 className="text-xs md:text-lg"> {includeComma(value.toFixed(2))} $ </h1>
          </div>
          <div className="flex flex-col items-center gap-2">
            <h3 className="text-xs md:text-sm">Invested</h3>
            <h1 className="text-xs md:text-lg"> {includeComma(invested_amount.toFixed(2))} $ </h1>
          </div>
          <div className="flex flex-col items-center gap-2">
            <h3 className="text-xs md:text-sm">Profit/Loss</h3>
            <h1 className={`text-xs md:text-lg text-${profit_loss >= 0 ? 'green' : 'red'}-500`}>
              {includeComma(profit_loss.toFixed(2))} $ ( {includeComma(percentage.toFixed(2))} % )
            </h1>
          </div>
          <button
            onClick={() => setOpenTable(!openTable)}
            className="p-2 font-600 text-base md:text-lg grid place-items-center rounded-full bg-purple-dark">
            {openTable ? <BsChevronUp /> : <BsChevronDown />}
          </button>
        </div>
      </div>
      {openTable && (
        <div className=" ">
          <ErrorBoundary FallbackComponent={ErrorComponent}>
            <PortfolioTable data={assets} trade={false} />
          </ErrorBoundary>
          <ErrorBoundary FallbackComponent={ErrorComponent}>
            <ResponsivePortfolioTable data={assets} trade={false} />
          </ErrorBoundary>
        </div>
      )}
    </div>
  )
}

export default SinglePortfolioSector
