import React from 'react'

const TermsPage = () => {
  return (
    <div className="min-h-screen text-white p-6 sm:p-8 md:p-10 text-sm   flex flex-col gap-6 leading-loose ">
      <p className="text-2xl font-semibold">CryptoResearchFund Terms and Services</p>
      <p> Last Updated: MAY 1, 2022</p>

      <p>
        Welcome to CRYPTORESEARCHFUND. These Terms of Use (&ldquo;Terms&rdquo;) are an agreement
        between you (&ldquo;you&rdquo;) and CRYPTORESEARCH, Inc. and its affiliates
        (&ldquo;Company,&rdquo; &ldquo;we,&rdquo; &ldquo;us,&rdquo; or &ldquo;our&rdquo;) that
        allows you to use our software, subscriptions, applications (&ldquo;apps&rdquo;), websites,
        and other products and services, as long as you follow the Terms.
      </p>

      <p>
        By accessing or using any of our websites, content, mobile applications
        (&ldquo;apps&rdquo;), tools, widgets, subscription products or services, software, API(s),
        widgets, and/or other product(s), service(s), data, or information supplied to you by the
        Company (collectively, as applicable, the &ldquo;Services&rdquo;), you signify your
        agreement to (1) all terms and conditions in these Terms; (2) our privacy policy located on
        our website (&ldquo;Privacy Policy&rdquo;); and (3) any other standard policies or
        community guidelines, if any, posted in our Services, which are all expressly incorporated
        herein and must also be observed and followed (clauses (1) through (3) collectively, the
        &ldquo;Agreement&rdquo;).
      </p>

      <p>
        WE ARE NOT RESPONSIBLE FOR ANY LOSSES (OR OTHER HARM OR LIABILITIES) INCURRED AS A RESULT
        OF USING ANY OF OUR SERVICES, INCLUDING, WITHOUT LIMITATION, ANY INFORMATION, GUIDANCE,
        RECOMMENDATIONS, IDEAS, OR STRATEGIES THEREIN. YOU ACCEPT ALL LIABILITY RESULTING FROM YOUR
        INVESTMENT DECISIONS. ONLY INVEST MONEY THAT YOU CAN AFFORD TO LOSE.
      </p>

      <p></p>

      <p>
        &#9679; The Services are available to users for informational purposes only. We do not give
        investment or financial advice, and we do not buy or sell assets in any way. You are
        encouraged to do your due diligence and independent research, and consult with an
        investment, tax, or legal professional before making any investment decisions. This data
        may not be accurate or complete, and you should not rely on it as such. You understand that
        you are using any and all information available here at your own risk.
      </p>

      <p>
        &#9679; Because the Services is available for informational purposes only, you may not
        copy, republish, or remarket data, research, screenshots, or other extracts in a public
        forum (e.g., a blog post, or your own product). However, some parts of the Services are
        clearly marked as permissible to share publicly. You cannot resell this content or
        information, but you may publish it on your websites as long as you add attribution to us
        (with a &ldquo;Powered by CRYPTORESEARCHFUND&rdquo; tagline) and with links back to
        CRYPTORESEARCHFUND.com.
      </p>

      <p>
        {' '}
        &#9679; You are responsible for any activity associated with your account. You will only
        use the Services and content in a manner that complies with all laws that apply to you. We
        can&rsquo;t and won&rsquo;t be responsible for your using the Services in any way that
        breaks the law.
      </p>

      <p></p>

      <p className="text-xl font-semibold">1. DESCRIPTION OF SERVICES</p>

      <p>
        1.1. Agreement to these Terms. By accessing or using any Services, including, without
        limitation, by downloading, installing, or using any associated software, APIs, or apps
        supplied by the Company, including any for which the purpose is to enable you to use the
        Services (collectively, the &quot;Software&quot;, which is considered a part of the
        Services), you agree to the Terms of this Agreement. Any reference to the
        &ldquo;website(s)&rdquo;, the &ldquo;web site(s)&rdquo;, the &ldquo;site&rdquo;,
        &ldquo;www.cryptoresearchfund.com&rdquo;, &ldquo;www.i8labs.io&rdquo;, or other similar
        references will include any and all pages, subdomains, affiliated domains, brands, products
        or other areas of our website, any other affiliated sites, or domains owned or operated by
        or on behalf of us, plus any of the online content, information, and services as made
        available in or through the website. The Services include, without limitation, all aspects
        of the website, or of any app or other product or service, including, but not limited, to
        all products, Software, and other applications, features, channels, and services offered
        therein. Any reference to &ldquo;content&rdquo; will include all content in all forms or
        mediums, such as, without limitation, text, software, scripts, graphics, photos, sounds,
        music, videos, audiovisual combinations, interactive features, and other materials you may
        view on, access through, or contribute to the Services.
      </p>

      <p>
        1.2. Requirements. You must be at least 18 years of age, and, where law requires an older
        legal age, of legal age for contractual consent or older to use this website and/or the
        other Services. Due to the age restrictions, no content or information from the Services
        falls within the Child Online Privacy Protection Act (&ldquo;COPA&rdquo;) and is not
        monitored as doing so. All information and services are exchanged electronically, via the
        internet. You are responsible for maintaining your own access to the internet, and for
        obtaining and maintaining any equipment and ancillary services needed to connect to,
        access, or otherwise use the Services, including, without limitation, modems, hardware,
        servers, software, operating systems, networking, web servers and the like (collectively,
        &ldquo;Equipment&rdquo;), and for maintaining the security of the Equipment. You consent to
        receiving communications electronically. You are responsible for any activity associated
        with your account. You will only use the Services and content in a manner that complies
        with all applicable laws. If your use of the Services is prohibited by applicable laws,
        then you are not authorized to use the Services. We can&rsquo;t and won&rsquo;t be
        responsible for your using the Services in any way that breaks the law.
      </p>

      <p>
        1.3. License. With respect to any Software, including, without limitation, subscription
        software as a service, subject to the terms and conditions of this Agreement, we hereby
        grant to you a limited, nonexclusive, non-transferable license to access and use such
        Software during the term of this Agreement, solely by the number of authorized users, and
        only up to the approved and mutually agreed usage volumes, as set forth on the applicable
        ordering document or as otherwise mutually agreed, and solely for internal and
        non-commercial purposes, provided that you must comply at all times with all official
        documentation, technical manuals, functional manuals, and operator and user guides and
        manuals.
      </p>

      <p>
        1.4. Informational Purposes. You may use the Services for informational purposes only, and
        you are strongly encouraged to use multiple sources and to not use our Services as the sole
        basis for making any decisions (such as, without limitation, purchase, sale, investment or
        pricing decisions); you must conduct proper due diligence and use your own judgment when
        making any such decisions. We do not guarantee the accuracy of any content, such as pricing
        data, and we are not responsible for typographical or database errors. All Services
        (including, without limitation, content, such as pricing data) are provided &ldquo;as
        is&rdquo; and for information only. We do not buy or sell assets, and any posted prices are
        not offers to purchase or sell. Our prices are not guarantees of value that can be obtained
        for any particular item.
      </p>

      <p>
        1.5. Internal &amp; Non-Commercial Use. Unless otherwise expressly set forth herein or
        otherwise mutually agreed in writing, your use of any and all Services (including, without
        limitation, subscription services and/or copies, downloads, PDFs, screenshots, extracts, or
        other replications or summaries thereof) is for your internal use only by one end user, and
        may not be resold, shared, redistributed, published, posted publicly or remarketed in any
        way, or used in any way to circumvent the general principle of one subscription per one
        user. Collecting, gathering or copying content from our Services, in whole or in part, is
        strictly prohibited, whether manually or via automated tools, such as scraping, spiders,
        bots, etc. Your violation of this section (or any provision in this Agreement) will allow
        us to immediately terminate and revoke your access and rights in and to any Services,
        including, without limitation, canceling any of your paid subscriptions without refund.
      </p>

      <p> </p>

      <p className="text-xl font-semibold">2. USER OBLIGATIONS</p>

      <p>
        2.1. Representations. You represent and warrant that all information that you provide to us
        will be true, accurate, complete, and current, and that you have the right to provide such
        information to us in connection with your use of the Services. You must comply with the
        terms of any applicable policies posted in our Services, including any Acceptable Use
        Policy. If you are agreeing to these Terms on behalf of an organization or entity, you
        represent and warrant that you are authorized to agree to these Terms on that organization
        or entity&rsquo;s behalf and bind them to these Terms (in which case, the references to
        &ldquo;you&rdquo; and &ldquo;your&rdquo; refer to that organization or entity).
      </p>

      <p>2.2. Use. The Services may be used only by you:</p>

      <p>(a) For your internal personal or business purposes, and only for your direct benefit;</p>

      <p>
        (b) Only by the number of end users for whom a license fee has been paid, and all such use
        may only be those end users using the Services for your benefit in the course and scope of
        their employment, subject to the terms hereof;
      </p>

      <p>
        (c) Only in its original form without alteration or combination with any third-party
        products, services, or software, except as expressly authorized in an applicable contract;
        and
      </p>

      <p>
        (d) In compliance with all applicable laws, and with all documentation and instructions as
        provided by us, if applicable;
      </p>

      <p>
        2.3. Restrictions. You will not and will not attempt to, nor authorize or allow any third
        party to or attempt to:
      </p>

      <p>
        (a) Upload, post, publish, reproduce, transmit, or distribute in any way any component of
        the Services itself or derivative works with respect thereto;
      </p>

      <p>
        (b) Sell, resell, lease, license, sublicense, distribute, redistribute, copy, duplicate, or
        otherwise transfer or exploit for commercial purposes, directly or indirectly, any portion
        of the Services, or access to them;
      </p>

      <p>
        (c) Make any derivative works based, in whole or in part, on any portion or all of the
        Services;
      </p>

      <p>
        (d) Download or otherwise obtain a copy of the Services (as applicable as such term is used
        herein, including any portion thereof) in any form, or mirror, cache, or store any pages or
        portions of the Services;
      </p>

      <p>
        (e) Reverse engineer, reverse compile, decompile, disassemble, or translate, exploit, or
        otherwise derive the source code of the Service, or otherwise modify the Service or create
        derivative works thereof;
      </p>

      <p>
        (f) Use, or authorize or permit the use of, the Services on behalf of any third party or
        for any purpose other than as expressly permitted in this Agreement or other applicable
        agreement with Company;
      </p>

      <p>
        (g) Portray Company or any company affiliated with it in a negative manner or otherwise
        portray its Services in a false, misleading, derogatory, or offensive manner;
      </p>

      <p>
        (h) Co-brand any portion of the Services, or otherwise imply any relationship with or
        endorsement of your brands or services;
      </p>

      <p>
        (i) Remove, alter, or obscure any titles, product logo or brand name, trademarks, copyright
        notices, proprietary notices or other indications of the intellectual property rights
        and/or our rights and ownership thereof, whether such notice or indications are affixed on,
        contained in or otherwise connected to the software or on any copies made in accordance
        with this Agreement;
      </p>

      <p>
        (j) Use, or authorize or permit the use of, the Services in any manner that could damage,
        disable, overburden, or impair our servers, or otherwise interfere with or disrupt the
        integrity or performance of the Services;
      </p>

      <p>
        (k) Restrict, inhibit, or otherwise interfere with any other user's use or enjoyment of the
        Services;
      </p>

      <p>
        (l) Access, or attempt to gain unauthorized access to, the Services, or related systems or
        networks (including methods such as password mining, data mining, robots, or other similar
        data gathering and extraction tools or processes), or through any automated means
        (including use of scripts or web crawlers);
      </p>

      <p>
        (m) Use i-frames, webpage frames, or any similar framing, to enclose, capture, or
        distribute any part of the Services;
      </p>

      <p>
        (n) Use a false email address, impersonate any person or entity, forge e-mail headers,
        including other attempts to disguise the origin of any communication, mislead as to the
        source of the information you provide to the Services, or post or transmit any photograph
        or likeness of another person without that person's consent, if and to the extent necessary
        under applicable laws;
      </p>

      <p>
        (o) Use, or authorize or permit the use of, the Services to perform any activity (including
        posting or transmitting) which is or may be, directly or indirectly, unlawful, fraudulent,
        libelous, defamatory, obscene, pornographic, vulgar, sexually-orientated, profane,
        threatening, abusive, hateful, offensive, false, misleading, derogatory, or otherwise
        objectionable in any way, including, without limitation, any transmissions constituting or
        encouraging conduct that would constitute a criminal offense, give rise to civil liability,
        or otherwise violate any local, state, national or foreign law, including, without
        limitation, the U.S. export control laws and regulations;
      </p>

      <p>
        (p) Post or otherwise transmit any materials that contain URLs or links to websites that
        compete with the Services, nor other competitive content or references;
      </p>

      <p>
        (q) Post, send, process, or store material containing software viruses, worms, Trojan
        horses, or other harmful or malicious computer code, files, scripts, agents, or programs;
      </p>

      <p>
        (r) Post or transmit any advertisements, solicitations, chain letters, pyramid schemes,
        investment opportunities or schemes, or other unsolicited commercial communication (except
        as otherwise expressly permitted by Company), or engage in spamming or flooding, or use
        email addresses obtained from the Services for solicitation purposes of any kind, directly
        or indirectly;
      </p>

      <p>
        (s) Post, publish, transmit, reproduce, distribute, or in any way exploit any information,
        software, or other material obtained through the Services for commercial purposes (other
        than as expressly permitted by the Services and by the provider of such information,
        software, or other material);
      </p>

      <p>
        (t) Upload, post, publish, transmit, reproduce, or distribute in any way, information,
        software, or other material obtained through the Services which is protected by copyright
        or other proprietary right, or derivative works with respect thereto, without obtaining
        permission of the copyright owner or rightsholder, or which otherwise violates or infringes
        the rights of others, including, without limitation, patent, trademark, trade secret,
        copyright, publicity, or other proprietary rights; or
      </p>

      <p>
        (u) Copy, duplicate, or imitate, in whole or in part, any concept, idea, business model,
        business process, product, service, or other intellectual property, or ideas or content
        embodied in the Services or learned by you from your use of or access to the Services.
      </p>

      <p>
        2.4. Confidential Information. You will keep and protect any of our Confidential
        Information as confidential, using at least the same efforts you use to protect your own
        confidential information and, in no event, less than reasonable and industry standard
        efforts. Our &ldquo;Confidential Information&rdquo; includes the Services, documentation
        and information about the Services and their operation, and any other information you
        obtain from or about us or from or about the Services, or any other information which a
        reasonable person would or should understand to be confidential or proprietary in nature.
        You agree to return or destroy our Confidential Information when this Agreement is
        terminated or expired. You acknowledge and agree we will be entitled to seek equitable
        relief in any court of competent jurisdiction without the necessity of posting bond and in
        addition to such other remedies as may be available under law or in equity. Your
        confidentiality obligations will survive termination or expiration of this Agreement.
      </p>

      <p>
        2.5. Business Users. If you are a business user offering some portions of the Services to
        your customers or end users (which may only be done if and to the extent expressly
        permitted by us), then this section will also apply. You are responsible for clearly and
        conspicuously disclosing to your customers and end users (&ldquo;your end users&rdquo;)
        (including, without limitation, in any posted terms and conditions, and in any written
        agreements with your customers): (a) that you (and not we) are solely responsible for all
        content and any services you provide to your end users or any messages or content sent (or
        attempted to send) to, from, between, or among your end users; (b) that we will have no
        liability whatsoever to your end users; (c) that we are not responsible for any disputes
        between you and your end users; (d) all disclaimers, warranty limitations, and limitations
        of liability by us herein or otherwise with respect to the Services; and (e) with respect
        to use of the Services by your end users, such end users are responsible for complying
        with, and subject to, all the same terms herein. You will not state or imply otherwise. You
        hereby agree to indemnify, defend, and hold us harmless from any for any allegation, loss,
        liability, or claim made against us by any of your end users, or otherwise arising out of
        any breach of this section.
      </p>

      <p>
        2.6. Bound by entire agreement. While this Section highlights some of your key obligations,
        headers and section titles are for convenience only, and you are bound by all the terms of
        this Agreement.
      </p>

      <p></p>

      <p className="text-xl font-semibold">3. COMPANY RIGHTS</p>

      <p>
        3.1. Company has no obligation to monitor the Services. However, you acknowledge and agree
        that Company has the right to monitor the Services electronically from time to time, and to
        disclose any information as necessary or appropriate to satisfy any law, regulation, or
        other governmental request; to operate the Services properly; or to protect itself or its
        customers. Company reserves the right to refuse to post or to remove any information or
        materials, in whole or in part, that, in its sole discretion, are unacceptable,
        undesirable, inappropriate, or in violation of this Agreement.
      </p>

      <p></p>

      <p className="text-xl font-semibold">4. DISCLAIMERS</p>

      <p>
        4.1. This is not financial advice. The Services, and the content, information and tools
        therein, are provided for educational, informational, and entertainment purposes only. We
        are not securities brokers/dealers, cryptoasset brokers/dealers, or financial advisers,
        analysts, or planners. We are neither licensed nor qualified to provide investment advice.
        The information contained within the Services is not an offer to buy or sell securities, or
        any other assets. Nothing within the Services or on associated websites takes into account
        the particular investment objectives, financial situations, or needs of individuals;
        therefore, it should not be construed as a personal recommendation.
      </p>

      <p>
        4.2. We are not soliciting any action. The information provided on the Services does not
        constitute investment advice, financial advice, trading advice, or any other sort of
        advice, and you should not treat any of the website's content as such. We do not recommend
        that any cryptocurrency should be bought, sold, or held by you. Conduct your own due
        diligence and consult your financial advisor before making any investment decisions.
      </p>

      <p>
        4.3. Do your own due diligence. The information provided is not intended as a complete
        source of information on any particular company, investment, asset or market. An individual
        should never make investment decisions based solely on information contained within our
        Services or associated media (or any service, website, book, tool or app, for that matter).
        All users should assume that ALL INFORMATION PROVIDED REGARDING COMPANIES, INVESTMENTS,
        ASSETS OR MARKETS IS NOT TRUSTWORTHY UNLESS VERIFIED BY THEIR OWN INDEPENDENT RESEARCH.
      </p>

      <p>
        4.4. Reliability of data. The contents of the Services and any associated media&mdash;data,
        text, graphics, links and other materials&mdash;are based on public information that we do
        not control; we do not represent that it is accurate or complete, and it should not be
        relied on as such. The Services and associated media may contain inaccuracies,
        typographical errors, and other errors. All information and materials are provided on an
        &ldquo;as is&rdquo; and &ldquo;as available&rdquo; basis, without warranty or condition of
        any kind either expressed or implied. We do not warrant the quality, accuracy, reliability,
        adequacy, or completeness of any of such information and material, and expressly disclaim
        any liability for errors or omissions in such information and material. You understand that
        you are using any and all information available here at your own risk. Opinions expressed
        herein are merely opinions and merely as of the date of publication of those opinions, and
        may or may not be updated.
      </p>

      <p>
        4.5. Investing in securities and/or cryptoassets is high risk. Any individual who chooses
        to invest in any securities and/or cryptoassets should do so with caution. Investing in
        securities and/or cryptoassets is speculative and carries a high degree of risk; you may
        lose some or all of the money that is invested, and if you engage in margin transactions,
        your loss may exceed the amount invested.
      </p>

      <p>
        4.6. Past performance is no guarantee of future results. Trading results can never be
        guaranteed. You should be suspicious of anyone who promises you guaranteed results. The
        information provided in the Services and associated media regarding the past performance of
        any security and/or cryptoassets, or strategy is only representative of historical
        conditions in the marketplace and is not to be construed as a guarantee that such
        conditions will exist in the future or that such performance will be achieved in the
        future. The price and value of investments referred to in the Services and associated
        media, and the income from them may go down as well as up, and investors may realize losses
        on any investments. Past performance is no guarantee of future results. Future returns are
        not guaranteed, and a loss of original capital may occur.
      </p>

      <p>
        4.7. No warranties. We, and the Services, do not guarantee or warrant the quality,
        accuracy, completeness, timeliness, appropriateness, or suitability of the information, or
        of any product or services referenced. We assume no obligation to update the information or
        advise on further developments concerning topics mentioned. HYPOTHETICAL OR SIMULATED
        PERFORMANCE RESULTS, AND/OR QUANTITATIVE MODELING, HAVE CERTAIN LIMITATIONS. UNLIKE AN
        ACTUAL PERFORMANCE RECORD, SIMULATED OR MODELED RESULTS DO NOT REPRESENT ACTUAL TRADING.
        ALSO, SINCE THE TRADES HAVE NOT BEEN EXECUTED, THE RESULTS MAY HAVE UNDER- OR
        OVER-COMPENSATED FOR THE IMPACT, IF ANY, OF CERTAIN MARKET FACTORS, SUCH AS LACK OF
        LIQUIDITY. SIMULATED TRADING PROGRAMS AND/OR QUANTITATIVE ANALYSIS IN GENERAL ARE ALSO
        SUBJECT TO THE FACT THAT THEY ARE DESIGNED WITH THE BENEFIT OF HINDSIGHT. NO REPRESENTATION
        IS BEING MADE THAT ANY ACCOUNT WILL OR IS LIKELY TO ACHIEVE PROFIT OR LOSSES SIMILAR TO
        THOSE SHOWN.
      </p>

      <p>
        4.8. Suitability of strategies is personal to your circumstances. The investments and
        services described herein not be suitable for all investors and may not be suitable for you
        or your situation. You should seek advice from an independent financial advisor. None of
        the content published in the Services constitutes a recommendation that any particular
        security, portfolio of securities, transaction, or investment strategy is suitable for any
        specific person. We are not advising you personally concerning the nature, potential,
        value, or suitability of any particular security, portfolio of securities, transaction,
        investment strategy, or other matter.
      </p>

      <p>
        4.9. Consider other relevant factors. While the Services may be of use to investors in
        evaluating certain information about assets or securities, it should not be considered to
        include every factor that may be necessary for an investor to make a decision to invest. A
        decision to invest in a particular asset or security may be based on a number of factors,
        including investors&rsquo; needs, goals, and comfort with risk. Before making an investment
        decision you should read the issuer's financial statements and other reports (for example,
        annual reports and Forms 10K, 10Q, 8K), or the in the case of initial public offerings or
        new issuances the applicable offering documents or registration statement, or similar
        disclosures for cryptoassets. Please also consider risks associated with the trading of
        cryptoassets, or other assets or securities, including volatility in the market and
        individual assets or securities. The Services may provide results that identify assets or
        securities by name, and each name may contain a hyperlink to additional information. The
        additional information may include, among other things, price and performance information,
        and financial/accounting ratios. The Services are highly dependent upon data provided by
        third parties, and we cannot guarantee its accuracy, timeliness, or fitness for a specific
        purpose. The data is subject to change and revision by the third-party data providers. As
        with any investments through any source, you must decide for yourself if an investment in
        any asset or security is consistent with your evaluation of the asset or security, your
        investment objectives, your risk tolerance, your investment time frame, and your financial
        situation. By making information about investments available, we, and the Services, are not
        recommending or endorsing it.
      </p>

      <p>
        4.10. Use qualified advisors. We are not providing any financial or investment advice or
        services via the Services and should not be relied upon as a substitute for consultations
        with qualified professionals who are familiar with your individual needs. Please consult
        your accountant, tax advisor, stockbroker, and/or financial advisor as necessary. You
        acknowledge that the information on the Website and other Services is provided &ldquo;as
        is&rdquo; for general information only. You may use the Services for informational purposes
        only, as an aid, but only as one information source among many, and not as the sole basis
        for making any decisions; you must conduct proper due diligence and use your own judgment
        when making any decisions based on any information, analytics, or reports derived from the
        Services.
      </p>

      <p>
        4.11. For advisors or brokers. If you use the Services to provide any services to any third
        parties, such as, without limitation, if you are an investment advisor or broker, you are
        fully responsible for all services and recommendations, and represent and warrant that you
        are appropriately qualified and certified to do so, possessing all necessary licenses and
        permits to do so. You will not state nor imply to any customer or client any responsibility
        of us for any decisions or recommendations. In addition: (a) you (and not we) are solely
        responsible for all advice, recommendations, or services you provide to your clients, even
        if you use our Services in whole or in part to create such advice; (b) we have no liability
        whatsoever to your clients; (c) we are not responsible for any disputes between you and
        your clients; (d) you will not state or imply to your clients anything contrary to all
        disclaimers, warranty limitations, and limitations of liability, by us herein or otherwise
        with respect to the Services. You hereby agree to indemnify, defend, and hold us harmless
        from and against any allegation, loss, liability, or claim made against us by any of your
        clients or otherwise arising out of any breach of this section (or any other portion of
        this Agreement) by you or your agents, or any failure by you or your agents to follow any
        applicable laws, rules, and regulations.
      </p>

      <p>
        4.12. Additional Services. We may from time to time offer products and services as part of
        the Services that provide you with additional features and functionality, including,
        without limitation, the ability to participate in on-chain governance for supported
        cryptocurrency assets via governance tooling (through our product currently referred to as
        &ldquo;Governor&rdquo; or &ldquo;Governance by Messari&rdquo;). You may participate in
        these and similar Messari products and services by linking your digital wallets on
        supported bridge extensions (e.g., MetaMask), provided that you have downloaded a supported
        electronic wallet extension. Any transactions initiated through the Services are
        facilitated and run by third-party electronic wallet extensions and subject to the terms
        and conditions and privacy policies of those third party products and services and Messari
        shall have no liability with resect to the use of those third party products or services.
        For the avoidance of doubt, we do not not store, send or receive any cryptocurrency assets,
        and any transfer of ownership takes place outside of the Services.
      </p>

      <p></p>

      <p className="text-xl font-semibold">5. ACCOUNT ACCESS AND FEES</p>

      <p>
        5.1. Registration. In order to access some features of the Services, you may have to
        register or create an account. When creating your account, you must provide accurate,
        current, and complete information. If we believe the information you provide is not
        correct, current, or complete, we have the right to reuse you access to the Services or any
        of its resources, and to terminate or suspend your access at any time, without notice.
        Registration or subscription to the Services, and payment of any applicable fee, authorizes
        a single individual to use the Services unless otherwise expressly stated.
      </p>

      <p>
        5.2. User Obligations. You are solely responsible for the activity that occurs on your
        account, and you must keep your account password secure. You are solely responsible for all
        charges incurred on your account, including applicable taxes, fees, surchargers, and
        purchases made by you or anyone you allow to use your account (including your children,
        family, friends, or any other person with implied, actual, or apparent authority), or
        anyone who gains access to your account as a result of your failure to safeguard your
        username, password, or other authentication credentials or information. You must notify
        Company immediately of any breach of security, or unauthorized use of your account.
        Although Company will not be liable for your losses caused by any unauthorized use of your
        account, you may be liable for the losses of Company or others due to unauthorized use.
      </p>

      <p>
        5.3. Restrictions. You agree not to download any content, software or services unless you
        see a &ldquo;download&rdquo; or similar link displayed by Company on the Services for that
        content. You agree not to copy, reproduce, distribute, transmit, broadcast, display, sell,
        license, or otherwise exploit any content for any other purposes without the prior written
        consent of Company or the respective licensors of the content. Company and its licensors
        reserve all rights not expressly granted in and to the Services and their content. You
        agree not to circumvent, disable, or otherwise interfere with security-related features of
        the Services; or features that prevent or restrict use or copying of any content, or that
        enforce limitations on use of the Services or the content therein.
      </p>

      <p>
        5.4. Fees and Payments. If and to the extent any portion of the Services may require a fee
        payment, incremental payment, or subscription, you agree to pay Company any applicable fees
        posted for the Services. By completing and submitting any credit card or other payment
        authorization through the Services, you are authorizing Company to charge the fees to the
        account you identify. You must keep all billing information, including payment method, up
        to date. You agree to pay us for all charges incurred under your account, including all
        applicable taxes, fees, and surcharges. You authorize and direct us to charge your
        designated payment method for these charges or, if your designated payment method fails, to
        charge any other payment method you have on file with your account. Further, you authorize
        and direct us to retain information about the payment method(s) associated with your
        account. If we do not receive payment from your designated payment method or any other
        payment method on file, you agree to pay all amounts due upon demand by us. You will be
        responsible for accrued but unpaid charges, even if your account is canceled by you or
        terminated by us. During any free trial or other promotion, if any, you will still be
        responsible for any purchases and surcharges incurred using your account.
      </p>

      <p>
        5.5. Nonpayment. After 30 days from the date of any unpaid charges, your fee-based Services
        will be deemed delinquent, and we may terminate or suspend your account and Services for
        nonpayment. We reserve the right to assess an additional 1.5% late charge (or the highest
        amount allowed by law, whichever is lower) per month if your payment is more than 30 days
        past due, and to use any lawful means to collect any unpaid charges. You are liable for any
        fees, including attorney and collection fees, incurred by us in our efforts to collect any
        remaining balances from you. Except as otherwise mutually agreed in writing, we reserve the
        right to change our fees with 30 days&rsquo; notice.
      </p>

      <p>
        5.6. Refunds. If you are a subscriber, you may access or modify your subscription level on
        the Messari.io dashboard at any time.
      </p>

      <p>
        (a) General policy: We assess refund or credit requests on a case-by-case basis. If issued,
        you will receive a partial return, subject to a 10% fee, or a pro-rated refund, whichever
        is lesser.
      </p>

      <p>
        (b) Limitations: We are not obligated to provide a refund or credit if: you changed your
        mind about a product or subscription; you purchased a subscription by mistake; you failed
        to cancel your free trial before getting charged; you do not have sufficient expertise to
        use a product or subscription; OR you cannot access the product(s) or subscription due to
        your inability to log in, excluding extenuating circumstances that impact the broader
        subscriber base or community.
      </p>

      <p>
        (c) Payment terms: If we do decide to issue a refund or credit, it will be in the form of
        original payment, where possible, and in US dollars (we are not responsible for conversion
        costs or any fees related to any such refund).
      </p>

      <p></p>

      <p className="text-xl font-semibold">6. DURATION OF TERMS AND TERMINATION</p>

      <p>
        6.1. Duration of Terms. Once in effect, this Agreement will continue in operation until
        terminated by either you or us. However, even after termination, the provisions of Sections
        2, 5&ndash;16 of this Agreement will remain in effect in relation to any prior use of the
        Services by you.
      </p>

      <p>
        6.2. Termination. You may terminate this Agreement at any time and for any reason by
        providing notice to Company in the manner specified in this Agreement, or by choosing to
        cancel your access to the Services using the tools provided for that purpose within the
        Services. We may terminate this Agreement without notice or, at our option, temporarily
        suspend your access to the Services, in the event that you breach this Agreement.
        Notwithstanding the foregoing, Company also reserves the right to terminate this Agreement
        at any time and for any reason by providing notice to you either through email or other
        reasonable means.
      </p>

      <p>
        6.3. Effects of Termination. After termination of this Agreement for any reason, you
        understand and acknowledge that Company will have no further obligation to provide the
        Services or access thereto. Upon termination, all licenses and other rights granted to you
        by this Agreement, if any, will immediately cease, but your licenses to us will survive,
        and certain of your obligations (including payment obligations, if any) will survive in
        accordance with the terms hereof.
      </p>

      <p></p>

      <p className="text-xl font-semibold">7. PUBLIC POSTINGS AND LICENSED MATERIALS</p>

      <p>
        7.1. Public Postings. Certain material you may post on our Services is or may be available
        to the public, including, without limitation, any public profile data, feedback, questions,
        comments, suggestions, uploads, blog entries, ratings, reviews, images, videos, poll
        answers, etc., in any form or media, that you post via the Services or otherwise
        (collectively, &quot;Public Postings&quot;). These Public Postings will be treated as
        non-confidential and nonproprietary. You are responsible for any Public Postings, and the
        consequences of sharing or publishing such content with others or the general public. This
        includes, for example, any personal information, such as your address, the address of
        others, or your current location. WE ARE NOT RESPONSIBLE FOR THE CONSEQUENCES OF PUBLICLY
        SHARING OR POSTING ANY PERSONAL OR OTHER INFORMATION ON THE SERVICES.
      </p>

      <p>
        7.2. Submissions. Other content or communications you transmit to us, including, without
        limitation, any feedback, data, questions, comments, suggestions, in any form or media,
        that you submit to us via e-mail, the Services, or otherwise (to the extent excluding any
        Personally Identifiable Information, collectively, &quot;Submissions&quot;), will be
        treated as non-confidential and nonproprietary.
      </p>

      <p>
        7.3. User Obligations. You are solely responsible for your own content, and any Public
        Postings and Submissions. You affirm, represent, and warrant that you own or have the
        necessary licenses, rights, consents, and permissions to publish content you post or
        submit. You further agree that content you submit via Public Postings or Submissions will
        not contain third-party copyrighted material, or material that is subject to other
        third-party proprietary rights, unless you have permission from the rightful owner of the
        material or you are otherwise legally entitled to post the material, and to grant us all of
        the license rights granted herein. You further agree that you will not submit to the
        Services any content or other material that is contrary to any posted &ldquo;community
        guidelines&rdquo; or similarly titled document, if any, which may be updated from time to
        time, or contrary to applicable local, national, and international laws and regulations.
      </p>

      <p>
        7.4. Rights. By providing any Public Posting or Submission, you (a) grant to Company a
        royalty-free, non-exclusive, perpetual, irrevocable, sub-licensable right to use,
        reproduce, modify, adapt, publish, translate, create derivative works (including products)
        from, distribute, and display such content throughout the world in all media, and you
        license to us all patent, trademark, trade secret, copyright, or other proprietary rights
        in and to such content for publication on the Services pursuant to this Agreement; (b)
        agree that we are free to use any ideas, concepts, or techniques embodied therein for any
        purpose whatsoever, including, but not limited to, developing and marketing products or
        services incorporating such ideas, concepts, or techniques, without attribution, without
        any liability or obligation to you; (c) grant to Company the right to use the name that you
        submit in connection with such content. In addition, you hereby waive all moral rights that
        you may have in any Public Posting or Submissions.
      </p>

      <p>
        7.5. Historical Data &amp; Listings. If you elect to voluntarily provide us, directly or
        indirectly, any data or information (such as, without limitation, any historical or
        current: pricing data, transaction data, business information, listings, directory
        information, etc.) (collectively, &ldquo;Historical Data &amp; Listings&rdquo;), you agree
        that we own our copy of such Historical Data &amp; Listings, and have a perpetual,
        irrevocable, non-exclusive, royalty free license to use, publish, and distribute such
        Historical Data &amp; Listings, during and after the Term. For example, and without
        limitation, if you supply us with a data feed of transactions and prices, whether or not in
        exchange for publicity (such as name attribution, logos, and/or links to your website) for
        us to publish on our Services, in whole or in part, then, even after the end of the
        relationship, in perpetuity, we reserve the right (in our discretion, but not the
        obligation) to continue to publish such data, in whole or in part, in our Services. Certain
        content from you, in Submissions and/or in Historical Data &amp; Listings, may also be
        subject to CC BY-NC 4.0, if and as applicable.
      </p>

      <p>
        7.6. Personally Identifiable Information. We will use reasonable efforts to protect the
        confidentiality of certain personally identifiable information you submit to us (e.g., your
        address and credit card information submitted by you initially for the purpose subscribing
        to the Service) (&ldquo;Personally Identifiable Information&rdquo;), in accordance with the
        Privacy Policy posted on our website.
      </p>

      <p>
        7.7. Disclaimers. We are not responsible for end user error or errors in inputs, or for
        errors in any user-supplied data. We do not independently verify the truthfulness or
        accuracy of any data or content input into the Services and are not responsible for the
        fraud, misrepresentation, negligence, or misconduct of any end user or other third party.
      </p>

      <p>
        7.8. Content may not be considered endorsements. We do not endorse any content submitted to
        the Services by any user or other licensor, or any opinion, recommendation, or advice
        expressed therein, and we expressly disclaim any and all liability in connection with
        content. We do not permit copyright infringing activities and infringement of intellectual
        property rights on the Services, and we will remove all content if properly notified that
        such content infringes on another's intellectual property rights as set forth herein below.
        We reserve the right to remove content without prior notice. We reserve the right to decide
        whether your content violates this Agreement for reasons other than copyright infringement,
        such as, but not limited to, pornography, obscenity, or excessive length. We may at any
        time, without prior notice and in our sole discretion, remove such content, and/or
        terminate a user's account or otherwise block access for submitting such material in
        violation of this Agreement.
      </p>

      <p>
        7.9. Marketing. Neither party may issue or release any announcement, statement, press
        release, or other publicity or marketing materials relating to this Agreement, or otherwise
        use the other party&rsquo;s trademarks, service marks, trade names, logos, domain names or
        other indicia of source, affiliation, or sponsorship, in each case, without the prior
        written consent of the other party, which consent will not be unreasonably withheld,
        conditioned, or delayed, provided, however, that we may, without your consent, include your
        name and/or other indicia in our lists of current or former customers in promotional and
        marketing materials. Additionally, we may prepare a case study relating to your access and
        use of the Services, including, but not limited to, one or more testimonials from you or
        your users, aggregated data of the results of your use of the Services, and other
        informational material as determined by us. You agree to provide reasonable assistance to
        us and timely responses in our preparation of said case study.
      </p>

      <p></p>

      <p> </p>

      <p className="text-xl font-semibold">8. WARRANTIES AND LIMITATIONS OF WARRANTIES</p>

      <p>
        8.1. Warranties. If you are not completely satisfied with the Services, your sole remedy is
        to cease using the Services. With respect to any fee-based Services, if you signed up for
        designated term or timeframe, you will still be responsible for payment for the full term.
        If you did not subscribe for any minimum period, then you may cancel at any time on 30
        days&rsquo; advanced notice and cease to use the Services, then you will not be charged any
        additional amounts after the effective date of such termination. In any case, you will be
        responsible for any and all charges and activity accrued prior to your Services termination
        date, and those obligations will survive your termination of the Services. Company
        undertakes commercially reasonable efforts to ensure that the information it provides is
        current and accurate; however, Company does not warrant the accuracy of information.
        Company also undertakes commercially reasonable efforts to protect the confidentiality of
        any confidential information you provide, in accordance with the Privacy Policy; however,
        Company does not guaranty the confidentiality of such information against unauthorized
        third-party access or system failure.
      </p>

      <p>
        8.2. Limitations of Warranties. THE SERVICES, THE WEBSITE, AND ALL INFORMATION, CONTENT,
        AND MATERIALS RELATED TO THE FOREGOING, ARE PROVIDED &quot;AS IS.&quot; EXCEPT AS EXPRESSLY
        STATED IN THIS AGREEMENT, WE DISCLAIM ALL WARRANTIES, EXPRESS OR IMPLIED, INCLUDING, BUT
        NOT LIMITED TO, ANY WARRANTIES OF MERCHANTABILITY, FITNESS FOR A PARTICULAR PURPOSE, TITLE,
        NON-INFRINGEMENT, NON-INTERFERENCE, SYSTEM INTEGRATION, AND ACCURACY OF DATA. WE DO NOT
        WARRANT THAT USE OF THE SERVICES WILL BE UNINTERRUPTED, ERROR-FREE, OR VIRUS-FREE. ALTHOUGH
        INFORMATION THAT YOU SUBMIT MAY BE PASSWORD PROTECTED, WE DO NOT GUARANTEE THE SECURITY OF
        ANY INFORMATION TRANSMITTED TO OR FROM THE SERVICES, AND YOU AGREE TO ASSUME THE SECURITY
        RISK FOR ANY INFORMATION YOU PROVIDE THROUGH THE SERVICES.
      </p>

      <p></p>

      <p className="text-xl font-semibold">9. LIMITATIONS OF LIABILITY</p>

      <p>
        9.1. Company Obligations. We will not be liable for any circumstances arising out of causes
        beyond our reasonable control or without our fault or negligence, including, but not
        limited to, acts of God, acts of civil or military authority, fires, riots, wars,
        embargoes, pandemics, epidemics, Internet disruptions, hacker attacks, or communications
        failures, or other force majeure. IN NO EVENT WILL WE OR OUR AFFILIATES BE LIABLE FOR ANY
        INDIRECT, INCIDENTAL, CONSEQUENTIAL, OR SPECIAL DAMAGES, OR FOR LOSS OF PROFITS OR DAMAGES
        ARISING DUE TO BUSINESS INTERRUPTION, OR FROM LOSS OR INACCURACY OF INFORMATION, INCLUDING
        IF AND TO THE EXTENT ANY OF THE FOREGOING ARISES IN CONNECTION WITH THIS AGREEMENT, OR YOUR
        USE OR INABILITY TO USE THE SERVICES, WHETHER OR NOT SUCH DAMAGES WERE FORESEEABLE AND EVEN
        IF WE WERE ADVISED THAT SUCH DAMAGES WERE LIKELY OR POSSIBLE. IN NO EVENT WILL THE
        AGGREGATE LIABILITY OF US TO YOU FOR ANY AND ALL CLAIMS ARISING IN CONNECTION WITH THIS
        AGREEMENT OR THE SERVICES, EXCEED THE TOTAL FEES PAID TO US BY YOU, IF ANY, DURING THE
        SIX-MONTH PERIOD PRECEDING THE DATE OF ANY CLAIM (OR $10 IF THE SERVICES ARE FREE). YOU
        ACKNOWLEDGE THAT THIS LIMITATION OF LIABILITY IS AN ESSENTIAL TERM BETWEEN YOU AND US
        RELATING TO THE PROVISION OF THE SERVICE TO YOU, AND WE WOULD NOT PROVIDE THE SERVICE TO
        YOU WITHOUT THIS LIMITATION.
      </p>

      <p>
        9.2. User Obligations. You acknowledge that the information on the Website and other
        Services is provided &ldquo;as is&rdquo; for general information only. You may use the
        Services for informational purposes only, as an aid, but only as one information source
        among many, and not as the sole basis for making any decisions; you must conduct proper due
        diligence and use your own judgment when making any decisions based on any information,
        analytics, or reports derived from the Services. If you use the Services to provide any
        services in any heavily regulated industry (such as, without limitation, medical, legal,
        tax, or financial advice), you are fully responsible for all such services, and represent
        and warrant that you are appropriately qualified and certified to do so, possessing all
        necessary licenses and permits to do so. YOU AGREE TO INDEMNIFY, DEFEND, AND HOLD HARMLESS
        US AND OUR AFFILIATED COMPANIES, AND EACH OF OUR AND THEIR RESPECTIVE OFFICERS, DIRECTORS,
        MEMBERS, AGENTS, AND EMPLOYEES FROM AND AGAINST ALL LOSSES, EXPENSES, DAMAGES, CLAIMS,
        JUDGMENTS, COSTS, EXPENSES, AND LIABILITIES, INCLUDING REASONABLE ATTORNEYS' FEES, INCURRED
        BY US OR SUCH PARTIES AND/OR ARISING OUT OF OR RESULTING FROM (a) ANY ACTUAL OR ALLEGED
        VIOLATION BY YOU OF THIS AGREEMENT (INCLUDING ANY REPRESENTATION OR WARRANTY HEREIN); (b)
        ANY ACTIVITY RELATED TO YOUR ACCOUNT BY YOU OR ANY OTHER PERSON ACCESSING THE SERVICE
        THROUGH YOUR ACCOUNT; (c) YOUR USE OF AND ACCESS TO THE SERVICES; (d) YOUR ACTUAL OR
        ALLEGED VIOLATION OF ANY THIRD-PARTY RIGHT, INCLUDING, WITHOUT LIMITATION, ANY COPYRIGHT,
        PROPERTY, OR PRIVACY RIGHT; (e) YOUR ACTUAL OR ALLEGED VIOLATION OF ANY LAW, RULE, OR
        REGULATION; AND/OR (f) YOUR CONTENT OR DATA, INCLUDING IF IT CAUSES ANY DAMAGE TO A THIRD
        PARTY. YOUR DEFENSE, INDEMNIFICATION, AND HOLD HARMLESS OBLIGATIONS IN THIS AGREEMENT WILL
        SURVIVE THIS AGREEMENT AND YOUR USE OF THE SERVICES.
      </p>

      <p>
        9.3. Limitations or Exclusions. If we breach this Agreement, you agree that your exclusive
        remedy is to recover, from us or any affiliates, resellers, distributors, and vendors,
        direct damages up to an amount equal to your Services fee for one month (or up to US $10.00
        if the Services are free). YOU CAN'T RECOVER ANY OTHER DAMAGES OR LOSSES, INCLUDING,
        WITHOUT LIMITATION, DIRECT, CONSEQUENTIAL, LOST PROFITS, SPECIAL, INDIRECT, INCIDENTAL, OR
        PUNITIVE. These limitations and exclusions apply if this remedy doesn't fully compensate
        you for any losses or fails of its essential purpose, or if we knew or should have known
        about the possibility of the damages. To the maximum extent permitted by law, these
        limitations and exclusions apply to anything related to this Agreement such as, without
        limitation, loss of content; any virus affecting your use of the Services; delays or
        failures in starting or completing transmissions or transactions; claims for breach of
        contract, warranty, guarantee, or condition; strict liability, negligence,
        misrepresentation, or omission; trespass or other tort; violation of statute or regulation;
        or unjust enrichment. Some or all of these limitations or exclusions may not apply to you
        if your state, province, or country doesn't allow the exclusion or limitation of
        incidental, consequential, or other damages.
      </p>

      <p></p>

      <p className="text-xl font-semibold">12. INDEMNIFICATION</p>

      <p>
        12.1. Indemnification by User. You indemnify us for any failure by you or your agents to do
        so and/or to follow any applicable laws, rules, and regulations. We are not providing any
        financial advice or services via the Services and should not be relied upon as a substitute
        for consultations with qualified professionals who are familiar with your individual needs.
        Please consult your financial advisor as necessary.
      </p>

      <p></p>

      <p className="text-xl font-semibold">13. MODIFICATIONS</p>

      <p>
        13.1. Modification of Terms. Company may change the terms of this Agreement from time to
        time. You will be notified of any such changes via e-mail (if you have provided a valid
        email address) or by our posting notice of the changes on the Services (which may consist
        of publishing the changes on our website). Any such changes will become effective when
        notice is received or when posted on the Services, whichever first occurs. If you object to
        any such changes, your sole recourse will be to terminate this Agreement by cancelling your
        subscription or applicable contract(s). Continued use of the Services following such notice
        of any such changes will indicate your acknowledgement of such changes and agreement to be
        bound by such changes.
      </p>

      <p>
        13.2. Modifications to Services. We reserve the right to modify or discontinue the Services
        at any time with or without notice to you, including, without limitation, by adding or
        subtracting features and functionality, third-party content, etc. In the event of such
        modification or discontinuation of the Services, your sole remedy will be to terminate this
        Agreement as set forth herein. Continued use of the Services following notice of any such
        changes will indicate your acknowledgement and acceptance of such changes, and satisfaction
        with the Services as so modified.
      </p>

      <p></p>

      <p className="text-xl font-semibold">14. THIRD-PARTY CONTENT AND SERVICES</p>

      <p>
        14.1. Third-Party Content. Certain content (including, without limitation, advertisements)
        on the Services may be supplied by third parties. Company does not have editorial control
        over such content. Any opinions, advice, statements, services, offers, or other information
        that constitutes part of the content expressed or made available by third parties,
        including, without limitation, suppliers and vendors, advertisers, or any customer or user
        of the Services, are those of the respective authors or distributors, and not of Company or
        its affiliates, or any of its officers, directors, employees, or agents. In many instances,
        the content available on the Services represents the opinions and judgments of the
        respective third parties, whether or not under contract with Company. You may enter into
        correspondence with or participate in promotions of such third parties, such as advertisers
        promoting their products, services, or content on this site. Any such correspondence or
        participation, including the delivery of and the payment for products, services, or
        content, are solely between you and each such third party. Company neither endorses nor is
        responsible for the accuracy or reliability of any opinion, advice, submission, posting, or
        statement made on the Services. Under no circumstances will Company or its affiliates, or
        any of their respective officers, directors, employees, or agents, be liable for any loss
        or damage caused by your reliance on any content or other information obtained through the
        Services.
      </p>

      <p>
        14.2. Third-Party Services. The Services may integrate or interact with third-party
        services, such as via APIs or browser extensions. For example, the Services may leverage
        APIs from third parties, or rely on third-party browser extensions, and Company has no
        affiliation, association, endorsement, or sponsorship by any other third-party services
        with which it integrates or interacts from time to time (collectively, &ldquo;Third-Party
        Services&rdquo;). Company makes no claim, representation, or warranty of any kind, type, or
        nature concerning any Third-Party Services, nor Company&rsquo;s or any User&rsquo;s
        compliance with any third-party terms of service for any such Third-Party Services
        (collectively, &ldquo;Third-Party Terms&rdquo;). Each User is solely responsible to analyze
        and interpret any applicable Third-Party Terms and their actions relevant to compliance
        thereof. By using the Services, you hereby release Company, and waive any and all claims or
        claim rights that you may have against Company, and release and indemnify Company against
        any claims that any third party may have against you, which includes claims with respect to
        your use of any Third-Party Services (including if accessed or used via our Services), and
        with respect to Third-Party Terms, applicable privacy policies, or any other rules or
        regulations of such third parties.
      </p>

      <p>
        14.3. Third-Party Authentication. Without limiting the generality of the foregoing, we may
        elect, in our discretion, to utilize social logins, allowing you to login to the Services
        via other third-party authentication services, such as, without limitation, your Facebook,
        Twitter, LinkedIn, Google, or other account credentials. You understand that these are
        Third-Party Services, and that this in no way creates an endorsement of, by, or from us to
        them or vice versa; that we are not responsible for their logins, systems, or data; and
        that by using such third-party logins, you may be subject to their respective privacy
        policies and other terms of use.
      </p>

      <p>
        14.4. Exposure through Company. You understand that when using the Services, you will be
        exposed to content from a variety of sources, and that we are not responsible for the
        accuracy, usefulness, safety, or intellectual property rights of or relating to such
        content. You further understand and acknowledge that you may be exposed to content that is
        inaccurate, offensive, indecent, or objectionable, and you agree to waive, and hereby do
        waive, any legal or equitable rights or remedies you have or may have against us with
        respect thereto, and, to the extent permitted by applicable law, agree to indemnify and
        hold harmless Company, its owners, operators, affiliates, licensors, and licensees to the
        fullest extent allowed by law regarding all matters related to your use of the Services.
      </p>

      <p>
        14.5. Links or Communications through Company. As a convenience to you, Company may
        provide, in or through the Services, one or more links to third-party web sites or services
        and/or provide email contacts respecting third parties. Company makes no endorsement of
        such third parties, nor any representation or warranty regarding anything that takes place
        between you and any such third parties, including, without limitation, visits to
        third-party web sites, services, email correspondence with third parties, and business or
        other transactions with third parties found through the Services. Please understand that
        such third parties are independent from and not controlled by Company, even if, for
        example, a Company link or logo appears on a website linked from this website or our other
        Services. It is up to you to read those third-party sites&rsquo; applicable terms of use,
        privacy, and other applicable policies. For example, without limitation, we may outsource
        operation of certain aspects of our Services to one or more third parties, and they may
        have access to certain data by virtue of operating such Services, subject to their own
        policies.
      </p>

      <p></p>

      <p className="text-xl font-semibold">15. DIGITAL MILLENNIUM COPYRIGHT ACT</p>

      <p>
        15.1. Copyright Owners. If you are a copyright owner or an agent thereof, and believe that
        any content in our Services infringes upon your copyrights, you may submit a notification
        pursuant to the U.S. Digital Millennium Copyright Act (&quot;DMCA&quot;) by providing our
        Copyright Agent with the following information in writing (see 17 U.S.C 512(c)(3) for
        further detail):
      </p>

      <p>
        (a) A physical or electronic signature of a person authorized to act on behalf of the owner
        of an exclusive right that is allegedly infringed;
      </p>

      <p>
        (b) Identification of the copyrighted work claimed to have been infringed, or, if multiple
        copyrighted works at a single online site are covered by a single notification, a
        representative list of such works at that site;
      </p>

      <p>
        (c) Identification of the material that is claimed to be infringing or to be the subject of
        infringing activity and that is to be removed or access to which is to be disabled and
        information reasonably sufficient to permit the service provider to locate the material;
      </p>

      <p>
        (d) Information reasonably sufficient to permit the service provider to contact you, such
        as an address, telephone number, and, if available, an e-mail address;
      </p>

      <p>
        (e) A statement that you have a good faith belief that use of the material in the manner
        complained of is not authorized by the copyright owner, its agent, or the law; and
      </p>

      <p>
        (f) A statement that the information in the notification is accurate, and under penalty of
        perjury, that you are authorized to act on behalf of the owner of an exclusive right that
        is allegedly infringed.
      </p>

      <p>
        15.2. Notifications of Claimed Infringement. Company's designated method to receive
        notifications of claimed infringement is by emailing the Copyright Agent at
        admin@messari.io. You acknowledge that if you fail to comply with all of the requirements
        of this Section, your DMCA notice may not be valid.
      </p>

      <p>
        15.3. Counter-Notice. If you believe that your content that was removed (or to which access
        was disabled) is not infringing, or that you have the authorization from the copyright
        owner, the copyright owner's agent, or pursuant to the law, to post and use the material in
        your content, you may send a counter-notice containing the following information to the
        Copyright Agent:
      </p>

      <p>(a) Your physical or electronic signature;</p>

      <p>
        (b) Identification of the content that has been removed or to which access has been
        disabled, and the location at which the content appeared before it was removed or disabled;
      </p>

      <p>
        (c) A statement that you have a good faith belief that the content was removed or disabled
        as a result of mistake or a misidentification of the content; and
      </p>

      <p>
        (d) Your name, address, telephone number, and e-mail address, a statement that you consent
        to the jurisdiction of the federal court in New York, and a statement that you will accept
        service of process from the person who provided notification of the alleged infringement.
      </p>

      <p>
        15.4. Effects of Counter-Notice(s). If a counter-notice is received by the Copyright Agent,
        Company may send a copy of the counter-notice to the original complaining party informing
        that person that it may replace the removed content or cease disabling it in 10 business
        days. Unless the copyright owner files an action seeking a court order against the content
        provider, member or user, the removed content may be replaced, or access to it restored, in
        10 or more business days after receipt of the counter-notice, at Company's sole discretion.
      </p>

      <p></p>

      <p className="text-xl font-semibold">16. CLASS ACTION AND ARBITRATION</p>

      <p>
        16.1. Notice. THIS SECTION CONTAINS A BINDING ARBITRATION CLAUSE AND CLASS ACTION WAIVER.
        IT AFFECTS YOUR RIGHTS ABOUT HOW TO RESOLVE ANY DISPUTE WITH US. Except if you opt-out as
        expressly permitted below, or except to the extent contrary to applicable law
        (&quot;Excluded Disputes&quot;), you hereby agree that all disputes between you and us
        (whether or not such dispute involves a third party) with regard to your relationship with
        us, including, without limitation, disputes related to these Terms of Use, your use of the
        Service, and/or rights of privacy and/or publicity, may, in our discretion, be resolved by
        binding, individual arbitration under the American Arbitration Association's rules for
        arbitration of consumer-related disputes, and you hereby expressly waive trial by jury. As
        an alternative, you may bring your claim in your local &quot;small claims&quot; court, if
        permitted by that small claims court's rules. You may bring claims only on your own behalf.
        You agree that you will not participate in any class action or class-wide arbitration for
        any claims covered by this Agreement. You also agree not to participate in claims brought
        in a private attorney general or representative capacity, or consolidated claims involving
        another person's account, if we are a party to the proceeding. This dispute resolution
        provision will be governed by the U.S. Federal Arbitration Act, to the extent permissible.
        In the event the American Arbitration Association is unwilling or unable to set a hearing
        date within 160 days of filing the case, then either we or you can elect to have the
        arbitration administered instead by the Judicial Arbitration and Mediation Services (JAMS).
        Judgment on the award rendered by the arbitrator may be entered in any court having
        competent jurisdiction. Any provision of applicable law notwithstanding, the arbitrator
        will not have authority to award damages, remedies, or awards that conflict with these
        Terms of Use.
      </p>

      <p>
        16.2. Opt Out. You may opt out of this agreement to arbitrate. If you do so, neither you
        nor we can require the other to participate in an arbitration proceeding. To opt out, you
        must notify us in writing within 30 days of the date that you first became subject to this
        arbitration provision. You must use this address to opt out: admin@messari.io. You must
        include your name and residence address, the email address you use for your account with
        us, and a clear statement that you want to opt out of this arbitration agreement. If and to
        the extent the prohibition against class actions and other claims brought on behalf of
        third parties contained above is found to be unenforceable, then such preceding language in
        this Arbitration section will be null and void. This arbitration agreement will survive the
        termination of your relationship with us.
      </p>

      <p className="text-xl font-semibold">17. MISCELLANEOUS</p>

      <p>
        17.1. Compliance. You must comply with all laws, rules and regulations, now or hereafter
        promulgated by any government authority or agency that are applicable to your use of the
        Services or the transactions contemplated in this Agreement.
      </p>

      <p>
        17.2. Entire Agreement. This is the entire agreement between you and Company with regard to
        the matters described herein, and govern your use of the Services, superseding any prior
        agreements between you and Company with respect thereto, excluding applicable executed
        contracts for other Messari products and services. The failure of Company to exercise or
        enforce any right or provision of this Agreement will not constitute a waiver of such right
        or provision. If any provision of this Agreement is found by a court of competent
        jurisdiction to be invalid, the parties nevertheless agree that the court should endeavor
        to give effect to the parties' intentions as reflected in the provision, and the other
        provisions hereof shall remain in full force and effect. You agree that regardless of any
        statute or law to the contrary, any claim or cause of action arising out of this Agreement
        or related to use of the Services must be filed within 3 months after such claim or cause
        of action arose, or be forever barred.
      </p>

      <p>
        17.3. Assignment. You may not assign your rights or obligations hereunder, and any attempt
        by you to sublicense, assign, or transfer any of the rights, duties, or obligations
        hereunder, or to exceed the scope of this Agreement is void. In the event that Company is
        sold to a third party, such a sale will not be deemed a transfer of personal information so
        long as that third party agrees to assume Company's obligations as to these Terms of
        Services and any associated Privacy Policy.
      </p>

      <p>
        17.4. Governing Law. This Agreement, the Services, and the rights and obligations of the
        parties with respect to the Services will be subject to and construed in accordance with
        the laws of the State of New York, excluding conflict of law principles. By accessing or
        using any Services, you agree that the statutes and laws of the State of New York, without
        regard to conflicts of law principles thereof, will apply to all matters arising from or
        relating to the use thereof. You also agree and hereby submit to the exclusive legal
        jurisdiction and venues of the Courts of New York, New York with respect to such matters.
      </p>
    </div>
  )
}

export default TermsPage
