export const dateTimeConversion = (dateString) => {
  var d = JSON.stringify(new Date(dateString))
  var months = [
    'Jan',
    'Feb',
    'Mar',
    'Apr',
    'May ',
    'Jun',
    'Jul',
    'Aug',
    'Sep',
    'Oct',
    'Nov',
    'Dec'
  ]
  const slicedDate = d.slice(1, 11)
  const splitDate = slicedDate.split('-')
  const slicedTime = d.slice(12, 17)
  return (
    splitDate[2] +
    ' ' +
    months[parseInt(splitDate[1]) - 1] +
    ' , ' +
    splitDate[0] +
    ' ' +
    slicedTime
  )
}

export const includeComma = (number) => {
  if (number === null) {
    return
  }
  return number.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',')
}
