import React from 'react'
import { Avatar } from '@material-ui/core'
import { includeComma } from '../../../../services/globalFunctions'

const SingleResponsiveRankTable = ({ data, rank }) => {
  const {
    profit,
    contest_id,
    initial_coins,
    name,
    value: invested,
    contestant_id,
    change_per
  } = data
  return (
    <div className="flex justify-between items-start px-4 py-3 gap-4 font-700">
      <div className="  flex-1 flex gap-2 items-start text-sm">
        <p>{rank}</p>

        <p>{name}</p>
      </div>

      <div className={`  text-center text-base text-${change_per < 0 ? `red` : `green`}-600 `}>
        {change_per === null ? <p>0 %</p> : <p>{includeComma(change_per.toFixed(2))}%</p>}
      </div>
      <div className=" text-center text-base ">
        {invested === null ? (
          <p className="p-1 px-3 text-xs bg-gray-300 rounded-full">0 $</p>
        ) : (
          <p className="p-1 px-3 text-xs bg-gray-300 rounded-full">
            {includeComma(
              (invested > 1000 || invested < -1000 ? invested / 1000 : invested).toFixed(2)
            )}
            {(invested < -1000 || invested > 1000) && ` k`} $
          </p>
        )}
      </div>
    </div>
  )
}

export default SingleResponsiveRankTable
