export const TradeSimulatorData = [
  {
    title: 'Get 100000 of Virtual Token to trade with',
    image: 'https://assets2.lottiefiles.com/private_files/lf30_0vn96xcf.json',
    description:
      'No deposit/Credit Card Info needed. Practice trading with virtual money to sharpen your knowledge of how the cryto Market Works.',
    invert: false
  },

  {
    title: 'Avoid getting fooled with zero trading commissions in other simulators.',
    image: 'https://assets8.lottiefiles.com/packages/lf20_txepjtac.json',
    description:
      'Our trading contest includes commission simulation so you get less unpleasant suprises when you start trading with real money.',
    invert: true
  },

  {
    title: 'Get Rewards by participating in the contest',
    image: 'https://assets7.lottiefiles.com/packages/lf20_chmdvwyh.json',
    description:
      'Get prizes and awards on winning the contest. Be recognized as a Cryto Expert and earn fee by guiding others on crypto Trading.',
    invert: false
  }
]
