import React, { useState, useEffect, Fragment } from 'react'
import { makeStyles } from '@material-ui/styles'
import clsx from 'clsx'
import SingleArticle from './SingleArticle'
import axios from '../../../../services/axios'
import EndPoints from '../../../../services/api'
import Loader from '../../loader/Loader'
import uuid from 'react-uuid'
import { ErrorBoundary } from 'react-error-boundary'
import ErrorComponent from '../../error/ErrorComponent'

const useStyles = makeStyles((theme) => ({
  scrollBar: {
    '&::-webkit-scrollbar': {
      background: 'transparent'
    }
  }
}))

const { getAboutCoin } = EndPoints.singleCoin

const ArticleComponent = () => {
  const [articleData, setArticleData] = useState([])
  const [loading, setLoading] = useState(true)

  const fetchArticleData = async () => {
    try {
      const { data } = await axios.get(`${getAboutCoin}?type=news`)

      //console.log(JSON.parse(data.body).message)
      setArticleData(JSON.parse(data.body).message)
      setLoading(false)
    } catch (err) {
      console.log(err.message)
    }
  }

  useEffect(() => {
    fetchArticleData()
  }, [])

  const classes = useStyles()
  return (
    <div
      className={`h-full flex flex-col overflow-scroll overflow-x-hidden sm:p-4 md:p-2 lg:p-4 w-full lg:w-1/3 text-white  bg-purple-dark `}>
      {loading ? (
        <Loader />
      ) : (
        <Fragment>
          <ErrorBoundary FallbackComponent={ErrorComponent}>
            {articleData.map((article) => (
              <SingleArticle key={uuid()} article={article} />
            ))}
          </ErrorBoundary>
        </Fragment>
      )}

      <div className="pb-52"></div>
    </div>
  )
}

export default ArticleComponent
