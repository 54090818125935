import React, { useEffect, useState } from 'react'
import { useHistory } from 'react-router-dom'
import { includeComma } from '../../../services/globalFunctions'

const SingleListing = ({ singleCoinData, theme = 'light' }) => {
  const {
    Domain: domain,
    PercentChange7d: change7d,
    PercentChange24h: change24h,
    activity,
    category,
    coinname,
    risk,
    id,
    marketcap,
    maxsupply: coinlimit,
    newsRating,
    price,
    score,
    symbol,
    tweetsRating,
    urlOfLogo: logo
  } = singleCoinData

  const [changedPrice, setChangedprice] = useState(price === null ? -1 : price.toFixed(2))
  const [changed24h, setChanged24h] = useState(change24h === null ? 0 : change24h.toFixed(2))
  const [changed7d, setChanged7d] = useState(change7d === null ? 0 : change7d.toFixed(2))

  const limit = includeComma(Math.floor(parseFloat(coinlimit.replace(/,/g, '')) / 1000000))

  const fakePriceChange = (random) => {
    if (price !== null) {
      if (random >= price) {
        return
      }

      if (Math.floor(random * 3) % 2 === 0) {
        setChangedprice((price + random / 10).toFixed(2))
      }

      setChangedprice((price - random / 10).toFixed(2))
    }
  }

  const fake24hChange = (random) => {
    if (change24h !== null) {
      if ((change24h > 0 && random >= change24h) || change24h - random / 10 < -100) {
        return
      }

      if (Math.floor(random * 3) % 2 === 0) {
        setChanged24h((change24h + random / 10).toFixed(2))
      }

      setChanged24h((change24h - random / 10).toFixed(2))
    }
  }

  const fake7dChange = (random) => {
    if (change7d !== null) {
      if ((change7d > 0 && random >= change7d) || change7d - random / 10 < -100) {
        return
      }

      if (Math.floor(random * 3) % 2 === 0) {
        setChanged7d((change7d + random / 10).toFixed(2))
      }

      setChanged7d((change7d - random / 10).toFixed(2))
    }
  }

  useEffect(() => {
    const changeInterval = setInterval(() => {
      const random = parseFloat(Math.random().toFixed(2))

      fakePriceChange(random)
      fake24hChange(random)
      fake7dChange(random)
    }, 3000)

    return () => {
      clearInterval(changeInterval)
    }
  }, [])
  const history = useHistory()

  return (
    <tr
      className={` text-xs xl:text-sm font-600 cursor-pointer ${
        theme === 'dark' ? 'bg-purple-darkest' : 'bg-purple-700 bg-opacity-10 '
      }  `}
      onClick={() => history.push(`/coin/${coinname}/${id}`)}>
      <td className="flex justify-center items-center p-2 xl:px-4 ">
        <div className=" h-8 w-8 self-start mt-2">
          <img className="w-full h-full object-cover" src={logo} alt={coinname} />
        </div>
        <div className="flex flex-col mx-4 py-1 flex-1  ">
          <h4 className="text-lg font-600 mb-1">
            {coinname} <span className="text-xs">({symbol})</span>
          </h4>
          <p className="text-xs max-w-xs ">Domain : {domain}</p>
        </div>
      </td>
      <td className="text-center p-2 ">
        $ {changedPrice === -1 ? '-' : includeComma(changedPrice)}
      </td>
      {change24h === null ? (
        <td>-</td>
      ) : (
        <td className={`text-center p-2 ${changed24h >= 0 ? `text-green-500` : ` text-red-500`}`}>
          {changed24h >= 0 ? `+ ${changed24h}` : ` ${changed24h}`}%
        </td>
      )}

      {change7d === null ? (
        <td>-</td>
      ) : (
        <td className={`text-center p-2 ${changed7d >= 0 ? `text-green-500` : ` text-red-500`}`}>
          {changed7d >= 0 ? `+ ${changed7d}` : ` ${changed7d}`} %
        </td>
      )}

      <td className="text-center p-2">
        $ {marketcap !== null ? `${includeComma((marketcap / 1000000000).toFixed(2))} B` : '-'}
      </td>
      <td className="text-center p-2 capitalize">{category}</td>
      <td className="text-center p-2 ">{coinlimit === '-1' ? 'unlimited' : `${limit} M`}</td>
      <td className="text-center p-2">
        {activity !== null
          ? activity.split(' ')[0].toLowerCase() === 'no'
            ? `None`
            : activity.split(' ')[0]
          : ''}
      </td>
      <td className="text-center p-2">{score === null ? '-' : score.toFixed(2)}</td>
      <td className="text-center p-2">{risk === null ? '-' : risk.toFixed(2)}</td>
      <td className="text-center p-2">
        {tweetsRating !== null || newsRating !== null
          ? Math.max(tweetsRating, newsRating).toFixed(2)
          : '-'}
      </td>
    </tr>
  )
}

export default SingleListing
