import digijournal from '../../assets/img/digi-journal.png'
import digiherald from '../../assets/img/digi-herald.png'
import fox from '../../assets/img/fox.png'
import financialcontent from '../../assets/img/financial-content.png'
import marketwatch from '../../assets/img/mar-watch.png'
import fortwaynes from '../../assets/img/fort-waynes.png'

export const brandData = [
  {
    name: 'Digital Journal',
    image: digijournal,
    url: 'https://www.digitaljournal.com/pr/crypto-research-fund-provides-essential-tools-insights-to-understand-cryptocurrency-better'
  },
  {
    name: 'Fox',
    image: fox,
    url: 'https://www.wtnzfox43.com/story/46190313/crypto-research-fund-provides-essential-tools-insights-to-understand-cryptocurrency-better'
  },
  {
    name: 'Market Watch',
    image: marketwatch,
    url: 'https://www.marketwatch.com/press-release/crypto-research-fund-provides-essential-tools-insights-to-understand-cryptocurrency-better-2022-03-30'
  },
  {
    name: 'Daily Herald',
    image: digiherald,
    url: 'http://finance.dailyherald.com/dailyherald/news/read/42298961/'
  },
  {
    name: 'Financial Content',
    image: financialcontent,
    url: 'https://markets.financialcontent.com/startribune/news/read/42298961'
  },
  {
    name: 'fwnbc',
    image: fortwaynes,
    url: 'https://fwnbc.marketminute.com/article/getnews-2022-3-30-crypto-research-fund-provides-essential-tools-and-insights-to-understand-cryptocurrency-better'
  }
]
