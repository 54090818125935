import React from 'react'

const TextInput = ({ onChange, value, labelText, placeholder, errorMessage }) => {
  return (
    <label className="flex flex-col text-xs flex-1 font-600 my-2 z-0 ">
      <p>{labelText}* </p>

      <input
        className="p-4 rounded-xl border-2  my-2 font-600 border-gray-300 placeholder-gray-400"
        type="text"
        value={value}
        onChange={onChange}
        placeholder={placeholder}
        style={{ width: '100%' }}
      />
      {errorMessage && <p className="text-red-500 text-xs ml-4">{errorMessage}</p>}
    </label>
  )
}

export default TextInput
