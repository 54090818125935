export const HOME_PAGE = '/'
export const SUBSCRIPTION_PAGE = '/subscribe'
export const NEWS_PAGE = '/news'
export const LISTING_PAGE = '/listing'
export const SINGLE_COIN_PAGE = '/coin/:name/:id'
export const WATCH_LIST_PAGE = '/watchlist'
export const RECOMMENDATION_PAGE = '/recommendation'
export const PORTFOLIO_PAGE = '/portfolio'
export const STATIC_PORTFOLIO_PAGE = '/static-portfolio'
export const INVESTMENT_PAGE = '/invest'
export const AUTHENTICATION_PAGE = '/auth'
export const GAME_PAGE = '/games'
export const BUCKET_PAGE = '/buckets'
export const ANALYST_PAGE = '/analyst'
export const UNTOLD_STORY_PAGE = '/untold-stories'
export const SINGLE_BUCKET_PAGE = '/bucket/:name/:id'
export const ALL_CONTEST_PAGE = '/all-contests'
export const SINGLE_CONTEST_PAGE = '/single-contest/:name/:id'
export const CONTEST_PAGE = '/contest/:name/:id'
export const RANK_LIST_PAGE = '/rank-list/:name/:id'
export const TRADE_LIST_PAGE = '/trade-list/:name/:id'
export const BOT_RECOMMENDATION_PAGE = '/algo-signals'
export const BOT_PAGE = '/algo-performance'
export const TRADE_SIMULATOR_PAGE = '/trade-simulator'
export const DASHBOARD_PAGE = '/dashboard'
export const BLOG_PAGE = '/blogs'
export const POLICY_PAGE = '/privacy-policy'
export const TERMS_PAGE = '/terms-of-use'
export const CONTACT_US_PAGE = '/contact-us'
export const ERROR_PAGE = '*'
