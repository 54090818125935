import { makeStyles, Tooltip } from '@material-ui/core'
import React, { useRef, useState } from 'react'
import { useAlert } from 'react-alert'
import { AiFillStar, AiOutlineStar } from 'react-icons/ai'
import { useSelector } from 'react-redux'
import { useHistory } from 'react-router-dom'
import uuid from 'react-uuid'
import EndPoints from '../../../services/api'
import axios from '../../../services/axios'
import { includeComma } from '../../../services/globalFunctions'
import AuthModal from '../auth/AuthModal'
import ClickWrapper from '../click-wrapper/ClickWrapper'

const Header = ({ name, id, data, watchList }) => {
  const [addToWatchlist, setAddToWatchlist] = useState(watchList)
  const [watchListLoader, setWatchlistLoader] = useState(false)
  const [showAuthModal, setShowAuthModal] = useState(false)
  const { loggedIn, user } = useSelector((state) => state.auth)
  const history = useHistory()
  const labelRef = useRef()
  const { price } = data[1][`${id}`]
  const { score, risk } = data[5][`${id}`]
  const { change24hr } = data[0].keymetrics
  const { last30dcommits } = data[2][`id${id}`]
  const { newsRating, tweetsRating } = data[4][`${id}`]

  const { addToWatchlistEndpoint } = EndPoints.watchList
  const alert = useAlert()
  const addCoinToWatchlist = async () => {
    const config = {
      headers: {
        Authorization: `Bearer ${user.token}`
      }
    }
    setWatchlistLoader(true)
    try {
      const { data } = await axios.post(
        `${addToWatchlistEndpoint}?user_id=${user.user_id}`,
        {
          assetid: id,
          userid: user.user_id,
          command: 'ADD'
        },
        config
      )
      //console.log(data)
      setAddToWatchlist(true)
      alert.show('Added to Watchlist')
    } catch (err) {
      console.log(err.message)
    }
    setWatchlistLoader(false)
  }

  const handleAddToWatchList = () => {
    if (loggedIn) {
      //do something
      if (!addToWatchlist) {
        addCoinToWatchlist()
      }
    } else {
      setShowAuthModal(true)
    }
  }
  const useStyles = makeStyles((theme) => ({
    arrow: {
      color: theme.palette.common.black
    },
    tooltip: {
      backgroundColor: theme.palette.common.black,
      maxWidth: 300,
      padding: 10,
      fontSize: 12
    }
  }))
  const classes = useStyles()
  return (
    <div className="w-full flex md:items-center justify-evenly md:justify-between flex-col md:flex-row p-2 md:px-10 relative">
      <div className="flex flex-col">
        <div className="flex flex-col my-4 md:my-0 px-4 md:px-0 ">
          <h2 className="flex items-center text-lg font-600">
            About {name}{' '}
            <Tooltip
              arrow
              key={uuid()}
              title={addToWatchlist ? 'Added to Watchlist' : 'Add to Watchlist'}
              classes={classes}>
              <label
                onClick={handleAddToWatchList}
                ref={labelRef}
                className="text-yellow-500 text-lg mx-3 p-1 cursor-pointer ">
                <input
                  className="hidden"
                  value={addToWatchlist}
                  // onChange={(e) => setAddToWatchlist(e.target.checked)}
                  type="checkbox"
                  disabled={watchListLoader}
                />
                {addToWatchlist ? <AiFillStar /> : <AiOutlineStar />}
              </label>
            </Tooltip>
          </h2>
          <p className="text-xs">
            Accurately tracking the investment performance of your crypto assets.
          </p>
        </div>
      </div>

      <div className="flex justify-between md:justify-evenly  text-xs p-2 md:ml-4 ">
        <div className="flex flex-col mx-2 sm:mx-4  text-center">
          <p className="mb-2 md:mb-4">Price</p>
          <p className="text-xs lg:text-sm mb-1 font-500">
            ${price === null ? '-' : includeComma(price.toFixed(2))}
          </p>
          {change24hr === null ? (
            <p className="text-center">-</p>
          ) : (
            <p className={`font-500 text-${change24hr >= 0 ? 'green-500' : 'red-500'}`}>
              {change24hr > 0 ? `+${change24hr}` : change24hr}%
            </p>
          )}
        </div>
        <div className="flex flex-col mx-2 sm:mx-4  text-center">
          <p className="mb-2 md:mb-4">Trade Rating</p>
          <p className="text-xs lg:text-sm">
            {score === null ? '-' : ((score * 5) / 100).toFixed(2)}
          </p>
        </div>
        <div className="flex flex-col mx-2 sm:mx-4  text-center">
          <p className="mb-2">CRF Rating</p>
          <p className="bg-purple-400 bg-opacity-10 rounded-full p-2 text-xs lg:text-sm">
            {score === null ? '-' : score.toFixed(2)}
          </p>
        </div>
        <div className="flex flex-col mx-2 sm:mx-4  text-center">
          <p className="mb-2">Risk Rating</p>
          <p className="bg-purple-400 bg-opacity-10 rounded-full p-2 text-xs lg:text-sm">
            {risk === null ? '-' : risk.toFixed(2)}
          </p>
        </div>
        <div className="flex flex-col mx-2 sm:mx-4 text-center">
          <p className="mb-2 md:mb-4">Github Activity</p>
          <p className="text-xs lg:text-sm">{last30dcommits === null ? 'None' : last30dcommits}</p>
        </div>
        <div className="flex flex-col mx-2 sm:mx-4 text-center">
          <p className="mb-2 md:mb-4">Social Score</p>
          <p className="text-xs lg:text-sm">
            {tweetsRating === null || newsRating === null
              ? '-'
              : Math.max(tweetsRating, newsRating)}
          </p>
        </div>
      </div>
      {showAuthModal && (
        <div className="fixed left-0 right-0 top-0 h-screen w-screen z-10 backdrop-filter backdrop-blur-sm grid place-items-center">
          <div className="z-50">
            <ClickWrapper func={() => setShowAuthModal(false)}>
              <AuthModal close={() => setShowAuthModal(false)} />
            </ClickWrapper>
          </div>
        </div>
      )}
    </div>
  )
}

export default Header
