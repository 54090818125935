export const recommendationtableHeader = [
  {
    title: '#'
  },
  {
    title: 'Coin / Name'
  },
  {
    title: 'Reco Date'
  },
  {
    title: 'Reco Price'
  },
  {
    title: 'LTP'
  },

  {
    title: 'Price Change (Reco Date)'
  },
  {
    title: 'View'
  },
  {
    title: 'Risk'
  },
  {
    title: 'Potential Holding'
  }
]
