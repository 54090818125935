import React, { useEffect, useState } from 'react'
import { useHistory } from 'react-router-dom'
import { includeComma } from '../../../services/globalFunctions'

const SingleResponsiveTable = ({ singleCoinData, theme = 'light' }) => {
  const {
    Domain: domain,
    PercentChange7d: change7d,
    PercentChange24h: change24h,
    coinname,
    id,
    marketcap,
    newsRating,
    price,
    risk,
    score,
    symbol,
    tweetsRating,
    urlOfLogo: logo
  } = singleCoinData

  const [changedPrice, setChangedprice] = useState(price === null ? -1 : price.toFixed(2))
  const [changed24h, setChanged24h] = useState(change24h === null ? 0 : change24h.toFixed(2))
  const [changed7d, setChanged7d] = useState(change7d === null ? 0 : change7d.toFixed(2))

  const fakePriceChange = (random) => {
    if (price !== null) {
      if (random >= price) {
        return
      }

      if (Math.floor(random * 3) % 2 === 0) {
        setChangedprice((price + random / 10).toFixed(2))
      }

      setChangedprice((price - random / 10).toFixed(2))
    }
  }

  const fake24hChange = (random) => {
    if (change24h !== null) {
      if ((change24h > 0 && random >= change24h) || change24h - random / 10 < -100) {
        return
      }

      if (Math.floor(random * 3) % 2 === 0) {
        setChanged24h((change24h + random / 10).toFixed(2))
      }

      setChanged24h((change24h - random / 10).toFixed(2))
    }
  }

  const fake7dChange = (random) => {
    if (change7d !== null) {
      if ((change7d > 0 && random >= change7d) || change7d - random / 10 < -100) {
        return
      }

      if (Math.floor(random * 3) % 2 === 0) {
        setChanged7d((change7d + random / 10).toFixed(2))
      }

      setChanged7d((change7d - random / 10).toFixed(2))
    }
  }

  useEffect(() => {
    const changeInterval = setInterval(() => {
      const random = parseFloat(Math.random().toFixed(2))

      fakePriceChange(random)
      fake24hChange(random)
      fake7dChange(random)
    }, 3000)

    return () => {
      clearInterval(changeInterval)
    }
  }, [])

  const history = useHistory()

  return (
    <div
      className={`${
        theme === 'dark' ? 'bg-purple-darkest' : 'bg-purple-400 bg-opacity-10 '
      } rounded-2xl  p-3 sm:px-4 sm:py-6 my-3 cursor-pointer`}
      onClick={() => history.push(`/coin/${coinname}/${id}`)}>
      <div className="flex justify-between ">
        <div className="flex ">
          <div className=" h-8 w-8 self-start mt-2">
            <img className="w-full h-full object-cover" src={logo} alt={coinname} />
          </div>

          <div className="flex flex-col ml-2">
            <h4 className="text-lg font-600 mb-1">{coinname}</h4>
            <p className="text-xs">{symbol}</p>
          </div>
        </div>

        <div className="flex flex-col items-center py-1">
          <p className="text-xl font-600">
            {' '}
            $ {changedPrice === -1 ? '-' : includeComma(changedPrice)}
          </p>
        </div>
      </div>

      <div className="text-sm flex justify-between items-center my-6">
        <div className=" flex flex-col items-center">
          <p className="font-500">MKTCAP</p>
          <p>
            {' '}
            $ {marketcap !== null ? `${includeComma((marketcap / 1000000000).toFixed(2))} B` : '-'}
          </p>
        </div>

        <div className="flex flex-col items-center">
          <p className="font-500">7 D</p>
          {change7d === null ? (
            <p className="text-center">-</p>
          ) : (
            <p
              className={`flex items-center text-sm font-600 ${
                changed7d >= 0 ? `text-green-400` : `text-red-400`
              }`}>
              {changed7d >= 0 ? `+ ${changed7d}` : ` ${changed7d}`} %{' '}
              <span className="ml-2 text-lg font-700">{changed7d >= 0 ? `↑` : `↓`}</span>
            </p>
          )}
        </div>
        <div className="flex flex-col items-center">
          <p className="font-500">24 H</p>
          {change24h === null ? (
            <p className="text-center"> - </p>
          ) : (
            <p
              className={`flex items-center text-sm font-600 ${
                changed24h >= 0 ? `text-green-400` : `text-red-400`
              } `}>
              {changed24h >= 0 ? `+ ${changed24h}` : ` ${changed24h}`} %{' '}
              <span className="ml-2 text-lg font-700">{changed24h >= 0 ? `↑` : `↓`}</span>
            </p>
          )}
        </div>
      </div>
      <div className="flex justify-between items-center gap-2 text-xs">
        <div className="mr-4 text-center">
          <p>
            Domain <br></br> {domain}
          </p>
        </div>
        <div className="text-center">
          <p>
            Rating <br></br> {score === null ? '-' : score.toFixed(2)}
          </p>
        </div>
        <div className="text-center">
          <p>
            Risk <br></br> {risk === null ? '-' : risk.toFixed(2)}
          </p>
        </div>

        <div className="ml-4 text-center">
          <p>
            Social Score <br></br>{' '}
            {tweetsRating !== null || newsRating !== null
              ? Math.max(tweetsRating, newsRating).toFixed(2)
              : '-'}
          </p>
        </div>
      </div>
    </div>
  )
}

export default SingleResponsiveTable
