import React, { memo } from 'react'
import { useSelector } from 'react-redux'
import { Switch, Route, Redirect } from 'react-router-dom'
import ContestPage from '../main/pages/contest/ContestPage'
import RanklistPage from '../main/pages/rank-list/RanklistPage'
import TradelistPage from '../main/pages/trade-list/TradelistPage'
import AuthPage from '../main/pages/auth/AuthPage'
import BucketPage from '../main/pages/bucket/BucketPage'
import ErrorPage from '../main/pages/error/ErrorPage'
import GamePage from '../main/pages/games/GamePage'
import InvestmentPage from '../main/pages/invest/InvestmentPage'
import LandingPage from '../main/pages/landing/LandingPage'
import ListingPage from '../main/pages/listing/ListingPage'
import NewsPage from '../main/pages/news/NewsPage'
import Recommendation from '../main/pages/recommendation/Recommendation'
import SingleBucketPage from '../main/pages/single-bucket/SingleBucketPage'
import SingleCoinPage from '../main/pages/single-coin/SingleCoinPage'
import StaticPortfolioPage from '../main/pages/static-portfolio/StaticPortfolioPage'
import WatchlistPage from '../main/pages/watchlist/WatchlistPage'
import PortfolioPage from '../main/pages/portfolio/PortfolioPage'
import HomePage from '../main/pages/home/HomePage'
import AnalystPage from '../main/pages/analyst/AnalystPage'
import TradeSimulatorPage from '../main/pages/trade-simulator/TradeSimulatorPage'
import UntoldPage from '../main/pages/untold-stories/UntoldPage'
import AllContestPage from '../main/pages/all-contest/AllContestPage'
import SingleContestPage from '../main/pages/single-contest/SingleContestPage'
import PolicyPage from '../main/pages/privacy/PrivacyPage'
import TermsPage from '../main/pages/terms/TermsPage'
import {
  HOME_PAGE,
  NEWS_PAGE,
  LISTING_PAGE,
  ERROR_PAGE,
  SINGLE_COIN_PAGE,
  WATCH_LIST_PAGE,
  RECOMMENDATION_PAGE,
  STATIC_PORTFOLIO_PAGE,
  PORTFOLIO_PAGE,
  GAME_PAGE,
  INVESTMENT_PAGE,
  AUTHENTICATION_PAGE,
  BUCKET_PAGE,
  SINGLE_BUCKET_PAGE,
  CONTEST_PAGE,
  RANK_LIST_PAGE,
  SUBSCRIPTION_PAGE,
  TRADE_LIST_PAGE,
  ANALYST_PAGE,
  TRADE_SIMULATOR_PAGE,
  UNTOLD_STORY_PAGE,
  ALL_CONTEST_PAGE,
  SINGLE_CONTEST_PAGE,
  TERMS_PAGE,
  DASHBOARD_PAGE,
  POLICY_PAGE,
  BOT_RECOMMENDATION_PAGE,
  BLOG_PAGE,
  BOT_PAGE,
  CONTACT_US_PAGE
} from './Routes'
import DashboardPage from '../main/pages/dashboard/DashboardPage'
import BlogPage from '../main/pages/blogs/BlogPage'
import BotRecommendation from '../main/pages/bot-recommendation/BotRecommendation'
import BotPage from '../main/pages/bot-page/BotPage'
import ContactPage from '../main/pages/contact/ContactPage'

const SwitchConfig = () => {
  const { loggedIn } = useSelector((state) => state.auth)

  return (
    <Switch>
      <Route exact path={HOME_PAGE}>
        {!loggedIn ? <HomePage /> : <Redirect to={LISTING_PAGE} />}
      </Route>
      <Route exact path={NEWS_PAGE}>
        <NewsPage />
      </Route>
      <Route exact path={LISTING_PAGE}>
        <ListingPage />
      </Route>
      <Route exact path={SINGLE_COIN_PAGE}>
        <SingleCoinPage />
      </Route>
      <Route exact path={WATCH_LIST_PAGE}>
        <WatchlistPage />
      </Route>
      <Route exact path={RECOMMENDATION_PAGE}>
        <Recommendation />
      </Route>
      <Route exact path={STATIC_PORTFOLIO_PAGE}>
        <StaticPortfolioPage />
      </Route>
      <Route exact path={PORTFOLIO_PAGE}>
        <PortfolioPage />
      </Route>
      <Route exact path={BOT_RECOMMENDATION_PAGE}>
        <BotRecommendation />
      </Route>
      <Route exact path={BOT_PAGE}>
        <BotPage />
      </Route>
      <Route exact path={GAME_PAGE}>
        <GamePage />
      </Route>
      <Route exact path={INVESTMENT_PAGE}>
        <InvestmentPage />
      </Route>
      <Route exact path={UNTOLD_STORY_PAGE}>
        <UntoldPage />
      </Route>
      <Route exact path={ALL_CONTEST_PAGE}>
        <AllContestPage />
      </Route>
      <Route exact path={SINGLE_CONTEST_PAGE}>
        <SingleContestPage />
      </Route>
      <Route exact path={AUTHENTICATION_PAGE}>
        {!loggedIn ? <AuthPage /> : <Redirect to={LISTING_PAGE} />}
      </Route>
      <Route exact path={BUCKET_PAGE}>
        <BucketPage />
      </Route>
      <Route exact path={SINGLE_BUCKET_PAGE}>
        <SingleBucketPage />
      </Route>
      <Route exact path={CONTEST_PAGE}>
        {loggedIn ? <ContestPage /> : <Redirect to={ALL_CONTEST_PAGE} />}
      </Route>
      <Route exact path={RANK_LIST_PAGE}>
        {loggedIn ? <RanklistPage /> : <Redirect to={ALL_CONTEST_PAGE} />}
      </Route>
      <Route exact path={TRADE_LIST_PAGE}>
        {loggedIn ? <TradelistPage /> : <Redirect to={ALL_CONTEST_PAGE} />}
      </Route>
      <Route exact path={SUBSCRIPTION_PAGE}>
        {!loggedIn ? <LandingPage /> : <Redirect to={LISTING_PAGE} />}
      </Route>
      <Route exact path={DASHBOARD_PAGE}>
        <DashboardPage />
      </Route>
      <Route exact path={BLOG_PAGE}>
        <BlogPage />
      </Route>
      <Route exact path={POLICY_PAGE}>
        <PolicyPage />
      </Route>
      <Route exact path={TERMS_PAGE}>
        <TermsPage />
      </Route>
      <Route exact path={CONTACT_US_PAGE}>
        <ContactPage />
      </Route>
      <Route exact path={ANALYST_PAGE}>
        <AnalystPage />
      </Route>
      <Route exact path={TRADE_SIMULATOR_PAGE}>
        <TradeSimulatorPage />
      </Route>
      <Route exact path={ERROR_PAGE}>
        <ErrorPage />
      </Route>
    </Switch>
  )
}

export default memo(SwitchConfig)
