import React from 'react'
import SingleInvest from '../../apps/invest/SingleInvest'
import SingleResponsiveInvest from '../../apps/invest/SingleResponsiveInvest'

const InvestmentPage = () => {
  return (
    <div className="bg-purple-darkest text-white p-3 sm:p-6 md:p-10">
      {' '}
      <div className="flex flex-col my-4 lg:my-0 sm:px-4 md:px-0 ">
        <h2 className="text-lg font-600 mb-2">Invest in companies growth</h2>
        <p className="text-xs">
          Accurately tracking the investment performance of your crypto assets.
        </p>
      </div>
      <div className="hidden lg:block">
        <SingleInvest />
        <SingleInvest />
        <SingleInvest />
        <SingleInvest />
        <SingleInvest />
        <SingleInvest />
        <SingleInvest />
        <SingleInvest />
        <SingleInvest />
        <SingleInvest />
      </div>
      <div className="grid grid-cols-2 md:grid-cols-3  gap-4 lg:hidden p-4 px-1 sm:px-3">
        <SingleResponsiveInvest />
        <SingleResponsiveInvest />
        <SingleResponsiveInvest />
        <SingleResponsiveInvest />
        <SingleResponsiveInvest />
        <SingleResponsiveInvest />
        <SingleResponsiveInvest />
        <SingleResponsiveInvest />
        <SingleResponsiveInvest />
        <SingleResponsiveInvest />
        <SingleResponsiveInvest />
        <SingleResponsiveInvest />
      </div>
      <div className="p-10 pt-32 md:pt-56 pb-20 text-xs text-center">
        <p>
          Never invest in cryptos more than you can afford to lose and always try to keep them in
          your own wallet!
        </p>
      </div>
    </div>
  )
}

export default InvestmentPage
