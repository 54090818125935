import { Avatar, makeStyles } from '@material-ui/core'
import React from 'react'
import clsx from 'clsx'
import SingleResponsiveRankTable from './SingleResponsiveRankTable'
import RankGift from './RankGift'
import uuid from 'react-uuid'

const useStyles = makeStyles((theme) => ({
  hiddenScrollBar: {
    '&::-webkit-scrollbar': {
      display: 'none'
    }
  }
}))

const ResponsiveRankTable = ({ data }) => {
  const classes = useStyles()
  return (
    <div className="bg-purple-darkest text-white flex-1 ">
      <h1 className="text-center text-blue-400 text-2xl font-600 p-4">Best Traders !!!</h1>
      <div className="relative flex flex-col justify-end h-full ">
        <div className="bg-purple-400 bg-opacity-10 w-full p-10 h-full rounded-t-full">
          <div className="bg-purple-400 bg-opacity-10 w-full h-full rounded-t-full "></div>
        </div>
        <div className="absolute top-0 left-2  ">
          <RankGift />
        </div>
        <div className="absolute top-24 right-3  ">
          <RankGift />
        </div>
        <div className="absolute top-0 right-2 bg-pink-500 p-4 rounded-full"></div>
        <div className="absolute top-32 left-1 bg-blue-500 p-3 rounded-full"></div>
        <div className="absolute -top-12 left-4 bg-green-500 p-2 rounded-full"></div>

        <div className="absolute top-0 left-0 w-full flex justify-center items-end   ">
          <div className="w-4/5 flex justify-center items-end bg-yellow-100 text-center  relative">
            {data[1] && (
              <div className="flex flex-col items-center w-1/3 absolute top-8 left-6 ">
                <Avatar
                  className="mb-3 "
                  src="https://img.icons8.com/color/48/000000/second-place-ribbon.png"
                  style={{ width: 50, height: 50 }}
                />

                <div
                  id="ranklist_second"
                  className="flex flex-col items-center justify-start bg-gradient-to-b from-yellow-600 to-yellow-200 w-full h-44 sm:h-64 font-700 text-sm">
                  <p className="w-20">{data[1].name}</p>
                </div>
              </div>
            )}

            {data[0] && (
              <div className="flex flex-col items-center w-1/3 absolute top-1 left-1/2 transform -translate-x-1/2 z-10">
                <Avatar
                  className="mb-3 "
                  src="https://img.icons8.com/color/48/000000/first-place-ribbon.png"
                  style={{ width: 50, height: 50 }}
                />
                <div
                  id="ranklist_first"
                  className="flex flex-col items-center justify-start bg-gradient-to-b from-red-600 to-yellow-400 w-full h-44 sm:h-64 font-700 text-xl">
                  <p className="text-base w-20">{data[0].name}</p>
                </div>
              </div>
            )}

            {data[2] && (
              <div className="flex flex-col items-center w-1/3 absolute top-14 right-6">
                <Avatar
                  className="mb-3  "
                  src="https://img.icons8.com/color/48/000000/third-place-ribbon.png"
                  style={{ width: 50, height: 50 }}
                />

                <div
                  id="ranklist_third"
                  className="flex flex-col items-center justify-start bg-gradient-to-b from-yellow-500 to-yellow-100 w-full h-44 sm:h-64 font-700 text-xs">
                  <p className="w-20">{data[2].name}</p>
                </div>
              </div>
            )}
          </div>
        </div>

        <div className="h-2/3 absolute bottom-0 left-0 right-0 z-20 text-black">
          <div
            className={clsx(
              classes.hiddenScrollBar,
              'bg-white w-full h-full overflow-y-scroll rounded-t-3xl p-4 relative'
            )}>
            {data.length > 0 &&
              data.map((singlerank, idx) => (
                <SingleResponsiveRankTable key={uuid()} data={singlerank} rank={idx + 1} />
              ))}
          </div>
        </div>
      </div>
    </div>
  )
}

export default ResponsiveRankTable
