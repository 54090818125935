import React, { useEffect, useState } from 'react'
import { useSelector } from 'react-redux'
import uuid from 'react-uuid'
import { Legend, Line, LineChart, ResponsiveContainer, Tooltip, XAxis, YAxis } from 'recharts'
import EndPoints from '../../../services/api'
import axios from '../../../services/axios'
import { includeComma } from '../../../services/globalFunctions'
import Loader from '../loader/Loader'
const btnArray = [
  {
    title: '7 D',
    value: '7',
    price_value: 'week'
  },
  {
    title: '1 M',
    value: '30',
    price_value: 'month'
  },
  {
    title: '1 Y',
    value: '365',
    price_value: 'year'
  }
]
const MovingAverageChart = ({ coinid, setShowAuthModal }) => {
  const [loading, setLoading] = useState(false)
  const [chartData, setChartData] = useState([])
  const { getSingleCoinIndicators } = EndPoints.singleCoin
  const [duration, setDuration] = useState(btnArray[0])
  const { loggedIn } = useSelector((state) => state.auth)

  const fetchChartData = async () => {
    setLoading(true)
    try {
      const { data } = await axios.get(
        `${getSingleCoinIndicators}?limit=${
          duration.value
        }&coin_id=${coinid}&indiactor=${'SMA_ALL'}`
      )
      //console.log(JSON.parse(data.data))
      const parsedData = JSON.parse(data.data)

      let smaArr = []
      let n = parsedData.date.length
      for (var i = 0; i < n; i++) {
        smaArr.push({
          price: parsedData.price[n - i - 1],
          date: parsedData.date[n - i - 1],
          sma: parsedData.sma[n - i - 1],
          sma50: parsedData.sma50[n - i - 1],
          sma200: parsedData.sma200[n - i - 1]
        })
      }
      //console.log(smaArr)
      setChartData(smaArr)

      setLoading(false)
    } catch (err) {
      console.log(err.message)
    }
  }

  useEffect(() => {
    fetchChartData()
  }, [duration])

  return (
    <div className=" ">
      <div className="bg-purple-400 bg-opacity-10  flex-1 mx-2 rounded-xl p-2 sm:p-4  py-4 text-white ">
        <div className="flex flex-col md:flex-row gap-2 md:gap-4 md:justify-between md:items-center">
          <div className="flex items-center justify-between w-full">
            <h2 className="text-xs rounded-full p-3 py-2 bg-purple-dark">Moving Average</h2>
            <div className="flex items-center">
              {btnArray.map((btn) => (
                <button
                  onClick={() => {
                    if (btn.value === '365') {
                      if (!loggedIn) {
                        setShowAuthModal(true)
                        return
                      }
                    }
                    setDuration(btn)
                  }}
                  className={`p-1 px-2 m-1 text-xs rounded-full bg-opacity-10 ${
                    duration.value === btn.value ? 'bg-purple-dark' : '  '
                  } `}
                  key={uuid()}>
                  {btn.title}
                </button>
              ))}
            </div>
          </div>
        </div>
        {loading ? (
          <Loader />
        ) : (
          <ResponsiveContainer width="100%" height={350}>
            <LineChart
              width={500}
              height={300}
              data={chartData}
              margin={{
                top: 20,
                right: 10,
                left: 10,
                bottom: 10
              }}>
              <XAxis
                dataKey="date"
                // axisLine={false}
                tick={{ fill: 'white' }}
                tickLine={{ fill: 'white' }}
                style={{
                  fontSize: '.8rem'
                }}
              />
              <YAxis
                yAxisId="left"
                axisLine={false}
                tick={{ fill: 'white' }}
                tickLine={false}
                domain={['auto', 'auto']}
                style={{
                  fontSize: '.8rem'
                }}
              />
              <Tooltip
                wrapperStyle={{ background: 'transparent', fontSize: '.8rem', padding: '.5rem' }}
                labelStyle={{
                  color: 'white',
                  paddingBottom: '.4rem',
                  borderBottom: '1px solid gray'
                }}
                itemStyle={{ color: 'white', fontWeight: 'semi-bold' }}
                formatter={function (value) {
                  return `${includeComma(value)}`
                }}
                labelFormatter={function (value) {
                  return `${value}`
                }}
              />

              <Legend wrapperStyle={{ fontSize: '.8rem' }} />
              <Line
                yAxisId="left"
                type="monotone"
                dataKey="sma"
                stroke="#00E396"
                dot={false}
                activeDot={{ r: 4 }}
                strokeWidth={4}
              />
              <Line
                yAxisId="left"
                type="monotone"
                dataKey="sma50"
                stroke="#FFC300"
                dot={false}
                activeDot={{ r: 4 }}
                strokeWidth={4}
              />
              <Line
                yAxisId="left"
                type="monotone"
                dataKey="sma200"
                stroke="#ED4141"
                dot={false}
                activeDot={{ r: 4 }}
                strokeWidth={4}
              />
              <Line
                yAxisId="left"
                dot={false}
                type="monotone"
                dataKey="price"
                stroke="#41CBED"
                activeDot={{ r: 4 }}
                strokeWidth={5}
              />
            </LineChart>
          </ResponsiveContainer>
        )}
      </div>
    </div>
  )
}

export default MovingAverageChart
