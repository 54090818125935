import React, { useState } from 'react'
import SellTab from './SellTab'

import { IoClose } from 'react-icons/io5'
import TransactionDetails from './TransactionDetails'

const TradingTransactionModal = ({ close, tradingData, refreshData }) => {
  const [tab, setTab] = useState(0)
  const [transactionData, setTransactionData] = useState({})
  const [loading, setLoading] = useState(false)

  const sendTransactionData = async (data) => {
    setLoading(true)
    await setTransactionData(data)
    setLoading(false)
  }

  return (
    <div className="bg-purple-darkest text-left max-w-md w-screen m-auto text-white shadow-2xl rounded-xl overflow-hidden">
      <div className="bg-purple-900 p-4  md:p-8 bg-opacity-10 w-full h-full">
        <div className="flex justify-between items-center">
          <h1 className="text-lg font-600">Sell Transaction</h1>

          <button className="text-xl" onClick={close}>
            <IoClose />
          </button>
        </div>

        <div>
          {tab === 0 && (
            <SellTab
              tradingData={tradingData}
              setTab={(val) => setTab(val)}
              sendTransactionData={sendTransactionData}
            />
          )}
          {tab === 1 && (
            <TransactionDetails
              closeTab={close}
              refreshData={refreshData}
              data={transactionData}
            />
          )}
        </div>
      </div>
    </div>
  )
}

export default TradingTransactionModal
