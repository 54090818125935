import { makeStyles } from '@material-ui/core'
import React, { Fragment } from 'react'
import clsx from 'clsx'
import SingleResponsiveTable from './SingleResponsiveTable'
import uuid from 'react-uuid'

const useStyles = makeStyles((theme) => ({
  hiddenScrollBar: {
    '&::-webkit-scrollbar': {
      display: 'none'
    }
  }
}))

const ResponsiveTable = ({ data, func }) => {
  const classes = useStyles()

  return (
    <div
      className={clsx(classes.hiddenScrollBar, ' flex flex-col sm:p-2 md:p-10 md:pt-4 md:hidden')}>
      {Object.keys(data).length === 0 ? (
        <Fragment>
          <p className="text-center text-gray-300  font-600 py-4">Your Watchlist is Empty</p>
        </Fragment>
      ) : (
        Object.keys(data).map((singleData) => (
          <SingleResponsiveTable
            key={uuid()}
            id={singleData}
            data={data[singleData]}
            refresh={func}
          />
        ))
      )}
    </div>
  )
}

export default ResponsiveTable
