import React, { Fragment, useEffect, useState } from 'react'
import { ErrorBoundary } from 'react-error-boundary'
import { Helmet } from 'react-helmet'
import { useSelector } from 'react-redux'
import { Link, useHistory, useParams } from 'react-router-dom'
import uuid from 'react-uuid'
import { LISTING_PAGE, NEWS_PAGE } from '../../../navigation/Routes'
import EndPoints from '../../../services/api'
import axios from '../../../services/axios'
import AuthModal from '../../apps/auth/AuthModal'
import ClickWrapper from '../../apps/click-wrapper/ClickWrapper'
import ErrorComponent from '../../apps/error/ErrorComponent'
import Loader from '../../apps/loader/Loader'
import NewsAccordion from '../../apps/news/news/NewsAccordion'
import SingleTweet from '../../apps/news/tweets/SingleTweet'
import AlgorithmChart from '../../apps/single-coin/AlgorithmChart'
import ChartComponent from '../../apps/single-coin/Chart'
import ComparisionChart from '../../apps/single-coin/ComparisionChart'
import Header from '../../apps/single-coin/Header'
import KeyMetrics from '../../apps/single-coin/KeyMetrics'
import MovingAverageChart from '../../apps/single-coin/MovingAverageChart'
import PriceMetrics from '../../apps/single-coin/PriceMetrics'
import ResponsiveChart from '../../apps/single-coin/ResponsiveChart'
import SingleCoinBotRecommendation from '../../apps/single-coin/SingleCoinBotRecommendation'

const SingleCoinPage = () => {
  const { id, name } = useParams()
  const history = useHistory()
  const { user, loggedIn } = useSelector((state) => state.auth)
  const [pageLoading, setPageLoading] = useState(true)
  const [addedToWatchList, setAddedToWatchlist] = useState(false)
  const [loading, setLoading] = useState(true)
  const [newsData, setNewsData] = useState({})
  const [tweetData, setTweetdata] = useState({})
  const [truncate, setTruncate] = useState(true)
  const [singleCoinData, setSingleCoinData] = useState([])
  const [showAuthModal, setShowAuthModal] = useState(false)

  const {
    getSingleNews,
    getSingleTweet,
    getSingleCoinPrice,
    getSingleCoinSentiment,
    getSingleCoinGithub,
    getSingleCoin24hChange,
    getSingleCoinMetrics,
    getChartData,
    getSingleCoinRating,
    getAboutCoin,
    getAllSingleCoinData,
    checkWatchlist,
    getSingleCoinBotRecommendation
  } = EndPoints.singleCoin

  const fetchNewsAndTweet = async () => {
    try {
      const res = await Promise.all([
        axios.get(`${getSingleNews}?coinid=${id}`),
        axios.get(`${getSingleTweet}?coinid=${id}`)
      ])
      const newData = res.map((res) => JSON.parse(res.data.body))

      setNewsData(newData[0])
      setTweetdata(newData[1])
      setLoading(false)
    } catch (err) {
      console.log(err.message)
    }
  }

  const fetchSingleCoinData = async () => {
    try {
      const res = await Promise.all([
        axios.get(`${getSingleCoinMetrics}?coinid=${id}`),
        axios.get(`${getSingleCoinPrice}?coinid=${id}`),
        axios.get(`${getSingleCoinGithub}?coinid=${id}`),
        axios.get(`${getSingleCoin24hChange}?coinid=${id}`),
        axios.get(`${getSingleCoinSentiment}?coinid=${id}`),
        axios.get(`${getSingleCoinRating}?coinid=${id}`),
        axios.get(`${getAllSingleCoinData}?id=${id}`),
        axios.get(`${getAboutCoin}?type=static&coin_id=${id}`),
        axios.get(`${getSingleCoinBotRecommendation}/coinwise?coin_id=${id}&limit=7&filter=ALL`)
      ])

      console.log(res.map((res) => res.data))
      const coinData = res.map((res) => JSON.parse(res.data.body))
      //console.log(coinData)
      setSingleCoinData(coinData)
      setPageLoading(false)
    } catch (err) {
      console.log(err.message)
    }
  }

  const checkWatchList = async () => {
    const config = {
      headers: {
        Authorization: `Bearer ${user.token}`
      }
    }
    try {
      const { data } = await axios.get(
        `${checkWatchlist}?user_id=${user.user_id}&coin_id=${id}`,
        config
      )
      // console.log(JSON.parse(data.body))
      setAddedToWatchlist(JSON.parse(data.body).message)
    } catch (err) {
      console.log(err.message)
    }
  }

  useEffect(() => {
    window.scrollTo(0, 0)
    if (loggedIn) {
      checkWatchList()
    }
    fetchSingleCoinData()
    fetchNewsAndTweet()
  }, [])

  return (
    <div className="bg-purple-darkest text-white">
      <Helmet>
        <meta charSet="utf-8" />
        <title className="capitalize">
          {name} Crypto Prices and Rating | CryptoResearchfund-A CryptoEd Platform{' '}
        </title>
      </Helmet>
      <div className="w-full ">
        <div className="pt-4 px-4 md:px-10">
          <p className="text-xs">
            <Link to={LISTING_PAGE} className="text-blue-300">
              Coin
            </Link>{' '}
            <span className="mx-2"> &#62; </span>{' '}
            <button onClick={() => history.push(`/coin/${name}/${id}`)}>{name}</button>
          </p>
        </div>
      </div>
      {pageLoading ? (
        <Loader />
      ) : (
        <>
          <ErrorBoundary FallbackComponent={ErrorComponent}>
            <Header id={id} name={name} data={singleCoinData} watchList={addedToWatchList} />
          </ErrorBoundary>
          <ErrorBoundary FallbackComponent={ErrorComponent}>
            {' '}
            <ChartComponent
              id={id}
              endpoint={getChartData}
              data={singleCoinData}
              setShowAuthModal={setShowAuthModal}
            />
          </ErrorBoundary>
          <ErrorBoundary FallbackComponent={ErrorComponent}>
            <ResponsiveChart
              id={id}
              endpoint={getChartData}
              data={singleCoinData}
              setShowAuthModal={setShowAuthModal}
            />
          </ErrorBoundary>

          <ErrorBoundary FallbackComponent={ErrorComponent}>
            <div className="p-2 sm:p-6 flex flex-col gap-10">
              <ErrorBoundary FallbackComponent={ErrorComponent}>
                {' '}
                <ComparisionChart coinid={id} setShowAuthModal={setShowAuthModal} />
              </ErrorBoundary>

              <ErrorBoundary FallbackComponent={ErrorComponent}>
                {' '}
                <AlgorithmChart coinid={id} setShowAuthModal={setShowAuthModal} />
              </ErrorBoundary>
              <ErrorBoundary FallbackComponent={ErrorComponent}>
                {' '}
                <MovingAverageChart coinid={id} setShowAuthModal={setShowAuthModal} />
              </ErrorBoundary>
            </div>
          </ErrorBoundary>
          <ErrorBoundary FallbackComponent={ErrorComponent}>
            <SingleCoinBotRecommendation
              coinid={id}
              data={singleCoinData[8].message}
              setShowAuthModal={setShowAuthModal}
            />
          </ErrorBoundary>

          <ErrorBoundary FallbackComponent={ErrorComponent}>
            <div className="w-full flex flex-col md:flex-row p-2 sm:p-5 max-w-7xl m-auto ">
              <KeyMetrics data={singleCoinData[0].keymetrics} />

              <PriceMetrics data={singleCoinData[0].pricemetrics} />
            </div>
          </ErrorBoundary>
        </>
      )}

      <div className="grid place-items-center p-4 sm:p-10 w-full max-w-7xl m-auto">
        <h1
          to={NEWS_PAGE}
          className="text-center bg-red-dark p-2 rounded-full font-700 uppercase w-full my-10 sm:mb-10 sm:mt-2">
          Influencers
        </h1>
        {loading ? (
          <Loader />
        ) : (
          <>
            <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-5">
              <ErrorBoundary FallbackComponent={ErrorComponent}>
                {Object.keys(tweetData)
                  .slice(0, 6)
                  .map((tweet) => (
                    <SingleTweet
                      bg_color={'bg-purple-400 '}
                      marginY={'0'}
                      key={uuid()}
                      tweet={tweetData[tweet]}
                    />
                  ))}
              </ErrorBoundary>
            </div>
            <Link to={NEWS_PAGE} className="mt-6 text-sm ">
              Click here to <span className="text-blue-400">see more</span>
            </Link>
          </>
        )}
      </div>

      <div className="grid place-items-center p-4 sm:p-10 w-full max-w-7xl m-auto">
        <h1
          to={NEWS_PAGE}
          className="text-center bg-red-dark p-2 rounded-full font-700 uppercase w-full mb-10">
          News
        </h1>
        {loading ? (
          <Loader />
        ) : (
          <>
            <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-5 w-full py-4">
              <ErrorBoundary FallbackComponent={ErrorComponent}>
                {Object.keys(newsData)
                  .slice(0, 6)
                  .map((news) => (
                    <NewsAccordion key={uuid()} news={newsData[news]} />
                  ))}
              </ErrorBoundary>
            </div>
            <Link to={NEWS_PAGE} className="mt-2 text-sm ">
              Click here to <span className="text-blue-400">see more</span>
            </Link>
          </>
        )}
      </div>

      <div className="p-4 sm:p-10 w-full max-w-7xl m-auto">
        {loading ? (
          <Loader />
        ) : (
          <ErrorBoundary FallbackComponent={ErrorComponent}>
            <Fragment>
              {singleCoinData[7]?.message.length > 0 && (
                <div className="w-full rounded-none md:rounded-2xl lg:rounded-3xl bg-purple-darkest md:bg-purple-400 md:bg-opacity-10 text-white p-2 md:p-4 lg:p-6 font-300 text-sm tracking-wider">
                  <h2 className="text-lg font-500 my-4">About {name}</h2>

                  <p className="my-4">{singleCoinData[7]?.message[0]?.Short_Desc}</p>

                  <h2 className="text-lg font-500 my-4">What is {name} ?</h2>

                  <div className="my-4">
                    <p className={`${truncate ? 'truncate' : ''}`}>
                      {singleCoinData[7]?.message[0]?.Long_Desc}
                    </p>
                    <button
                      onClick={() => setTruncate(!truncate)}
                      className="text-blue-500 font-semibold mt-1">
                      {' '}
                      Read {truncate ? 'More' : 'Less'}
                    </button>
                  </div>
                </div>
              )}
            </Fragment>
          </ErrorBoundary>
        )}
        {showAuthModal && (
          <div className="fixed left-0 right-0 top-0 h-screen w-screen z-10 backdrop-filter backdrop-blur-sm grid place-items-center">
            <div className="z-50">
              <ClickWrapper func={() => setShowAuthModal(false)}>
                <AuthModal close={() => setShowAuthModal(false)} />
              </ClickWrapper>
            </div>
          </div>
        )}
      </div>
    </div>
  )
}

export default SingleCoinPage
