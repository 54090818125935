import React from 'react'
import ReactApexChart from 'react-apexcharts'

const Crfscore = ({ score }) => {
  let scoreArray = []
  scoreArray.push(Math.ceil(score))

  const chartState = {
    series: scoreArray,
    options: {
      chart: {
        type: 'radialBar',
        toolbar: {
          show: false
        }
      },
      plotOptions: {
        radialBar: {
          startAngle: -90,
          endAngle: 90,
          hollow: {
            margin: 0,
            size: '65%',
            background: 'transparent',
            image: undefined,
            imageOffsetX: 0,
            imageOffsetY: 0,
            position: 'front',
            dropShadow: {
              enabled: true,
              top: 3,
              left: 0,
              blur: 4,
              opacity: 0.24
            }
          },
          track: {
            background: '#43397b',
            strokeWidth: '65%',
            margin: 0, // margin is in pixels
            dropShadow: {
              enabled: false
            }
          },

          dataLabels: {
            show: true,
            name: {
              show: false
            },

            value: {
              formatter: function (val) {
                return parseInt(val)
              },
              color: '#fff',
              fontSize: '14px',
              show: true
            }
          }
        }
      },
      fill: {
        type: 'gradient',
        gradient: {
          shade: 'dark',
          type: 'horizontal',
          shadeIntensity: 0.5,
          gradientToColors: ['#ABE5A1'],
          inverseColors: true,
          opacityFrom: 1,
          opacityTo: 1,
          stops: [0, 100]
        }
      },
      stroke: {
        lineCap: 'round'
      },
      labels: ['']
    }
  }

  // useEffect(() => {
  //   let scoreArray = []
  //   scoreArray.push(score)

  //   setState({
  //     series: scoreArray,
  //     ...state
  //   })
  // }, [])

  return (
    <div className="w-32 text-white ">
      <div id="chart" className="w-full h-full object-contain">
        <ReactApexChart
          options={chartState.options}
          series={chartState.series}
          type="radialBar"
          height={100}
          className="p-0  "
        />
      </div>
    </div>
  )
}

export default Crfscore
