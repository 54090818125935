import React, { useState } from 'react'
import ReactApexChart from 'react-apexcharts'
import { includeComma } from '../../../services/globalFunctions'

const PortfolioDonut = ({ sectorData }) => {
  const { sector_wise } = sectorData
  const series = Object.keys(sector_wise).map((sector) => sector_wise[sector].value)
  const options = {
    chart: {
      type: 'donut',
      foreColor: '#ffffff'
    },
    stroke: {
      width: 0
    },
    plotOptions: {
      pie: {
        startAngle: 0,
        endAngle: 360
      }
    },
    labels: Object.keys(sector_wise).map((sector) => sector),
    dataLabels: {
      enabled: true
    },
    fill: {
      type: 'gradient'
    },
    legend: {
      formatter: function (val, opts) {
        return val + ' - $ ' + includeComma(opts.w.globals.series[opts.seriesIndex].toFixed(2))
      },
      position: 'bottom'
    },
    title: {
      text: 'Investment by Sector',
      align: 'center',
      style: {
        fontSize: '10px'
      }
    }
    // responsive: [
    // 	{
    // 		breakpoint: 1024,
    // 		options: {
    // 			chart: {
    // 				width: 200,
    // 			},
    // 			legend: {
    // 				position: 'bottom',
    // 			},
    // 		},
    // 	},
    // ],
  }

  // const [donutSeries, setDonutSeries] = useState(series)
  // const [donutOptions, setDonutOptions] = useState(options)

  return (
    <div className="bg-purple-400 bg-opacity-10 h-full w-full p-6 lg:px-1 xl:p-10  rounded-xl ">
      <div id="chart" className="grid place-items-center text-white text-xs text-center">
        <ReactApexChart
          options={options}
          series={series}
          type="donut"
          height={350}
          className="w-full h-full grid place-items-center"
        />
      </div>
    </div>
  )
}

export default PortfolioDonut
