import React, { Fragment, useEffect, useState } from 'react'
import { ErrorBoundary } from 'react-error-boundary'
import { useParams } from 'react-router'
import EndPoints from '../../../services/api'
import axios from '../../../services/axios'
import ClickWrapper from '../../apps/click-wrapper/ClickWrapper'
import ErrorComponent from '../../apps/error/ErrorComponent'
import Loader from '../../apps/loader/Loader'
import BucketPerformanceChart from '../../apps/single-bucket/BucketPerformanceChart'
import BucketPieChart from '../../apps/single-bucket/BucketPieChart'
import BucketTable from '../../apps/single-bucket/BucketTable'
import CryptoTable from '../../apps/single-bucket/CryptoTable'
import TradingTransactionModal from '../../apps/single-bucket/modal/TradingTransactionModal'
import ResponsiveBucketChart from '../../apps/single-bucket/ResponsiveBucketChart'
import SectorDonut from '../../apps/single-bucket/SectorDonut'
import SingleBucketChart from '../../apps/single-bucket/SingleBucketChart'

const SingleBucketPage = () => {
  const { id, name } = useParams()

  const [singleBucketData, setSingleBucketData] = useState({})
  const [loading, setLoading] = useState(true)
  const [showTransactionModal, setShowTransactionModal] = useState(false)

  const [sectorData, setSectorData] = useState({
    series: [],
    labels: []
  })
  const [marketData, setMarketData] = useState({
    series: [],
    labels: []
  })
  const [allocationData, setAllocationData] = useState({
    series: [],
    labels: []
  })

  const ConvertChartData = (array, property, setState) => {
    let labelArr = []
    let seriesArr = []
    array.forEach((arr) => {
      if (labelArr.includes(arr[property])) {
        seriesArr[labelArr.indexOf(arr[property])] += arr.allocation
      } else {
        if (property === 'market_cap') {
          labelArr.push(parseFloat(arr[property]))
        } else labelArr.push(arr[property])

        seriesArr.push(arr.allocation)
      }
    })

    setState({
      labels: labelArr,
      series: seriesArr
    })
  }

  const { getSingleBucketChartData, getSingleBucketStaticData } = EndPoints.singleBucket

  const fetchSingleBucketData = async () => {
    try {
      const res = await Promise.all([
        axios.get(`${getSingleBucketChartData}/details?crfno=${id}`),
        axios.get(`${getSingleBucketStaticData}?bucket_id=${id}`)
      ])
      const parsedData = res.map((res) => JSON.parse(res.data.body).message)
      //console.log(parsedData)
      setSingleBucketData(parsedData)
      ConvertChartData(parsedData[0], 'sectors', setSectorData)
      ConvertChartData(parsedData[0], 'market_size', setMarketData)
      ConvertChartData(parsedData[0], 'coin_name', setAllocationData)
      setLoading(false)
    } catch (err) {
      console.log(err.message)
    }
  }

  useEffect(() => {
    fetchSingleBucketData()
  }, [])

  return (
    <div className="w-full h-full p-2 sm:p-4 md:p-6 text-white relative ">
      {!loading && (
        <div className="p-2 sm:p-0">
          <div className="flex flex-col  pt-6 ">
            <h2 className="text-lg font-600">{name}</h2>
            {/* <p className="text-xs">These are the coins with high risk and high profit potentials</p> */}
          </div>

          <div className="flex flex-col items-end  py-3 ">
            <div className="flex mt-3">
              <button
                onClick={() => setShowTransactionModal(true)}
                className="rounded-full bg-purple-400 bg-opacity-10 p-2 text-xs px-4 cursor-pointer">
                Invest now
              </button>
            </div>
          </div>
        </div>
      )}

      {loading ? (
        <Loader />
      ) : (
        <Fragment>
          <div>
            <ErrorBoundary FallbackComponent={ErrorComponent}>
              <SingleBucketChart staticData={singleBucketData[1]} id={id} />
              <ResponsiveBucketChart staticData={singleBucketData[1]} id={id} />
            </ErrorBoundary>
          </div>

          <div>
            <ErrorBoundary FallbackComponent={ErrorComponent}>
              <BucketPerformanceChart id={id} />
            </ErrorBoundary>
          </div>

          <div>
            <ErrorBoundary FallbackComponent={ErrorComponent}>
              {' '}
              <BucketTable data={singleBucketData[0]} />
            </ErrorBoundary>
          </div>

          <div className="grid grid-cols-1  md:grid-cols-3 gap-4 my-10 ">
            <ErrorBoundary FallbackComponent={ErrorComponent}>
              <SectorDonut header={'Sector'} data={sectorData} />
            </ErrorBoundary>
            <ErrorBoundary FallbackComponent={ErrorComponent}>
              {' '}
              <BucketPieChart header={'Currency'} data={allocationData} />
            </ErrorBoundary>
            <ErrorBoundary FallbackComponent={ErrorComponent}>
              <SectorDonut header={'Market'} data={marketData} />
            </ErrorBoundary>
          </div>
        </Fragment>
      )}
      {showTransactionModal && (
        <div className="fixed left-0 right-0 top-0 h-screen w-full z-10 backdrop-filter backdrop-blur-sm grid place-items-center">
          <div className="z-50">
            <ClickWrapper func={() => setShowTransactionModal(false)}>
              <TradingTransactionModal
                close={() => setShowTransactionModal(false)}
                bucketData={{ bucketName: name, bucketId: id, coinData: singleBucketData[0] }}
              />
            </ClickWrapper>
          </div>
        </div>
      )}

      <div className="p-10 pt-32 md:pt-56 pb-20 text-xs text-center">
        <p>
          Never invest in cryptos more than you can afford to lose and always try to keep them in
          your own wallet!
        </p>
      </div>
    </div>
  )
}

export default SingleBucketPage
