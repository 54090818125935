import React from 'react'
import BotStats from '../../apps/bot-page/BotStats'

const BotPage = () => {
  return (
    <div className="p-4 sm:p-6 md:p-10 min-h-screen text-white">
      <BotStats />
    </div>
  )
}

export default BotPage
