import React from 'react'
import { Link } from 'react-router-dom'
import { ALL_CONTEST_PAGE, GAME_PAGE } from '../../../navigation/Routes'
import SingleSimulator from '../../apps/trade-simulator/SingleSimulator'
import { TradeSimulatorData } from '../../apps/trade-simulator/TradeSimulatorData'
import tradeImg from '../../apps/trade-simulator/assets/Cryptoreseachfund-TradingSimulator.png'
import { Helmet } from 'react-helmet'

const TradeSimulatorPage = () => {
  return (
    <div className="p-4 sm:p-6 md:p-10 w-full min-h-screen text-white text-center md:text-left">
      <Helmet>
        <meta charSet="utf-8" />
        <title className="capitalize">Crypto Trade Simulator | Play and Earn Rewards.</title>
      </Helmet>
      <div className="w-full p-4 sm:p-6 max-w-7xl m-auto ">
        <div className="flex flex-col md:flex-row justify-between gap-10 items-center">
          <div className="md:w-2/5">
            <lottie-player
              src="https://assets2.lottiefiles.com/packages/lf20_3nndonug.json"
              background="transparent"
              speed="1"
              style={{ width: '100%', height: '100%' }}
              loop
              autoplay></lottie-player>
          </div>
          <div className="md:w-1/2 text-4xl font-bold flex flex-col justify-around gap-10">
            <h2 className="text-red-500">Practice trading and compete for fortune & fame</h2>

            <p className="text-xl font-light">
              75% of beginner traders lose their money. Use the Crypto Research Fund simulator to
              learn the basics and technical strategies of trading with zero risk of losing your
              hard-earned money or student loan.
            </p>
          </div>
        </div>

        <div className="">
          <h1 className="py-20 text-center text-4xl font-bold text-red-500">
            3 reasons why you’ll like Crypto Research Fund paper trading game
          </h1>
          <div className="flex flex-col gap-10">
            {TradeSimulatorData.map((data, idx) => (
              <SingleSimulator key={idx} idx={idx} data={data} />
            ))}
          </div>
          <div className="flex items-center gap-8 justify-center my-8">
            <Link
              className="capitalize bg-blue-500 text-white border-2 border-blue-500 hover:bg-blue-400 transition-all duration-300 px-3 py-2 text-sm font-500"
              to={GAME_PAGE}>
              Join with code
            </Link>
            <Link
              className="capitalize bg-blue-500 text-white border-2 border-blue-500 hover:bg-blue-400 transition-all duration-300 px-3 py-2 text-sm font-500"
              to={ALL_CONTEST_PAGE}>
              Join directly
            </Link>
          </div>
        </div>
      </div>
    </div>
  )
}

export default TradeSimulatorPage
