import React, { useEffect, useState } from 'react'
import { useAlert } from 'react-alert'
import { useSelector } from 'react-redux'
import uuid from 'react-uuid'
import EndPoints from '../../../../services/api'
import axios from '../../../../services/axios'
import { includeComma } from '../../../../services/globalFunctions'
import Loader from '../../loader/Loader'
import SingleBucketSellCoin from './SingleBucketSellCoin'

const SellTab = ({ bucketData, setTab, sendTransactionData }) => {
  const { bucketDetails } = bucketData
  const {
    block_user_id,
    bought_date,
    bucket_description,
    bucket_id,
    bucket_name,
    id,
    invested_amount,
    percent,
    proft_loss,
    user_id,
    value
  } = bucketDetails
  const { user } = useSelector((state) => state.auth)
  const [userAmount, setUserAmount] = useState(value)
  const [bucketSellNotes, setBucketSellNotes] = useState('')
  const [bucketSellData, setBucketSellData] = useState([])
  const [loadingTransaction, setLoadingTransaction] = useState(false)
  const [loading, setLoading] = useState(true)
  const alert = useAlert()
  const { buyCoinTrade } = EndPoints.trade
  const { bucketTransaction } = EndPoints.singleBucket
  const { getSingleBucketChartData } = EndPoints.singleBucket
  const fetchSellBucketData = async () => {
    const config = {
      headers: {
        Authorization: `Bearer ${user.token}`
      }
    }
    const postData = {
      bucket_id,
      block_user_id,
      user_id
    }
    try {
      const res = await Promise.all([
        axios.post(`${bucketTransaction}/sell?user_id=${user.user_id}`, postData, config),
        axios.get(`${getSingleBucketChartData}/details?crfno=${bucket_id}`, config)
      ])
      const parsedData = res.map((res) => JSON.parse(res.data.body).message)
      console.log(parsedData)
      setBucketSellData(parsedData)
      setLoading(false)
    } catch (err) {
      console.log(err.message)
    }
  }

  const makeTransaction = async (postData) => {
    const config = {
      headers: {
        Authorization: `Bearer ${user.token}`
      }
    }
    setLoadingTransaction(true)
    console.log(postData)
    try {
      const { data } = await axios.post(
        `${bucketTransaction}/transaction?user_id=${user.user_id}`,
        postData,
        config
      )
      if (data.errorMessage) {
        alert.error(data.errorMessage)
        setLoadingTransaction(false)
        return
      }
      alert.success(JSON.parse(data.body).message)
      console.log(JSON.parse(data.body))
      await sendTransactionData(JSON.parse(data.body))
      setLoadingTransaction(false)
      setTab(1)
    } catch (err) {
      console.log(err.message)
    }
  }

  const handleSellAsset = (e) => {
    e.preventDefault()
    const bucketPostData = {
      user_id: user.user_id,
      block_user_id,
      asset_type: 'CRYPTO',
      crf_block_id: bucket_id,
      trans_type: 'SELL',
      notes: bucketSellNotes,
      flag: 'NEW',
      fees: 0.1 * value,
      amount: userAmount,
      blocks: bucketSellData[1].map((coin) => {
        const bucketCoin = bucketSellData[0].data.find((data) => data.asset_id === coin.coin_id)
        const {
          alloted_price,
          amount_received,
          asset_id,
          charges,
          current_price,
          profit_loss,
          quantity,
          settlement_amount,
          trade_id,
          unit_price
        } = bucketCoin
        return {
          sector: coin.sectors,
          trade_id,
          ecosystem: 'TEST',
          asset_id: coin.coin_id,
          asset_name: coin.coin_name,
          unit_price: current_price,
          alloted_price,
          charges,
          fee: charges,
          quantity
        }
      })
    }
    console.log(bucketPostData)
    makeTransaction(bucketPostData)
  }

  useEffect(() => {
    fetchSellBucketData()
  }, [])

  return (
    <div className=" ">
      {loading ? (
        <Loader />
      ) : (
        <div className="flex flex-col w-full">
          <p className="text-xs my-2 font-400">Wallet : $ 10000</p>
          <div className="flex justify-center   bg-purple-darkest cursor-pointer border-2 border-purple-400 border-opacity-20 rounded-lg p-4 py-3 text-sm my-2">
            <p>{bucket_name}</p>
            {/* <p>
          {coinname} ({symbol})
        </p> */}
            {/* <p>$ {includeComma(price)}</p> */}
          </div>
          <div className="flex flex-col gap-3 max-h-52 overflow-y-scroll scrollbar-hidden">
            {bucketSellData[0].data.map((singleCoinData) => (
              <SingleBucketSellCoin key={uuid()} coinData={singleCoinData} />
            ))}
          </div>

          <div className="flex justify-between gap-2 my-2 text-gray-600 text-xs  placeholder-gray-400">
            <div className="flex flex-col flex-1  font-600">
              <p className="mb-3 text-white">Bucket Amount</p>

              <div className="p-2 bg-white text-gray-500 font-600 placeholder-gray-100  rounded-lg ">
                <p>{(0.99 * userAmount).toFixed(3)}</p>
              </div>
            </div>
            <div className="flex flex-col flex-1  font-600">
              <p className="mb-3 text-white">Fee</p>

              <div className="p-2 bg-white text-gray-500 font-600 placeholder-gray-100  rounded-lg ">
                <p>{(0.01 * userAmount).toFixed(3)}</p>
              </div>
            </div>
            <div className="flex flex-col flex-1  font-600">
              <p className="mb-3 text-white">Total</p>

              <div className="p-2 bg-white text-gray-500 font-600 placeholder-gray-100  rounded-lg ">
                <p>{userAmount.toFixed(3)}</p>
              </div>
            </div>
          </div>

          <div className="w-full my-2 text-xs">
            <textarea
              onChange={(e) => {
                setBucketSellNotes(e.target.value)
              }}
              value={bucketSellNotes}
              className="w-full p-2 rounded-lg font-600 h-20 text-gray-500"
              placeholder="Notes"
            />
          </div>

          <button
            onClick={handleSellAsset}
            className="w-full p-2 my-2 rounded-xl bg-red-dark hover:bg-red-600 transition-all duration-300">
            {loadingTransaction ? 'Processing ...' : 'Sell Bucket'}
          </button>
        </div>
      )}
      {/* <p className="text-xs my-2 font-400">Wallet : $ {includeComma(wallet)}</p> */}
    </div>
  )
}

export default SellTab
