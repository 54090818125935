import React from 'react'

const StaticPortfolioHeader = () => {
  return (
    <div
      id="static__portfolio__bg"
      className="flex flex-col md:flex-row justify-between p-8 pt-16 md:pt-20 relative ">
      <div className="flex flex-col w-full max-w-md md:pt-10">
        <h1 className="text-2xl md:text-3xl  text-blue-500 font-600 my-2 leading-snug tracking-wide">
          CRF Buckets are simple .
        </h1>

        <h5 className="text-lg font-400 my-2 leading-relaxed">
          a curated solution of crypto investments together with a strategy designed by pros and
          our intelligent Algos to simplify crypto investments .
        </h5>
      </div>
      <div className="w-full md:w-1/2 mt-10 md:mt-0 ">
        <lottie-player
          src="https://assets9.lottiefiles.com/packages/lf20_8wuout7s.json"
          background="transparent"
          speed="1"
          style={{ width: '100%', objectFit: 'contain' }}
          loop
          autoplay></lottie-player>
      </div>
    </div>
  )
}

export default StaticPortfolioHeader
