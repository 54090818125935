import React from 'react'
import { includeComma } from '../../../services/globalFunctions'
import ReactApexChart from 'react-apexcharts'
const BotChart = ({ data, amount }) => {
  const series = [
    {
      name: 'price',
      data: data.price.map((price) => price * amount)
    }
  ]

  const options = {
    chart: {
      type: 'area',
      foreColor: '#ffffff',
      toolbar: {
        show: false
      }
    },

    dataLabels: {
      enabled: false
    },
    stroke: {
      curve: 'smooth'
    },
    grid: {
      show: false
    },

    xaxis: {
      type: 'datetime',

      categories: data.date
    },
    yaxis: [
      {
        labels: {
          formatter: (value) => {
            return includeComma(value.toFixed(3))
          }
        }
      }
    ],
    tooltip: {
      x: {
        format: 'dd/MM/yy HH:mm'
      },
      theme: 'dark'
    },
    responsive: [
      {
        breakpoint: 786,
        options: {
          yaxis: [
            {
              labels: {
                formatter: function (val) {
                  return val > 1000 ? includeComma((val / 1000).toFixed(3)) + 'k' : val
                }
              }
            }
          ]
        }
      }
    ]
  }
  return <ReactApexChart options={options} series={series} type="line" height={250} />
}

export default BotChart
