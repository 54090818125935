import React, { useEffect, useState, useRef } from 'react'
import { useParams, useHistory } from 'react-router-dom'
import EndPoints from '../../../services/api'
import axios from '../../../services/axios'
import ListingTable from '../../apps/trade-list/ListingTable'
import ResponsiveTable from '../../apps/trade-list/ResponsiveTable'
import Loader from '../../apps/loader/Loader'
import useDidMountEffect from '../../hooks/useDidMountEffect'
import { ErrorBoundary } from 'react-error-boundary'
import ErrorComponent from '../../apps/error/ErrorComponent'
import { useSelector } from 'react-redux'
import { includeComma } from '../../../services/globalFunctions'
import { Helmet } from 'react-helmet'
import { FiSearch } from 'react-icons/fi'
import uuid from 'react-uuid'
import Chip from '@mui/material/Chip'
import { Fragment } from 'react'

const TradelistPage = () => {
  const { id, name } = useParams()
  const [loadingTrade, setLoadingTrade] = useState(true)
  const [contestData, setContestData] = useState({})
  const [loadingTable, setLoadingTable] = useState(true)
  const [listingData, setListingData] = useState({})
  const inputRef = useRef()
  const [showChip, setShowChip] = useState(false)
  const [filteredListingData, setFilteredListingData] = useState([])
  const [pageNumber, setPageNumber] = useState(1)
  const { user } = useSelector((state) => state.auth)
  const [totalPage, setTotalPage] = useState(0)
  const [coinNameInput, setCoinNameInput] = useState('')
  const [sort, setSort] = useState('marketcap')
  const [sortOrder, setSortOrder] = useState('desc')

  const sortArr = [
    {
      title: 'None',
      property: ''
    },
    {
      title: 'Price',
      property: 'price'
    },
    {
      title: 'Marketcap',
      property: 'marketcap'
    },
    {
      title: '24 h change',
      property: 'PercentChange24h'
    },
    {
      title: '7 d change',
      property: 'PercentChange7d'
    },

    {
      title: 'Rating',
      property: 'score'
    }
  ]

  const quickSearchArr = [
    {
      title: 'Top assets',
      property: ''
    },
    {
      title: 'Top assets',
      property: ''
    },
    {
      title: 'Top assets',
      property: ''
    },
    {
      title: 'Top assets',
      property: ''
    },
    {
      title: 'Top assets',
      property: ''
    },
    {
      title: 'Top assets',
      property: ''
    },
    {
      title: 'Top assets',
      property: ''
    },
    {
      title: 'Top assets',
      property: ''
    }
  ]

  const { getSearchCoin, getAllInOne, getSortCoin } = EndPoints.listing

  const history = useHistory()
  const { getContestSummary, checkContestValidity } = EndPoints.contest

  const sliceData = (array, start, end) => {
    return array.slice(start - 1, end)
  }

  const fetchTableData = async () => {
    try {
      const { data } = await axios.get(
        `${getAllInOne}?page=${pageNumber}&limit=${20}&sort_by=${sort}&order=${sortOrder}`
      )

      setTotalPage(JSON.parse(data.body).total_pages)
      //console.log(JSON.parse(data.body).message)
      setListingData(JSON.parse(data.body).message)
      setFilteredListingData(JSON.parse(data.body).message)
      setLoadingTable(false)
    } catch (err) {
      console.log(err.message)
    }
  }

  const fetchTradingPageData = async () => {
    const config = {
      headers: {
        Authorization: `Bearer ${user.token}`
      }
    }
    try {
      const res = await Promise.all([
        axios.get(`${getContestSummary}?user_id=${user.user_id}&contest_id=${id}`, config),
        axios.get(`${checkContestValidity}/check?contest_id=${id}`)
      ])

      const parsed_data = res.map((res) => JSON.parse(res.data.body))
      //console.log(parsed_data)
      if (parsed_data[1].message) {
        setContestData(parsed_data[0].message)
        fetchTableData()
        setLoadingTrade(false)
      } else {
        history.goBack()
      }
    } catch (err) {
      console.log(err.message)
    }
  }

  useEffect(() => {
    fetchTradingPageData()
  }, [])

  const filterStaticData = async (e) => {
    e.preventDefault()

    setLoadingTable(true)
    if (inputRef.current.value.length === 0) {
      fetchTableData()
      return
    }
    setShowChip(true)
    try {
      const { data } = await axios.get(
        `${getSearchCoin}?search=${inputRef.current.value}&page=${pageNumber}&limit=${20}`
      )
      // console.log(JSON.parse(data.body).message)
      setTotalPage(JSON.parse(data.body).total_pages)
      setFilteredListingData(
        sliceData(
          JSON.parse(data.body).message,
          (pageNumber - 1) * 20 + 1,
          (pageNumber - 1) * 20 + 20
        )
      )
      //setCoinNameInput('')
      inputRef.current.value = ''
      setLoadingTable(false)
    } catch (err) {
      console.log(err.message)
    }
  }

  const fetchSortedData = async (sortValue) => {
    try {
      const { data } = await axios.get(
        `${getSortCoin}?&page=${pageNumber}&limit=${20}&sort_by=${sortValue}&order=${sortOrder}`
      )
      // console.log(JSON.parse(data.body).message)
      setTotalPage(JSON.parse(data.body).total_pages)
      setFilteredListingData(JSON.parse(data.body).message)
    } catch (err) {
      console.log(err.message)
    }
  }

  const handleChipDelete = () => {
    setShowChip(false)
    setCoinNameInput('')
    fetchTableData()
  }

  useEffect(() => {
    fetchSortedData(sort)
  }, [sortOrder])

  const handleSorting = async (e) => {
    //e.preventDefault()
    setSort(e.target.value)
    setPageNumber(1)
    fetchSortedData(e.target.value)
  }

  useDidMountEffect(() => {
    window.scrollTo({ behavior: 'smooth', top: '0px' })

    fetchTableData()
  }, [pageNumber])

  return (
    <div className="text-white min-h-screen">
      <Helmet>
        <meta charSet="utf-8" />
        <title>CRF | Trade - {name} </title>
      </Helmet>

      <div className="text-center text-blue-500 hidden md:flex justify-between items-center p-4 sm:p-6 md:p-10">
        <button
          onClick={() => history.push(`/rank-list/${name}/${id}`)}
          className="rounded-full border-2 border-blue-500 p-3 text-xs w-32 cursor-pointer font-700 text-white">
          {' '}
          Rank List
        </button>
        <h1 className="mx-4 font-700 text-2xl md:text-2xl">{name}</h1>

        <button
          onClick={() => history.push(`/contest/${name}/${id}`)}
          className="rounded-full border-2 border-blue-500 p-3 text-xs w-32 cursor-pointer font-700 text-white">
          {' '}
          Contest
        </button>
      </div>
      <div className="text-center text-blue-500 flex flex-col md:hidden items-center mt-6 pb-10">
        <h1 className="mx-4 font-700 text-2xl">{name}</h1>
        <div className="mt-4 flex justify-evenly">
          <button
            onClick={() => history.push(`/rank-list/${name}/${id}`)}
            className="rounded-full border-2 border-blue-500 p-3 text-xs w-32 mx-4">
            {' '}
            Rank List
          </button>
          <button
            onClick={() => history.push(`/contest/${name}/${id}`)}
            className="rounded-full border-2 border-blue-500 p-3 text-xs w-32 mx-4">
            {' '}
            Contest
          </button>
        </div>
      </div>
      {loadingTrade ? (
        <Loader />
      ) : (
        <div className="px-4 sm:px-6 md:px-10 ">
          <div className="flex bg-purple-darker justify-evenly items-start mb-6 px-4 sm:px-6 md:px-10 py-6 rounded-xl overflow-hidden text-sm md:text-base ">
            <div className="flex flex-col justify-between h-full mx-2 ">
              <p className="mb-2">CRF Balance:</p>
              <p className=" font-600">$ {includeComma(contestData.token)}</p>
            </div>
            <div className="flex flex-col justify-between items-center h-full mx-2 ">
              <p className="mb-2">Invested</p>
              <p className=" font-600">$ {includeComma(contestData.invested_amount)}</p>
            </div>
            <div className="flex flex-col justify-between h-full mx-2 ">
              <p className="mb-2">Change</p>
              <p className=" font-600">$ {includeComma(contestData.change.toFixed(2))}</p>
            </div>
          </div>
        </div>
      )}

      <div className=" p-4 sm:p-6 md:p-10">
        <div className="flex justify-between items-center">
          <h1 className=" text-xl sm:text-3xl md:text-4xl my-2 mb-2 sm:mb-5 border-b border-dotted border-white w-max pb-2">
            Crypto Market
          </h1>
          <div className="flex flex-col gap-2 ">
            <form
              className="flex justify-between items-center bg-transparent md:w-80 p-2 border rounded-md overflow-hidden  border-indigo-300 border-opacity-40"
              onSubmit={filterStaticData}>
              <input
                ref={inputRef}
                className="bg-transparent text-xs "
                value={coinNameInput}
                type="text"
                placeholder="Search Coin "
                onChange={(e) => setCoinNameInput(e.target.value.trim())}
              />
              <button className="grid place-items-center text-xs" type="submit">
                <FiSearch />
              </button>
            </form>
            {showChip && coinNameInput.length > 0 && (
              <Chip
                sx={{
                  mb: 1,
                  color: 'white',
                  display: 'flex',
                  justifyContent: 'space-between',
                  alignItems: 'center',
                  width: 150
                }}
                label={coinNameInput}
                color="primary"
                variant="outlined"
                // onClick={handleClick}
                onDelete={handleChipDelete}
              />
            )}
          </div>
        </div>
        {loadingTable ? (
          <Loader />
        ) : (
          <Fragment>
            <ErrorBoundary FallbackComponent={ErrorComponent}>
              <div className="mb-4 flex justify-between items-center gap-3">
                {' '}
                <div className="flex gap-2 items-center ">
                  {/* {quickSearchArr.map((quickSearch) => (
                  <button
                    className="p-2 px-3 rounded-md border-1 border-purple-500 border-opacity-50 text-xs hover:bg-purple-400 hover:bg-opacity-10 "
                    key={uuid()}>
                    {quickSearch.title}
                  </button>
                ))} */}
                </div>
                <div className="flex items-center gap-2 ">
                  <div className="border-1 border-purple-300 border-opacity-40 rounded-md overflow-hidden p-1">
                    <select
                      value={sortOrder}
                      onChange={(e) => setSortOrder(e.target.value)}
                      className="bg-transparent text-xs text-gray-300  cursor-pointer">
                      <option value={'asc'} className="bg-purple-darker p-1 text-xs">
                        Ascending
                      </option>
                      <option value={'desc'} className="bg-purple-darker p-1 text-xs">
                        Descending
                      </option>
                    </select>
                  </div>
                  <div className="border-1 border-purple-300 border-opacity-40 rounded-md overflow-hidden p-1">
                    <select
                      value={sort}
                      onChange={handleSorting}
                      className="bg-transparent text-xs text-gray-300  cursor-pointer">
                      {sortArr.map((sort) => (
                        <option
                          key={uuid()}
                          value={sort.property}
                          className="bg-purple-darker p-1 text-xs">
                          {sort.title}
                        </option>
                      ))}
                    </select>
                  </div>
                </div>
              </div>
              <ListingTable
                contestId={id}
                contestName={name}
                wallet={contestData.token}
                cryptoData={filteredListingData}
                page={pageNumber}
                onPageChange={(page) => setPageNumber(page)}
                count={totalPage}
              />
            </ErrorBoundary>
            <ErrorBoundary FallbackComponent={ErrorComponent}>
              <ResponsiveTable
                contestId={id}
                contestName={name}
                wallet={contestData.token}
                cryptoData={filteredListingData}
                page={pageNumber}
                onPageChange={(page) => setPageNumber(page)}
                count={totalPage}
              />{' '}
            </ErrorBoundary>
          </Fragment>
        )}
      </div>

      <div className="p-10 pt-32 md:pt-56 pb-20 text-xs text-center">
        <p>
          Never invest in cryptos more than you can afford to lose and always try to keep them in
          your own wallet!
        </p>
      </div>
    </div>
  )
}

export default TradelistPage
