import React, { useEffect, useState, Fragment } from 'react'
import ReactApexChart from 'react-apexcharts'
import uuid from 'react-uuid'
import EndPoints from '../../../services/api'
import axios from '../../../services/axios'
import { BsInfoSquare } from 'react-icons/bs'
import { includeComma } from '../../../services/globalFunctions'
import Loader from '../loader/Loader'
import IndexTable from './IndexTable'
import { ErrorBoundary } from 'react-error-boundary'
import ErrorComponent from '../error/ErrorComponent'
import ClickWrapper from '../click-wrapper/ClickWrapper'
import { makeStyles } from '@material-ui/core/styles'
import { Tooltip } from '@material-ui/core'

const useStyles = makeStyles((theme) => ({
  arrow: {
    color: theme.palette.common.black
  },
  tooltip: {
    backgroundColor: theme.palette.common.black,
    maxWidth: 300,
    padding: 10,
    fontSize: 12
  }
}))

const btnArray = [
  {
    title: '7 D',
    value: '7'
  },
  {
    title: '1 M',
    value: '30'
  },
  {
    title: '3 M',
    value: '90'
  },
  {
    title: '6 M',
    value: '180'
  }
]

const IndexChart = () => {
  const series = [
    {
      name: 'price',
      data: []
    }
  ]

  const options = {
    chart: {
      type: 'area',
      foreColor: '#ffffff',
      toolbar: {
        show: false
      }
    },

    dataLabels: {
      enabled: false
    },
    stroke: {
      curve: 'smooth'
    },
    grid: {
      show: false
    },

    xaxis: {
      type: 'datetime',

      categories: []
    },
    yaxis: {
      labels: {
        show: true,
        formatter: (value) => {
          return includeComma(value.toFixed(2))
        }
      }
    },
    tooltip: {
      x: {
        format: 'dd/MM/yy HH:mm'
      },
      theme: 'dark'
    }
  }
  const classes = useStyles()
  const [duration, setDuration] = useState(btnArray[0].value)
  const [loading, setLoading] = useState(true)
  const [price, setPrice] = useState(0)
  const [chartSeries, setChartSeries] = useState(series)
  const [chartOptions, setChartOptions] = useState(options)
  const [changePer, setChangePer] = useState(0)
  const [bucketId, setBucketId] = useState('')

  const [showInfo, setShowInfo] = useState(false)

  const { getSingleBucketIndex } = EndPoints.singleBucket

  const fetchSingleBucketGraphData = async () => {
    setLoading(true)
    try {
      const { data } = await axios.get(
        `${getSingleBucketIndex}?duration=${duration}&type=CRF_INDEX`
      )
      const parsedData = JSON.parse(data.body).message
      //console.log(parsedData)
      setBucketId(parsedData.bucket_id)
      setChartSeries([
        {
          name: 'percent',
          data: parsedData.price
        }
      ])
      setPrice(parsedData.price[parsedData.price.length - 1])
      setChangePer(
        -(
          (parsedData.price[0] - parsedData.price[parsedData.price.length - 1]) /
          parsedData.price[parsedData.price.length - 1]
        ) * 100
      )
      setChartOptions({
        ...chartOptions,
        xaxis: {
          ...chartOptions.xaxis,

          categories: parsedData.dates
        }
      })

      setLoading(false)
    } catch (err) {
      console.log(err.message)
    }
  }

  useEffect(() => {
    fetchSingleBucketGraphData()
  }, [duration])

  return (
    <div className="bg-purple-darker rounded-lg relative">
      <ErrorBoundary FallbackComponent={ErrorComponent}>
        <div className="p-2 sm:p-4">
          <div className=" flex justify-between items-center gap-6">
            <div className="flex flex-col md:flex-row gap-2 md:gap-4 ">
              <div className="flex items-center gap-4  ">
                <p className="">CRF Index</p>
                <Tooltip arrow title={'CRF Index Info'} classes={classes}>
                  <button className=" text-blue-400" onClick={() => setShowInfo(true)}>
                    <BsInfoSquare />
                  </button>
                </Tooltip>

                {showInfo && (
                  <div className="bg-purple-darker rounded-xl capitalize text-xs flex flex-col justify-evenly absolute top-10 left-10 shadow-lg z-10">
                    <ClickWrapper func={() => setShowInfo(false)}>
                      <ErrorBoundary FallbackComponent={ErrorComponent}>
                        {loading ? <Loader /> : <IndexTable id={bucketId} />}
                      </ErrorBoundary>
                    </ClickWrapper>
                  </div>
                )}
              </div>
              <h1 className=" font-600 text-xl md:text-2xl ">
                ${includeComma(price.toFixed(2))}
                <sup
                  className={`mx-1 sm:mx-3 font-400 text-sm text-${
                    changePer >= 0 ? 'green' : 'red'
                  }-500`}>
                  {changePer.toFixed(2)} %
                </sup>
              </h1>
            </div>

            <div className="grid grid-cols-2 md:grid-cols-4 gap-2">
              {btnArray.map((btn) => (
                <button
                  onClick={() => setDuration(btn.value)}
                  className={`p-1 px-2 m-1 text-xs rounded-full  ${
                    duration === btn.value ? 'bg-purple-dark' : ''
                  } `}
                  key={uuid()}>
                  {btn.title}
                </button>
              ))}
            </div>
          </div>
          {loading ? (
            <Loader />
          ) : (
            <ReactApexChart options={chartOptions} series={chartSeries} type="area" height={250} />
          )}
        </div>
      </ErrorBoundary>
    </div>
  )
}

export default IndexChart
