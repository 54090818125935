import {
  LOGIN_FAIL,
  LOGIN_SUCCESS,
  LOGOUT,
  REGISTER_FAIL,
  REGISTER_SUCCESS
} from '../actions/actionTypes'

const authUser = localStorage.getItem('AUTH_USER')

const dummyUser = {
  name: 'CRF Bot',
  user_id: 101,
  email: 'crypto@crf.com',
  profile_image:
    'https://static.news.bitcoin.com/wp-content/uploads/2018/04/bitcoin-trading-bot.jpg',
  token: ''
}

const initialState = {
  loggedIn: authUser ? true : false,
  user: authUser ? JSON.parse(authUser) : dummyUser,
  authError: ''
}

export const authReducer = (state = initialState, action) => {
  const { type, payload } = action

  switch (type) {
    case REGISTER_SUCCESS:
      return {
        ...state
      }
    case REGISTER_FAIL:
      return {
        loggedIn: false,
        user: dummyUser,
        authError: 'Error signing up'
      }
    case LOGIN_SUCCESS:
      return {
        loggedIn: true,
        user: payload,
        authError: ''
      }
    case LOGIN_FAIL:
      return {
        loggedIn: false,
        user: dummyUser,
        authError: payload
      }
    case LOGOUT:
      return {
        loggedIn: false,
        user: dummyUser,
        authError: ''
      }
    default:
      return state
  }
}
