import React, { Fragment, useEffect, useState } from 'react'
import { ErrorBoundary } from 'react-error-boundary'
import { Helmet } from 'react-helmet'
import { Link } from 'react-router-dom'
import uuid from 'react-uuid'
import { NEWS_PAGE } from '../../../navigation/Routes'
import EndPoints from '../../../services/api'
import axios from '../../../services/axios'
import ErrorComponent from '../../apps/error/ErrorComponent'
import Loader from '../../apps/loader/Loader'
import NewsAccordion from '../../apps/news/news/NewsAccordion'

const UntoldPage = () => {
  const [newsData, setNewsData] = useState({})
  const [loading, setLoading] = useState(true)
  const { getUntoldStories } = EndPoints.news

  const fetchNews = async () => {
    try {
      const { data } = await axios.get(`${getUntoldStories}/getbystatus?type=NEGATIVE`)

      const news = JSON.parse(data.body).message

      setNewsData(news)
      setLoading(false)
    } catch (err) {
      console.log(err.message)
    }
  }

  useEffect(() => {
    fetchNews()
  }, [])

  return (
    <div
      id="landing_modal"
      className="w-full h-screen text-white  bg-purple-darkest flex flex-col  text-left  p-4 sm:p-6 md:p-10 rounded-md shadow-xl">
      <Helmet>
        <meta charSet="utf-8" />
        <title>Untold Stories of Crypto | CryptoResearchfund. </title>
      </Helmet>
      <div className="max-w-xl p-6 text-sm">
        <p className="font-light mb-1 uppercase">untold stories</p>
        <h1 className="text-4xl font-semibold mb-4">Untold Stories of Crypto Market</h1>

        <p className="font-light my-1">Real people losing Real Money</p>
        <p className="font-light">
          CryptoReseach fund want to bring some sense to the crazy world of Crypto.
        </p>
      </div>

      <div className="p-2 lg:p-6 h-full overflow-y-scroll scrollbar-hidden">
        {loading ? (
          <Loader />
        ) : (
          <Fragment>
            <div className="hidden lg:grid lg:grid-cols-3  gap-6">
              <ErrorBoundary FallbackComponent={ErrorComponent}>
                {newsData.map((news) => (
                  <NewsAccordion key={uuid()} news={news} />
                ))}
              </ErrorBoundary>
            </div>
            <div className="grid grid-cols-1 sm:grid-cols-2 gap-4 lg:hidden ">
              <ErrorBoundary FallbackComponent={ErrorComponent}>
                {newsData.map((news) => (
                  <NewsAccordion key={uuid()} news={news} />
                ))}
              </ErrorBoundary>
            </div>
          </Fragment>
        )}
      </div>
    </div>
  )
}

export default UntoldPage
