import React from 'react'
import uuid from 'react-uuid'
import { LeaderBoardHeader } from './LeaderBoardData'
import SingleLeaderboard from './SingleLeaderboard'

const GameLeaderBoard = ({ data }) => {
  return (
    <div id="leaderboard" className="p-10 text-xs lg:text-sm hidden lg:block rounded-2xl ">
      <table className="p-4 w-full  ">
        <tbody className="text-xs xl:text-sm">
          <tr className="bg-purple-dark p-2 bg-opacity-10 ">
            {LeaderBoardHeader.map((head) => (
              <th key={uuid()} className="text-center p-2 cursor-pointer ">
                {head.title}{' '}
              </th>
            ))}
          </tr>
          {data.map((singleRank, idx) => (
            <SingleLeaderboard key={uuid()} data={singleRank} idx={idx} />
          ))}
        </tbody>
      </table>
    </div>
  )
}

export default GameLeaderBoard
