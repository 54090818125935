import React, { Fragment, useEffect, useState } from 'react'
import Helmet from 'react-helmet'
import uuid from 'react-uuid'
import EndPoints from '../../../services/api'
import axios from '../../../services/axios'
import SingleContestCard from '../../apps/all-contest/SingleContestCard'
import Loader from '../../apps/loader/Loader'

const AllContestPage = () => {
  const [contest, setContest] = useState([])
  const [loading, setLoading] = useState(true)
  const { getAllContest } = EndPoints.contest

  const fetchAllContest = async () => {
    try {
      const { data } = await axios.get(`${getAllContest}?status=ACTIVE&privacy=ALL`)
      //console.log(JSON.parse(data.body).message)
      setContest(JSON.parse(data.body).message)
      setLoading(false)
    } catch (err) {
      console.log(err.message)
    }
  }

  useEffect(() => {
    fetchAllContest()
  }, [])

  return (
    <div className="p-4 sm:p-6 md:p-10 text-white min-h-screen">
      <Helmet>
        <meta charSet="utf-8" />
        <title className="capitalize">CRF Contests.</title>
      </Helmet>
      {loading ? (
        <Loader />
      ) : (
        <Fragment>
          {contest.length > 0 ? (
            <div className="grid grid-cols-1 md:grid-cols-2 xl:grid-cols-3 gap-6 w-full ">
              {contest.map((contest) => (
                <SingleContestCard key={uuid()} contestData={contest} />
              ))}
            </div>
          ) : (
            <p className="text-center text-lg ">No Live Contests .</p>
          )}
        </Fragment>
      )}
    </div>
  )
}

export default AllContestPage
