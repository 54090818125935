export const featuresData = [
  'Market Captilization',
  'Liquidity',
  'Team',
  'Daily Volume',
  'Crypto Concept',
  'Social Score',
  'Google Trends',
  'Nodes',
  'User',
  'Country',
  'Usage',
  'Stage',
  'Platform ',
  'Theme',
  'Exchanges'
]
