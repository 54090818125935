import { Tooltip } from '@material-ui/core'
import React, { Fragment } from 'react'
import uuid from 'react-uuid'
import SingleTable from './SingleTable'
import { WatchListHeader } from './WatchListTableData'

const WatchListTable = ({ data, func }) => {
  return (
    <div className="hidden md:block">
      <div className="text-center bg-purple-darkest w-full p-2 py-3 font-600 text-sm border-1 border-gray-500 border-opacity-60">
        My Watchlist
      </div>
      <table
        className={`p-4 w-full ${
          Object.keys(data).length !== 0 && `rounded-b-2xl`
        } overflow-hidden`}>
        <tbody className="text-xs xl:text-sm ">
          <tr className="bg-purple-400 bg-opacity-20 p-2">
            {WatchListHeader.map((head) => (
              <th key={uuid()} className="text-center p-2 py-3 ">
                {head.title}{' '}
              </th>
            ))}
          </tr>

          {Object.keys(data).length !== 0 &&
            Object.keys(data).map((singleData) => (
              <SingleTable key={uuid()} id={singleData} data={data[singleData]} refresh={func} />
            ))}
        </tbody>
      </table>
      {Object.keys(data).length === 0 && (
        <Fragment>
          <p className="text-center w-full font-600 py-4 my-4 text-gray-300">
            Your Watchlist is Empty
          </p>
        </Fragment>
      )}
    </div>
  )
}

export default WatchListTable
