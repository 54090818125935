import React from 'react'
import { IoClose } from 'react-icons/io5'
import SplashLogo from '../splash-screen/SplashLogo'
import landingLogo from '../logo/assets/img/crypto1.png'

const HomePopup = ({ close }) => {
  return (
    <div className="w-full max-w-3xl bg-purple-500 bg-opacity-40 flex flex-col items-center relative p-6 sm:p-10 backdrop-filter backdrop-blur-xl  sm:backdrop-blur-lg rounded-lg">
      <button className="absolute top-2 right-2 p-2 text-xl" onClick={close}>
        <IoClose />
      </button>
      <div className="w-40 sm:w-64 grid place-items-center">
        <div className="animate-bounce text-center pt-4">
          <SplashLogo />
        </div>

        <img className="w-56 h-auto object-contain my-8 mt-4" src={landingLogo} alt="CRF" />
      </div>
      <h3 className="text-2xl font-semibold my-6 text-center">
        First CryptoEdu Platform. Demystify the Crypto Market .
      </h3>

      <p className="text-sm font-base leading-relaxed text-center font-semibold drop-shadow-lg">
        We build tools and insights that help people understand the crypto market and assess the
        risk. Due to a lack of understanding of the crypto-asset risks, millions of students,
        parents, daily wage workers, and investors lose money every day in this revolutionary new
        asset class.
      </p>

      <p className="text-lg font-base leading-relaxed text-center font-semibold my-4">
        We want to change.
      </p>
    </div>
  )
}

export default HomePopup
