import React from 'react'
import { dateTimeConversion } from '../../../../services/globalFunctions'

const NewsAccordion = ({ news, bg_color = 'bg-purple-darker' }) => {
  const date = dateTimeConversion(news.publishedAt)
  return (
    <div className={`p-3 ${bg_color} text-white rounded-lg w-full`}>
      <a href={news.url} target="blank" className="w-full flex gap-3 item-start">
        <img className="w-20 h-20 object-cover rounded-md" src={news.image_url} alt={''} />
        <div className="flex flex-col gap-3 justify-between ">
          <p className=" font-500 text-sm ">
            {news.title.slice(0, 100)} ...
            <span className="text-blue-300 text-xs font-400 mx-1">more</span>
          </p>
          <p className="flex gap-2 text-xs  "> {date} </p>
        </div>
      </a>
    </div>
  )
}

export default NewsAccordion
