import React, { useState, useEffect, Fragment } from 'react'
import { makeStyles } from '@material-ui/styles'
import clsx from 'clsx'
import SingleTweet from './SingleTweet'
import uuid from 'react-uuid'
import Loader from '../../loader/Loader'
import axios from '../../../../services/axios'
import EndPoints from '../../../../services/api'
import { ErrorBoundary } from 'react-error-boundary'
import ErrorComponent from '../../error/ErrorComponent'

const useStyles = makeStyles((theme) => ({
  scrollBar: {
    '&::-webkit-scrollbar-track': {
      backgroundColor: 'rgba(39,27,79,.9)'
    }
  }
}))

const TweetComponent = () => {
  const classes = useStyles()
  const [loading, setLoading] = useState(true)
  const [tweetData, setTweetData] = useState({})

  const fetchTweets = async () => {
    try {
      const { data } = await axios.get(EndPoints.news.getAllTweets)

      const tweets = JSON.parse(data.body)
      console.log(tweets)
      setTweetData(tweets)
      setLoading(false)
    } catch (err) {
      console.log(err.message)
    }
  }

  useEffect(() => {
    fetchTweets()
  }, [])
  return (
    <div className="h-full flex flex-col bg-purple-darker text-white w-full md:w-1/2 ">
      {loading ? (
        <Loader />
      ) : (
        <div
          className={clsx(
            classes.scrollBar,
            ` grid grid-cols-1 lg:grid-cols-2 gap-4 overflow-scroll overflow-x-hidden p-4  h-full  xl:px-8 pb-52 `
          )}>
          <ErrorBoundary FallbackComponent={ErrorComponent}>
            {Object.keys(tweetData).length > 0 ? (
              Object.keys(tweetData).map((tweet) => (
                <SingleTweet
                  bg_color={'bg-purple-darkest'}
                  marginY={'4'}
                  key={uuid()}
                  tweet={tweetData[tweet]}
                />
              ))
            ) : (
              <Fragment>
                <p className="text-center w-full font-600 py-4 my-4 text-gray-300">
                  Unfortunately , No Tweets.
                </p>
              </Fragment>
            )}
          </ErrorBoundary>
        </div>
      )}
    </div>
  )
}

export default TweetComponent
