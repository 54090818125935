export const BucketTableHeader = [
  {
    title: '#'
  },
  {
    title: 'Coin'
  },
  {
    title: 'Market Cap'
  },
  {
    title: 'Sectors'
  },
  {
    title: 'Allocation'
  }
]
