import React, { useEffect, useState } from 'react'
import { Link } from 'react-router-dom'
import { BUCKET_PAGE } from '../../../../../navigation/Routes'
import bucketIMG from '../../assets/img/buckets.png'
import axiosInstance from '../../../../../services/axios'
import EndPoints from '../../../../../services/api'
import Loader from '../../../loader/Loader'
import uuid from 'react-uuid'
import SingleHomeBucket from './SingleHomeBucket'

const BucketComponent = () => {
  const [bucketData, setBucketData] = useState({})
  const [loading, setLoading] = useState(true)
  const { getAllBuckets } = EndPoints.bucket
  const fetchBucketData = async () => {
    try {
      const { data } = await axiosInstance.get(getAllBuckets)
      //console.log(JSON.parse(data.body).message)
      setBucketData(JSON.parse(data.body).message)
      setLoading(false)
    } catch (err) {
      console.log(err.message)
    }
  }

  useEffect(() => {
    fetchBucketData()
  }, [])
  return (
    <div className="bg-purple-darker rounded-3xl mx-auto w-full max-w-8xl flex flex-col-reverse lg:flex-row gap-10 p-4 sm:p-6 md:px-10 items-center">
      <div className="w-full">
        {loading ? (
          <Loader />
        ) : (
          <div className="flex flex-col gap-6">
            {Object.keys(bucketData)
              .slice(0, 3)
              .map((bucket) => (
                <SingleHomeBucket key={uuid()} data={bucketData[bucket]} id={bucket} />
              ))}
          </div>
        )}
      </div>
      <div className="flex w-full flex-col items-center gap-12 p-4 md:p-8">
        <h1 className="text-4xl text-center w-full font-bold ">CRF Buckets</h1>
        <p className="leading-6 text-base text-center  ">
          CRF Buckets are simple - a curated solution of crypto investments together with a
          strategy designed by professionals and our intelligent Algorithms to simplify crypto
          investments. CryptoReseachFund will help you find institutional grade actionable ideas
          driven by data science and Advance AI that are customised especially Individuals.
        </p>
        <img className="w-4/5 object-contain" src={bucketIMG} alt="" />
        <Link
          to={BUCKET_PAGE}
          className="px-4 p-2 text-center text-lg w-full max-w-xs rounded-full bg-blue-500">
          Learn More
        </Link>
      </div>
    </div>
  )
}

export default BucketComponent
