import { makeStyles } from '@material-ui/core'
import React, { Fragment, useEffect, useState } from 'react'
import SingleBucket from '../../apps/bucket/SingleBucket'
import { BsFilterLeft } from 'react-icons/bs'
import clsx from 'clsx'
import axios from '../../../services/axios'
import EndPoints from '../../../services/api'
import Loader from '../../apps/loader/Loader'
import uuid from 'react-uuid'
import { Helmet } from 'react-helmet'
import { ErrorBoundary } from 'react-error-boundary'
import ErrorComponent from '../../apps/error/ErrorComponent'
import SingleResponsiveBucket from '../../apps/bucket/SingleResponsiveBucket'
import { useSelector } from 'react-redux'

const useStyles = makeStyles((theme) => ({
  hiddenScrollBar: {
    '&::-webkit-scrollbar': {
      display: 'none'
    }
  }
}))

const BucketPage = () => {
  const classes = useStyles()
  const [showFilter, setShowFilter] = useState(false)
  const [bucketData, setBucketData] = useState({})
  const [loading, setLoading] = useState(true)
  const { user } = useSelector((state) => state.auth)
  const { getAllBuckets } = EndPoints.bucket

  const config = {
    headers: {
      Authorization: `Bearer ${user.token}`
    }
  }

  const fetchBucketData = async () => {
    try {
      const { data } = await axios.get(getAllBuckets, config)
      //console.log(JSON.parse(data.body).message)
      setBucketData(JSON.parse(data.body).message)
      setLoading(false)
    } catch (err) {
      console.log(err.message)
    }
  }

  useEffect(() => {
    fetchBucketData()
  }, [])

  return (
    <div className="bg-purple-darkest  p-4 sm:p-6 md:p-10 text-white">
      <Helmet>
        <meta charSet="utf-8" />
        <title>Invest in Crypto done right | CryptoResearchfund. </title>
      </Helmet>{' '}
      <div className="flex flex-col ">
        <h2 className="text-2xl font-600">Discover</h2>
        <p className="text-xs my-4 ">All CRF Buckets for our investors</p>
      </div>
      <div className="py-2 lg:px-10 flex justify-between ">
        <button
          className="bg-purple-400 bg-opacity-10 p-2 text-lg flex items-center rounded-lg"
          onClick={() => setShowFilter(!showFilter)}>
          <BsFilterLeft /> <p className="text-xs ml-1">filter</p>
        </button>

        <div className=" px-2 sm:py-1 border-opacity-40 border-1 border-gray-300  w-40 ">
          <select className="bg-purple-darkest text-xs font-600 cursor-pointer w-full ">
            <option className="font-600 ">Type</option>
            <option className="font-600 ">Type</option>
            <option className="font-600 ">Type</option>
          </select>
        </div>
      </div>
      <div className="flex flex-col lg:flex-row  ">
        {showFilter && (
          <div className="flex flex-row lg:flex-col p-2 sm:p-4 md:p-6 lg:p-2 justify-between lg:justify-start xl:w-64  ">
            <div className="text-xs lg:mb-8">
              <h2 className=" text-xs sm:text-sm md:text-xl font-600 mb-4">Investment Amount</h2>

              <label className="flex items-center my-1">
                <input type="radio" />
                <p className="ml-2">Any</p>
              </label>

              <label className="flex items-center my-1">
                <input type="radio" />
                <p className="ml-2">Under $2,000</p>
              </label>

              <label className="flex items-center my-1">
                <input type="radio" />
                <p className="ml-2">Under $5,000</p>
              </label>

              <label className="flex items-center my-1">
                <input type="radio" />
                <p className="ml-2">Under $10,000</p>
              </label>
            </div>

            <div className="text-xs lg:mb-8">
              <h2 className="text-xs sm:text-sm md:text-xl font-600 mb-4">Risk Analysis</h2>

              <label className="flex items-center my-1">
                <input type="radio" />
                <p className="ml-2">High</p>
              </label>

              <label className="flex items-center my-1">
                <input type="radio" />
                <p className="ml-2">Medium</p>
              </label>

              <label className="flex items-center my-1">
                <input type="radio" />
                <p className="ml-2">Low</p>
              </label>
            </div>

            <div className="text-xs lg:mb-8">
              <h2 className="text-xs sm:text-sm md:text-xl font-600 mb-4">Profit Return %</h2>

              <label className="flex items-center my-1">
                <input type="radio" />
                <p className="ml-2">High</p>
              </label>

              <label className="flex items-center my-1">
                <input type="radio" />
                <p className="ml-2">Medium</p>
              </label>

              <label className="flex items-center my-1">
                <input type="radio" />
                <p className="ml-2">Low</p>
              </label>
            </div>
          </div>
        )}

        {loading ? (
          <Loader />
        ) : (
          <ErrorBoundary FallbackComponent={ErrorComponent}>
            <Fragment>
              {Object.keys(bucketData).length === 0 ? (
                <Fragment>
                  <p className="text-center w-full font-600 py-4 my-4 text-gray-300">
                    Your Bucket is Empty
                  </p>
                </Fragment>
              ) : (
                <Fragment>
                  <div className={clsx('flex flex-col  lg:px-10 flex-1')}>
                    {Object.keys(bucketData).map((id) => (
                      <SingleBucket key={uuid()} data={bucketData[id]} id={id} />
                    ))}
                  </div>
                  {/* <div className={clsx('flex sm:hidden flex-col lg:px-10 flex-1')}>
                    {Object.keys(bucketData).map((id) => (
                      <SingleResponsiveBucket key={uuid()} data={bucketData[id]} id={id} />
                    ))}
                  </div> */}
                </Fragment>
              )}
            </Fragment>
          </ErrorBoundary>
        )}
      </div>
      <div className="p-10 pt-32 md:pt-56 pb-20 text-xs text-center">
        <p>
          Never invest in cryptos more than you can afford to lose and always try to keep them in
          your own wallet!
        </p>
      </div>
    </div>
  )
}

export default BucketPage
