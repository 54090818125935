import axios from 'axios'
import store from '../redux/store'
import authAction from '../redux/actions/authAction'

const axiosInstance = axios.create({})

// axiosInstance.interceptors.request.use(
//   function (config) {
//     config.headers['Authorization'] = 'Bearer ' + getToken()
//     return config
//   },
//   function (error) {
//     return Promise.reject(error)
//   }
// )

axiosInstance.interceptors.response.use(
  function (response) {
    return response
  },
  function (error) {
    if (401 === error.response.status) {
      console.log(error.message + 'Unauthorized')
      //alert('Unauthorized')
      store.dispatch(authAction.logout())
      // handle error: inform user, go to login, etc
    } else {
      return Promise.reject(error)
    }
  }
)
export default axiosInstance
