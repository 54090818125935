import firebase from 'firebase/compat/app'
import 'firebase/compat/auth'

const firebaseConfig = {
  apiKey: 'AIzaSyAfplMktlJFTrz8HEyrxkfC1yY3xU8dphk',
  authDomain: 'cryptoresearchfund.firebaseapp.com',
  projectId: 'cryptoresearchfund',
  storageBucket: 'cryptoresearchfund.appspot.com',
  messagingSenderId: '955393266524',
  appId: '1:955393266524:web:5be8da131a015f513d2799',
  measurementId: 'G-E3J11RD59R'
}

// Initialize Firebase
const app = firebase.initializeApp(firebaseConfig)
const auth = firebase.auth()
const provider = new firebase.auth.GoogleAuthProvider()

export { auth, provider }
