import React from 'react'

const SingleSimulator = ({ data, idx }) => {
  const { title, description, invert, image } = data
  return (
    <div
      className={`flex flex-col  ${
        invert ? 'md:flex-row-reverse' : 'md:flex-row'
      } justify-between items-center p-6 gap-6`}>
      <div className="w-1/2 md:w-2/5">
        <lottie-player
          src={image}
          background="transparent"
          speed="1"
          style={{ width: '100%', height: '100%' }}
          loop
          autoplay></lottie-player>
      </div>
      <div className="md:w-1/2 text-3xl font-bold flex flex-col  gap-10">
        <h2 className="text-blue-500">
          <span className=" mx-2">#{idx + 1}</span>
          {title}
        </h2>

        <p className="text-xl font-light">{description}</p>
      </div>
    </div>
  )
}

export default SingleSimulator
