import React, { useState, useEffect, useRef } from 'react'
import { AiOutlineStar, AiFillStar } from 'react-icons/ai'
import { includeComma } from '../../../services/globalFunctions'
import { useHistory } from 'react-router-dom'
import { useSelector } from 'react-redux'
import { AUTHENTICATION_PAGE } from '../../../navigation/Routes'
import ClickWrapper from '../click-wrapper/ClickWrapper'
import TradingTransactionModal from './modal/TradingTransactionModal'

const SingleListing = ({ singleCoinData, coinid, contestId, contestName, wallet }) => {
  const {
    Algo_used,
    Domain,
    ExchangesCount,
    Funds_Raised,
    PercentChange7d,
    PercentChange24h,
    PercentChange30d,
    Product_Live,
    Sale_type,
    Whitepaper,
    activity,
    category,
    coinname,
    country,
    exchanges,
    id,
    language,
    lastmonthcommits,
    launched,
    marketcap,
    maxsupply,
    newsRating,
    owner,
    platform,
    price,
    repo,
    score,
    symbol,
    timestamp,
    timestamp7d,
    timestamp24h,
    timestamp30d,
    tosymbol,
    total_public_repos,
    tweetsRating,
    type,
    urlOfFlag,
    urlOfLogo,
    website
  } = singleCoinData
  const { loggedIn } = useSelector((state) => state.auth)
  const [addToWatchlist, setAddToWatchlist] = useState(false)
  const [changedPrice, setChangedprice] = useState(price.toFixed(2))
  const [changed24h, setChanged24h] = useState(PercentChange24h)
  const [changed7d, setChanged7d] = useState(PercentChange7d)
  const [showTransactionModal, setShowTransactionModal] = useState(false)
  const labelRef = useRef()
  const limit = includeComma(Math.floor(parseFloat(maxsupply?.replace(/,/g, '')) / 1000000))

  const fakePriceChange = (random) => {
    if (random >= price) {
      return
    }

    if (Math.floor(random * 3) % 2 === 0) {
      setChangedprice((price + random / 10).toFixed(2))
    }

    setChangedprice((price - random / 10).toFixed(2))
  }

  const fake24hChange = (random) => {
    if (PercentChange24h !== null) {
      if (
        (PercentChange24h > 0 && random >= PercentChange24h) ||
        PercentChange24h - random / 10 < -100
      ) {
        return
      }

      if (Math.floor(random * 3) % 2 === 0) {
        setChanged24h((PercentChange24h + random / 10).toFixed(2))
      }

      setChanged24h((PercentChange24h - random / 10).toFixed(2))
    }
  }

  const fake7dChange = (random) => {
    if (PercentChange7d !== null) {
      if (
        (PercentChange7d > 0 && random >= PercentChange7d) ||
        PercentChange7d - random / 10 < -100
      ) {
        return
      }

      if (Math.floor(random * 3) % 2 === 0) {
        setChanged7d((PercentChange7d + random / 10).toFixed(2))
      }

      setChanged7d((PercentChange7d - random / 10).toFixed(2))
    }
  }

  useEffect(() => {
    const changeInterval = setInterval(() => {
      const random = parseFloat(Math.random().toFixed(2))

      fakePriceChange(random)
      fake24hChange(random)
      fake7dChange(random)
    }, 3000)

    return () => {
      clearInterval(changeInterval)
    }
  }, [])

  return (
    <tr className="text-xs xl:text-sm font-600 cursor-pointer bg-purple-700 bg-opacity-10 relative  hover:bg-opacity-20 ">
      <td className="flex justify-center items-center p-2 xl:px-4 ">
        <div className=" h-8 w-8 self-start mt-2">
          <img className="w-full h-full object-cover" src={urlOfLogo} alt={coinname} />
        </div>
        <div className="flex flex-col mx-4 py-1 flex-1  ">
          <h4 className="text-lg font-600 mb-1">
            {coinname} <span className="text-xs ml-2">- {symbol}</span>
          </h4>
          <p className="text-xs">Domain : {Domain}</p>
        </div>
        {/* <label ref={labelRef} className="text-yellow-500 text-lg  p-1">
          <input
            className="hidden"
            value={addToWatchlist}
            onChange={(e) => setAddToWatchlist(e.target.checked)}
            type="checkbox"
          />
          {addToWatchlist ? <AiFillStar /> : <AiOutlineStar />}
        </label> */}
      </td>
      <td className="text-center p-2 ">$ {includeComma(changedPrice)}</td>
      <td className={`text-center p-2 ${changed24h >= 0 ? `text-green-500` : ` text-red-500`}`}>
        {changed24h >= 0 ? `+ ${changed24h}` : ` ${changed24h}`}%
      </td>
      <td className={`text-center p-2 ${changed7d >= 0 ? `text-green-500` : ` text-red-500`}`}>
        {changed7d >= 0 ? `+ ${changed7d}` : ` ${changed7d}`} %
      </td>
      <td className="text-center p-2">$ {includeComma((marketcap / 1000000000).toFixed(2))} B</td>
      <td className="text-center p-2 capitalize">{category}</td>
      <td className="text-center p-2 ">{maxsupply === '-1' ? 'unlimited' : `${limit} M`}</td>

      <td className="text-center p-2">{score.toFixed(2)}</td>
      <td className="text-center p-2">{((tweetsRating + newsRating) / 2).toFixed(2)}</td>
      <td className="text-center p-2">
        <button
          className="text-xs px-3 py-2 border-2  transition-all duration-200 border-purple-400 border-opacity-30 cursor-pointer rounded-full "
          onClick={() => setShowTransactionModal(true)}>
          Buy
        </button>
        {showTransactionModal && (
          <div className="fixed left-0 right-0 top-0 h-screen w-full z-10 backdrop-filter backdrop-blur-sm grid place-items-center">
            <div className="z-50">
              <ClickWrapper func={() => setShowTransactionModal(false)}>
                <TradingTransactionModal
                  close={() => setShowTransactionModal(false)}
                  tradingData={{
                    coinname,
                    logo: urlOfLogo,
                    price,
                    symbol,
                    id,
                    domain: Domain,
                    platform,
                    category,
                    contestId,
                    wallet,
                    contestName
                  }}
                />
              </ClickWrapper>
            </div>
          </div>
        )}
      </td>
    </tr>
  )
}

export default SingleListing
