import React, { Fragment, useEffect, useState } from 'react'
import GameLeaderBoard from '../../apps/games/GameLeaderBoard'
import ResponsiveLeaderBoard from '../../apps/games/ResponsiveLeaderBoard'
import GameHeader from '../../apps/games/GameHeader'
import { Helmet } from 'react-helmet'
import ContestCard from '../../apps/games/ContestCard'
import { useSelector } from 'react-redux'
import axios from '../../../services/axios'
import EndPoints from '../../../services/api'
import Loader from '../../apps/loader/Loader'
import uuid from 'react-uuid'
import { ErrorBoundary } from 'react-error-boundary'
import ErrorComponent from '../../apps/error/ErrorComponent'

const GamePage = () => {
  const { loggedIn, user } = useSelector((state) => state.auth)
  const [loading, setLoading] = useState(true)
  const [userContest, setUserContest] = useState([])
  const [rankData, setRankData] = useState([])
  const { getUserContests, getUserRankings } = EndPoints.gamePage

  const fetchGamePagedata = async (user_id) => {
    setLoading(true)
    const config = {
      headers: {
        Authorization: `Bearer ${user.token}`
      }
    }
    try {
      const res = await Promise.all([
        axios.get(`${getUserContests}?user_id=${user_id}`, config),
        axios.get(getUserRankings)
      ])
      // console.log(res)
      const newData = res.map((res) => JSON.parse(res.data.body).message)
      //console.log(newData)
      setUserContest(newData[0])
      setRankData(newData[1])
    } catch (err) {
      console.log(err.message)
    } finally {
      setLoading(false)
    }
  }

  const fetchrankList = async () => {
    try {
      const { data } = await axios.get(getUserRankings)
      setRankData(JSON.parse(data.body).message)
    } catch (err) {
      console.log(err.message)
    }
    setLoading(false)
  }

  useEffect(() => {
    if (loggedIn) {
      fetchGamePagedata(user.user_id)
    } else {
      fetchrankList()
    }
  }, [loggedIn])

  return (
    <div className="bg-purple-darkest text-white">
      <Helmet>
        <meta charSet="utf-8" />

        <title>Crypto Research Contest | CryptoResearchfund-A CryptoEd Platform.</title>
        <meta
          name="title"
          content="Crypto Research Contest | CryptoResearchfund-A CryptoEd Platform."
        />
        <meta
          name="description"
          content=" Practice trading and compete for Rewards & fame. Use the CryptoResearchFund Crypto simulator to learn the basics and technical strategies of crypto trading with zero risk of losing your hard-earned money."
        />

        <meta property="og:type" content="website" />
        <meta property="og:url" content="http://www.cryptoresearchfund.com/games" />
        <meta
          property="og:title"
          content="Crypto Research Contest | CryptoResearchfund-A CryptoEd Platform."
        />
        <meta
          property="og:description"
          content=" Practice trading and compete for Rewards & fame. Use the CryptoResearchFund Crypto simulator to learn the basics and technical strategies of crypto trading with zero risk of losing your hard-earned money."
        />
        <meta
          property="og:image"
          content="http://www.cryptoresearchfund.com/dataScienceAndAIApproch.png"
        />

        <meta property="twitter:card" content="summary_large_image" />
        <meta property="twitter:url" content="http://www.cryptoresearchfund.com/games" />
        <meta
          property="twitter:title"
          content="Crypto Research Contest | CryptoResearchfund-A CryptoEd Platform."
        />
        <meta
          property="twitter:description"
          content=" Practice trading and compete for Rewards & fame. Use the CryptoResearchFund Crypto simulator to learn the basics and technical strategies of crypto trading with zero risk of losing your hard-earned money."
        />
        <meta
          property="twitter:image"
          content="http://www.cryptoresearchfund.com/dataScienceAndAIApproch.pnghttp://www.cryptoresearchfund.com/dataScienceAndAIApproch.png"
        />
      </Helmet>
      {!loading && <GameHeader />}

      {loggedIn && (
        <ErrorBoundary FallbackComponent={ErrorComponent}>
          <Fragment>
            {loading ? (
              <Loader />
            ) : (
              <div className=" p-4 sm:p-6 md:px-10 grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-3 gap-4 lg:gap-6">
                {userContest.map((contest) => (
                  <ContestCard key={uuid()} contestData={contest} />
                ))}
              </div>
            )}
          </Fragment>
        </ErrorBoundary>
      )}

      <div className="py-2 sm:p-4 md:p-6 lg:p-10">
        {loading ? (
          <Loader />
        ) : (
          <div>
            <ErrorBoundary FallbackComponent={ErrorComponent}>
              <GameLeaderBoard data={rankData} />
            </ErrorBoundary>
            <ErrorBoundary FallbackComponent={ErrorComponent}>
              <ResponsiveLeaderBoard data={rankData} />
            </ErrorBoundary>
          </div>
        )}

        <div className="p-6 py-10 sm:p-10 md:p-20 text-xs text-center">
          <p>
            Never invest in cryptos more than you can afford to lose and always try to keep them in
            your own wallet!
          </p>
        </div>
      </div>
    </div>
  )
}

export default GamePage
