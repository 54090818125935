import { makeStyles } from '@material-ui/core'
import React, { useEffect, useState } from 'react'
import clsx from 'clsx'
import axios from '../../../services/axios'
import EndPoints from '../../../services/api'
import Loader from '../loader/Loader'
import { includeComma } from '../../../services/globalFunctions'
import uuid from 'react-uuid'

const useStyles = makeStyles((theme) => ({
  hiddenScrollBar: {
    '&::-webkit-scrollbar': {
      display: 'none'
    }
  }
}))

const CryptoTable = () => {
  const classes = useStyles()

  const [tableData, setTableData] = useState({})
  const [loadingTable, setLoadingTable] = useState(true)

  const { getStaticData, getCurrentPrice } = EndPoints.listing

  const fetchTableData = async () => {
    try {
      const res = await Promise.all([axios.get(getStaticData), axios.get(getCurrentPrice)])
      const newData = res.map((res) => JSON.parse(res.data.body))
      setTableData(newData)
      setLoadingTable(false)
    } catch (err) {
      console.log(err.message)
    }
  }

  const sumOfObject = (object, property) => {
    let sum = 0
    Object.keys(object).forEach((obj) => {
      sum += object[obj][property]
    })

    return sum
  }

  useEffect(() => {
    fetchTableData()
  }, [])

  return (
    <div className="bg-purple-400 bg-opacity-10 rounded-lg  ">
      {loadingTable ? (
        <Loader />
      ) : (
        <>
          <div className="flex justify-between p-3 items-center border-b-3 border-black border-opacity-20 ">
            <p className="text-xs sm:text-sm font-600">Market Capital</p>
            <p className="text-xs sm:text-sm font-500">
              Currency : ${' '}
              {includeComma((sumOfObject(tableData[1], 'marketcap') / 1000000000).toFixed(2))} B
            </p>
          </div>

          <div className={clsx(classes.hiddenScrollBar, 'overflow-scroll h-96 px-2 md:px-0')}>
            {Object.keys(tableData[1]).map((id) => (
              <div key={uuid()} className="flex justify-between p-2 sm:p-3 items-center">
                <div className="flex items-start">
                  <img
                    className="w-4 sm:w-6 h-4 sm:h-6 mr-2 object-contain"
                    src={tableData[0][id].logo}
                    alt={tableData[0][id].coinname}
                  />

                  <p className="text-xs sm:text-sm font-500">{tableData[0][id].coinname}</p>
                </div>

                <p className="text-xs sm:text-sm font-500">
                  $ {includeComma((tableData[1][id].marketcap / 1000000000).toFixed(2))} B
                </p>
              </div>
            ))}
          </div>
        </>
      )}
    </div>
  )
}

export default CryptoTable
