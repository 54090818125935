import React from 'react'

const RankGift = () => {
  return (
    <svg width="50" height="52" viewBox="0 0 50 52" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M45.1287 14.376V49.838C45.1277 50.3052 44.9417 50.753 44.6114 51.0834C44.281 51.4137 43.8332 51.5998 43.366 51.6008H5.20805C4.74086 51.5998 4.29309 51.4137 3.96274 51.0833C3.63239 50.753 3.44634 50.3052 3.44531 49.838V14.376C3.44563 13.9086 3.63145 13.4604 3.96196 13.1299C4.29247 12.7994 4.74065 12.6136 5.20805 12.6133H43.366C43.8334 12.6136 44.2816 12.7994 44.6121 13.1299C44.9426 13.4604 45.1284 13.9086 45.1287 14.376Z"
        fill="#6C63FF"
      />
      <path
        d="M26.9827 51.7793H21.5908C21.1295 51.7847 20.685 51.607 20.3545 51.2851C20.0241 50.9632 19.8348 50.5234 19.8281 50.0621V13.2764C19.8348 12.8151 20.0241 12.3753 20.3545 12.0534C20.6849 11.7315 21.1295 11.5538 21.5908 11.5592H26.9827C27.444 11.5538 27.8886 11.7315 28.2191 12.0534C28.5495 12.3753 28.7388 12.8151 28.7455 13.2764V50.0621C28.7388 50.5234 28.5495 50.9632 28.2191 51.2851C27.8886 51.607 27.444 51.7847 26.9827 51.7793Z"
        fill="#3F3D56"
      />
      <path
        d="M23.1765 1.51313C25.9477 3.91171 25.0727 9.46259 25.0727 9.46259C25.0727 9.46259 19.7047 11.1246 16.9335 8.72604C14.1623 6.32746 15.0374 0.776587 15.0374 0.776587C15.0374 0.776587 20.4054 -0.885437 23.1765 1.51313Z"
        fill="#3F3D56"
      />
      <path
        d="M32.3797 8.26643C29.9898 11.0451 24.4362 10.1874 24.4362 10.1874C24.4362 10.1874 22.7574 4.82459 25.1473 2.04593C27.5372 -0.732726 33.0908 0.124961 33.0908 0.124961C33.0908 0.124961 34.7696 5.48778 32.3797 8.26643Z"
        fill="#3F3D56"
      />
      <path
        opacity="0.2"
        d="M45.1287 14.376V20.3465L3.44531 20.1702V14.376C3.44563 13.9086 3.63145 13.4604 3.96196 13.1299C4.29247 12.7994 4.74063 12.6136 5.20804 12.6133H43.366C43.8334 12.6136 44.2816 12.7994 44.6121 13.1299C44.9426 13.4604 45.1284 13.9086 45.1287 14.376Z"
        fill="black"
      />
      <path
        d="M49.6167 18.0057C49.4517 18.2225 49.2386 18.3981 48.9942 18.5186C48.7498 18.6391 48.4807 18.7012 48.2082 18.7L1.75538 18.5052C1.52389 18.5042 1.29487 18.4577 1.08138 18.3682C0.867885 18.2787 0.674093 18.1481 0.511093 17.9837C0.348094 17.8193 0.219069 17.6245 0.131377 17.4102C0.0436854 17.196 -0.000951363 16.9666 1.53732e-05 16.7351L0.0217946 11.5506C0.0237159 11.0832 0.211235 10.6357 0.543107 10.3065C0.874978 9.97735 1.32402 9.7935 1.79143 9.79543H1.79196L48.2446 9.99016C48.4761 9.99113 48.7051 10.0377 48.9186 10.1272C49.1321 10.2166 49.3259 10.3473 49.4889 10.5116C49.6519 10.676 49.7809 10.8709 49.8686 11.0851C49.9563 11.2993 50.0009 11.5287 50 11.7602L49.9782 16.9447C49.9771 17.3284 49.8501 17.7011 49.6167 18.0057Z"
        fill="#6C63FF"
      />
      <path
        d="M19.9397 9.45752L19.9023 18.7896L28.8196 18.8253L28.857 9.49325L19.9397 9.45752Z"
        fill="#3F3D56"
      />
    </svg>
  )
}

export default RankGift
