import React from 'react'
import AuthComponent from '../../apps/auth/AuthComponent'
import { Helmet } from 'react-helmet'

const AuthPage = () => {
  return (
    <div className="flex w-screen h-screen">
      <Helmet>
        <meta charSet="utf-8" />
        <title>CRF | Authentication</title>
      </Helmet>
      <div className="w-full flex justify-center items-start p-2 xl:max-w-xl bg-white ">
        <AuthComponent />
      </div>

      <div className="bg-purple-darkest  flex-col  items-center flex-1 hidden xl:flex p-10 text-center text-white font-600">
        <h1 className="text-3xl my-2">
          <span className="text-blue-500">CRF</span> Launches
        </h1>
        <div id="static__portfolio__bg">
          <lottie-player
            src="https://assets9.lottiefiles.com/packages/lf20_8wuout7s.json"
            background="transparent"
            speed="1"
            style={{ width: '95%', height: '95%', objectFit: 'contain' }}
            loop
            autoplay></lottie-player>
        </div>
        <h2 className="text-2xl my-2">Safe Investment through ML and AI</h2>
      </div>
    </div>
  )
}

export default AuthPage
