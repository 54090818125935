import React, { useEffect } from 'react'
import { useSelector } from 'react-redux'
import RouterConfig from './navigation/RouterConfig'
import { googleAnalyticsActions } from './utils/googleAnalytics'
import CookieConsent from 'react-cookie-consent'

function App() {
  const { loggedIn } = useSelector((state) => state.auth)

  useEffect(() => {
    googleAnalyticsActions.initGoogleAnalytics(process.env.REACT_APP_GOOGLE_ANALYTICS)
  }, [])

  return (
    <div
      className={`App overflow-x-hidden min-h-screen w-screen bg-purple-darkest relative ${
        loggedIn && 'pt-16'
      } `}>
      <RouterConfig />
      <CookieConsent
        //debug={true}
        buttonText="Accept All"
        // location="bottom"
        enableDeclineButton
        style={{ background: '#161616', fontSize: '13px' }}
        buttonStyle={{ color: 'white', fontSize: '13px', backgroundColor: '#2759D0' }}
        declineButtonStyle={{ color: 'white', fontSize: '13px' }}
        onAccept={() => {
          // alert('yay!')
        }}
        onDecline={() => {
          // alert('nay!')
        }}>
        We use our own cookies as well as third-party cookies on our websites to enhance your
        experience, analyze our traffic, and for security and marketing. Select "Accept All" to
        allow them to be used.
      </CookieConsent>
    </div>
  )
}

export default App
