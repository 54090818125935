import React from 'react'
import AuthComponent from './AuthComponent'
import { IoClose } from 'react-icons/io5'

const AuthModal = ({ close }) => {
  return (
    <div className=" w-80 md:w-96 grid place-items-center p-2 max-w-xl bg-white rounded-xl max-h-screen  relative">
      <button onClick={close} className="absolute top-4 right-4 text-xl font-semibold text-black">
        <IoClose />
      </button>
      <AuthComponent closeTab={close} />
    </div>
  )
}

export default AuthModal
