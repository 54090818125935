export const rankTableData = [
  {
    title: 'Rank'
  },
  {
    title: 'Initial Fund'
  },
  {
    title: 'Notional P/L'
  },

  {
    title: 'Change %'
  },
  {
    title: 'Current Value'
  }
]
