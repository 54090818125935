import axios from 'axios'
import React, { Fragment, useState } from 'react'
import { useAlert } from 'react-alert'
import { useSelector } from 'react-redux'
import { useHistory } from 'react-router-dom'
import uuid from 'react-uuid'
import EndPoints from '../../../../services/api'
import { includeComma } from '../../../../services/globalFunctions'
import SingleCoinDetails from './SingleCoinDetails'

const BuyTab = ({ bucketData, setTab, sendTransactionData }) => {
  const { bucketName, bucketId, coinData } = bucketData
  const { user } = useSelector((state) => state.auth)
  const [showAmountTab, setShowAmountTab] = useState(true)
  const [showCoinsTab, setShowCoinsTab] = useState(false)
  const [userAmount, setUserAmount] = useState(0)
  const [bucketTransactionData, setBucketTransactionData] = useState({})
  const [bucketNotes, setBucketNotes] = useState('')
  const [loadingTransaction, setLoadingTransaction] = useState(false)

  const { bucketTransaction } = EndPoints.singleBucket
  const alert = useAlert()

  const makeTransaction = async (postData) => {
    setLoadingTransaction(true)
    const config = {
      headers: {
        Authorization: `Bearer ${user.token}`
      }
    }
    try {
      const { data } = await axios.post(
        `${bucketTransaction}/transaction?user_id=${user.user_id}`,
        postData,
        config
      )
      // console.log(data)
      if (data.errorMessage) {
        alert.error(data.errorMessage)
        setLoadingTransaction(false)
        return
      }
      alert.success(JSON.parse(data.body).message)
      //console.log(JSON.parse(data.body))
      await sendTransactionData(JSON.parse(data.body))
      setLoadingTransaction(false)
      setTab(1)
    } catch (err) {
      console.log(err.message)
    }
  }

  const handleBuyAsset = (e) => {
    e.preventDefault()

    //console.log(coinData)
    const bucketTransaction = {
      user_id: user.user_id,
      block_user_id: bucketTransactionData.unique_code,
      asset_type: 'CRYPTO',
      crf_block_id: bucketId,
      trans_type: 'BUY',
      notes: bucketNotes,
      flag: 'NEW',
      fees: bucketTransactionData.fees,
      amount: userAmount,
      blocks: coinData.map((coin) => {
        const bucketCoin = bucketTransactionData.data.find((data) => data.coin_id === coin.coin_id)
        const { allocation, alloted_price, current_price, fees, quantity } = bucketCoin
        return {
          sector: coin.sectors,
          ecosystem: 'TEST',
          asset_id: coin.coin_id,
          asset_name: coin.coin_name,
          unit_price: current_price,
          alloted_price,
          charges: fees,
          fee: fees,
          quantity
        }
      })
    }
    //(bucketTransaction)
    makeTransaction(bucketTransaction)
  }

  const handleBucketAmount = async (e) => {
    setLoadingTransaction(true)
    e.preventDefault()

    const postData = {
      bucket_id: bucketId,
      amount: userAmount,
      user_id: user.user_id
    }
    const config = {
      headers: {
        Authorization: `Bearer ${user.token}`
      }
    }
    try {
      const { data } = await axios.post(
        `${bucketTransaction}/info?user=${user.user_id}`,
        postData,
        config
      )
      // console.log(JSON.parse(data.body))
      setBucketTransactionData(JSON.parse(data.body).message)
      setLoadingTransaction(false)
      setShowAmountTab(false)
      setShowCoinsTab(true)
    } catch (err) {
      console.log(err.message)
    }
  }

  return (
    <div className="flex flex-col w-full text-left">
      {/* <p className="text-xs my-2 font-400">Wallet : $ {includeComma(wallet)}</p> */}
      <p className="text-xs my-2 font-400">Wallet : $ 10000</p>
      <div className="flex justify-center   bg-purple-darkest cursor-pointer border-2 border-purple-400 border-opacity-20 rounded-lg p-4 py-3 text-sm my-2">
        <p>{bucketName}</p>
        {/* <p>$ {includeComma(price)}</p> */}
      </div>

      {showAmountTab && (
        <Fragment>
          <div className="flex justify-between items-center my-2 text-xs w-full  ">
            <label className="flex flex-col flex-1  font-600">
              <div className="mb-3">
                <p className="">Amount</p>
                {/* {userAmount > wallet && (
              <p className="text-red-500 font-400 text-xs">Not enough Balance</p>
            )} */}
              </div>
              <input
                className="p-2 bg-transparent text-white font-600 placeholder-gray-500 border-2 border-purple-400 border-opacity-20 rounded-lg  "
                type="number"
                value={userAmount}
                onChange={(e) => {
                  // setTransactionData({
                  //   ...transactionData,
                  //   quantity: e.target.value
                  //     ? parseFloat((0.99 * parseFloat(e.target.value)) / price)
                  //     : 0,
                  //   platform_charges: e.target.value
                  //     ? parseFloat(0.01 * parseFloat(e.target.value))
                  //     : 0
                  // })
                  setUserAmount(e.target.value ? parseFloat(e.target.value) : 0)
                }}
                //placeholder={price}
                min={0}
                //max={wallet}
                step="0.01"
                style={{ width: '100%' }}
              />
            </label>
          </div>
          <div className="flex justify-between gap-2 my-2 text-gray-600 text-xs  placeholder-gray-400">
            {/* <div className="flex flex-col flex-1  font-600">
            <p className="mb-3 text-white">Quantity</p>

            <div className="p-2 bg-white text-gray-500 font-600 placeholder-gray-100  rounded-lg ">
              <p>{transactionData.quantity.toFixed(3)}</p>
            </div>
          </div> */}
            <div className="flex flex-col flex-1  font-600">
              <p className="mb-3 text-white">Bucket Amount</p>

              <div className="p-2 bg-white text-gray-500 font-600 placeholder-gray-100  rounded-lg ">
                <p>{(0.99 * userAmount).toFixed(3)}</p>
              </div>
            </div>
            <div className="flex flex-col flex-1  font-600">
              <p className="mb-3 text-white">Fee</p>

              <div className="p-2 bg-white text-gray-500 font-600 placeholder-gray-100  rounded-lg ">
                <p>{(0.01 * userAmount).toFixed(3)}</p>
              </div>
            </div>
            <div className="flex flex-col flex-1  font-600">
              <p className="mb-3 text-white">Total Spent</p>

              <div className="p-2 bg-white text-gray-500 font-600 placeholder-gray-100  rounded-lg ">
                <p>{userAmount.toFixed(3)}</p>
              </div>
            </div>
          </div>
        </Fragment>
      )}

      {showCoinsTab && (
        <div className="flex flex-col gap-3 max-h-48 overflow-y-scroll scrollbar-hidden">
          {bucketTransactionData.data.map((singleCoin) => (
            <SingleCoinDetails key={uuid()} coinData={singleCoin} />
          ))}
        </div>
      )}

      <div className="w-full my-2 text-xs">
        <textarea
          onChange={(e) => {
            setBucketNotes(e.target.value)
          }}
          value={bucketNotes}
          className="w-full p-2 rounded-lg font-600 h-20 text-gray-500"
          placeholder="Notes"
        />
      </div>

      {showAmountTab && (
        <button
          onClick={handleBucketAmount}
          className="w-full p-2 my-2 rounded-xl bg-red-dark hover:bg-red-600 transition-all duration-300">
          {loadingTransaction ? 'Processing ...' : 'Buy Bucket'}
        </button>
      )}
      {showCoinsTab && (
        <button
          onClick={handleBuyAsset}
          className="w-full p-2 my-2 rounded-xl bg-red-dark hover:bg-red-600 transition-all duration-300">
          {loadingTransaction ? 'Processing ...' : 'Complete Transaction'}
        </button>
      )}
    </div>
  )
}

export default BuyTab
