import React, { Fragment, useEffect, useState } from 'react'
import { ErrorBoundary } from 'react-error-boundary'
import { Link } from 'react-router-dom'
import { LISTING_PAGE } from '../../../../../navigation/Routes'
import EndPoints from '../../../../../services/api'
import axiosInstance from '../../../../../services/axios'
import ErrorComponent from '../../../error/ErrorComponent'
import ListingColumnChart from '../../../listing/ListingColumnChart'
import ListingTable from '../../../listing/ListingTable'
import ResponsiveTable from '../../../listing/ResponsiveTable'
import Loader from '../../../loader/Loader'

const MarketComponent = () => {
  const [loadingTable, setLoadingTable] = useState(true)
  const [listingData, setListingData] = useState({})
  const { getAllInOne } = EndPoints.listing

  const fetchTableData = async () => {
    try {
      const { data } = await axiosInstance.get(
        `${getAllInOne}?page=${1}&limit=${10}&sort_by=${'marketcap'}&order=${'desc'}`
      )

      console.log(JSON.parse(data.body).message)
      setListingData(JSON.parse(data.body).message)
      setLoadingTable(false)
    } catch (err) {
      console.log(err.message)
    }
  }

  useEffect(() => {
    fetchTableData()
  }, [])
  return (
    <div className="bg-purple-darker rounded-3xl mx-auto w-full  max-w-8xl  flex flex-col p-4 items-center">
      <div className="flex flex-col-reverse lg:flex-row gap-10 items-center">
        <div className="w-full">
          {loadingTable ? (
            <Loader />
          ) : (
            <div className="flex flex-col gap-4">
              <ListingColumnChart cryptoData={listingData} theme={'dark'} mobile />
            </div>
          )}
        </div>
        <div className="flex w-full flex-col gap-12 items-center  p-4 md:p-8">
          <h1 className="text-4xl text-center w-full font-bold ">Crypto Market</h1>
          <p className="leading-6 text-base text-center  ">
            Easy to use research tools to evaluate cryptocurrencies based on their developer
            activity, community team, and liquidity that saves your time, help you act decisively,
            and improve your success rate.
          </p>
          <Link
            to={LISTING_PAGE}
            className="px-4 p-2 text-center text-lg w-full max-w-xs rounded-full bg-blue-500">
            Learn More
          </Link>
        </div>
      </div>
      <div className="w-full flex flex-col gap-4 ">
        {loadingTable ? (
          <Loader />
        ) : (
          <Fragment>
            <ErrorBoundary FallbackComponent={ErrorComponent}>
              <ListingTable
                cryptoData={listingData.slice(0, 5)}
                showPagination={false}
                theme={'dark'}
              />
            </ErrorBoundary>
            <ErrorBoundary FallbackComponent={ErrorComponent}>
              <ResponsiveTable
                cryptoData={listingData.slice(0, 5)}
                showPagination={false}
                theme={'dark'}
              />
            </ErrorBoundary>
          </Fragment>
        )}
        <Link to={LISTING_PAGE} className=" text-center w-full text-sm">
          View More
        </Link>
      </div>
    </div>
  )
}

export default MarketComponent
