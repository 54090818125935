import React from 'react'

const SingleBlogCard = ({ blogData }) => {
  const {
    Coin_Name,
    Coin_id,
    Create_Date,
    Image_Link1,
    Image_link2,
    Keyword,
    Long_Desc,
    Second_Heading,
    Short_Desc,
    Type,
    Userid,
    id
  } = blogData
  return (
    <a
      href="http://www.cryptoresearchfund.com"
      target="blank"
      className="flex flex-col  rounded-lg overflow-hidden bg-white ">
      <img
        src="https://st2.depositphotos.com/4107269/7635/i/450/depositphotos_76358181-stock-photo-messy-workspace-of-freelancer-concept.jpg"
        alt="blog img"
        className="w-full object-cover"
      />
      <div className="flex flex-col gap-2 justify-center p-2 md:p-4  ">
        <p className="text-blue-400 font-600 text-lg">Forbes</p>
        <h2 className="text-lg font-600 ">
          Alchemy, which aims to be the ‘de facto platform’ for developers to build on Web3, is now
          valued at $10.2B
        </h2>
        <p className="text-gray-400 font-600 text-sm leading-relaxed">
          The startup has seen its value surge by a staggering 19.8x since last April.
        </p>
      </div>
    </a>
  )
}

export default SingleBlogCard
