import React, { useState, Fragment } from 'react'
import { MdSettings } from 'react-icons/md'
import { useSelector } from 'react-redux'
import { useHistory } from 'react-router'
import { AUTHENTICATION_PAGE } from '../../../navigation/Routes'
import HeaderLogo from './assets/logo/HeaderLogo'
import ClickWrapper from '../click-wrapper/ClickWrapper'
import EnterContestModal from './EnterContestModal'
import AuthModal from '../auth/AuthModal'

const GameHeader = () => {
  const { loggedIn } = useSelector((state) => state.auth)
  const [showContestModal, setShowContestModal] = useState(false)
  const [showAuthModal, setShowAuthModal] = useState(false)
  const history = useHistory()
  return (
    <div className="p-4  relative">
      <div className="flex flex-col gap-2 justify-center items-center  py-4 ">
        <div className="w-full max-w-xs my-4 grid place-items-center transform translate-x-3 ">
          {!loggedIn && (
            <lottie-player
              src="https://assets9.lottiefiles.com/packages/lf20_V53nC8.json"
              background="transparent"
              speed="1"
              style={{ width: '100%', objectFit: 'contain' }}
              loop
              autoplay
            />
          )}
        </div>
        <div>
          {!loggedIn ? (
            <Fragment>
              <div className="flex flex-col justify-center items-center  relative">
                <p className="my-4 text-center">
                  Log in to participate in Gamified Mock Crypto Trading Competition and get ranked
                  according to your performance.{' '}
                </p>

                <button
                  onClick={() => setShowAuthModal(true)}
                  className="p-2 w-28 bg-red-500 uppercase text-sm font-500 ">
                  Log In
                </button>
                {showAuthModal && (
                  <div className="fixed left-0 right-0 top-0 h-screen w-screen z-10 backdrop-filter backdrop-blur-sm grid place-items-center">
                    <div className="z-50">
                      <ClickWrapper func={() => setShowAuthModal(false)}>
                        <AuthModal close={() => setShowAuthModal(false)} />
                      </ClickWrapper>
                    </div>
                  </div>
                )}
              </div>
            </Fragment>
          ) : (
            <Fragment>
              <div className="grid place-items-center mx-3 ">
                <button
                  onClick={() => setShowContestModal(true)}
                  className="bg-blue-500 px-3 py-2  hover:bg-transparent border-2 uppercase font-600  border-blue-500 transition-all duration-300  text-xs">
                  Enter Contest
                </button>
              </div>
            </Fragment>
          )}
        </div>

        {/* <div className="hidden lg:flex flex-col justify-between">
          <HeaderLogo />
        </div> */}
      </div>

      {showContestModal && (
        <div className=" grid place-items-center fixed inset-0  backdrop-filter backdrop-blur-sm z-20  ">
          <div className="w-full  m-auto  z-10 p-4 py-8">
            <div className="w-full max-w-lg m-auto z-50">
              <ClickWrapper func={() => setShowContestModal(false)}>
                <EnterContestModal close={() => setShowContestModal(false)} />
              </ClickWrapper>
            </div>
          </div>
        </div>
      )}
    </div>
  )
}

export default GameHeader
