import React from 'react'
import { useHistory } from 'react-router-dom'

const SingleHomeBucket = ({ data, id }) => {
  const history = useHistory()

  const {
    crf_score,
    name,
    sector,
    short_desc,
    status,
    type,
    return_1year,
    return_24hrs,
    return_30days
  } = data

  return (
    <div onClick={() => history.push(`/bucket/${name}/${id}`)}>
      <div className="flex flex-col sm:flex-row justify-between  gap-6 bg-purple-darkest p-5 rounded-2xl">
        <div className="flex-1 flex flex-col gap-3 ">
          <h3 className="text-lg font-600 flex gap-3 items-center">
            {name}{' '}
            <span
              className={`p-1 rounded-full text-xs bg-${
                status.toLowerCase() === 'active' ? 'green' : 'red'
              }-500`}></span>
          </h3>

          <p className="text-xs max-w-xs">{short_desc}</p>
        </div>
        <div className="flex flex-col gap-3">
          <div className="flex-1   flex  justify-evenly sm:justify-end items-end text-right gap-3 text-xs">
            <div className="flex w-full flex-col gap-2">
              <p>1 D Return</p>
              <p
                className={`p-2   rounded-xl bg-${
                  return_24hrs >= 0 ? 'green' : 'red'
                }-500 text-sm font-600 grid place-items-center `}>
                {return_24hrs.toFixed(2)}%
              </p>
            </div>
            <div className="flex  w-full flex-col gap-2">
              <p>1 M Return</p>
              <p
                className={`p-2  rounded-xl bg-${
                  return_30days >= 0 ? 'green' : 'red'
                }-500 text-sm font-600 grid place-items-center `}>
                {return_30days.toFixed(2)}%
              </p>
            </div>
            <div className="flex  w-full flex-col gap-2">
              <p>1 Y Return</p>
              <p
                className={`p-2  rounded-xl bg-${
                  return_1year >= 0 ? 'green' : 'red'
                }-500 text-sm font-600 grid place-items-center `}>
                {return_1year.toFixed(2)}%
              </p>
            </div>
          </div>

          <div className="flex-1 flex flex-col gap-3 text-xs">
            <div className="flex justify-evenly items-center gap-3">
              <div className="flex flex-col items-center gap-2">
                <h5 className="text-xs">Type</h5>
                <p>{type}</p>
              </div>
              <div className="flex flex-col items-center gap-2">
                <h5 className="text-xs">Sector</h5>
                <p>{sector}</p>
              </div>
              <div className="flex flex-col items-center gap-2">
                <h5 className="text-xs">CRF Score</h5>
                <p>{crf_score}</p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default SingleHomeBucket
