import {
  LISTING_PAGE,
  NEWS_PAGE,
  PORTFOLIO_PAGE,
  RECOMMENDATION_PAGE,
  STATIC_PORTFOLIO_PAGE,
  WATCH_LIST_PAGE,
  GAME_PAGE,
  BUCKET_PAGE,
  SUBSCRIPTION_PAGE,
  ANALYST_PAGE,
  HOME_PAGE,
  TRADE_SIMULATOR_PAGE,
  UNTOLD_STORY_PAGE,
  ALL_CONTEST_PAGE,
  DASHBOARD_PAGE,
  BLOG_PAGE,
  BOT_PAGE,
  BOT_RECOMMENDATION_PAGE,
  CONTACT_US_PAGE
} from '../../../navigation/Routes'

export const headerMenu = [
  {
    title: 'Home',
    target: HOME_PAGE,
    child: [],
    loggedIn: false
  },
  {
    title: 'Market',
    target: LISTING_PAGE,
    child: [],
    loggedIn: true
  },
  {
    title: '10X Coins',
    target: ANALYST_PAGE,
    child: [],
    loggedIn: true
  },
  // {
  //   title: 'Play & Win',
  //   target: GAME_PAGE,
  //   child: [],
  //   loggedIn: true
  // },
  {
    title: 'Dashboard',
    target: DASHBOARD_PAGE,
    child: [],
    loggedIn: true
  },
  {
    title: 'Algo Trading',
    target: '#',
    loggedIn: true,
    child: [
      {
        title: 'Recommendation',
        target: RECOMMENDATION_PAGE,
        child: [],
        loggedIn: true
      },
      {
        title: 'Algo Signals',
        target: BOT_RECOMMENDATION_PAGE,
        loggedIn: true
      },
      {
        title: 'Algo Performance',
        target: BOT_PAGE,
        loggedIn: true
      }
    ]
  },

  {
    title: 'Trade Simulator',
    target: TRADE_SIMULATOR_PAGE,
    loggedIn: true,
    child: [
      {
        title: 'Play & Win',
        target: GAME_PAGE,
        loggedIn: true
      },
      {
        title: 'Contest',
        target: ALL_CONTEST_PAGE,
        loggedIn: true
      }
    ]
  },
  {
    title: 'CRF Fund',
    target: STATIC_PORTFOLIO_PAGE,
    child: [
      {
        title: 'Buckets',
        target: BUCKET_PAGE,
        child: [],
        loggedIn: true
      }
    ],
    loggedIn: true
  },
  {
    title: 'Crypto Pulse',
    target: NEWS_PAGE,
    child: [
      {
        title: 'Untold Stories',
        target: UNTOLD_STORY_PAGE,
        child: [],
        loggedIn: true
      }
    ],
    loggedIn: true
  },

  {
    title: 'Watchlist',
    target: WATCH_LIST_PAGE,
    child: [],
    loggedIn: true
  },

  {
    title: 'Contact Us',
    target: CONTACT_US_PAGE,
    child: [
      {
        title: 'CRF Research',
        target: SUBSCRIPTION_PAGE,
        child: [],
        loggedIn: false
      }
    ],
    loggedIn: false
  },

  {
    title: 'Portfolio',
    target: PORTFOLIO_PAGE,
    child: [],
    loggedIn: true
  }
]
