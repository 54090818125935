import React from 'react'
import { dateTimeConversion } from '../../../../services/globalFunctions'

const SingleNewsCard = ({ news }) => {
  const date = dateTimeConversion(news?.publishedAt)

  return (
    <a href={news.url} target="blank" className="flex flex-col  items-start p-1   ">
      <img
        className="w-full h-1/2 object-cover max-h-52 sm:max-h-96  lg:max-h-52"
        src={news?.image_url}
        alt={''}
      />

      <h2 className="my-3  text-lg leading-snug font-500">{news?.title}</h2>
      <div className="pb-3 mt-3 border-b-1 border-gray-500">
        <p className=" text-xs font-500 max-w-max pr-1 uppercase">by {news.source}</p>

        <p className="text-xs mt-2">{date}</p>
      </div>

      <p className="my-5 text-xs font-400">
        {news.description}
        {'...'}
        <span className="text-blue-600 ml-2 font-500">read more</span>
      </p>
    </a>
  )
}

export default SingleNewsCard
