import axios from 'axios'
import React, { useEffect, useState } from 'react'
import EndPoints from '../../../../services/api'
import { includeComma } from '../../../../services/globalFunctions'
import DataLoader from '../../loader/DataLoader'

const SingleCoinDetails = ({ coinData }) => {
  const [loading, setLoading] = useState(true)
  const [staticData, setStaticData] = useState({})
  const { allocation, alloted_price, coin_id: assetId, current_price, quantity } = coinData
  const { getStaticData } = EndPoints.watchList

  const fetchSingleWatchList = async () => {
    try {
      const res = await Promise.all([axios.get(`${getStaticData}?coinid=${assetId}`)])

      const singleWatchListData = res.map((res) => JSON.parse(res.data.body))
      const { symbol, logo, coinname } = singleWatchListData[0][`${assetId}`]

      setStaticData({
        coinname,
        symbol,
        logo
      })
      setLoading(false)
    } catch (err) {
      console.log(err.message)
    }
  }

  useEffect(() => {
    fetchSingleWatchList()
  }, [])
  return (
    <div className="p-2 flex justify-between   text-xs items-center bg-transparent text-white font-600 placeholder-gray-500 border-2 border-purple-400 border-opacity-20 rounded-lg  ">
      {loading ? (
        <DataLoader />
      ) : (
        <div className="flex gap-2 items-center ">
          <img
            className="w-4 h-4 object-contain"
            src={staticData.logo}
            alt={staticData.coinname}
          />
          <p>
            {staticData.coinname} ({staticData.symbol})
          </p>
        </div>
      )}
      {!loading && (
        <div className="flex gap-2 items-center">
          <p>{includeComma(quantity.toFixed(3))} units</p>
          <p>{includeComma(alloted_price.toFixed(2))} $ </p>
        </div>
      )}
    </div>
  )
}

export default SingleCoinDetails
